import React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { DrawRequestRow } from "./DrawRequestRow";
import { Controller, useFormContext } from "react-hook-form";
import { Badge, IconButton } from "@mui/material";
import { usePermissions } from "@lib/hooks";
import { Task } from "../../../Models";
import { FileWithId, PersistentProjectFile } from "@lib/APITypes";

import { ImageGallery } from "../../../Components/ImageGallery";
import { useEntityFeatures } from "@lib/hooks/useEntityFeatures";
import { LocalTaskFile } from "./LocalTaskFile";
import { AttachedFilesIcon } from "@lib/uikit";

const PREFIX = "DrawRequestValuesEditor";

const classes = {
  root: `${PREFIX}-root`,
  tableContainer: `${PREFIX}-tableContainer`,
  table: `${PREFIX}-table`,
  tableBody: `${PREFIX}-tableBody`,
};

const Root = styled("div")(({ theme: { spacing, palette } }) => ({
  [`&.${classes.root}`]: {
    width: "100%",
  },

  [`& .${classes.tableContainer}`]: {
    maxHeight: 660,
    // margin: spacing(1),
  },

  [`& .${classes.table}`]: {
    // minWidth: 650,
    maxHeight: "40vh",
  },

  [`& .${classes.tableBody}`]: {
    "& .MuiTableCell-root": {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
}));

export type TaskCompleteRequest = (
  taskId: Task["id"],
  completed: boolean
) => Promise<unknown>; // TODO: Promise<BackendGetTaskResponse>; (after backend starts returning it)
export type TaskEditRequest = (taskId: Task["id"]) => void;
export type TaskDeleteRequest = (taskId: Task["id"]) => void;

export function DrawRequestValuesEditor({
  putMode,
  onTaskCompleteRequested,
  onTaskDeleteRequested,
  onTaskEditRequested,
  onTaskViewRequested,
  tasks,
  initialAmounts,
  retention, // TODO: project?
  rowsPerPage: initialRowsPerPage,
  files,
  onDeleteFile,
}: {
  putMode: boolean;
  onTaskCompleteRequested?: TaskCompleteRequest;
  onTaskDeleteRequested?: (taskId: Task["id"]) => void;
  onTaskEditRequested?: TaskEditRequest;
  onTaskViewRequested?: TaskEditRequest;
  tasks: Readonly<Task>[];
  initialAmounts?: Readonly<number[]>;
  retention: number;
  rowsPerPage?: number;
  files?: (PersistentProjectFile | LocalTaskFile)[];
  onDeleteFile: (index: number) => Promise<void>;
}): JSX.Element {
  const { control } = useFormContext();

  const {
    perLineItemAttachments: canSeePerLineItemAttachments,
    restrictInspectors,
  } = useEntityFeatures();
  const { canCreateDrawRequests } = usePermissions();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(
    initialRowsPerPage || 100
  );
  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: { target: { value: string } }) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const nTasksNotDisplayedBefore = page * rowsPerPage;
  const tasksThisPage = tasks.slice(
    nTasksNotDisplayedBefore,
    nTasksNotDisplayedBefore + rowsPerPage
  );

  const [lightboxFiles, setLightboxFiles] = React.useState<
    (PersistentProjectFile | LocalTaskFile)[]
  >([]);
  React.useEffect(() => {
    console.log("lightboxFiles =>", lightboxFiles);
  }, [lightboxFiles]);
  const [lightboxOpen, setLightboxOpen] = React.useState(false);
  const handleShowFiles = (
    files: (PersistentProjectFile | LocalTaskFile)[]
  ) => {
    setLightboxFiles(files);
    setLightboxOpen(true);
  };

  return (
    <Root className={classes.root}>
      {lightboxOpen ? (
        <ImageGallery
          files={lightboxFiles}
          open={lightboxOpen}
          onClose={() => setLightboxOpen(false)}
          onDelete={({ index }) => onDeleteFile(index)}
        />
      ) : null}
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table stickyHeader aria-label="draw table" className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Task</TableCell>
              <TableCell>Value</TableCell>
              {canCreateDrawRequests({ restrictInspectors }) ? (
                <>
                  <TableCell>Requested</TableCell>
                  {initialAmounts ? <TableCell>Funding</TableCell> : null}
                </>
              ) : null}
              <TableCell>{putMode ? "Outstanding" : "Remaining"}</TableCell>
              <TableCell>Spent</TableCell>
              {canSeePerLineItemAttachments && files ? (
                <TableCell>Files</TableCell>
              ) : null}
              {/* {onTaskCompleteRequested ||
              onTaskEditRequested ||
              onTaskDeleteRequested ? (
                <TableCell>More</TableCell>
              ) : null} */}
            </TableRow>
          </TableHead>
          <TableBody className={classes.tableBody}>
            {tasksThisPage.map((task, idx) => (
              <Controller
                // name={`rows[${
                //   idx + nTasksNotDisplayedBefore
                // }].requestedAmount`}
                name={`amounts.${nTasksNotDisplayedBefore + idx}` as const}
                control={control}
                key={nTasksNotDisplayedBefore + idx}
                render={({ field: { value, onChange, ref, ...rest } }) => (
                  <DrawRequestRow
                    {...rest}
                    initialAmount={
                      initialAmounts
                        ? initialAmounts[nTasksNotDisplayedBefore + idx] || 0
                        : undefined
                    }
                    amountRequested={value}
                    onAmountRequestedChange={(newValue) =>
                      onChange(newValue === undefined ? 0 : newValue)
                    }
                    key={nTasksNotDisplayedBefore + idx}
                    task={task}
                    retention={retention}
                    putMode={Boolean(putMode)}
                    onCompleteRequested={
                      onTaskCompleteRequested
                        ? (completed: boolean) =>
                            onTaskCompleteRequested(task.id, completed).then(
                              () => ({ ...task, completed })
                            ) // TODO: backend should return updated task, and we should pass that
                        : undefined
                    }
                    onEditRequested={
                      onTaskEditRequested
                        ? () => onTaskEditRequested(task.id)
                        : undefined
                    }
                    onTitleClicked={
                      onTaskViewRequested
                        ? () => onTaskViewRequested(task.id)
                        : undefined
                    }
                    onDeleteRequested={
                      onTaskDeleteRequested
                        ? () => onTaskDeleteRequested(task.id)
                        : undefined
                    }
                    inputRef={ref}
                    renderFileAffordance={
                      canSeePerLineItemAttachments && files
                        ? () => {
                            const filesThisLine = files.filter(
                              (f) =>
                                (f as LocalTaskFile).id === task.id ||
                                (FileWithId.is(f) && f.task_id === task.id)
                            );
                            return filesThisLine.length > 0 ? (
                              <IconButton
                                onClick={() => handleShowFiles(filesThisLine)}
                                size="large"
                              >
                                <Badge
                                  badgeContent={filesThisLine.length}
                                  color="primary"
                                >
                                  <AttachedFilesIcon />
                                </Badge>
                              </IconButton>
                            ) : undefined;
                          }
                        : undefined
                    }
                  />
                )}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={tasks.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Root>
  );
}
