import useSWR from "swr";
import { useBackend } from ".";
import {
  BackendGetProjectFilesResponse,
  BackendUserResponse,
} from "../APITypes";
import { FetchError } from "../API";
import {
  InvoiceId,
  Position,
  ProjectId,
  StoredFile,
  TaskId,
} from "@project-centerline/project-centerline-api-types";
import { useAuth } from "@lib/hooks/useAuth";
import { throwIfNot } from "../util/throwIfNot";
import { logAndThrow } from "../ErrorLogging";
import { SWRKeys } from "../swrKeys";

interface FileInfo {
  storedFile: StoredFile;
  allowedUsers?: Readonly<BackendUserResponse[]>;
  email: string;
  tags?: string[];
  invoiceId?: InvoiceId;
  taskId?: TaskId;
  deviceLocation?: Position;
}

export function useProjectFiles(project?: { projectId: string }): {
  project: {
    files?: Readonly<BackendGetProjectFilesResponse>;
    addFile: (file: FileInfo) => Promise<void>;
    addFiles: (files: FileInfo[]) => Promise<void>;
    removeFile: (arg: { file_id: string }) => Promise<void>;
  };
  errors?: {
    files?: Readonly<FetchError>;
  };
} {
  const { projectId } = project ?? {};
  const { currentUserEmail: email } = useAuth();
  const {
    getProjectFiles,
    addFileToProject,
    removeFile: backendRemoveFile,
  } = useBackend();

  const {
    data: files,
    error: filesError,
    mutate,
  } = useSWR<BackendGetProjectFilesResponse, FetchError>(
    SWRKeys.project(projectId as ProjectId).files,
    (_url: unknown) =>
      getProjectFiles(
        throwIfNot(email, "email is required"),
        projectId ?? "should never be here; key should have short circuited"
      )
  );

  const addFile = async (fileInfo: FileInfo) => {
    // leaving this commented out because (a) not quite there and b) maybe we want them to wait, i this case.
    //   if (files) {
    //      // update the local data immediately, but disable the revalidation
    //      mutate(`/api/project/${projectId}/files`, files.userFiles.concat([{ ...fileName, project_id: projectId}]), false)
    //   }
    const { allowedUsers, ...rest } = fileInfo;

    if (!projectId) {
      logAndThrow(new Error("Can't addFile without project"));
    }

    // wait for the backend to store
    /* const addedFile = */ await addFileToProject({
      ...rest,
      projectId,
      allowedUsers: allowedUsers || [
        { email: "<currentProjectUsers>" } as BackendUserResponse,
      ],
    });

    // trigger a revalidation (refetch) to make sure our local data is correct
    mutate();

    // return [addedFile];
  };

  /// Someday we might make a bulk API, but at least in the meantime this one mutates as it goes then refreshes at the end
  const addFiles = async (fileInfos: FileInfo[]) => {
    await fileInfos.reduce(async (previousPromise, fileInfo) => {
      await previousPromise;

      const { allowedUsers, ...rest } = fileInfo;

      if (!projectId) {
        logAndThrow(new Error("Can't addFileToProject without project"));
      }
      return addFileToProject({
        ...rest,
        projectId,
        allowedUsers: allowedUsers || [
          { email: "<currentProjectUsers>" } as BackendUserResponse,
        ],
      }).then((newFiles) => {
        /* can't do this yet b/c backend returns "success" instead of JSON: */
        // mutate(newFiles, false);
      });
    }, Promise.resolve());

    // refresh to be sure
    mutate();
  };

  const removeFile = async (file: { file_id: string }) => {
    const { file_id: victimFileId } = file;

    // optimistic update
    mutate(
      {
        userFiles:
          files?.userFiles.filter(({ file_id }) => file_id !== victimFileId) ??
          [],
      },
      false
    );

    // wait for backend to do it
    await backendRemoveFile(file);

    // trigger refetch
    mutate();
  };

  const errors = filesError
    ? {
        errors: {
          files: filesError || undefined,
        },
      }
    : {};

  return {
    project: {
      files,
      addFile,
      addFiles,
      removeFile,
    },
    ...errors,
  };
}
