import { styled } from "@mui/material/styles";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import BuildIcon from "@mui/icons-material/Build";
import PageviewIcon from "@mui/icons-material/Pageview"; //spell-checker:ignore pageview
import React from "react";
import { BackendUserResponse } from "@lib/APITypes";

const PREFIX = "UserSummaryView";

const classes = {
  userHeader: `${PREFIX}-userHeader`,
};

const Root = styled("div")({
  [`&.${classes.userHeader}`]: {
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
});

export function UserSummaryView({
  user,
}: {
  user: BackendUserResponse;
}): JSX.Element {
  return (
    <Root className={classes.userHeader}>
      {user.role === "Lender" || user.role === "LenderAdmin" ? (
        <AccountBalanceIcon />
      ) : null}
      {user.role === "Contractor" || user.role === "Subcontractor" ? (
        <BuildIcon />
      ) : null}
      {user.role === "Inspector" || user.role === "InspectorAdmin" ? (
        <PageviewIcon />
      ) : null}
      {user.role === "Super Admin" ||
      user.role === "Owner" ||
      user.role === "Approver" ? (
        <AccountCircleIcon />
      ) : null}
      <strong style={{ marginLeft: "10px" }}>
        {user.first_name + " " + user.last_name}
      </strong>
    </Root>
  );
}
