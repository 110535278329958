import {
  // StoredFile,
  // LinkedFile,
  ApprovalStatus,
  PersistentProjectFile,
} from "@lib/APITypes";
import { Invoice, PendingInvoice, Task } from "@/Models";
import PendingInvoices from "./PendingInvoices";
import { throwIfNot } from "@lib/util/throwIfNot";
import { Link, useParams } from "react-router-dom";
import { useProjectInvoices } from "@lib/hooks/useProjectInvoices";
import { Typography } from "@mui/material";
import { byCreatedAt, useProjectTasks } from "@lib/hooks/useProjectTasks";
import { useSnackbar } from "notistack";
import { useProjectFiles } from "@lib/hooks/useProjectFiles";
import Center from "@/Components/Center";
import { useProjectName } from "@lib/hooks/misc";
import { ProjectId } from "@project-centerline/project-centerline-api-types";
import React from "react";

import { EditInvoiceLineItemDialog } from "./EditLineItemDialog";
import { logAnomaly, logError } from "@/Lib/ErrorLogging";
import { APIError, uploadSupportingFiles } from "@/Lib/API";
import {
  UploadProgressDialog,
  openAccordingToProgress,
  useProgress,
} from "@/Components/UploadProgressDialog";
import { useAuth } from "@/Lib/hooks/useAuth";
import { logAndThrow } from "@/Lib/ErrorLogging";
import { hasBeenInvoiced } from "./hasBeenInvoiced";

export const PendingDrawsPage = () => {
  const projectId = throwIfNot(
    useParams<"projectId">().projectId,
    "Missing project id"
  ) as ProjectId;

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { currentUserEmail } = useAuth();
  const {
    project: { invoices, invoice: invoiceFns },
  } = useProjectInvoices(projectId, { status: ApprovalStatus.Pending });
  const {
    project: { tasks, editTask },
  } = useProjectTasks(projectId, { sortBy: byCreatedAt });
  const {
    project: { files: { userFiles } = {}, addFiles },
  } = useProjectFiles({ projectId });
  const projectName = useProjectName(projectId);

  const [lineItemBeingEdited, setLineItemBeingEdited] = React.useState<
    { invoice: Invoice; task: Task } | undefined
  >();

  const item = React.useMemo(() => {
    if (lineItemBeingEdited) {
      const { invoice, task } = lineItemBeingEdited;
      const item =
        invoice.drawItems.find(
          ({ task: candidateTask }) => candidateTask?.id === task.id
        ) ||
        (() => {
          logAnomaly(new Error("Invoice needs upgrading"), { invoice });
          return invoice.drawItems.find(({ title }) => title === task.title);
        })();
      if (!item) {
        logError(new Error("can't find line item to edit"), {
          task,
          invoice,
        });
        enqueueSnackbar(
          "Sorry, something went wrong. Can't edit this item right now.",
          { variant: "error" }
        );
      }

      return item;
    }

    return undefined;
  }, [enqueueSnackbar, lineItemBeingEdited]);

  const [progress, setProgress] = useProgress();

  if (tasks?.tasks?.length === 0) {
    return (
      <Center>
        <Typography>
          There is no budget yet.{" "}
          <Link to="../../more/budget" relative="path">
            Create one?
          </Link>
        </Typography>
      </Center>
    );
  }

  if (invoices?.length === 0) {
    return (
      <Center>
        <Typography>
          There are no pending draws.{" "}
          <Link to="../new" relative="path">
            Create one?
          </Link>
        </Typography>
      </Center>
    );
  }

  return (
    <>
      <PendingInvoices
        onEditLineItemRequested={setLineItemBeingEdited}
        onDeleteLineItemFile={function (
          file: PersistentProjectFile
        ): Promise<void> {
          const reason = "New UI can't delete line item files yet";
          enqueueSnackbar({ variant: "error", message: reason });
          return Promise.reject(reason);
        }}
        onDeleteInvoiceFile={() => {
          const reason = "New UI can't delete draw files yet";
          enqueueSnackbar({ variant: "error", message: reason });
          return Promise.reject(reason);
        }}
        invoices={(invoices as Array<PendingInvoice>) ?? []}
        filenameHint={projectName ?? "unknown project"}
        tasks={tasks?.tasks ?? []}
        projectId={projectId}
        onTitleClicked={({ draw, invoice }) => {
          logAnomaly(new Error("How can this happen in the new ui?"), {
            draw,
            invoice,
          });
          setLineItemBeingEdited({ invoice, task: draw.task });
        }}
        files={userFiles ?? []}
      />
      <EditInvoiceLineItemDialog
        projectId={projectId}
        invoice={lineItemBeingEdited?.invoice}
        item={item}
        done={() => {
          setLineItemBeingEdited(undefined);
        }}
        taskTitleEditable={
          !hasBeenInvoiced(invoices)(lineItemBeingEdited?.task)
        }
        onSubmit={async ({ taskData, drawData, dirtyFields, files }) => {
          if (!lineItemBeingEdited) {
            logAndThrow(new Error("finished editing nothing"), {
              lineItemBeingEdited,
              taskData,
              drawData,
              dirtyFields,
              files,
              investigationPromised: true,
            });
          }
          const {
            invoice: { invoice_id: invoiceId, drawItems },
            task: { id: taskId, title, description },
          } = lineItemBeingEdited;
          if (!invoiceFns) {
            logAndThrow(new Error("finished editing with no invoice"), {
              lineItemBeingEdited,
              taskData,
              drawData,
              dirtyFields,
              files,
              investigationPromised: true,
              invoiceFns,
            });
          }

          const { updateDraws } = throwIfNot(
            invoiceFns(invoiceId),
            "How can we not have invoice functions when we are editing?"
          );

          // const draw = taskOrDrawBeingEdited.draw;
          // if (!taskId) {
          //   throw new Error("logic error: no task Id?");
          // }

          // if (
          //   taskOrDrawBeingEdited.inputs.title !== taskData.title &&
          //   hasBeenInvoiced(taskOrDrawBeingEdited.task)
          // ) {
          //   alert(`Task cannot be renamed because it has been invoiced`);
          //   return;
          // }

          const titleDirty = taskData?.title !== title;
          const descDirty = taskData?.description !== description;

          if (titleDirty || descDirty) {
            const taskUploadSnack = enqueueSnackbar({
              message: "Updating task...",
              variant: "info",
            });
            await editTask(taskId, {
              title,
              description,
            })
              .catch((err) => {
                logError(new Error("Error editing task (PendingDrawsPage)"), {
                  titleDirty,
                  descDirty,
                  title,
                  description,
                  taskId,
                  err,
                });
                enqueueSnackbar({
                  message: "Error editing task",
                  variant: "error",
                });
                throw err;
              })
              .finally(() => closeSnackbar(taskUploadSnack));
          }

          //   const taskUpdates =(["description", "title"] as keyof EditTaskFormProps["taskData"]).filter(prop => dirtyFields.includes(prop)).map(prop => [prop, taskData[prop]]))
          //   if (dirtyFields.includes("description") || dirtyFields.includes("title")) {
          // editTask(lineItemBeingEdited.task, {
          //       ...(lineItemBeingEdited?.task.title !== )
          //     })
          //   }

          try {
            // if (draw) {
            // setLoading("files");
            const uploadedFiles = await uploadSupportingFiles({
              files,
              prefix: `${projectId}/t/${taskId}${
                invoiceId ? `/i/${invoiceId}` : ""
              }`,
              progress: setProgress,
            });
            // setLoading("other stuff");

            await addFiles(
              uploadedFiles.map((storedFile) => ({
                storedFile,
                email: throwIfNot(
                  currentUserEmail,
                  "should never get here without a user"
                ),
                invoiceId,
                taskId,
              }))
            );
            // } else {
            //   setIngredientFiles((existing) =>
            //     existing.concat(
            //       files.map((f) => ({
            //         title: f.name as NonEmptyString,
            //         href: URL.createObjectURL(f) as NonEmptyString,
            //         id: taskId,
            //         file: f,
            //       }))
            //     )
            //   );
            // }

            // if (draw) {
            if (drawData && dirtyFields.includes("amountRequested")) {
              // const invoice = draw.invoice;
              await updateDraws(
                drawItems.map((existing) =>
                  taskId === existing.task?.id
                    ? { ...existing, amount: drawData.amountRequested }
                    : existing
                )
              );
            }
            // } else {
            //   if (dirtyFields.includes("completed")) {
            //     await completeTask(taskId, taskData.completed);
            //   }
            //   if (
            //     dirtyFields.some(
            //       (field) =>
            //         field !== "completed" && field !== "amountRequested"
            //     )
            //   ) {
            //     await updateTask(taskId, taskData);
            //   }
            // }
            setLineItemBeingEdited(undefined);
          } catch (error) {
            // we don't really expect 304 NOT_MODIFIED, but we don't prevent useless requests
            if ((error as APIError).response?.status !== 304) {
              logError(error);
              enqueueSnackbar(`Could not edit draw`, {
                variant: "error",
              });
            }
          } finally {
            setProgress(undefined);
          }
        }}
      />
      <UploadProgressDialog
        progress={progress}
        open={openAccordingToProgress(progress)}
      />
    </>
  );
};

export default PendingDrawsPage;
