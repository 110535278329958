import { Storage } from "aws-amplify";
import React from "react";
import { useLocation } from "react-router-dom";
import { CircularProgress, Typography } from "@mui/material";

export default function FileRedirector(): JSX.Element {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const storageKey = searchParams.get("storageKey");
  const decoded = storageKey && decodeURIComponent(storageKey);

  const [url, setUrl] = React.useState<string | undefined>(undefined);

  React.useEffect(() => {
    let stillMounted = true;
    if (decoded) {
      Storage.get(decoded).then((preSigned) => {
        if (stillMounted && typeof preSigned === "string") {
          setUrl(preSigned);
          window.location.href = preSigned;
        }
      });
    }

    return () => {
      stillMounted = false;
    };
  }, [decoded]);

  return url ? (
    <Typography align="center">
      If you are not redirected, please <a href={url}>click here</a> to continue
    </Typography>
  ) : (
    <CircularProgress />
  );
}
