import { useContext, createContext } from "react";

export type AdminData = Record<string, unknown>;

type AdminChildren = Record<string, React.ReactNode>;

type AdminContextType = {
  adminData: AdminData;
  adminChildren: AdminChildren;
  setAdminData: (updater: (prevState: AdminData) => AdminData) => void;
  setAdminChildren: (
    updater: (prevState: AdminChildren) => AdminChildren
  ) => void;
};

export const AdminContext = createContext<AdminContextType>({
  adminData: {},
  adminChildren: {},
  setAdminData: () => {
    throw new Error("Uninitialized");
  },
  setAdminChildren: () => {
    throw new Error("Uninitialized");
  },
});

export function useAdminData(): AdminContextType {
  return useContext(AdminContext);
}
