import * as Sentry from "@sentry/react";
import React, { useState, useEffect } from "react";
import { Auth, Signer } from "aws-amplify";
import LoggedInHeader from "./Components/LoggedInHeader";
import LoggedOutHeader from "./Components/LoggedOutHeader";
import { AppContext, Notification } from "@lib/UserContext";
import { querystring } from "@lib/querystring";
import "./App.css";
import { useHotkeys } from "react-hotkeys-hook";
import { Role } from "@lib/roles";
import { Button, StyledEngineProvider } from "@mui/material";
import { projectCenterlineTheme } from "./projectCenterlineTheme";
import { BackendContext, FeaturesProvider } from "@lib/hooks";
import APIs, { setCallerEmail } from "@lib/API";
import { IntlProvider } from "react-intl";
import { shouldPolyfill } from "@formatjs/intl-numberformat/should-polyfill";
import config from "./config";
import URL from "url-parse";
import { MixpanelProvider } from "react-mixpanel-browser";
import { SnackbarKey, SnackbarProvider } from "notistack";
import { SWRConfig, useSWRConfig } from "swr";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { ThemeProvider } from "@mui/material/styles";
import { useMatches, useNavigate } from "react-router-dom";
import { PositionProvider } from "@lib/hooks/usePosition";
import { useAuth } from "@lib/hooks/useAuth";
import { useGetUserRole } from "@/Lib/hooks/useGetUserRole";
// import usePrevious from "@lib/hooks/usePrevious";
import { MaterialUIControllerProvider } from "@tim/context";
import { useNewUIHook } from "./Lib/hooks/useNewUI";
import { AdminContext } from "@lib/AdminContext";

async function polyfill(locale: string) {
  if (shouldPolyfill()) {
    // Load the polyfill 1st BEFORE loading data
    await import("@formatjs/intl-numberformat/polyfill");
  }
  if (
    (Intl.NumberFormat as typeof Intl.NumberFormat & { polyfilled: boolean })
      .polyfilled
  ) {
    switch (locale) {
      default:
        (await import("@formatjs/intl-numberformat/locale-data/en")) as unknown;
        break;
      // case "fr":
      //   (await import("@formatjs/intl-numberformat/locale-data/fr")) as unknown;
      //   break;
    }
  }
}

const signUrl = async (s: string) => {
  const url = new URL(s, true);

  const credentials = Auth.essentialCredentials(
    await Auth.currentCredentials()
  );
  const user = await Auth.currentAuthenticatedUser();

  const idJwt = user.signInUserSession.idToken.jwtToken;
  const accessJwt = user.signInUserSession.accessToken.jwtToken;

  url.query.Authorization = accessJwt;
  url.query.id = idJwt;

  const {
    accessKeyId: access_key,
    secretAccessKey: secret_key,
    sessionToken: session_token,
  } = credentials;

  const signedURL = Signer.signUrl(
    url.toString(),
    {
      access_key,
      secret_key,
      session_token,
    },
    { region: config.apiGateway.REGION, service: "execute-api" },
    60 * 60 // 1 hour
  );

  return signedURL;
};

const getPresignedWebsocketUrl = async () => {
  const signedURL = await signUrl(config.apiGateway.WEBSOCKET_URL);
  return signedURL;
};

const swrConfig = {
  focusThrottleInterval: 15 * 1000,
};

function App({ root }: { root: React.ReactElement }): JSX.Element {
  const appMatches = useMatches();
  // console.log({ appMatches });
  const newUIBecauseRoute = appMatches
    .map(
      ({ handle }) =>
        (handle as unknown as { newUIBecauseRoute: boolean } | undefined)
          ?.newUIBecauseRoute
    )
    ?.find((uiChangeRequested) => uiChangeRequested !== undefined)
    ?.valueOf();
  const { useNewUI, setUseNewUI } = useNewUIHook();
  React.useEffect(() => {
    // console.log("setUseNewUI effect", { newUIBecauseRoute });
    if (setUseNewUI && newUIBecauseRoute !== undefined) {
      setUseNewUI(newUIBecauseRoute);
    }
  }, [newUIBecauseRoute, setUseNewUI]);

  const navigate = useNavigate();
  const [notifications, setNotifications] = useState<Notification[]>([]); // TODO: mst
  const { user } = useGetUserRole();
  const role =
    user?.role === undefined ? ("**uninitialized" as Role) : user.role; // TODO: let role be undefined
  // const [role, setRole] = useState<Role>("**uninitialized**" as Role);
  // const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [showAdmin, requestAdminView] = React.useState(false);
  const [backendVersion, setBackendVersion] = React.useState<
    string | undefined
  >();
  const [headerContent, setHeaderContent] = React.useState<
    React.ReactNode | undefined
  >();

  const theme = React.useMemo(
    () => projectCenterlineTheme(useNewUI),
    [useNewUI]
  );
  const { currentUserEmail, isValidating, isAuthenticated, signOut } =
    useAuth();

  React.useEffect(() => {
    // HACK tell the backend to send a header with our email if local backend.
    // usual and better, is that we get identity from lambda authenticator
    // Could probably do this cleaner by bottlenecking all API access through a hook
    setCallerEmail(currentUserEmail);
  }, [currentUserEmail]);

  // if you log out / change user, clear cache
  const { cache } = useSWRConfig();
  useEffect(() => {
    if (!isValidating && !isAuthenticated) {
      if (!(cache instanceof Map)) {
        throw new Error("this not gonna work");
      }

      cache.clear();
    }
  }, [cache, isAuthenticated, isValidating]);

  useEffect(() => {
    async function onLoad() {
      await polyfill("en-US");
    }

    onLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const prevRole = usePrevious(role);
  useEffect(() => {
    // if (prevRole === "*uninitialized*")
    if (role && role !== ("**uninitialized**" as Role)) {
      const redirectTo = querystring("redirect");
      if (redirectTo) {
        navigate(redirectTo);
      }
    }
  }, [navigate, role]);

  async function handleLogout() {
    await signOut();
    navigate("/");
  }

  useHotkeys(
    "ctrl+a,cmd+a",
    () => {
      requestAdminView((showAdmin) => !showAdmin);
    },
    { enabled: role === "Super Admin" },
    [role]
  );

  // add action to all snack bars
  const snackStackRef = React.createRef<SnackbarProvider>();
  const onClickDismiss = (key: SnackbarKey) => () => {
    snackStackRef.current?.closeSnackbar?.(key);
  };

  const appCtx = React.useMemo(() => {
    return {
      role,
      notifications,
      setNotifications,
      showAdmin,
      requestAdminView,
      backendVersion,
      setBackendVersion,
      headerContent,
      setHeaderContent,
      signUrl,
      getPresignedWebsocketUrl,
    };
  }, [backendVersion, headerContent, notifications, role, showAdmin]);

  const [adminData, setAdminData] = React.useState<Record<string, unknown>>({});
  const [adminChildren, setAdminChildren] = React.useState<
    Record<string, React.ReactNode>
  >({});

  return (
    // <React.StrictMode>
    // <MSTProvider value={rootStore}>
    <AdminContext.Provider
      value={{ adminData, adminChildren, setAdminData, setAdminChildren }}
    >
      <SWRConfig value={swrConfig}>
        <MaterialUIControllerProvider>
          <MixpanelProvider config={{ debug: config.nonProdEnv }}>
            <StyledEngineProvider injectFirst>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <ThemeProvider theme={theme}>
                  <IntlProvider defaultLocale="en-US" locale="en-US">
                    <FeaturesProvider>
                      <BackendContext.Provider value={APIs}>
                        <SnackbarProvider
                          anchorOrigin={{
                            horizontal: "center",
                            vertical: "bottom",
                          }}
                          preventDuplicate={true}
                          ref={snackStackRef}
                          action={(key) => (
                            <Button onClick={onClickDismiss(key)}>
                              Dismiss
                            </Button>
                          )}
                        >
                          <AppContext.Provider value={appCtx}>
                            <PositionProvider>
                              <div>
                                {isAuthenticated ? (
                                  useNewUI ? null : (
                                    <LoggedInHeader logout={handleLogout}>
                                      {headerContent}
                                    </LoggedInHeader>
                                  )
                                ) : (
                                  <LoggedOutHeader />
                                )}

                                <div id="ui-container">{root}</div>
                              </div>
                            </PositionProvider>
                          </AppContext.Provider>
                        </SnackbarProvider>
                      </BackendContext.Provider>
                    </FeaturesProvider>
                  </IntlProvider>
                </ThemeProvider>
              </LocalizationProvider>
            </StyledEngineProvider>
          </MixpanelProvider>
        </MaterialUIControllerProvider>
      </SWRConfig>
    </AdminContext.Provider>
    // </MSTProvider>
    // </React.StrictMode>
  );
}

export default Sentry.withProfiler(App);
