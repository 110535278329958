import { types } from "mobx-state-tree";

/**
 * Stuff that is persisted in local storage
 */
export const DeviceSettings = types
  .model({
    useNewUI: types.boolean,
  })
  .actions((self) => ({
    setUseNewUI(newUI: boolean) {
      self.useNewUI = newUI;
    },
  }));
