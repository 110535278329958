import { useAppContext } from "../UserContext";
import { throwIfNot } from "../util/throwIfNot";
import { useAnalytics } from "./useAnalytics";
import { useAuth } from "./useAuth";
import { useBackend } from "./useBackend";

/**
 * This is the preferred way to create a project in 2024. It requires fewer inputs and calls mixpanel
 */
export const useAddProject = () => {
  const { addProject } = useBackend();
  const analytics = useAnalytics();
  const { role } = useAppContext();
  const { currentUserEmail } = useAuth();
  const user = throwIfNot(currentUserEmail, "email is required");

  return {
    addProject: ({
      projectData,
      uploadedFiles,
    }: Omit<Parameters<typeof addProject>[0], "user" | "role">) =>
      addProject({ projectData, uploadedFiles, role, user }).then(
        (projectId) => {
          analytics.track("project:created", {
            projectId,
            name: projectData.project.address,
          });
          return projectId;
        }
      ),
  };
};
