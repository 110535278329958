import {
  Invoice,
  // PendingInvoice,
  Task,
} from "../Models";
import { pipe, flow } from "fp-ts/lib/function";
import {
  drawForTask,
  extractAmount,
} from "../Pages/ProjectView/Invoice/taskUtils";
import * as A from "fp-ts/lib/Array";

export function initialRequestFetcher(
  invoicesById: Record<Invoice["invoice_id"], Invoice>
) {
  return (invoiceId: Invoice["invoice_id"]) => {
    const { drawItems, ancestors: ancestorIds } = invoicesById[invoiceId];
    const ancestorDraws = (ancestorIds || []).map((ancestorId) => {
      const ancestor = invoicesById[ancestorId];
      return ancestor.drawItems;
    });

    // ancestors is assumed to be oldest first, so find the first
    // if no ancestor has it we will fall back to current
    const searchDraws = ancestorDraws.concat([drawItems]);

    return (task: Readonly<Pick<Task, "id" | "title">>) => {
      // if at least one has a task, we will not try to match on title.
      // https://projectcenterline.slack.com/archives/G01D11FMKB8/p1695340916470799
      const modern = drawItems.some(({ task }) => task);
      return pipe(
        searchDraws,
        A.findFirstMap(flow(drawForTask(task, { matchTitles: !modern }))),
        // should always match because we backstopped with current, but...
        extractAmount(() => 0)
      );
    };
  };
}
