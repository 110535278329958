import React from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { CircularProgress } from "@mui/material";
import { Role } from "@lib/roles";
import PersonForm, {
  commonLayouts,
  PossibleFields,
} from "../../../Forms/PersonForm";
import { AllPersonInputs, AllPersonInputsSchema } from "@lib/APITypes";
import { logError } from "@lib/ErrorLogging";
import { useSnackbar } from "notistack";
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useFocus } from "@lib/hooks";
import { useProjectUsers } from "@lib/hooks/useProjectUsers";
import { roleAttributes } from "@lib/RoleDisplay";

const PREFIX = "EditUser";

const classes = {
  wrapper: `${PREFIX}-wrapper`,
  userHeader: `${PREFIX}-userHeader`,
};

const StyledDialog = styled(Dialog)(({ theme: { spacing } }) => ({
  [`& .${classes.wrapper}`]: {
    marginTop: "20px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  [`& .${classes.userHeader}`]: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    "& *": {
      paddingLeft: spacing(1),
      paddingRight: spacing(1),
    },
  },
}));

const roleProps: Partial<
  Record<
    Role,
    {
      fields?: PossibleFields[];
    }
  >
> = {
  Lender: { fields: commonLayouts.companyFirst },
  LenderAdmin: { fields: commonLayouts.companyFirst },
  Contractor: { fields: commonLayouts.companyFirst },
  Subcontractor: { fields: commonLayouts.companyFirst },
  InspectorAdmin: { fields: commonLayouts.companyLast },
  Inspector: { fields: commonLayouts.companyLast },
};

export interface EditUserProps {
  editUser: Partial<AllPersonInputs> & Pick<User, "id" | "role">;
  open: boolean;
  callBack: () => void;
  projectId: string;
}

export default function EditUser(props: EditUserProps): JSX.Element | null {
  const [loading, setLoading] = React.useState(false);
  const { projectId, editUser } = props;
  const { role, id, ...defaultValues } = editUser;
  const { enqueueSnackbar } = useSnackbar();
  const {
    project: { updateUser },
  } = useProjectUsers(projectId);

  const fieldsToUse =
    (role && roleProps[role]?.fields) || commonLayouts.default;
  console.log("fields", { fieldsToUse });
  const effectiveSchema = AllPersonInputsSchema.pick(
    fieldsToUse.reduce(
      (result, curr) =>
        curr === "email" ? result : { ...result, [curr]: true },
      {} as Record<PossibleFields, true>
    )
  );
  const formMethods = useForm<AllPersonInputs>({
    defaultValues, //: Object.fromEntries(fieldsToUse.map((field) => [field, ""])),
    resolver: zodResolver(effectiveSchema),
  });

  const handleClose = () => {
    formMethods.reset();
    props.callBack();
  };

  const handleSubmit = (formValues: AllPersonInputs) => {
    const justTheApplicableFields = effectiveSchema
      .strip()
      .safeParse(formValues);

    if (justTheApplicableFields.success) {
      setLoading(true);

      updateUser({
        ...justTheApplicableFields.data,
        email: editUser.email || formValues.email, // TODO: existing backends require email. This means you can't edit email. Fix backend then update here.
        id,
        role,
      })
        .then(() => {
          setLoading(false);
          props.callBack();
        })
        .catch((error) => {
          logError(error);
          enqueueSnackbar(`Could not edit user. ${error.message}`, {
            variant: "error",
          });
          setLoading(false);
        });
    } else {
      logError(new Error("success values didn't pass validation?!?"), {
        justTheApplicableFields,
      });
      enqueueSnackbar("Unexpected error updating user", {
        variant: "error",
      });
    }
  };

  const [submitRef, focusSubmit] = useFocus<HTMLButtonElement>();

  return role ? (
    <StyledDialog
      open={Boolean(props.open)}
      onClose={handleClose}
      maxWidth="lg"
    >
      <DialogContent>
        <h3 className={classes.userHeader}>
          {roleAttributes[role].icon}
          <span>Edit User Information</span>
        </h3>
        <div className={classes.wrapper}>
          <FormProvider {...formMethods}>
            <form
              id="edit-user-form"
              onSubmit={formMethods.handleSubmit(handleSubmit, (err) => {
                alert(JSON.stringify(err));
              })}
            >
              <PersonForm
                {...roleProps[role]}
                hideEmail
                onLastFieldEntered={focusSubmit}
                header={`${roleAttributes[role].label} Information`}
              />
            </form>
          </FormProvider>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button
          type="submit"
          form="edit-user-form"
          color="primary"
          disabled={loading}
          ref={submitRef}
        >
          {loading ? <CircularProgress /> : "Submit"}
        </Button>
      </DialogActions>
    </StyledDialog>
  ) : null;
}

// the following are used in the API so we can't fix their spelling here:
// spell-checker:ignore inspectoradmin lenderadmin
