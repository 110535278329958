import useSWR from "swr";
import {
  getEntityList,
  createEntity,
  updateEntity,
  deleteEntity,
} from "../API";
import { Entity } from "../APITypes";
import { useAuth } from "@lib/hooks/useAuth";

type UseEntityHook = () => {
  addToAllProjects?: string;
  entities?: Entity[];
  myEntity?: Entity;
  createEntity: (
    arg: Omit<Entity, /* TODO: restrict to identities? */ "id" | "etag">
  ) => Promise<Entity>;
  updateEntity: (arg: Entity) => Promise<Entity>;
  deleteEntity: (arg: Entity) => Promise<void>;
};

export const useEntity: UseEntityHook = () => {
  const { currentUserEmail: email } = useAuth();
  const emailDomain = email?.split("@").slice(-1)[0];

  const { data: entities, mutate } = useSWR(email && "/entity", getEntityList, {
    focusThrottleInterval: 60 * 1000,
  });

  const myEntity =
    (email &&
      entities?.find(({ matchEmails }) => matchEmails?.includes(email))) ||
    (emailDomain &&
      entities?.find(({ matchDomains }) =>
        matchDomains?.includes(emailDomain)
      )) ||
    undefined;

  // auto add is moving to the backend, but this is already here...
  return {
    entities,
    myEntity,

    createEntity: (
      entity: Omit<Entity, /* TODO: restrict to identities? */ "id" | "etag">
    ) => {
      if (!email) {
        // TODO: Should probably pass email (and other user context?) to authenticated routes as a prop or context
        throw new Error("email is required");
      }
      return createEntity({ email, entity })
        .then((newEntity) => {
          mutate(entities?.concat([newEntity]) ?? [newEntity], false);
          return newEntity;
        })
        .finally(() => mutate());
    },

    updateEntity: async (entity: Entity) => {
      // optimistic
      if (!entities) {
        throw new Error("Can't update entities you've never fetched");
      }
      mutate(
        entities.map((existing) => {
          if (existing.id === entity.id) {
            // const { etag, ...rest } = entity;
            // return rest;
            return entity; // etag wil be wrong until mutate but at least it won't get erased in case of error
          }
          return existing;
        }),
        false
      );

      // go do it
      const updatedEntity = await updateEntity({ entity });

      // refetch to be sure
      mutate();

      return updatedEntity;
    },

    deleteEntity: (entity: Entity) => {
      // optimistic
      mutate(
        entities?.filter((existing) => existing.id !== entity.id) ?? [],
        false
      );

      // go do it
      return deleteEntity(entity).finally(() => {
        // refetch to be sure
        mutate();
      });
    },
  };
};
