import React from "react";
import { Money } from "@lib/Money";
import NumberFormat from "react-number-format";
import { InputAdornment, TextField } from "@mui/material";

const MONEY_EPSILON = 0.000005; // https://trello.com/c/eSem85fq/509-investigate-cannot-enter-last-030-in-some-unknown-set-of-circumstances

interface MoneyEditorProps {
  onChange: (newAmount?: Money, e?: unknown) => void;
  value: Money;
  maximumValue?: Money;
  [key: string]: unknown; // ...restProps
  endAdornmentText?: string;
}

const AdornedInput = (props: {
  [x: string]: unknown;
  amount?: number;
  endAdornmentText?: string;
}) => {
  // const { amount: value, ...restProps } = props;
  const { endAdornmentText, ...restProps } = props;
  return (
    <TextField
      variant="standard"
      {...restProps}
      InputProps={{
        startAdornment: <InputAdornment position="start">$</InputAdornment>,
        ...(endAdornmentText && {
          endAdornment: (
            <InputAdornment
              style={{ position: "absolute", right: 0 }}
              position="end"
            >
              {endAdornmentText}
            </InputAdornment>
          ),
        }),
      }}
    />
  );
};

export const MoneyEditor = React.forwardRef(
  (
    {
      onChange,
      maximumValue,
      // required,
      value,
      ...restProps
    }: MoneyEditorProps,
    ref
  ) => (
    <NumberFormat
      {...restProps}
      value={value === null ? "" : value}
      displayType="input"
      inputRef={ref}
      customInput={
        AdornedInput /*InputProps={{startAdornment: <InputAdornment position="start">$</InputAdornment>}}*/
      }
      thousandSeparator
      decimalScale={2}
      /* fixedDecimalScale */ allowNegative={false}
      isAllowed={({ floatValue }) =>
        maximumValue === undefined ||
        floatValue === undefined ||
        (0 <= floatValue && floatValue <= maximumValue + MONEY_EPSILON)
      }
      placeholder="0.00"
      // textAlign="left"
      onValueChange={({ floatValue }) => {
        onChange(floatValue);
      }}
      // value={isNaN(value) ? "" : value}
      // selectOnFocus
      // caretPositionOnFocus={null}
      // modifyValueOnWheel={false}
    />
  )
);
