import useSWR from "swr";
import { FetchError, getUser } from "@lib/API";
import { useAnalytics, useBackend } from "@lib/hooks";
import { useGetUserRole } from "@/Lib/hooks/useGetUserRole";
import { User } from "@/Lib/APITypes";
import { pipe, flow } from "fp-ts/lib/function";
import { getOrThrowDecodeError } from "@/Lib/util/typeUtils";
import * as t from "io-ts";
import { useMemo } from "react";
import { SWRKeys } from "@/Lib/swrKeys";

const UserWithETag = t.intersection([User, t.type({ etag: t.string })]);

export function useUser(who: "me" | Partial<Pick<User, "id">>): {
  user?: User;
  error?: Readonly<FetchError>;
  fixUser?: () => Promise<string[]>;
  setUseNewUI?: (useNewUI: boolean) => Promise<void>;
} {
  const { fixUser: fixUserAPI, updateUser } = useBackend();

  const { user: { id: currentUserId } = {} } = useGetUserRole();
  const id = who === "me" ? currentUserId : who.id;

  const {
    data: user,
    mutate,
    error,
  } = useSWR(SWRKeys.user(id).self, () =>
    getUser({ id }).then((apiUser) => {
      // console.log({ apiUser });
      const decodedUser = pipe(
        apiUser,
        flow(UserWithETag.decode, getOrThrowDecodeError)
      );
      // console.log({ decodedUser });
      return decodedUser;
    })
  );

  const fixUser = useMemo(
    () =>
      user &&
      (async () => {
        return fixUserAPI({ id: user.id }).then(({ actionsTaken }) => {
          mutate();
          return actionsTaken;
        });
      }),
    [fixUserAPI, mutate, user]
  );

  const analytics = useAnalytics();

  const setUseNewUI = useMemo(
    () =>
      user &&
      (async (useNewUI: boolean) => {
        const updates = {
          settings: {
            ...user.settings,
            ...(user.settings && {
              frontend: {
                ...user.settings.frontend,
                useNewUI,
              },
            }),
          },
        };

        analytics.track("new-ui:change", {
          currentUserId,
          useNewUI,
          email: user?.email,
          role: user?.role,
        });

        // // optimistic update
        // mutate(
        //   {
        //     ...user,
        //     ...updates,
        //   },
        //   false
        // );

        // wait for backend to do it
        await updateUser({ userId: user.id, updates, etag: user.etag });

        // trigger refetch
        mutate();
      }),
    [analytics, currentUserId, mutate, updateUser, user]
  );

  // TODO: useUser renders too much; understand
  // useEffect(() => {
  //   console.log("user => ", user);
  // }, [user]);
  // useEffect(() => {
  //   console.log("fixUser => ", fixUser);
  // }, [fixUser]);
  // useEffect(() => {
  //   console.log("setUseNewUI => ", setUseNewUI);
  // }, [setUseNewUI]);

  // useEffect(() => {
  //   console.log("currentUserId => ", currentUserId);
  // }, [currentUserId]);
  // useEffect(() => {
  //   console.log("who => ", who);
  // }, [who]);
  // useEffect(() => {
  //   console.log("id => ", id);
  // }, [id]);
  // useEffect(() => {
  //   console.log("error => ", error);
  // }, [error]);

  // console.log("useUser", { user, error });

  return {
    user,
    error,
    fixUser,
    setUseNewUI,
  };
}
