import { Button, CircularProgress, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import useSWR from "swr";
import { dispatchInspection, fetchInspectionStatus } from "@lib/API";
import { logError } from "@lib/ErrorLogging";
import { useSnackbar } from "notistack";

export function DispatchInspection(): JSX.Element {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const token = searchParams.get("payload");
  const { enqueueSnackbar } = useSnackbar();

  const [state, setState] = React.useState<
    "init" | "ready" | "dispatching" | "dispatched" | "error"
  >("init");

  const { data: status, error } = useSWR(
    token ? ["inspection/validate", token] : null,
    () => fetchInspectionStatus(token)
  );

  useEffect(() => {
    if (error && state !== "error" && state !== "dispatched") {
      setState("error");
    }
    if (status && state === "init") {
      setState("ready");
    }
  }, [error, state, status]);

  if (state === "init") {
    return (
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={3}
      >
        <Grid item>
          {" "}
          <CircularProgress />
        </Grid>
      </Grid>
    );
  }
  if (state === "error") {
    return (
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={3}
      >
        <Grid item>
          {" "}
          <Typography align="center">
            This inspection has already been dispatched or is expired
          </Typography>
        </Grid>
      </Grid>
    );
  }
  if (state === "dispatched") {
    return (
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={3}
      >
        <Grid item>
          {" "}
          <Typography align="center">
            Thank you for your confirmation. You may close this window.
          </Typography>
        </Grid>
      </Grid>
    );
  }
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={3}
    >
      <Grid item>
        <Typography>
          Pressing the button below will tell us that you received the request
          and will perform the inspection
        </Typography>
      </Grid>
      {status?.status === "ready" && status.address ? (
        <Grid item>
          <address>
            {status.address.streetAddress}
            <br />
            {status.address.city}, {status.address.stateCode}{" "}
            {status.address.zip}
          </address>
        </Grid>
      ) : undefined}
      <Grid item>
        {state === "ready" ? (
          <Button
            variant="contained"
            onClick={() => {
              if (!token) {
                throw new Error("this can't happen");
              }
              setState("dispatching");
              dispatchInspection(token)
                .then(() => {
                  setState("dispatched");
                })
                .catch((err) => {
                  logError(err);
                  enqueueSnackbar(
                    "Error dispatching inspection; please try again",
                    { variant: "error" }
                  );
                  setState("ready");
                });
            }}
          >
            Accept Dispatch
          </Button>
        ) : (
          <CircularProgress />
        )}
      </Grid>
    </Grid>
  );
}
