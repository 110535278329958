import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { uploadSupportingFiles } from "@lib/API";
import { logError } from "@lib/ErrorLogging";
import { useEntity } from "@lib/hooks/useEntity";
import {
  UploadProgressDialog,
  openAccordingToProgress,
  useProgress,
} from "../../Components/UploadProgressDialog";
import { EntityBaseZodSchema, EntityEditor } from "./EntityEditor";
import { Entity } from "@/Lib/APITypes";

const EMPTY_ENTITY: Readonly<Omit<Entity, "id">> = {
  autoAddUserEmails: null,
  autoAddUserIdentities: null,
  ...EntityBaseZodSchema.parse({}),
  // following lines just fix type issues; would go away if we used (api-types) io-ts
  matchEmails: [],
  overrideInvoiceRouting: [],
  defaultLoanContact: null,
  prepopulateLenderId: null,
  logo: null,
};

export default function CreateEntity() {
  const navigate = useNavigate();
  const { createEntity } = useEntity();
  const { enqueueSnackbar } = useSnackbar();
  const [progress, setProgress] = useProgress();

  return (
    <>
      <EntityEditor
        initialValue={EMPTY_ENTITY}
        open={true}
        onClose={() => {
          navigate("..");
        }}
        title="New Entity"
        onSubmit={(values) => {
          const { newLogoFile, ...ingredients } = values;
          const uploadLogo = uploadSupportingFiles({
            files: newLogoFile ? [newLogoFile] : [],
            prefix: "e/(embryos)",
            progress: setProgress,
          });
          uploadLogo
            .then((logoFiles) =>
              createEntity({ ...ingredients, logo: logoFiles[0]?.storageKey })
            )
            .then(() => {
              navigate("..");
            })
            .catch((err) => {
              logError(err);

              enqueueSnackbar(
                "Sorry; something went wrong and we could not create the new entity",
                { variant: "error" }
              );
            });
        }}
      />
      <UploadProgressDialog
        progress={progress}
        open={openAccordingToProgress(progress)}
      />
    </>
  );
}
