import React from "react";
import PersonForm, { commonLayouts } from "../Forms/PersonForm";
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  BackendGetUserDetailsResponse,
  BusinessPersonInputs,
} from "@lib/APITypes";
import { IfEnterKeyEventHandler } from "@lib/boilerplate";

// Adapter between legacy and useForm
export function LenderInfoStep({
  preload,
  onSubmit,
  onLastFieldEntered,
}: {
  preload?: BackendGetUserDetailsResponse;
  onSubmit: (formValues: BusinessPersonInputs) => void;
  onLastFieldEntered: IfEnterKeyEventHandler;
}): JSX.Element {
  const {
    firstName: first_name = "",
    lastName: last_name = "",
    company = "",
    email = "",
    phone: phone_number = "",
  } = preload || {};

  const formMethods = useForm<BusinessPersonInputs>({
    defaultValues: {
      company: company || "",
      email,
      first_name,
      last_name,
      phone_number: phone_number || "",
    },
    resolver: zodResolver(BusinessPersonInputs),
  });

  return (
    <FormProvider {...formMethods}>
      <form id="lender-data-form" onSubmit={formMethods.handleSubmit(onSubmit)}>
        <PersonForm
          header="Lender Information"
          fields={commonLayouts.companyFirst}
          onLastFieldEntered={onLastFieldEntered}
        />
      </form>
    </FormProvider>
  );
}
