import { DatePickerProps } from "@mui/lab";

/**
 * Not all of the files I was working on liked `KeyboardEvent` - this is the parts we need
 * // TODO: Understand what wasn't working
 */
interface MinimalKeyboardEvent {
  preventDefault: () => void;
  stopPropagation: () => void;
  key: string;
}

const noOp = () => {
  /* no op */
};

export const dummyKeyboardEvent: () => MinimalKeyboardEvent = () => ({
  preventDefault: noOp,
  stopPropagation: noOp,
  key: "DummyKeyboardEvent",
});

/**
 * Function ((signature) to be called if it *is* the Enter key
 */
export type IfEnterKeyEventHandler = (event: MinimalKeyboardEvent) => unknown;

/**
 * Run code if the enter key is pressed. Use this to implement manual navigation, etc
 * @param action function to be called if we get an enter key
 */
export const ifEnterKey: (action: {
  (event: MinimalKeyboardEvent): unknown;
}) => (event: MinimalKeyboardEvent) => void = (action) => (event) => {
  // 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
  if (action !== undefined && event.key === "Enter") {
    event.preventDefault();
    event.stopPropagation();
    action(event);
  }
};

/**
 * How we usually configure our date pickers
 */
export const defaultDatePickerProps: Partial<DatePickerProps<Date>> = {
  disableToolbar: true,
  variant: "inline",
  format: "yyyy/MM/dd",
  margin: "normal",
  KeyboardButtonProps: {
    "aria-label": "change date",
  },
};
