/** A wrapper around mixpanel so if we change to use another package we don't have mixpanel embedded everywhere by name */

import type { Mixpanel } from "mixpanel-browser";
import { useMixpanel } from "react-mixpanel-browser";
import { Role } from "../roles";

// we don't want to litter the console on every analytics call if not wired.
let warned = false;
function onMisconfigurationDetected() {
  if (!warned) {
    console.error("mixpanel not configured");
    warned = true;
  }
}

export const useAnalytics: () => {
  track: Mixpanel["track"]; // we pretend it is a coincidence that our "track" API/signature matches mixpanel :-)
  identifyUser: (user: { id: string; email?: string; role?: Role }) => void;
} = () => {
  const mixpanel: Mixpanel & { config?: { token?: string } } = useMixpanel();
  // const mixpanel = useMixpanel();

  (window as { mixpanel?: Mixpanel }).mixpanel = mixpanel;

  if (!mixpanel?.config?.token) {
    onMisconfigurationDetected();
    return {
      track: () => {
        /* no op */
      },
      identifyUser: () => {
        /* no op */
      },
    };
  }

  return {
    track: mixpanel.track.bind(mixpanel),
    identifyUser: ({ id, email, role }) => {
      mixpanel.identify(id);
      if (email) {
        mixpanel.people.set({ $email: email });
      }
      if (role) {
        mixpanel.people.set({ role });
      }
    },
  };
};
