import React from "react";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import {
  defaultDatePickerProps,
  ifEnterKey,
  IfEnterKeyEventHandler,
} from "@lib/boilerplate";
import { useFocus } from "@lib/hooks/useFocus";
import {
  Controller,
  FieldNamesMarkedBoolean,
  useFormContext,
} from "react-hook-form";
import { multiRef } from "@lib/misc";
import { LoanModel } from "../Models/Loan";
import { DatePicker } from "@mui/x-date-pickers";
import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Switch,
  Tooltip,
} from "@mui/material";
import { MoneyEditor } from "../Components/MoneyEditor";
import { differenceInCalendarMonths } from "date-fns";
// import { DevTool } from "@hookform/devtools";
import { LoanType } from "@lib/LoanType";
import { Money } from "@lib/Money";

const LoanTypeContainer = styled("div")(({ theme: { spacing } }) => ({
  width: "100%",
  alignItems: "flex-start",
  textAlign: "start",
  marginBottom: spacing(2),
}));

/** A possibly incomplete {@link LoanModel} */
export type LoanFormLoanInput = Partial<
  LoanModel & {
    custom_1: string | null;
  }
>;

export interface LoanFormProps {
  onLastFieldEntered?: IfEnterKeyEventHandler;
  renderFiles?: () => JSX.Element;
  disabled?: boolean;
  requireBasicProps?: boolean;
  borrowerPlaceholder?: string;
  reportingFeature: boolean;
  dhlcCustom: boolean;
  showMaxDraws: boolean;
  showRetention: boolean;
}
const nullBecomesEmptyString = <T,>(v: T | "") => (v === null ? "" : v);

/**
 * (Collection of) Form (inputs) presenting the various loan parameters
 */
export function LoanForm({
  onLastFieldEntered,
  renderFiles,
  disabled,
  requireBasicProps,
  borrowerPlaceholder,
  reportingFeature,
  dhlcCustom,
  showMaxDraws,
  showRetention,
}: LoanFormProps): JSX.Element {
  const {
    formState: { errors, touchedFields },
    watch,
    control,
    setValue,
  } = useFormContext<LoanFormLoanInput>();

  const { loanAmount, startDate, maturityDate, interestRate, reporting } =
    watch();

  type DeepRequired<T> = Required<{
    [K in keyof T]: DeepRequired<NonNullable<T[K]>>;
  }>;

  const touchedReporting = touchedFields.reporting as Readonly<
    Partial<
      FieldNamesMarkedBoolean<DeepRequired<LoanFormLoanInput>["reporting"]>
    >
  >;
  const interestReserveTouched =
    reporting?.enabled &&
    reporting?.type === "Interest Reserve" &&
    touchedReporting?.interestReserve;

  const { enabled: reportingEnabled, type: reportType } = reporting || {};

  const [maxDrawsRef, focusMaxDraws] = useFocus<HTMLElement>();
  const [startDateRef, focusStartDate] = useFocus<HTMLElement>();
  const [maturityDateRef, focusMaturityDate] = useFocus<HTMLElement>();
  const [retentionRef, focusRetention] = useFocus<HTMLElement>();
  const [interestRateRef, focusInterestRate] = useFocus<HTMLElement>();
  const [interestReserveRef /* , focusInterestReserve */] =
    useFocus<HTMLElement>();
  const [loanIdentifierRef, focusLoanIdentifier] = useFocus<HTMLElement>();
  const [loanContactRef, focusLoanContact] = useFocus<HTMLElement>();
  const [loanBorrowerRef, focusLoanBorrower] = useFocus<HTMLElement>();
  const [returnAddressRef /* , focusReturnAddress */] = useFocus<HTMLElement>();
  const [custom1Ref, focusCustom1] = useFocus<HTMLElement>();

  const preventEnableReporting =
    Boolean(
      errors?.interestRate ||
        errors?.startDate ||
        errors?.maturityDate ||
        errors?.loanAmount
    ) ||
    loanAmount === undefined ||
    loanAmount === null ||
    interestRate === undefined ||
    interestRate === null ||
    !startDate ||
    !maturityDate;

  const loanDurationInMonths =
    maturityDate &&
    startDate &&
    differenceInCalendarMonths(maturityDate, startDate);

  // inspired by https://propertymetrics.com/blog/interest-reserve/
  const averagePercentOutstanding = 0.5;
  const averageLoanBalance =
    loanAmount && loanAmount * averagePercentOutstanding;
  const averageAnnualInterest =
    averageLoanBalance &&
    interestRate &&
    (averageLoanBalance * interestRate) / 100;
  const averageMonthlyInterest =
    averageAnnualInterest && averageAnnualInterest / 12;
  const defaultReserve =
    reportingEnabled &&
    loanDurationInMonths &&
    averageMonthlyInterest &&
    loanDurationInMonths * averageMonthlyInterest;

  React.useEffect(() => {
    if (
      defaultReserve &&
      !interestReserveTouched //&&
      // interestReserve === undefined
    ) {
      setValue("reporting.interestReserve", defaultReserve, {
        shouldDirty: false,
        shouldValidate: true,
      });
    }
  }, [defaultReserve, interestReserveRef, interestReserveTouched, setValue]);
  const hideReporting =
    !reportingFeature || !reportingEnabled || preventEnableReporting;
  return (
    <div
      style={{
        paddingRight: "5vw",
        paddingLeft: "5vw",
        textAlign: "center",
      }}
    >
      <Grid container direction="column" spacing={2}>
        <Grid item container direction="column" spacing={2}>
          <Grid item>
            <Controller
              name="loanType"
              control={control}
              render={({ field }) => (
                <LoanTypeContainer>
                  <FormControl
                    variant="standard"
                    component="fieldset"
                    disabled={disabled}
                  >
                    <FormLabel component="legend">Loan Type</FormLabel>
                    <RadioGroup row aria-label="Loan Type" {...field}>
                      <FormControlLabel
                        value={LoanType.Term}
                        control={<Radio color="primary" />}
                        label={LoanType.Term}
                      />
                      <FormControlLabel
                        value={LoanType.LoC}
                        control={<Radio color="primary" />}
                        label={LoanType.LoC}
                      />
                    </RadioGroup>
                  </FormControl>
                </LoanTypeContainer>
              )}
            />
          </Grid>
          <Grid item>
            <Controller
              name="loanAmount"
              control={control}
              rules={{
                min: 0,
                required: false,
              }}
              render={({ field: { ref, onChange, ...rest } }) => (
                <MoneyEditor
                  disabled={disabled}
                  onChange={(newValue?: Money) =>
                    onChange(newValue === undefined ? null : newValue)
                  }
                  fullWidth
                  {...rest}
                  label="Loan Amount"
                  ref={ref}
                  autoFocus
                  onKeyDown={ifEnterKey(focusStartDate)}
                />
              )}
            />
          </Grid>
          <Grid item>
            <Controller
              name="disbursedAtClosing"
              control={control}
              rules={{
                min: 0,
                required: false,
              }}
              render={({ field: { ref, onChange, ...rest } }) => (
                <MoneyEditor
                  // sx={margin}
                  disabled={disabled}
                  onChange={(newValue?: Money) =>
                    onChange(newValue === undefined ? null : newValue)
                  }
                  fullWidth
                  {...rest}
                  label="Disbursed at Closing"
                  ref={ref}
                  autoFocus
                  // TODO: focus
                  onKeyDown={ifEnterKey(focusStartDate)}
                />
              )}
            />
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            sx={({ spacing }) => ({
              paddingTop: spacing(3), // why?
              marginLeft: spacing(-2),
            })}
            spacing={4}
          >
            <Grid item>
              <Controller
                name="startDate"
                control={control}
                rules={{
                  required: requireBasicProps || reportingFeature,
                }}
                render={({ field: { ref, value, ...rest } }) => (
                  <DatePicker
                    disabled={disabled}
                    {...defaultDatePickerProps}
                    {...rest}
                    value={nullBecomesEmptyString(value)}
                    label="Start Date"
                    renderInput={(params) => (
                      <TextField
                        variant="standard"
                        {...params}
                        helperText={errors?.startDate?.message}
                        error={Boolean(errors.startDate)}
                        inputRef={ref}
                        InputProps={{
                          ...params.InputProps,
                          inputRef: multiRef([ref, startDateRef]),
                          onKeyDown: ifEnterKey(focusMaturityDate),
                        }}
                        id="start-date"
                        required={requireBasicProps || reportingFeature}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item>
              <Controller
                name="maturityDate"
                control={control}
                rules={{
                  required: requireBasicProps || reportingFeature,
                }}
                render={({ field: { ref, value, ...rest } }) => (
                  <DatePicker
                    disabled={disabled}
                    {...defaultDatePickerProps}
                    {...rest}
                    value={nullBecomesEmptyString(value)}
                    label="Maturity Date"
                    renderInput={(params) => (
                      <TextField
                        variant="standard"
                        {...params}
                        helperText={errors?.maturityDate?.message}
                        error={Boolean(errors.maturityDate)}
                        inputRef={ref}
                        InputProps={{
                          ...params.InputProps,
                          inputRef: multiRef([ref, maturityDateRef]),
                          onKeyDown: ifEnterKey(focusInterestRate),
                        }}
                        id="maturity-date"
                        required={requireBasicProps || reportingFeature}
                      />
                    )}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid item>
            <Controller
              name="interestRate"
              control={control}
              rules={{
                required: requireBasicProps || reportingFeature,
              }}
              render={({ field: { ref, value, onChange, ...rest } }) => (
                <TextField
                  value={nullBecomesEmptyString(value)}
                  onChange={({ target: { value } }) =>
                    value === "" ? onChange(null) : onChange(+value)
                  }
                  variant="standard"
                  label="Interest Rate"
                  required={requireBasicProps || reportingFeature}
                  disabled={disabled}
                  fullWidth
                  // sx={margin}
                  type="number"
                  id="interest-rate"
                  {...rest}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">%</InputAdornment>
                    ),
                  }}
                  error={Boolean(errors.interestRate)}
                  helperText={errors?.interestRate?.message}
                  onKeyDown={ifEnterKey(
                    showRetention
                      ? focusRetention
                      : showMaxDraws
                      ? focusMaxDraws
                      : focusLoanIdentifier
                  )}
                  inputRef={multiRef([ref, interestRateRef])}
                />
              )}
            />
          </Grid>
          <Grid item hidden={!showRetention}>
            <Controller
              name="retention"
              control={control}
              rules={{
                min: 0,
                max: 100,
              }}
              render={({ field: { ref, value, onChange, ...rest } }) => (
                <TextField
                  variant="standard"
                  label="Retention"
                  fullWidth
                  disabled={disabled}
                  // sx={margin}
                  type="number"
                  id="retention-amount"
                  {...rest}
                  value={nullBecomesEmptyString(value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">%</InputAdornment>
                    ),
                  }}
                  error={Boolean(errors.retention)}
                  helperText={errors?.retention?.message}
                  onKeyDown={ifEnterKey(
                    showMaxDraws ? focusMaxDraws : focusLoanIdentifier
                  )}
                  inputRef={multiRef([ref, retentionRef])}
                  onChange={({ target: { value } }) =>
                    value === "" ? onChange(null) : onChange(+value)
                  }
                />
              )}
            />
          </Grid>
          <Grid item hidden={!showMaxDraws}>
            <Controller
              name="maxDraws"
              control={control}
              rules={{
                pattern: /^[0-9]*$/, // since we only want positive integers :-)
              }}
              render={({ field: { ref, value, ...rest } }) => (
                <TextField
                  variant="standard"
                  fullWidth
                  disabled={disabled}
                  // sx={margin}
                  type="number"
                  id="maxDraws"
                  label="Maximum # Draw Requests"
                  {...rest}
                  value={nullBecomesEmptyString(value)}
                  error={Boolean(errors.maxDraws)}
                  helperText={errors?.maxDraws?.message}
                  inputRef={multiRef([ref, maxDrawsRef])}
                  onKeyDown={ifEnterKey(focusLoanIdentifier)}
                />
              )}
            />
          </Grid>
          <Grid item>
            <Controller
              name="loanIdentifier"
              control={control}
              render={({ field: { ref, value, ...rest } }) => (
                <FormControl
                  variant="standard"
                  fullWidth
                  // sx={margin}
                >
                  <TextField
                    value={value == null ? "" : value}
                    variant="standard"
                    disabled={disabled}
                    fullWidth
                    required={requireBasicProps || reportingFeature}
                    id="loan-number"
                    label="Loan # / Identifier"
                    inputRef={multiRef([ref, loanIdentifierRef])}
                    {...rest}
                    onKeyDown={ifEnterKey(focusLoanBorrower)}
                  />
                </FormControl>
              )}
            />
          </Grid>

          <Grid item>
            <Controller
              name="loanBorrowerOverride"
              control={control}
              render={({ field: { ref, value, ...rest } }) => (
                <Tooltip title="If set this will appear an draw reports">
                  <FormControl
                    variant="standard"
                    fullWidth
                    // sx={{
                    //   marginTop: "20px",
                    // }}
                  >
                    <TextField
                      variant="standard"
                      disabled={disabled}
                      value={value || ""}
                      fullWidth
                      placeholder={borrowerPlaceholder}
                      id="loan-borrower-override"
                      label="Company Name"
                      inputRef={multiRef([ref, loanBorrowerRef])}
                      {...rest}
                      onKeyDown={ifEnterKey(focusLoanContact)}
                    />
                  </FormControl>
                </Tooltip>
              )}
            />
          </Grid>

          <Grid item>
            <Controller
              name="loanContact"
              control={control}
              render={({ field: { ref, value, ...rest } }) => (
                <FormControl
                  variant="standard"
                  fullWidth
                  // sx={margin}
                >
                  <TextField
                    variant="standard"
                    value={value || ""}
                    fullWidth
                    multiline
                    placeholder="Text entered here
will appear at the top of Draw Reports
below the Company Name"
                    rows={4}
                    required={requireBasicProps || reportingFeature}
                    id="loan-contact"
                    label="Lender Address / Contact"
                    inputRef={multiRef([ref, loanContactRef])}
                    {...rest}
                    onKeyDown={
                      dhlcCustom
                        ? ifEnterKey(focusCustom1)
                        : onLastFieldEntered
                        ? ifEnterKey(onLastFieldEntered)
                        : undefined
                    }
                  />
                </FormControl>
              )}
            />
          </Grid>

          {dhlcCustom ? (
            <Grid item>
              <Controller
                name="custom_1"
                control={control}
                render={({ field: { ref, value, ...rest } }) => (
                  <FormControl
                    variant="standard"
                    fullWidth
                    // sx={{
                    //   marginTop: "20px",
                    // }}
                  >
                    <TextField
                      variant="standard"
                      fullWidth
                      id="investor"
                      label="Investor"
                      inputRef={multiRef([ref, custom1Ref])}
                      {...rest}
                      value={nullBecomesEmptyString(value)}
                      onKeyDown={
                        onLastFieldEntered
                          ? ifEnterKey(onLastFieldEntered)
                          : undefined
                      }
                    />
                  </FormControl>
                )}
              />
            </Grid>
          ) : undefined}
          {renderFiles ? <Grid item>{renderFiles()}</Grid> : null}
        </Grid>
        <Grid container direction="column" alignItems="stretch">
          <Grid item hidden={!reportingFeature}>
            <h2>Monthly Statements</h2>
          </Grid>
          <Grid
            item
            hidden={!reportingFeature}
            component={Box}
            alignSelf="flex-start"
          >
            <Controller
              name="reporting.enabled"
              render={({ field: { ref, value, onChange, ...rest } }) => (
                <FormControl variant="standard">
                  <FormControlLabel
                    label="Enabled"
                    control={
                      <Switch
                        color="primary"
                        checked={Boolean(value && !preventEnableReporting)}
                        disabled={disabled || preventEnableReporting}
                        onChange={(_, checked) => onChange(checked)}
                        inputRef={ref}
                      />
                    }
                  />
                  <FormHelperText hidden={!preventEnableReporting}>
                    Provide all required inputs to be able to enable statements
                  </FormHelperText>
                </FormControl>
              )}
              control={control}
            />
          </Grid>
          <Grid item hidden={hideReporting}>
            <Controller
              name="reporting.type"
              render={({ field: { ref, ...rest } }) => (
                <RadioGroup row {...rest}>
                  <FormControlLabel
                    value="Interest Reserve"
                    control={<Radio disabled={disabled} color="primary" />}
                    label="Interest Reserve"
                  />
                  <FormControlLabel
                    value="Invoice"
                    control={<Radio disabled={disabled} color="primary" />}
                    label="Invoice"
                  />
                </RadioGroup>
              )}
              control={control}
            />
          </Grid>
          <Grid item hidden={hideReporting}>
            <Controller
              name="reporting.statementReturnAddress"
              control={control}
              render={({ field: { ref, ...rest } }) => (
                <FormControl variant="standard" fullWidth>
                  <TextField
                    variant="standard"
                    multiline
                    fullWidth
                    rows={4}
                    required={reportingFeature}
                    id="return-address"
                    label="Statement Return Address"
                    inputRef={multiRef([ref, returnAddressRef])}
                    // onKeyDown={ifEnterKey(focusInterestReserve)}
                    {...rest}
                  />
                </FormControl>
              )}
            />
          </Grid>
          <Grid
            item
            hidden={hideReporting || reportType !== "Interest Reserve"}
          >
            <Controller
              name="reporting.interestReserve"
              control={control}
              rules={{
                min: 0,
                max: loanAmount ?? Number.MAX_VALUE,
                required: Boolean(
                  reportingFeature &&
                    reporting &&
                    reporting.enabled &&
                    reporting.type === "Interest Reserve"
                ),
              }}
              render={({ field: { ref, ...rest } }) => {
                return (
                  <MoneyEditor
                    // sx={margin}
                    disabled={disabled}
                    fullWidth
                    {...rest}
                    label="Interest Reserve"
                    error={Boolean(errors?.reporting?.interestReserve)}
                    helperText={errors?.reporting?.interestReserve?.message}
                    ref={multiRef([ref, interestReserveRef])}
                    onKeyDown={
                      onLastFieldEntered
                        ? ifEnterKey(onLastFieldEntered)
                        : undefined
                    }
                  />
                );
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      {/* <DevTool control={control} /> */}
    </div>
  );
}

export default LoanForm;
