import useSWR from "swr";
import { useBackend } from ".";
import { InvoiceActivity } from "@project-centerline/project-centerline-api-types";
import { ApiError } from "@openapi-io-ts/runtime";
import { either } from "fp-ts";

type ReturnType = {
  activity?: InvoiceActivity;
  errors?: ApiError;
  isValidating?: boolean;
};

/** SWR-load project, CRUD project_table */
export function useProjectInvoiceActivity(projectId: string): ReturnType {
  const { getProjectDrawActivity } = useBackend();

  const {
    data: activityEither,
    error,
    isValidating,
  } = useSWR(
    () => projectId && `/project/{projectId}/draws/activity`,
    (_url: unknown) => getProjectDrawActivity(projectId)()
  );

  const activity =
    activityEither && either.isRight(activityEither)
      ? activityEither.right.data
      : undefined;
  const errors =
    error ||
    (activityEither && either.isLeft(activityEither)
      ? activityEither.left
      : undefined);

  return {
    activity,
    errors,
    isValidating,
  };
}
