import { throwIfNot } from "@lib/util/throwIfNot";
import { useParams } from "react-router-dom";
import { ProjectInfoTab } from "./ProjectInfoTab";

export const PropertyInfoPage = () => {
  const projectId = throwIfNot(
    useParams<"projectId">().projectId,
    "Missing project id"
  );

  return <ProjectInfoTab projectId={projectId} />;
};

export default PropertyInfoPage;
