import { throwIfNot } from "@lib/util/throwIfNot";
import { useParams } from "react-router-dom";
import { ProjectReportingTab } from "./ProjectReportingTab";
import { Grid } from "@mui/material";

export const ProjectReportingPage = () => {
  const projectId = throwIfNot(
    useParams<"projectId">().projectId,
    "Missing project id"
  );

  return (
    <Grid container justifyContent="center">
      <Grid item>
        <ProjectReportingTab projectId={projectId} />
      </Grid>
    </Grid>
  );
};

export default ProjectReportingPage;
