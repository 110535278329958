import { API } from "aws-amplify";
import { s3ListFiles, s3Upload } from "../Components/Storage";
import { Invoice, InvoiceActions, LoanModel, Task } from "../Models";
import {
  BackendRawGetProjectItemsTasksResponse,
  BackendGetProjectItemsTasksResponse,
  BackendGetProjectTasksResponse,
  BackendEditTaskInputs,
  BackendGetTaskResponse,
  CreateTaskInputs,
  BackendGetProjectsResponse,
  BackendUserResponse,
  notForGeneralUse,
  BackendGetProjectItemsDecisionsRawResponseEntry,
  BackendGetProjectItemsDecisionsResponseEntry,
  BackendGetProjectFilesResponse,
  legacyBackendGetProjectDetailsResponse,
  BackendGetUserRoleResponse,
  NotificationTypeEnum,
  BackendGetCommentsResponse,
  BackendGetConfigResponse,
  ProjectStatus,
  BackendGetEntityListResponse,
  Entity,
  BackendGetUserDetailsResponse,
  BackendGetProjectsRawResponse,
  ApprovalStatus,
  BusinessPersonInputs,
  BackendGetSearchRawDecision,
  BackendGetSearchRawDraw,
  BackendGetSearchRawChat,
  BackendGetInspectionResponse,
  BackendGetNVMSConfigResponse,
  BackendGetStripeResponse,
  Project,
  PartiallyModernizedGetProjectDetailsResponse,
  PersistentProjectFile,
} from "./APITypes";
import { Role } from "./roles";
import * as z from "zod";
import { logAnomaly, logError } from "./ErrorLogging";

import * as Sentry from "@sentry/react";

import {
  EmailAddress,
  PatchProjectBody,
  PatchProjectBodySchema,
  requestFunctionsBuilder,
  ProjectId,
  WireInvoice,
  InvoiceFromWire,
  synthesizeFileIfNecessary,
  Position,
  ProjectFile as BackendProjectFile,
  StoredFile,
  storedFileZodSchema,
  TaskId,
  AddDrawBodySchema,
  EditDrawBodySchema,
  User,
  UserId,
} from "@project-centerline/project-centerline-api-types";

import * as t from "io-ts";
import { flow, pipe } from "fp-ts/lib/function";
import { chain, fold } from "fp-ts/lib/Either";
import { either } from "fp-ts";

import { HttpRequestAdapter } from "@openapi-io-ts/runtime";
import { GpsOutput } from "../Pages/ProjectView/Invoice/locationUtils";
import { sanitizeIndividualTask } from "./APIConversions";
import * as E from "fp-ts/lib/Either";
import * as TE from "fp-ts/lib/TaskEither";
import * as A from "fp-ts/lib/Array";
import * as O from "fp-ts/lib/Option";
import { RecursivePartial, getOrThrowDecodeError } from "./util/typeUtils";
import { NonEmptyString } from "io-ts-types";
import { byProp } from "./misc";
import { PathReporter } from "io-ts/lib/PathReporter";
// import { PathReporter } from "io-ts/lib/PathReporter";
import {
  capDelay,
  exponentialBackoff,
  limitRetries,
  Monoid,
  RetryStatus,
} from "retry-ts";
import { retrying } from "retry-ts/Task";

const {
  sanitizeGetProjectsResponse,
  sanitizeIndividualProject,
  sanitizeGetUserRoleResponse,
  deduplicateTasks,
} = notForGeneralUse;

let callerEmail: string | undefined;

export function setCallerEmail(email?: string): void {
  callerEmail = email;
}

const withXCallerEmailAndSentry = <Arg extends Record<string, unknown>>(
  method: "PUT" | "PATCH" | "POST" | "GET" | "DEL",
  path: string,
  arg: Arg
) => {
  const existingTransaction = Sentry.getCurrentHub()
    .getScope()
    ?.getTransaction();
  const description = `Backend Request: ${method} ${path}`;
  const createdTransaction = existingTransaction
    ? existingTransaction.startChild({ op: "backend-call", description })
    : Sentry.startTransaction({ name: description });

  Sentry.configureScope((scope) => {
    scope.setSpan(createdTransaction);
  });

  const upgradedArg = {
    ...arg,
    ...(callerEmail && process.env.REACT_APP_BACKEND === "local"
      ? {
          headers: {
            ...(arg.headers ? (arg.headers as Record<string, string>) : {}),
            "x-caller-email": callerEmail,
          },
        }
      : {}),
  };

  const apiRequest =
    (method === "POST" && API.post("project-centerline", path, upgradedArg)) ||
    (method === "GET" && API.get("project-centerline", path, upgradedArg)) ||
    (method === "PUT" && API.put("project-centerline", path, upgradedArg)) ||
    (method === "PATCH" &&
      API.patch("project-centerline", path, upgradedArg)) ||
    API.del("project-centerline", path, upgradedArg);
  return apiRequest.finally(() => {
    createdTransaction.finish();
  });
};

const post = <Arg extends Record<string, unknown>>(path: string, arg: Arg) =>
  withXCallerEmailAndSentry("POST", path, arg);
const patch = <Arg extends Record<string, unknown>>(path: string, arg: Arg) =>
  withXCallerEmailAndSentry("PATCH", path, arg);
const get = <Arg extends Record<string, unknown>>(path: string, arg?: Arg) =>
  withXCallerEmailAndSentry("GET", path, arg ?? {});
const put = <Arg extends Record<string, unknown>>(path: string, arg: Arg) =>
  withXCallerEmailAndSentry("PUT", path, arg);
const del = <Arg extends Record<string, unknown>>(path: string, arg: Arg) =>
  withXCallerEmailAndSentry("DEL", path, arg);

export function getProjectTasks(
  projectId: string
): Promise<BackendGetProjectTasksResponse> {
  return post("getprojectitems", {
    body: {
      project_id: projectId,
      item: "Tasks",
    },
  }).then(sanitizeTasksResponse) as Promise<BackendGetProjectTasksResponse>;
}

export function editTask(
  projectId: string,
  taskId: string,
  taskData: Partial<BackendEditTaskInputs>
): Promise<string> {
  // TODO: probably still currently returns "success"; should return new contents so we can use it
  return post("edittask", {
    // spell-checker:ignore edittask
    body: {
      taskData: {
        ...taskData,
        task_id: taskId,
      },
      project_id: projectId,
    },
  });
}

export function deleteTask(taskId: string, projectId: string): Promise<string> {
  // TODO: probably still currently returns "success"; should return new contents so we can use it
  return post("removeobject", {
    body: {
      task_id: taskId,
      project_id: projectId,
      deleteObject: "Task",
    },
  });
}

export function markTaskCompleted(
  projectId: string,
  taskId: string,
  completed: boolean,
  user: string
): Promise<string> {
  // TODO: probably still currently returns "success"; should return new contents so we can use it
  return post("completedtask", {
    // spell-checker:ignore completedtask
    body: {
      completed,
      task_id: taskId,
      project_id: projectId,
      user,
    },
  });
}
/**
 * Clean up a response from the backend. For who knows what reason, number values such as e.g. task_value
 * are stored as a string. And yet most of the code does math on them as if they are numbers. Sometimes
 * they have commas, I've seen the string value 'NaN', etc.
 *
 * @param {array<object>} tasks Tasks list received from the backend
 */
export const sanitizeTasksResponse: (
  // TODO: stop exporting, make everybody use our APIs
  response: BackendRawGetProjectItemsTasksResponse
) => BackendGetProjectItemsTasksResponse = (response) => {
  const { tasks, ...unmodifiedProps } = response;

  return tasks
    ? {
        ...unmodifiedProps,
        tasks: tasks.map(sanitizeIndividualTask),
      }
    : (response as unknown as BackendGetProjectItemsTasksResponse);
};

/**
 * Create a new task on the backend
 *
 * @param project_id project id to associate the task with
 * @param retention percentage to hold back. We should not really need this here; see https://github.com/Project-Centerline/project-centerline-ac-backend/issues/37, https://github.com/Project-Centerline/project-centerline-ac-backend/issues/38
 * @param email email address of creator // TODO: Get this from auth in backend; no need to send
 * @param ingredients new task ingredients
 */
export function createTask(
  project_id: string,
  retention: number,
  email: string,
  ingredients: CreateTaskInputs
): Promise<Task> {
  const { value, ...rest } = ingredients;
  return post("addtask", {
    body: {
      project_id,
      retention,
      user: email,
      newTask: {
        ...rest,
        taskvalue: value, // spell-checker:ignore taskvalue
      },
    },
  }).then(sanitizeIndividualTask);
}

export const DecisionType = z.enum([
  "full_concierge_inspection",
  "feasibility_report",
  "standard",
]);
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type DecisionType = z.infer<typeof DecisionType>;

export const CreateDecisionInputs = z
  .object({
    projectId: z.string(),
    description: z.string(),
    task: z
      .object({
        title: z.string().optional(),
        task_id: z.string().optional(),
      })
      .refine((task) => task.title || task.task_id, {
        message: "You must specify either title or task_id",
      })
      .optional(),
    email: z.string().email(),
    timeFrame: z.date().nullable(),
    files: z.array(storedFileZodSchema),
    type: DecisionType,
  })
  .refine(
    (inputs) =>
      inputs.task ||
      inputs.type === "feasibility_report" ||
      inputs.type === "full_concierge_inspection",
    "Some form of task identifier is required (except for PC inspection or feasibility)"
  );
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type CreateDecisionInputs = z.infer<typeof CreateDecisionInputs>;

/**
 * Create a new decision on the backend
 *
 * @param project_id project id to associate the task with
 * @param retention percentage to hold back. We should not really need this here; see https://github.com/Project-Centerline/project-centerline-ac-backend/issues/37, https://github.com/Project-Centerline/project-centerline-ac-backend/issues/38
 * @param email email address of creator // TODO: Get this from auth in backend; no need to send
 * @param ingredients new task ingredients
 */
export function createDecision({
  projectId,
  description,
  task,
  email,
  timeFrame,
  files,
  type,
}: CreateDecisionInputs): Promise<BackendGetProjectItemsDecisionsResponseEntry> {
  return post("adddecision", {
    // spell-checker:ignore adddecision
    body: {
      project_id: projectId,
      description,
      task,
      user: email,
      timeframe: timeFrame, // spell-checker:ignore timeframe
      filename: files,
      type,
    },
  })
    .then(sanitizeGetProjectDecisionsResponse)
    .then((response) => response[0]);
}

export const TaskAPIs = {
  createTask,
  editTask,
  markTaskCompleted,
  deleteTask,
};
export type TaskAPIsType = typeof TaskAPIs;

const ModernProjectFile = t.intersection([
  BackendProjectFile,
  t.type({
    title: NonEmptyString,
    storage_key: NonEmptyString,
  }),
]); // new enough backend

const OldBackendProjectFile = t.intersection([
  BackendProjectFile,
  t.type({
    title: NonEmptyString,
    storage_key: t.null,
  }),
]); // new enough backend

// type MostModernProjectFile = S3ProjectFile & {
//   title: string;
//   storageKey: string;
//   temp_tile?: string;
// };

const BackendRawProjectFile = t.intersection([
  t.union([OldBackendProjectFile, ModernProjectFile]),
  t.partial({
    temp_title: t.string,
  }),
]);
// eslint-disable-next-line @typescript-eslint/no-redeclare
type BackendRawProjectFile = t.TypeOf<typeof BackendRawProjectFile>;

export /* i feel dirty */ const sanitizeIndividualGetProjectInvoicesResponseEntry: (
  tasksById: Record<TaskId, Task>
) => (raw: WireInvoice) => Invoice =
  (tasksById) =>
  // flow(
  (raw) =>
    pipe(
      raw,
      //   {
      //     ...raw,
      //     items: Array.isArray(raw.items)
      //       ? Object.fromEntries(
      //           raw.items.map(([taskId, stringOrObject]) => [
      //             taskId,
      //             typeof stringOrObject === "string"
      //               ? { amount: Number(stringOrObject) }
      //               : stringOrObject,
      //           ])
      //         )
      //       : raw.items,
      //   },
      // APIInvoice.decode,
      // E.chain(({ files, ...i }) =>
      //   {
      //     const foo =
      //   const newLocal = files.map(synthesizeFileIfNecessary);
      //     return E.right({ ...i, storedFiles: newLocal });
      //   }
      // ),
      InvoiceFromWire(Task)({ tasksById, logAnomaly }).decode,
      // E.chain(foo => foo),
      E.mapLeft((errors) => {
        logAnomaly(PathReporter.report(t.failures(errors)));
        return errors;
      }),
      getOrThrowDecodeError
    );

const sanitizeGetProjectInvoicesResponse: (
  tasks: Task[]
) => (raw: WireInvoice[]) => Invoice[] = (tasks) => (raw) =>
  raw.map(
    sanitizeIndividualGetProjectInvoicesResponseEntry(byProp(tasks)("id"))
  );

export function getProjectInvoices(
  projectId: string,
  email: string,
  /// temporary
  tasks: Task[]
): Promise<Invoice[]> {
  return post("getprojectitems", {
    body: {
      project_id: projectId,
      email: email,
      item: "Invoices",
    },
  }).then(sanitizeGetProjectInvoicesResponse(tasks));
}

export function createInvoice(
  projectId: ProjectId,
  email: EmailAddress,
  drawItems: Invoice["drawItems"],
  isPut: Invoice["is_put"],
  files: StoredFile[],
  /// temporary
  tasks: Task[]
): Promise<Invoice[]> {
  // TODO: probably still currently returns "success"; should return new contents so we can use it

  return post("adddrawrequest", {
    // spell-checker: ignore adddrawrequest
    body: t.exact(AddDrawBodySchema).encode({
      project_id: projectId,
      file: files, // TODO: really?
      creator: email,
      drawRequest: drawItems.map(({ amount, task }) => [
        task.title,
        amount,
        task.id,
      ]),
      isPut,
    }),
  }).then((newDraw) => sanitizeGetProjectInvoicesResponse(tasks)([newDraw]));
}

export function addFilesToExistingInvoice(
  files: File[],
  {
    invoice_id: invoiceId,
    project_id: projectId,
  }: { invoice_id: string; project_id: string },
  email: string,
  progress: UploadProgressFn
): Promise<unknown> {
  // TODO: probably still currently returns "success"; should return new contents so we can use it

  if (!files || files.length === 0) {
    return Promise.reject("No files");
  }

  return uploadSupportingFiles({ files, prefix: projectId, progress }).then(
    (storedFiles) => addStoredFileToInvoice(invoiceId, email, storedFiles)
  );
}

export function addStoredFileToInvoice(
  invoiceId: string,
  email: string,
  storedFiles: StoredFile[]
): Promise<unknown> {
  return post("adddrawrequest", {
    // spell-checker:ignore adddrawrequest
    body: {
      invoice_id: invoiceId,
      user: email,
      file: storedFiles,
      type: "Update",
    },
  });
}

export interface UploadProgress {
  file: Pick<File, "name">;
  loaded: number;
  total: number;
}

export type UploadProgressFn = (arg: UploadProgress) => void;

const retryPolicy = (retries: number) =>
  capDelay(2000, Monoid.concat(exponentialBackoff(200), limitRetries(retries)));

const logRetry = (
  { cumulativeDelay, iterNumber, previousDelay }: RetryStatus,
  { name }: Pick<File, "name">
) =>
  TE.rightIO(() =>
    pipe(
      previousDelay,
      O.map((delay) =>
        logAnomaly(new Error("retry upload"), {
          name,
          cumulativeDelay,
          previousDelay,
          iterNumber,
        })
      )
    )
  );

/**
 * Upload a single file, usually associated with a project.
 * NB: You probably want to follow this with a call to validateUploadedFile
 * @param file file to upload
 * @param prefix a "directory path segment" prefix, usually a project id
 */
export function uploadSupportingFile({
  file,
  prefix,
  progress,
  retries = 2,
}: {
  file: File;
  prefix: string;
  progress?: UploadProgressFn;
  retries?: number;
}): Promise<StoredFile> {
  progress?.({ file, loaded: 0, total: file.size });
  return pipe(
    retrying(
      retryPolicy(retries),
      (status) =>
        pipe(
          logRetry(status, file),
          TE.apSecond(
            TE.tryCatch(
              () =>
                s3Upload(file, prefix, ({ loaded, total }) =>
                  progress?.({ file, loaded, total })
                ),
              E.toError
            )
          )
        ),
      E.isLeft
    ),
    TE.getOrElse((err) => () => Promise.reject(err))
  )()
    .then((result) => {
      progress?.({ file, loaded: file.size, total: file.size });
      return result;
    })
    .catch((error) => {
      logError(new Error("Failed upload"), { error, file, prefix });
      return Promise.reject(error);
    });
}

/**
 * Validate a single uploaded file
 * @param file local file
 * @param storedFile (unwrapped) result from @see uploadSupportingFile
 * @returns a promise that resolves if all is well
 */
export function validateUploadedFile(file: File, storedFile: StoredFile) {
  return s3ListFiles(storedFile.storageKey).then((pagedListFilesResult) => {
    if (pagedListFilesResult.hasNextToken) {
      // if we ever see this, will have to go through the pain.
      logError(
        new Error(
          "Not handling paged file list result; upload error may sneak by"
        ),
        { pagedListFilesResult }
      );
    }
    const listedFile = pagedListFilesResult.results.find(
      (item) => item.key === storedFile.storageKey
    );
    if (listedFile?.size === file.size) {
      return { storedFile, listedFile };
    }

    const error = new Error("Upload consistency error");
    logError(error, {
      file,
      storedFile,
      listedFile,
      pagedListFilesResult,
    });
    return Promise.reject(error);
  });
}

/**
 * Upload files, usually associated with a project
 * @param files files to upload
 * @param prefix a "directory path segment" prefix, usually a project id
 */
export function uploadSupportingFiles({
  files,
  prefix,
  progress,
  retries = 2,
}: {
  files: File[];
  prefix: string;
  progress?: UploadProgressFn;
  retries?: number;
}): Promise<StoredFile[]> {
  // serialize.  would https://dev.to/gnomff_65/fp-ts-sequencet-and-sweet-sweet-async-typed-fp-5aop be clearer?
  const fileUploads = pipe(
    files,
    A.map((file) =>
      TE.tryCatch(
        () => uploadSupportingFile({ file, prefix, progress }),
        E.toError
      )
    ),
    A.sequence(TE.ApplicativeSeq),
    TE.getOrElse((err) => () => Promise.reject(err))
  )();

  return fileUploads.then((storedFiles) => {
    return storedFiles.length < 1
      ? []
      : s3ListFiles(`${prefix}/`).then((pagedListFilesResult) => {
          if (pagedListFilesResult.hasNextToken) {
            // if we ever see this, will have to go through the pain.
            logError(
              new Error(
                "Not handling paged file list result; upload error may sneak by"
              ),
              { pagedListFilesResult }
            );
          }
          const failures = storedFiles
            .map((storedFile, i) => [
              files[i],
              pagedListFilesResult.results.find(
                ({ key }) => key === storedFile.storageKey
              ),
            ])
            .filter(
              ([expected, actual]) => !actual || actual.size !== expected?.size
            );
          if (failures.length > 0) {
            const error = new Error("Upload consistency error");
            logError(error, {
              files,
              storedFiles,
              filesMatchingPrefix: pagedListFilesResult,
              failures,
            });
            return Promise.reject(error);
          }
          return storedFiles;
        });
  });
}

export function deleteInvoice(invoice: Invoice): Promise<string> {
  // TODO: probably still currently returns "success"; should return new contents so we can use it

  return post("removeobject", {
    body: {
      invoice_id: invoice.invoice_id,
      removeObject: "Invoice",
    },
  });
}

export function updateInvoiceStatus(
  invoiceId: string,
  action: InvoiceActions,
  email: string,
  projectId: string,
  role: string
): Promise<string> {
  // TODO: probably still currently returns "success"; should return new contents so we can use it

  return post("approvedraw", {
    // spell-checker:ignore approvedraw
    body: {
      invoice_id: invoiceId,
      approval: action,
      user: email,
      project_id: projectId,
      role: role,
    },
  });
}

function approveDecision({
  projectId,
  decisionId,
  approval,
  email,
  role,
}: {
  projectId: string;
  decisionId: string;
  email: string;
  role: Role;
  approval: ApprovalStatus;
}): Promise<unknown> {
  return post("approvedecision", {
    //spell-checker:ignore approvedecision
    body: {
      approval,
      approver: email,
      project_id: projectId,
      role,
      decision_id: decisionId,
    },
  });
}

export function reverseInvoice({
  invoiceId,
  projectId,
  callerEmail,
}: {
  invoiceId: string;
  projectId: string;
  callerEmail: string;
}): Promise<{
  updatedInvoice: Invoice;
  updatedTasks: BackendRawGetProjectItemsTasksResponse;
  updatedProject: BackendGetProjectsRawResponse;
}> {
  return post("reversedraw", {
    // spell-checker:ignore reversedraw
    body: {
      invoice_id: invoiceId,
      project_id: projectId,
    },

    ...(process.env.REACT_APP_BACKEND === "local"
      ? {
          headers: {
            "x-caller-email": callerEmail,
          },
        }
      : {}),
  }).then((updates) => ({
    ...updates,
    updatedInvoice: sanitizeIndividualGetProjectInvoicesResponseEntry(
      updates.updatedInvoice
    ),
  }));
}

// TODO: InvoiceAPIs didn't gain us much did they? remove.
export const InvoiceAPIs = {
  createInvoice,
  addFilesToExistingInvoice,
  uploadSupportingFiles,
  deleteInvoice,
  updateInvoiceStatus,
};

export type InvoiceAPIsType = typeof InvoiceAPIs;

// spell-checker:ignore addtask

export function getProjects({
  email,
  role,
  summary,
  health,
  before,
  after,
  limit,
}: {
  email: string;
  role: Role;
  summary?: boolean;
  health?: boolean;
  before?: string;
  after?: string;
  limit?: number;
}): Promise<BackendGetProjectsResponse> {
  const extraFields = [];
  summary && extraFields.push("summary");
  health && extraFields.push("health");
  return post("getprojects", {
    body: {
      email: email,
      role: role,
      extraFields,
      filter: {
        before,
        after,
        limit,
      },
    },
  })
    .then(sanitizeGetProjectsResponse)
    .then(deduplicateTasks);
}

export function deleteProject({
  projectId,
  role,
}: {
  projectId: string;
  role: Role;
}): Promise<void> {
  if (role !== "Super Admin") {
    const error = new Error("only admins can delete projects");
    logError(error, { projectId, role });
    throw error;
  }
  return post("removeobject", {
    body: {
      role: role,
      project_id: projectId,
      deleteObject: "Project",
    },
  }) as Promise<void>;
}

export function getProjectDetails(
  projectId: string
): Promise<PartiallyModernizedGetProjectDetailsResponse> {
  return post("getproject", {
    // spell-checker:ignore getproject
    body: {
      project_id: projectId,
    },
    response: true,
  }).then(
    (response: {
      data: BackendGetProjectsRawResponse;
      headers: Record<string, string>;
    }) => ({
      ...sanitizeIndividualProject(response.data[0]),
      etag: response.headers.etag,
    })
  );
}

export function getProjectUsers(
  projectId: string
): Promise<BackendUserResponse[]> {
  return post("getuserfullinfo", {
    // spell-checker:ignore getuserfullinfo
    body: {
      project_id: projectId,
    },
  }).then((response) => response.filter((item: unknown) => !!item)); // I've seen projects with blanks in the email list. Probably only broken ones on staging, but this can't hurt.
}

// TODO: remove uploader_files from backend as we just obsoleted it. Then remove this comment

const synthesizeStoredProjectFile = synthesizeFileIfNecessary(
  PersistentProjectFile
);
const sanitizeGetProjectFilesResponse: (raw: {
  userFiles: BackendRawProjectFile[]; // TODO: figure out where these come from, get a sample
}) => BackendGetProjectFilesResponse = (raw) => {
  return {
    userFiles: raw.userFiles.map((file) => {
      return {
        ...file,
        ...getOrThrowDecodeError(synthesizeStoredProjectFile(file)),
      };
    }),
  };
};

export function getProjectFiles(
  email: string,
  projectId: string
): Promise<BackendGetProjectFilesResponse> {
  return post("getprojectitems", {
    body: {
      email: email,
      item: "Files",
      all: true,
      project_id: projectId,
    },
  }).then(sanitizeGetProjectFilesResponse);
}

const synthesizeStoredFile = synthesizeFileIfNecessary(StoredFile);
const sanitizeGetProjectDecisionsResponse: (
  raw: BackendGetProjectItemsDecisionsRawResponseEntry[]
) => BackendGetProjectItemsDecisionsResponseEntry[] = (raw) =>
  raw.map(({ files, ...rest }) => ({
    ...rest,
    storedFiles: pipe(
      files,
      A.map(flow(synthesizeStoredFile, getOrThrowDecodeError))
    ),
  }));

export function getProjectDecisions(
  projectId: string,
  role: string,
  email: string
): Promise<BackendGetProjectItemsDecisionsResponseEntry[]> {
  return post("getprojectitems", {
    body: {
      project_id: projectId,
      role: role,
      email: email,
      item: "Decisions",
    },
  }).then(sanitizeGetProjectDecisionsResponse);
}

export function deleteDecision({
  decision_id,
}: {
  decision_id: string;
}): Promise<void> {
  return post("removeobject", {
    body: {
      decision_id,
      deleteObject: "Decision",
    },
  });
}

// spell-checker:ignore addtask taskvalue

export const ProjectAPIs = {
  getProjectUsers,
  getProjectTasks,
};

export function editProjectDetails(
  projectId: string,
  mode: "replace" | "loan" | "status",
  data: {
    loan?: Partial<LoanModel>;
    status?: ProjectStatus;
  }
): Promise<PartiallyModernizedGetProjectDetailsResponse> {
  const { loan } = data;
  return post("editproject", {
    // spell-checker:ignore editproject
    body: {
      // TODO: I just realized as I was copying this from editTask, that *anyone* can edit without even saying who they are (as long as they can auth)
      projectId,
      mode,
      data: {
        ...data,
        loan: {
          ...loan,
          loanContact: loan?.loanContact?.split("\n") ?? null,
        },
      },
    },
  }).then(sanitizeIndividualProject);
}

// extract optional and/or renamed props
const {
  new_square_feet,
  new_value,
  original_square_feet,
  original_value,
  property_type,
  ...asIsProps
} = PatchProjectBodySchema.props;

export const ProjectInfoFormSchema = t.intersection([
  t.type(asIsProps),
  t.type({
    originalSquareFeet: original_square_feet,
    newSquareFeet: new_square_feet,
    originalValue: original_value,
    futureValue: new_value,
    propertyType: property_type,
  }),
]);
type ProjectInfoFormOutput = t.TypeOf<typeof ProjectInfoFormSchema>;

const UpdateProjectInputsFromProjectInfoFormOutput =
  new t.Type<PatchProjectBody>(
    "PatchProjectBodyFromProjectInfoForm",
    PatchProjectBodySchema.is,
    flow(
      ProjectInfoFormSchema.validate,
      chain((form: ProjectInfoFormOutput) => {
        const {
          originalSquareFeet,
          originalValue,
          newSquareFeet,
          futureValue,
          propertyType,
          ...rest
        } = form;
        return either.right({
          ...rest,
          new_square_feet: newSquareFeet,
          new_value: futureValue,
          original_square_feet: originalSquareFeet,
          original_value: originalValue,
          property_type: propertyType,
        });
      })
    ),
    t.identity
  );

/**
 * Like @see editProjectDetails but uses the PATCH endpoint.
 */
export function updateProjectDetails({
  projectId,
  updates,
  etag,
}: {
  projectId: string;
  updates: ProjectInfoFormOutput;
  etag?: string;
}): Promise<legacyBackendGetProjectDetailsResponse> {
  return pipe(
    updates,
    UpdateProjectInputsFromProjectInfoFormOutput.decode,
    fold(Promise.reject, (body) =>
      patch(`project/${projectId}`, {
        body,
        headers: {
          "If-Match": etag,
        },
      }).then(sanitizeIndividualProject)
    )
  );
}

export function updateUser({
  userId,
  updates,
  etag,
}: {
  userId: UserId;
  updates: RecursivePartial<Pick<User, "settings">>;
  etag?: string;
}): Promise<legacyBackendGetProjectDetailsResponse> {
  return patch(`user/${userId}`, {
    body: updates,
    headers: {
      "If-Match": etag,
    },
  });
}

export function addFileToProject(fileInfo: {
  projectId: string;
  invoiceId?: string;
  taskId?: string;
  storedFile: StoredFile;
  allowedUsers: Readonly<{ email: string }[]>;
  email: string;
  tags?: string[];
  deviceLocation?: Position;
  gpsInfo?: GpsOutput;
}): Promise<BackendGetProjectFilesResponse> {
  const {
    storedFile,
    projectId: project_id,
    invoiceId: invoice_id,
    taskId: task_id,
    allowedUsers,
    email: uploader,
    tags,
    deviceLocation,
    gpsInfo,
  } = fileInfo;
  return post("addfile", {
    // spell-checker:ignore addfile

    body: {
      file: storedFile,
      users: allowedUsers.map(({ email }) => email),
      project_id,
      invoice_id,
      task_id,
      uploader,
      tags,
      deviceLocation,
      gpsInfo,
    },
  });
}

export interface AddUserInfo {
  email: EmailAddress;
  first_name: string;
  last_name: string;
  phone_number: string;
  company?: string;
  role: Role;
  division?: string;
  subdivision?: string;
  external: boolean;
}
export function addUserToProject(
  projectId: string,
  requestorEmail: EmailAddress,
  userToAdd: AddUserInfo,
  opt?: { quiet?: boolean }
): Promise<BackendUserResponse> {
  const { role, ...userInfo } = userToAdd;
  return post("adduser", {
    // spell-checker:ignore adduser
    body: {
      project_id: projectId,
      role,
      user: requestorEmail.trim().toLowerCase(),
      userInfo,
      ...opt,
    },
  }).then(({ user }) => user);
}

export function removeUserFromProject(
  projectId: string,
  userEmail: EmailAddress
): Promise<void> {
  return post("removeobject", {
    // spell-checker:ignore removeobject
    body: {
      project_id: projectId,
      role: userEmail, /// @deprecated use userEmail https://trello.com/c/sQIL2yz4/567-remove-user-api-sends-user-email-as-role
      userEmail,
      deleteObject: "User",
    },
  });
}

export function removeUserFromAllProjects({
  id,
}: Pick<User, "id">): Promise<void> {
  return post("removeobject", {
    // spell-checker:ignore removeobject
    body: {
      id,
      removeObject: "Leave All Projects",
    },
  });
}

export function replaceUserInAllProjects(
  replace: Pick<User, "id">,
  replaceWith: Pick<User, "id">
): Promise<void> {
  return post("admin/user/replace", {
    // spell-checker:ignore removeobject
    body: {
      replace: replace.id,
      replaceWith: replaceWith.id,
    },
  });
}

// TODO: There should not be two ways to leave a project
function leaveProject({
  email,
  role,
  projectId,
}: {
  email: string;
  role: Role;
  projectId: string;
}): Promise<void> {
  return post("removeobject", {
    body: {
      user: email,
      role: role,
      project_id: projectId,
      removeObject: "Leave Project",
    },
  });
}

export type EditUserInfo = Omit<
  BackendUserResponse,
  | "projects"
  | "created_at"
  | "external"
  | "tags" // I fully expect to edit tags eventually but RN it simplifies :-)
  | "role"
> &
  Pick<User, "role">;

export function editUser(u: EditUserInfo): Promise<void> {
  const { role, ...userInfo } = u;
  return post("edituser", {
    // spell-checker:ignore edituser
    body: {
      role,
      userInfo,
    },
  });
}

export function removeFile({ file_id }: { file_id: string }): Promise<void> {
  return post("removeobject", {
    body: {
      file_id,
      deleteObject: "File",
    },
  });
}

export function getUserRoleAndNotificationsByEmail(
  email: EmailAddress
): Promise<BackendGetUserRoleResponse> {
  return post("getuserrole", {
    // spell-checker:ignore getuserrole
    body: {
      email,
    },
  }).then(sanitizeGetUserRoleResponse);
  // .then((response) => {
  //   setNotifications(response.notifications);
  // });
}

export function removeNotifications(
  projectId: string,
  email: EmailAddress,
  type: NotificationTypeEnum
): Promise<void> {
  return post("removenotifications", {
    // spell-checker:ignore removenotifications
    body: {
      project_id: projectId,
      user: email,
      value: type,
    },
  });
}

export function getComments({
  decisionId,
  invoiceId,
}: {
  decisionId: BackendGetCommentsResponse["decision_id"];
  invoiceId: BackendGetCommentsResponse["invoice_id"];
}): Promise<BackendGetCommentsResponse[]> {
  if (!decisionId && !invoiceId) {
    return Promise.reject({
      reason: "Must specify either invoice or decision",
    });
  }
  return post("getcomments", {
    // spell-checker:ignore getcomments
    body: {
      decision_id: decisionId,
      invoice_id: invoiceId,
    },
  });
}

export function addComment({
  decisionId,
  invoiceId,
  message,
  email,
}: {
  decisionId: BackendGetCommentsResponse["decision_id"];
  invoiceId: BackendGetCommentsResponse["invoice_id"];
  message: BackendGetCommentsResponse["message"];
  email: string;
}): Promise<void> {
  return post("addcomment", {
    // spell-checker:ignore addcomment
    body: {
      decision_id: decisionId,
      invoice_id: invoiceId,
      comment: message,
      email,
    },
  });
}

export function deleteComment(commentId: string): Promise<unknown> {
  // TODO: probably still currently returns "success"; should return new contents so we can use it
  return post("removeobject", {
    body: {
      commentId,
      deleteObject: "Comment",
    },
  });
}

function getConfig(): Promise<BackendGetConfigResponse> {
  return get("config", {});
}

function getNVMSConfig(token: string): Promise<BackendGetNVMSConfigResponse> {
  return get("config/nvms", {
    headers: {
      "X-token": token,
    },
  });
}

function getStripeStuff(): Promise<BackendGetStripeResponse> {
  return get("config/stripe", {});
}

export interface FetchError {
  message?: string;
  response?: {
    status: number;
  };
}

export function getEntityList(): Promise<Entity[]> {
  return get("entity").then((data: BackendGetEntityListResponse) =>
    data.map((entry) => {
      const { ETag: etag, entity } = entry;
      return { ...entity, etag };
    })
  );
}

export function createEntity({
  email,
  entity,
}: {
  email: string;
  entity: Omit<Entity, "id">;
}): Promise<Entity> {
  return post("entity", {
    body: wireEntity(entity),
    response: true,
  }).then(
    (response: {
      data: Omit<Entity, "etag">;
      headers: Record<string, string>;
    }) => {
      const etag = response.headers.ETag;
      const newEntity = { ...response.data, etag };
      return newEntity;
    }
  );
}

export function updateEntity({
  entity: entityWithEtag,
}: {
  entity: Entity;
}): Promise<Entity> {
  const { etag, ...entity } = entityWithEtag;

  return put(`entity/${entity.id}`, {
    body: wireEntity(entity),
    headers: {
      "If-Match": etag,
    },
    response: true,
  });
}

function wireEntity(entity: Omit<Entity, "id">) {
  const { features } = entity;
  const { frontend } = features || {};
  const { onDemandInspections } = frontend || {};
  return {
    ...entity,
    features: {
      ...features,
      frontend: {
        ...frontend,
        onDemandInspections: Array.from(onDemandInspections ?? []).filter(
          (role) => role !== Role.SuperAdmin
        ),
      },
    },
  };
}

export function deleteEntity({
  id,
  etag,
}: Pick<Entity, "id" | "etag">): Promise<void> {
  return del(`entity/${id}`, {
    Headers: { "If-Match": etag },
  });
}

export function getUser({
  identity,
  email,
  id,
}: {
  identity?: string;
  email?: string;
  id?: UserId;
}): Promise<User & { etag: string }> {
  if (!email && !identity && !id) {
    throw new Error("Must specify id or email or identity");
  }

  const query = id
    ? `user/${id}`
    : email
    ? `user?email=${encodeURIComponent(email)}`
    : `user?id=${identity && encodeURIComponent(identity)}`; // should have been ?identity=
  return get(query, { response: true }).then(
    (response: { data: User; headers: Record<string, string> }) => ({
      ...response.data,
      etag: response.headers.etag,
    })
  );
}

export function fixUser({
  id,
}: {
  id: UserId;
}): Promise<{ actionsTaken: string[] }> {
  return post("admin/fix", {
    body: {
      userId: id,
    },
  });
}

export function requestTempPassword({
  email,
}: {
  email: string;
}): Promise<{ actionsTaken: string[] }> {
  return post("help/temppassword", {
    // spell-checker:ignore temppassword
    body: {
      email,
    },
  });
}

export function allUsers(): Promise<{
  users: BackendGetUserDetailsResponse[];
}> {
  return get(`user?scan=*`);
}

export function fetchInspectionStatus(token: string | null): Promise<
  | {
      status: "ready";
      address: {
        streetAddress: string;
        city: string;
        stateCode: string;
        zip: string;
      };
    }
  | { status: "invoice-changed" }
> {
  return post("inspection/validate", {
    body: {
      token,
    },
  });
}

export function dispatchInspection(token: string): Promise<string> {
  return post("inspection/dispatch", {
    body: {
      token,
    },
  });
}

export function updateDecisionRouting({
  projectId,
  approvers,
}: {
  projectId: string;
  approvers: ReadonlyArray<Pick<User, "email">>;
}): Promise<{
  approverChanges: { decision_id: string; current_approver: string }[];
}> {
  return post("updaterouting", {
    //spell-checker:ignore updaterouting
    body: {
      decisionRouting: approvers.map(({ email }) => ({ email })),
      type: "Decision",
      project_id: projectId,
    },
  });
}

export function updateProjectInvoiceRouting({
  projectId,
  approvers,
}: {
  projectId: string;
  approvers: Pick<User, "email">[];
}): Promise<{
  approverChanges: (Pick<Invoice, "invoice_id"> & {
    current_approver: EmailAddress;
  })[];
}> {
  return post("updaterouting", {
    //spell-checker:ignore updaterouting
    body: {
      invoiceRouting: approvers.map(({ email }) => ({ email })),
      type: "Invoice",
      project_id: projectId,
    },
  });
}

export function updateIndividualInvoiceRouting({
  projectId,
  invoiceId,
  approvers,
  comment,
}: {
  projectId: string;
  invoiceId?: string;
  approvers: ReadonlyArray<Pick<User, "email">>;
  comment?: string;
}): Promise<WireInvoice> {
  return post("updaterouting", {
    //spell-checker:ignore updaterouting
    body: {
      invoiceRouting: approvers.map(({ email }) => ({ email })),
      invoiceId,
      type: "Invoice",
      project_id: projectId,
      comment,
    },
  });
}

function editInvoice({
  body: { drawItems, invoice_id: invoiceId, ...body },
  etag,
  tasks,
}: {
  etag: string;
  body: Pick<Invoice, "invoice_id"> &
    Partial<Pick<Invoice, "drawItems" | "storedFiles" | "paid">> & {
      email: EmailAddress;
    };
  /// temporary
  tasks: Task[];
}): Promise<{
  updated: Invoice;
  orphaned: Invoice;
}> {
  const tasksById = byProp(tasks)("id");
  return post("editinvoice", {
    // spell-checker:ignore editinvoice
    body: t.exact(EditDrawBodySchema).encode({
      ...body,
      invoiceId,
      ...(drawItems && {
        draws: drawItems.map(({ amount, task }) => ({
          taskName: task.title,
          amount,
          taskId: task.id,
        })),
      }),
    }),
    headers: { "If-Match": etag },
  }).then(({ updated, orphaned }) => ({
    updated:
      sanitizeIndividualGetProjectInvoicesResponseEntry(tasksById)(updated),
    orphaned:
      sanitizeIndividualGetProjectInvoicesResponseEntry(tasksById)(orphaned),
  }));
}

function addLenderAdmin({
  lender,
}: {
  lender: BusinessPersonInputs;
}): Promise<void> {
  return post("addlendersetup", {
    // spell-checker:ignore addlendersetup
    body: {
      lenderData: lender,
      type: "LenderAdmin",
    },
  });
}

function selfAddLender({
  lender,
}: {
  lender: BusinessPersonInputs;
}): Promise<void> {
  return post("addlendersetup", {
    // spell-checker:ignore addlendersetup
    body: {
      lenderData: lender,
      selfAdd: true,
    },
  });
}

// TODO: get rid of "string |"
export interface AddProjectInputs {
  address: string;
  city: string;
  state: string;
  zipcode: string;
  /* spell-checker: ignore projecttype owneroccupied originalsquarefeet newsquarefeet originalvalue appraisedvalue squarefeet */
  projecttype?: string;
  owneroccupied?: string | boolean;
  originalsquarefeet?: string | number;
  newsquarefeet?: string | number;
  originalvalue?: string | number;
  appraisedvalue?: string | number;
  squarefeet?: string | number;
}

function addProject({
  projectData,
  uploadedFiles,
  role,
  user,
}: {
  projectData: { lender?: BusinessPersonInputs; project: AddProjectInputs };
  uploadedFiles: StoredFile[];
  role: Role;
  user: string;
}): Promise<string /* project id */> {
  return post("addproject", {
    //spell-checker:ignore addproject
    body: {
      projectData,
      fileData: uploadedFiles,
      type: "Add Project",
      role,
      user,
    },
  });
}

// TODO: There should not be two ways to add users to a project
function addUserToProject_projectVariant(
  {
    projectData,
    existingUserId,
    type,
    projectId,
  }: { projectId: string } & (
    | {
        projectData: { lender: BusinessPersonInputs };
        type: "Add Lender";
        existingUserId?: undefined;
      }
    | {
        type: "Add Existing";
        existingUserId: string;
        projectData?: undefined;
      }
  ),
  opt?: { quiet?: boolean }
): Promise<void> {
  return post("addproject", {
    body: {
      projectData,
      type,
      project_id: projectId,
      existingUserId,
      ...(opt?.quiet && { quiet: opt.quiet }),
    },
  });
}

function addChatRoom({
  roomName,
  namesInChat,
  email,
  projectId,
}: {
  roomName: string;
  namesInChat: string[];
  email: string;
  projectId: string;
}): Promise<void> {
  return post("addchatroom", {
    // spell-checker:ignore addchatroom
    body: {
      title: roomName,
      users: namesInChat,
      creator: email,
      project_id: projectId,
    },
  });
}

export interface ChatRoom {
  room_name: string;
  chat_room_id: string;
  users: string[];
}

function getChatRooms({
  email,
  role,
  projectId,
}: {
  email: string;
  role: Role;
  projectId: string;
}): Promise<ChatRoom[]> {
  return post("getprojectitems", {
    body: {
      user: email,
      role,
      item: "Chat Rooms",
      project_id: projectId,
    },
  });
}

export interface ChatData {
  action: string;
  chat_room_id: string;
  project_id: string;
  message: string;
  user: string;
  sender_name: string;
  sender_company: string;
  created_at: string;
}

function getChats({ chatRoomId }: { chatRoomId: string }): Promise<ChatData[]> {
  return post("getprojectitems", {
    body: {
      chat_id: chatRoomId,
      item: "Chats",
    },
  });
}

function performSearch({
  projectId,
  query,
}: {
  projectId: string;
  query: string;
}): Promise<{
  tasks: BackendGetTaskResponse[];
  decisions: BackendGetSearchRawDecision[];
  draws: BackendGetSearchRawDraw[];
  chats: BackendGetSearchRawChat[];
}> {
  return post("getsearch", {
    // spell-checker:ignore getsearch
    body: {
      project_id: projectId,
      query,
    },
  });
}

export function getInspection({
  id,
}: {
  id: string;
}): Promise<BackendGetInspectionResponse> {
  if (!id) {
    throw new Error("Must specify id");
  }

  return get(`inspection/${id}`);
}

/** observed (and not surprising) backend API error shape(s) */
export interface APIError {
  response?: {
    status?: number;
    data?: {
      reason?: string;
    };
  };
}
export const openApiGetRequestAdapter: HttpRequestAdapter = (url, init) => {
  // console.log({ url, init });
  const got = get(url.startsWith("/") ? url.slice(1) : url, {
    ...init,
    response: true,
  }).then(
    ({ headers, status, data }) =>
      new Response(JSON.stringify(data) /* err, that sucks :-) */, {
        headers,
        status,
      })
  );
  // console.log({ got });
  return got;
};
const requestFunctions = requestFunctionsBuilder(openApiGetRequestAdapter);

export function getProjectDrawActivity(projectId: Project["project_id"]) {
  return requestFunctions.getInvoiceActivity({
    params: { projectId },
  });
}

// export function getProjectDrawActivity(
//   projectId: Project["project_id"]
// ): Promise<string> {
//   if (!projectId) {
//     throw new Error("Must specify id");
//   }

//   return get(`project/${projectId}/invoices/reports/activity`);
// }

const exportedAPIs = {
  getProjects,
  deleteProject,
  getProjectDetails,
  getProjectDecisions,
  getProjectFiles,
  getProjectInvoices,
  getProjectTasks,
  getProjectUsers,
  addFileToProject,
  addUserToProject,
  removeUserFromProject,
  editUser,
  editProjectDetails,
  createInvoice,
  deleteInvoice,
  uploadSupportingFiles,
  addFilesToExistingInvoice,
  updateInvoiceStatus,
  reverseInvoice,
  deleteTask,
  editTask,
  markTaskCompleted,
  removeFile,
  getUserRoleAndNotificationsByEmail,
  removeNotifications,
  createTask,
  getComments,
  addComment,
  deleteComment,
  createDecision,
  getConfig,
  getNVMSConfig,
  getStripeStuff,
  getEntityList,
  fixUser,
  updateDecisionRouting,
  updateProjectInvoiceRouting,
  updateIndividualInvoiceRouting,
  approveDecision,
  editInvoice,
  addLenderAdmin,
  selfAddLender,
  addProject,
  addUserToProject_projectVariant,
  leaveProject,
  addChatRoom,
  getChatRooms,
  getChats,
  performSearch,
  getInspection,
  getProjectDrawActivity,
  updateUser,
};

export default exportedAPIs;
