import React from "react";
import { FormControlLabel, Switch, SwitchProps } from "@mui/material";
import { Controller, Path, PathValue, useFormContext } from "react-hook-form";

export type ControlledSwitchProps<Inputs extends Record<string, unknown>> = {
  name: Path<Inputs>;
  description: string;
  disabled?: boolean;
  onChange?: SwitchProps["onChange"];
  defaultValue?: PathValue<Inputs, Path<Inputs>>;
};

// TODO: figure out how to make types play well here
export const ControlledSwitch = <Inputs extends Record<string, unknown>>({
  name,
  description,
  disabled,
  onChange,
  defaultValue,
}: ControlledSwitchProps<Inputs>) => {
  const { control } = useFormContext<Inputs>();
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { value, onChange: formOnChange, ...rest } }) => (
        <FormControlLabel
          control={
            <Switch
              checked={Boolean(value)}
              {...rest}
              disabled={disabled}
              onChange={(evt, checked) => {
                formOnChange(evt, checked);
                onChange && onChange(evt, checked);
              }}
            />
          }
          label={description}
        />
      )}
    />
  );
};
