import { EditTaskForm, EditTaskFormProps } from "@/Forms";
import { SlideUp } from "@/Lib/Transitions";
import { Task } from "@/Models";
import { Dialog, DialogTitle } from "@mui/material";
import { useTaskFiles } from "../../../Lib/hooks/useTaskFiles";

export interface ViewTaskDialogProps {
  task?: Task;
  draw?: EditTaskFormProps["draw"];
  done: () => void;
}

export const ViewTaskDialog = ({ done, task, draw }: ViewTaskDialogProps) => {
  const existingFiles = useTaskFiles(task);
  return (
    <Dialog open={Boolean(task)} onClose={done} TransitionComponent={SlideUp}>
      <DialogTitle>Item Details</DialogTitle>
      <EditTaskForm
        existingFiles={existingFiles}
        onCancel={done}
        onSubmit={() => Promise.resolve(done())}
        taskData={task}
        draw={draw}
        disabled={true}
      />
    </Dialog>
  );
};

export default ViewTaskDialog;
