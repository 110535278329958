import * as t from "io-ts";
import { MustSatisfy } from "./misc";

export enum LoanType {
  Term = "Term Loan",
  LoC = "Line of Credit",
}

export const LoanTypeC = t.union([
  t.literal(LoanType.LoC),
  t.literal(LoanType.Term),
]);
/* eslint-disable @typescript-eslint/no-unused-vars */
type InferredLoanType = t.TypeOf<typeof LoanTypeC>;
type _sanityLoanType = MustSatisfy<InferredLoanType, LoanType>;
type _sanityLoanType2 = MustSatisfy<LoanType, InferredLoanType>;
/* eslint-enable @typescript-eslint/no-unused-vars */
