import React from "react";
import { styled } from "@mui/material/styles";
import { Storage } from "aws-amplify";
import { useAppContext } from "@lib/UserContext";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { Divider } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ApprovalStatus } from "@lib/APITypes";
import { useDecisionComments, usePermissions } from "@lib/hooks";
import { Decision } from "./DisplayDecisions";
import { invertedButtonStyle } from "../../../projectCenterlineTheme";
import { DisplayFiles } from "../../../Components/DisplayFiles";
import {
  StoredFile,
  LinkedFile,
} from "@project-centerline/project-centerline-api-types";
import { throwIfNot } from "@lib/util/throwIfNot";
import { useAuth } from "@lib/hooks/useAuth";
import { CommentEntry } from "@/Components/Comment";

const PREFIX = "DecisionCard";

const classes = {
  formControl: `${PREFIX}-formControl`,
  buttonSubmit: `${PREFIX}-buttonSubmit`,
};

const StyledCard = styled(Card)(({ theme: { palette } }) => {
  return {
    [`& .${classes.formControl}`]: {
      width: "50vw",
      fontSize: "2em",
      borderRadius: "80%",
    },
    [`& .${classes.buttonSubmit}`]: {
      ...invertedButtonStyle({ palette }),
      marginTop: "5px",
      width: "100%",
    },
  };
});

// TODO: it's hacky that most actions are delegated tot he parent but comment is inside here?
export function DecisionCard({
  decision,
  onDeleteRequest,
  onApproval,
  onShowFiles,
}: {
  decision: Decision;
  onDeleteRequest: () => void;
  onApproval: (status: ApprovalStatus) => void;
  onShowFiles: (files: (StoredFile | LinkedFile)[]) => void;
}): JSX.Element {
  const [requestedApproval, setRequestedApproval] = React.useState<
    ApprovalStatus | 0
  >(0);
  const [commentEntry, setCommentEntry] = React.useState("");
  const {
    comments = [],
    addComment,
    removeComment,
  } = useDecisionComments(decision.decision_id);
  const { canRemoveDecisionComment } = usePermissions();
  const [files, setFiles] = React.useState<StoredFile[]>([]);
  React.useEffect(() => {
    let stillMounted = true;
    Promise.all(
      decision.storedFiles.map(async (file) => ({
        ...file,
        name: file.path,
        url: (await Storage.get(file.storageKey)) as string,
      }))
    ).then((resolvedFiles) => {
      if (stillMounted) {
        setFiles(resolvedFiles);
      }
    });
    return function cleanup() {
      stillMounted = false;
    };
  }, [decision.storedFiles]);

  const { role } = useAppContext(); // TODO: move this to permissions?
  const email = throwIfNot(useAuth().currentUserEmail, "email is required");
  return (
    <StyledCard className="project-card">
      {role === "Super Admin" ? (
        <CardHeader
          avatar={
            <ClearIcon
              color="secondary"
              onClick={onDeleteRequest}
              style={{ cursor: "pointer" }}
            />
          }
          title={
            decision.inspector ? (
              <Typography variant="h5">
                {decision.type === "feasibility_report"
                  ? "** Feasibility Report **"
                  : "** Concierge Inspection ** "}
              </Typography>
            ) : (
              decision.task_name
            )
          }
        />
      ) : (
        <CardHeader title={decision.task_name} />
      )}
      <Divider />
      <CardContent>
        <div>
          <p>Need by Date</p>
          <FormControl variant="standard" fullWidth>
            <TextField
              variant="standard"
              required
              value={
                decision.timeframe
                  .split("T")
                  .shift() /* spell-checker:ignore timeframe */
              }
            />
          </FormControl>
        </div>
        <div>
          <p>Description</p>
          <FormControl variant="standard" fullWidth>
            <TextField
              required
              multiline
              variant="outlined"
              rows={4}
              value={decision.description ?? ""}
              name="description"
            />
          </FormControl>
        </div>
        {decision.approval_status === "Pending" ? (
          <div>
            {email === decision.current_approver ? (
              <strong>
                <p>Current Approver: {decision.current_approver}</p>
              </strong>
            ) : (
              <p>Current Approver: {decision.current_approver}</p>
            )}
          </div>
        ) : (
          <p>Final Approver: {decision.final_approver}</p>
        )}
        {files.length > 0 ? (
          <DisplayFiles files={files} onClick={() => onShowFiles(files)} />
        ) : null}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "10px",
            marginBottom: "10px",
          }}
        >
          <Typography variant="body2">
            Current Status: {decision.approval_status}
          </Typography>
        </div>
        <Divider />
        {(role === "Super Admin" || decision.current_approver === email) &&
        decision.approval_status === ApprovalStatus.Pending ? (
          <div
            style={{
              marginTop: "15px",
              marginBottom: "15px",
            }}
          >
            <p>Set Approval</p>
            <FormControl variant="standard" className={classes.formControl}>
              <Select
                variant="standard"
                value={requestedApproval}
                id={decision.decision_id}
                onChange={({ target: { value } }) => {
                  setRequestedApproval(value as ApprovalStatus);
                }}
                placeholder="Set Approval"
                disabled={decision.approval_status !== ApprovalStatus.Pending}
              >
                <MenuItem value={0}>
                  <em>Set Approval</em>
                </MenuItem>
                <MenuItem value="Approved">Approve</MenuItem>
                <MenuItem value="Rejected">Reject</MenuItem>
              </Select>
            </FormControl>
          </div>
        ) : null}
        <Accordion style={{ marginTop: "10px" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="comment-content"
            id="comment-header"
          >
            <p>Comments ({comments.length})</p>
          </AccordionSummary>
          <AccordionDetails style={{ flexDirection: "column" }}>
            {comments.map((comment) => (
              <CommentEntry
                comment={comment}
                onRemoveComment={
                  canRemoveDecisionComment(decision) && removeComment
                }
              />
            ))}
            <form>
              <FormControl variant="standard" fullWidth>
                <TextField
                  required
                  multiline
                  variant="outlined"
                  value={commentEntry}
                  rows={4}
                  name="comment"
                  onChange={({ target: { value } }) => setCommentEntry(value)}
                />
              </FormControl>
              <Button
                onClick={() => {
                  commentEntry.length &&
                    addComment(commentEntry).then(() => {
                      setCommentEntry("");
                    });
                }}
                color="primary"
                disabled={!commentEntry.length}
              >
                Submit
              </Button>{" "}
            </form>
          </AccordionDetails>
        </Accordion>
        {(role === "Super Admin" || decision.current_approver === email) &&
        decision.approval_status === ApprovalStatus.Pending ? (
          <Button
            disabled={
              decision.approval_status !== ApprovalStatus.Pending ||
              !requestedApproval
            }
            onClick={() => requestedApproval && onApproval(requestedApproval)}
            className={classes.buttonSubmit}
          >
            {(requestedApproval === ApprovalStatus.Approved &&
              "Submit Approval") ||
              (requestedApproval === ApprovalStatus.Rejected &&
                "Submit Rejection") ||
              "(select approve or reject above)"}
          </Button>
        ) : null}
      </CardContent>
    </StyledCard>
  );
}
