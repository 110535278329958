// import CheckCircleIcon from "@mui/icons-material/CheckCircle";
//import WarningIcon from "@mui/icons-material/Warning";
// import BlockIcon from "@mui/icons-material/Block";
// import { ProjectAPIs } from "@lib/API";
import {
  CircularProgress,
  Grid,
  IconButton,
  LinearProgress,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Collapse,
  Box,
  Menu,
  TextField,
  InputAdornment,
  Tooltip,
  Button,
  useMediaQuery,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { TableCellProps } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import {
  differenceInDays,
  formatDistanceStrict,
  formatDistanceStrictWithOptions,
  isAfter /* isAfter, subDays */,
  subDays,
} from "date-fns/fp";
import React, { useEffect, useMemo } from "react";
import sanitizeFilename from "sanitize-filename";
import { CSVExport } from "../../Components/CSVExport";
import { HealthIndicator, HealthLabel } from "../../Components/HealthIndicator";
import { HtmlTooltip } from "../../Components/HtmlTooltip";
import { LoanInfoTooltip } from "../../Components/LoanInfoTooltip";
import { ProjectCount } from "../../Components/ProjectCount";
import { StealthyLink } from "../../Components/StealthyLink";
import { Tile } from "../../Components/Tile";
import { TileContainer } from "../../Components/TileContainer";
import {
  ApprovalStatus,
  BackendGetProjectsResponse,
  BackendGetProjectsResponseEntry,
  EntityFeatures,
  ProjectStatus,
} from "@lib/APITypes";
import { LoanType } from "@lib/LoanType";
import {
  AppPermissions,
  Features,
  useAnalytics,
  useFeatures,
  useMoney,
  usePermissions,
} from "@lib/hooks";
import { MoneyFormatter } from "@lib/Money";
import { Role } from "@lib/roles";
// import DatePicker from '@mui/lab/DatePicker';
import { replaceInvalidNumberWith } from "@lib/util";
import HistoryIcon from "@mui/icons-material/History";
import InfoView from "../ProjectView/Info/InfoView";
import { filenameDateFormat } from "@lib/util/filenameDateFormat";
import { useLocation, useNavigate } from "react-router-dom";
import { ProjectViewTabs } from "../ProjectView";
import { format } from "date-fns";
import config from "../../config";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { useInspectors } from "@lib/hooks/useInspectors";
import { useAppContext } from "@lib/UserContext";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import qs from "qs";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SearchIcon from "@mui/icons-material/Search";
import { useSnackbar } from "notistack";
import { useEntityFeatures } from "@lib/hooks/useEntityFeatures";
import { throwIfNot } from "@lib/util/throwIfNot";
import { useAuth } from "@lib/hooks/useAuth";
import { useNewUIHook } from "@/Lib/hooks/useNewUI";
import { useAdminData } from "@/Lib/AdminContext";
import { BulkUserAdder } from "@/Components/BulkUserAdder";
import { EmailAddress } from "@project-centerline/project-centerline-api-types";

interface InspectorProps {
  email: string;
  vendor: string;
}

const NESTED_FEATURE = false;

enum InvoiceActions {
  None = "None",
  Approve = "Approve",
  Reject = "Reject",
  Edit = "Edit",
}

const DotDotDotMenu: (props: {
  projectId: string;
  status: ProjectStatus;
  onRequestDelete?: (projectId: string) => void;
  onUpdateStatus?: (
    projectId: string,
    newStatus: ProjectStatus,
    pending: unknown[]
  ) => void;
  pending: unknown[];
}) => JSX.Element = ({
  onUpdateStatus,
  projectId,
  status,
  onRequestDelete,
  pending,
}) => {
  const [taskMenuAnchor, setTaskMenuAnchor] = React.useState<
    HTMLElement | undefined
  >();
  const closeMenu = () => {
    setTaskMenuAnchor(undefined);
  };

  return (
    <div>
      <IconButton
        tabIndex={-1}
        aria-label="more actions"
        onClick={({ currentTarget }) => {
          setTaskMenuAnchor(currentTarget);
        }}
        size="large"
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={taskMenuAnchor}
        keepMounted
        open={Boolean(taskMenuAnchor)}
        onClose={closeMenu}
      >
        {onUpdateStatus ? (
          <MenuItem
            onClick={() => {
              onUpdateStatus(
                projectId,
                status === ProjectStatus.InProgress
                  ? ProjectStatus.Complete
                  : ProjectStatus.InProgress,
                pending
              );
              closeMenu();
            }}
          >
            {status === ProjectStatus.InProgress ? "Complete" : "Un-Complete"}
          </MenuItem>
        ) : null}

        {onRequestDelete ? (
          <MenuItem
            onClick={() => {
              onRequestDelete(projectId);
              closeMenu();
            }}
          >
            Delete
          </MenuItem>
        ) : null}
      </Menu>
    </div>
  );
};

/**
 * Instructions how to generate a given column from a project (whether we display that column or not)
 */
interface DisplayColumn {
  label: string | JSX.Element;
  numeric: boolean;
  disablePadding?: boolean;
  derive: (
    ingredients: BackendGetProjectsResponseEntry,
    extra: {
      urlBase: string;
      permissions: AppPermissions;
      entityFeatures: EntityFeatures;
      onRequestDelete?: (projectId: string) => void;
      onUpdateStatus?: (
        projectId: string,
        newStatus: ProjectStatus,
        pending: unknown[]
      ) => void;
      isConciergeInspector: (arg: { email: string }) => boolean;
      isFullConciergeInspector: (arg: { email: string }) => boolean;
      availableInspectors: Record<string, InspectorProps>;
      prefix: string;
    }
  ) => {
    value: number | string;
    formatted: string | JSX.Element;
    csv?: number | string;
  };
  sortFormatted?: boolean;
  linkToProjectView?: string; // TODO: projectview should export topics+sub-tabs
  csvHeader?: string;
  summable?: boolean;
  align?: TableCellProps["align"];
  alignHeader?: TableCellProps["align"];
  /** show sorters in table heading? DEFAULT IS TRUE IF UNSPECIFIED */
  sortable?: boolean; // NB: gets defaulted TRUE in `Column` identity function
}

const dateColumnCommon = {
  props: {
    numeric: true,
    sortFormatted: true, // because we dropped the time portion for display, and the date format already sorts right as strings.
  },
  format: (epochMs?: number) =>
    epochMs ? format(new Date(epochMs), "yyyy-MM-dd") : "---",
  formatForCSV: (epochMs?: number) =>
    epochMs ? format(new Date(epochMs), "M/d/yyyy") : "",
};

function effectiveInvoiceTimestamp({
  approvalTimestamp,
  timestamp,
}: {
  approvalTimestamp?: string | null;
  timestamp: string;
}) {
  return approvalTimestamp ?? timestamp;
}

function adjustedTaskValue({
  totalTaskValue,
  totalActiveTaskValue,
  loanType,
}: {
  totalTaskValue: number;
  totalActiveTaskValue: number;
  loanType: LoanType;
}) {
  return loanType === LoanType.LoC ? totalActiveTaskValue : totalTaskValue;
}

const invoiceDateComparator = (
  a: { approvalTimestamp?: string | null; timestamp: string },
  b: { approvalTimestamp?: string | null; timestamp: string }
) => {
  const aStamp = effectiveInvoiceTimestamp(a);
  const bStamp = effectiveInvoiceTimestamp(b);
  return aStamp < bStamp ? -1 : aStamp > bStamp ? 1 : 0;
};

const deriveNextAction = (
  project: BackendGetProjectsResponseEntry,
  {
    permissions,
    isConciergeInspector,
    availableInspectors,
    prefix,
  }: {
    permissions: AppPermissions;
    isConciergeInspector: (arg: { email: string }) => boolean;
    availableInspectors: Record<string, InspectorProps>;
    prefix: string;
  }
) => {
  const now = new Date();
  const fromNow = formatDistanceStrict(now);
  const recentDeadline = subDays(5)(now);
  const isRecent = isAfter(recentDeadline);

  const {
    /* loanAmount, */ summary,
    project_id: projectId,
    project_status: status,
  } = project;
  const { canSeeDecision } = permissions;

  const { draws, mri = [], p = [], pci = [] } = summary ?? {};
  const { Pending = 0, Approved = 0, Rejected = 0 } = draws ?? {};
  const sortedPendingInvoices = p.sort(invoiceDateComparator);
  // const mostRecentInvoice = mri
  //   ?.map(({ i }) => i)
  //   .sort(invoiceDateComparator)[0];
  // const mostRecentPendingInvoice = sortedPendingInvoices[0];
  // const oldestPendingInvoice = sortedPendingInvoices.slice(-1)[0];

  const lastDraw = lastDrawDate(mri);

  let decision:
    | {
        value: string;
        tooltipTitle?: string;
        link?: string;
        text: string;
        csv?: string;
      }
    | undefined;

  if (status === ProjectStatus.Complete) {
    decision = {
      value: "Completed",
      text: `Project has been marked closed.`,
    };
  }

  // PC inspections, for those who can see them
  const myInspection = pci[0];
  if (
    !decision &&
    myInspection &&
    canSeeDecision({ inspector: myInspection.i })
  ) {
    decision = {
      value:
        myInspection.t === "feasibility_report"
          ? "Feasibility Pending"
          : "Concierge Inspection Requested",
      link: `${prefix ?? ""}/projectview/${projectId}/Decision`,
      text:
        myInspection.t === "feasibility_report"
          ? "A Feasibility Report is in progress"
          : "A Project Centerline Inspection is pending",
    };
  } else {
    if (!decision) {
      //3. Inspections (no I don't agree with the order in the doc :-))
      const inspections = sortedPendingInvoices.filter(
        ({ role }) => role === Role.Inspector || role === Role.InspectorAdmin
      );
      if (inspections.length > 0) {
        const inspection = inspections[0];
        if (isRecent(new Date(effectiveInvoiceTimestamp(inspection)))) {
          const waitingOnConcierge = isConciergeInspector({
            email: inspection.currentApprover,
          });
          const inspectionVendor =
            availableInspectors[
              inspection.delegate || inspection.currentApprover
            ]?.vendor;
          decision = {
            value: `Inspection ${
              waitingOnConcierge && !inspection.delegate
                ? "Requested"
                : "Pending"
            }`,
            link: `${prefix ?? ""}/projectview/${projectId}/Invoices/Pending`,
            text:
              waitingOnConcierge && !inspection.delegate
                ? `Waiting for Project Centerline to dispatch an inspector${
                    inspectionVendor &&
                    !isConciergeInspector({
                      email: inspection.currentApprover,
                    })
                      ? ` from ${inspectionVendor}`
                      : ""
                  }.`
                : `${fromNow(
                    new Date(effectiveInvoiceTimestamp(inspection))
                  )} with ${
                    inspectionVendor ||
                    `inspector ${inspection.currentApprover}`
                  }`,
          };
        }
      }
    }

    //2. Draw Pending
    if (!decision && Pending > 0) {
      const invoice /* mostRecentPendingInvoice */ =
        sortedPendingInvoices.slice(-1)[0];
      const { currentApprover, delegate } = invoice;
      const conciergeDispatched =
        delegate && isConciergeInspector({ email: delegate });
      const effectiveApprover = conciergeDispatched
        ? delegate
        : currentApprover;
      const inspectorVendor =
        effectiveApprover && availableInspectors[effectiveApprover]?.vendor;
      const inspector = inspectorVendor || effectiveApprover || "(unknown)";

      decision = {
        value: isRecent(new Date(effectiveInvoiceTimestamp(invoice)))
          ? `${putOrDrawWord(invoice)} Pending`
          : "Delayed",
        link: `${prefix ?? ""}/projectview/${projectId}/Invoices/Pending`,
        text: conciergeDispatched
          ? `Inspection initiated with ${inspector} ${fromNow(lastDraw)} ago`
          : `${inspector} has been reviewing for ${fromNow(lastDraw)}`,
      };
    }

    // 4. Approved / Rejected
    const lastDrawFinalized = mri?.slice(0, 1)[0]?.i;
    if (
      !decision &&
      lastDrawFinalized &&
      isRecent(new Date(lastDrawFinalized.approvalTimestamp))
    ) {
      const approvedRejected = lastDrawFinalized.status;
      //     ? "Draw Approved"
      //     : "Draw Rejected";
      //     const invoiceCategory = approvedRejected === "Draw Approved" ? "Approved" : "Rejected";
      decision = {
        value: `${putOrDrawWord(lastDrawFinalized)} ${approvedRejected}`,
        link: `${
          prefix ?? ""
        }/projectview/${projectId}/Invoices/${approvedRejected}`,
        text: `${lastDrawFinalized.finalApprover} ${approvedRejected} ${fromNow(
          new Date(lastDrawFinalized.approvalTimestamp)
        )} ago`,
      };
    }

    // 5. Last Draw
    if (!decision && (Approved > 0 || Rejected > 0)) {
      decision = {
        value: "Awaiting Next Transaction",
        link: `${prefix ?? ""}/projectview/${projectId}/${
          ProjectViewTabs.Tasks
        }`,
        text: `${fromNow(lastDraw)} since last put or draw`,
      };
    }

    // https://projectcenterline.slack.com/team/U0138G0TZCN
    if (Pending === 0 && Approved === 0 && Rejected === 0) {
      decision = {
        value: "--",
        tooltipTitle: "No Draws",
        link: `${prefix ?? ""}/projectview/${projectId}/${
          ProjectViewTabs.Tasks
        }`,
        text: `No draws have been requested. Click here to add one.`,
        csv: "",
      };
    }

    // // 1. Pre-funding
    // if (isNaN(loanAmount ?? Number.NaN)) {
    //   return {
    //     value: "Pre-funding",
    //     formatted: "Pre-funding",
    //   };
    // }
  }
  if (decision) {
    const { value, link, text, tooltipTitle } = decision;
    const shortFormDiv = <div>{value}</div>;
    return {
      value,
      formatted: link ? (
        <HtmlTooltip
          disableFocusListener
          disableTouchListener
          title={
            <React.Fragment>
              <StealthyLink to={link}>
                <Typography color="inherit">{tooltipTitle || value}</Typography>
                {text}
              </StealthyLink>
            </React.Fragment>
          }
        >
          {shortFormDiv}
        </HtmlTooltip>
      ) : (
        shortFormDiv
      ),
      csv: decision.csv ?? `${value}: ${text}`,
    };
  }

  return {
    value: "",
    formatted: "",
  };
};

const effectiveAmount = ({
  loanAmount,
  totalTaskValue,
  totalActiveTaskValue,
  loanType,
}: Pick<
  BackendGetProjectsResponseEntry,
  "loanAmount" | "totalTaskValue" | "totalActiveTaskValue" | "loanType"
>) =>
  loanAmount ||
  adjustedTaskValue({ totalActiveTaskValue, totalTaskValue, loanType }) ||
  NaN;

/*
 * This list defines all the possible columns to be displayed, which for now is the same as the existing list :-) but
 * which will grow as time goes on. Then a selector (below, `const views = ...`) chooses a subset and ordering. The
 * `derive` member generates a "value" (which is what is sorted on by default) from an object based on the project
 * record, as well as (rarely used but required for `health`) the entire response from the server (as `__response`).
 * That "value" is fed to `format` for display. For things that *should* be sorted on their string values - like, well,
 * things that are natively strings :-) - you can set `sortFormatted` to be truthy.
 */
const Column = (generic: DisplayColumn) => ({
  ...generic,
  sortable: generic.sortable ?? true,
});
const availableColumns = {
  Name: Column({
    numeric: false,
    label: "Project Name",
    derive: ({ name, project_id }, { urlBase, prefix }) => ({
      value: name,
      formatted: name,
      csv: `=HYPERLINK(""${urlBase}${
        prefix ?? ""
      }/projectview/${project_id}"", ""${name}"")`,
    }),
    linkToProjectView: ProjectViewTabs.Tasks,
  }), // TODO: #65 global styles/themes
  FullAddress: Column({
    numeric: false,
    label: "Full Address",
    derive: ({ address, city, state, zipcode }, { urlBase }) => {
      const value = `${address}, ${city}, ${state}, ${zipcode}`;
      return {
        value,
        formatted: value,
        csv: value,
      };
    },
    linkToProjectView: ProjectViewTabs.Tasks,
  }),
  NumDraws: Column({
    numeric: true,
    label: "# Draws",
    derive: ({ summary }) => {
      const { draws } = summary || {};
      const value =
        (draws?.Approved ?? 0) + (draws?.Pending ?? 0) + (draws?.Rejected ?? 0);
      return {
        value,
        formatted: String(value) ?? "---",
        csv: value ?? "",
      };
    },
  }),
  LoanAmount: Column({
    numeric: true,
    label: InfoView.Tabs.Loan,
    derive: ({ loanAmount }) => {
      const value = loanAmount ?? Number.NaN;
      return {
        value,
        formatted: Number.isNaN(value) ? "---" : MoneyFormatter.format(value),
        csv: Number.isNaN(value) ? "" : MoneyFormatter.format(value),
      };
    },
    linkToProjectView: InfoView.Tabs.Loan,
    summable: true,
  }),
  Amount: Column({
    numeric: true,
    label: "Amount",
    derive: (project) => {
      const value = effectiveAmount(project);
      return {
        value,
        formatted: Number.isNaN(value) ? "---" : MoneyFormatter.format(value),
        csv: Number.isNaN(value) ? "" : MoneyFormatter.format(value),
      };
    },
    linkToProjectView: InfoView.Tabs.Loan,
    summable: true,
  }),
  EffectiveBudget: Column({
    numeric: true,
    label: "Effective Budget",
    derive: ({ effectiveBudget }: { effectiveBudget: number }) => ({
      value: effectiveBudget,
      formatted: MoneyFormatter.format(effectiveBudget),
    }),
    summable: true,
  }),
  TotalTaskValue: Column({
    numeric: true,
    label: "Total of Tasks",
    derive: ({
      totalTaskValue,
      loanType,
    }: Pick<
      BackendGetProjectsResponseEntry,
      "totalTaskValue" | "loanType"
    >) => {
      const value = totalTaskValue;
      return { value, formatted: MoneyFormatter.format(value) };
    },
    summable: true,
  }),
  TotalActiveTaskValue: Column({
    numeric: true,
    label: "Total of Active Tasks",
    derive: ({
      totalTaskValue,
      totalActiveTaskValue,
      loanType,
    }: Pick<
      BackendGetProjectsResponseEntry,
      "totalTaskValue" | "totalActiveTaskValue" | "loanType"
    >) => {
      const value = adjustedTaskValue({
        loanType,
        totalTaskValue,
        totalActiveTaskValue,
      });
      return { value, formatted: MoneyFormatter.format(value) };
    },
    summable: true,
  }),
  EndDate: Column({
    label: "Est. Completion",
    derive: ({ end_date }: { end_date: string }) => {
      const value = new Date(end_date).getTime();
      const formatted = dateColumnCommon.format(value);
      return { value, formatted, csv: dateColumnCommon.formatForCSV(value) };
    },
    ...dateColumnCommon.props,
    linkToProjectView: "Project", // TODO: https://projectcenterline.slack.com/archives/C01F1S4GS9J/p1619135702012100?thread_ts=1619135542.012000&cid=C01F1S4GS9J
  }),
  PercentComplete: Column({
    numeric: true,
    label: "Complete",
    derive: ({
      effectiveBudget,
      budgetRemaining,
    }: {
      effectiveBudget: number;
      budgetRemaining: number;
    }) => {
      const value =
        ((effectiveBudget - budgetRemaining) / effectiveBudget) * 100;
      const formatted = `${replaceInvalidNumberWith(0)(Math.round(value))}%`;
      return { value, formatted };
    },
  }),
  PercentBudgetRemaining: Column({
    numeric: true,
    label: "% Remaining",
    derive: ({
      effectiveBudget,
      budgetRemaining,
    }: {
      effectiveBudget: number;
      budgetRemaining: number;
    }) => {
      const value = (budgetRemaining / effectiveBudget) * 100;
      const formatted = `${replaceInvalidNumberWith("0")(Math.round(value))}%`;
      return { value, formatted };
    },
  }),
  LoanMaturityDate: Column({
    label: "Loan Maturity",
    derive: ({ maturityDate }) => {
      const value = maturityDate?.getTime() ?? 0;
      const formatted = dateColumnCommon.format(value);
      return { value, formatted, csv: dateColumnCommon.formatForCSV(value) };
    },
    ...dateColumnCommon.props,
    linkToProjectView: InfoView.Tabs.Loan,
  }),
  LoanStartDate: Column({
    label: "Loan Start",
    derive: ({ startDate }) => {
      const value = startDate?.getTime() ?? 0;
      const formatted = dateColumnCommon.format(value);
      return { value, formatted, csv: dateColumnCommon.formatForCSV(value) };
    },
    ...dateColumnCommon.props,
    linkToProjectView: InfoView.Tabs.Loan,
  }),
  Health: Column({
    label: <HealthLabel />,
    derive: ({
      health,
      project_id,
      loanAmount,
    }: BackendGetProjectsResponseEntry) => ({
      value: health ? health[0]?.severity ?? 0 : -1,
      formatted: (
        <HealthIndicator
          health={health}
          projectId={project_id}
          hasLoan={!isNaN(loanAmount ?? Number.NaN)}
        />
      ),
      csv: health?.[0]?.color ?? "",
    }),
    numeric: true,
    csvHeader: "Health",
  }),
  HealthMetric: Column({
    label: <HealthLabel />,
    derive: ({ health }) => {
      const value = health ? health?.[0]?.severity ?? 0 : -1;
      return { value, formatted: String(value) };
    },
    numeric: true,
    csvHeader: "Health Danger Score",
  }),
  RemainingLooserBalance: Column({
    label: "Remaining",
    derive: ({
      loanAmount,
      loanType,
      budget_spent,
      totalTaskValue,
      totalActiveTaskValue,
      project_id,
      disbursedAtClosing,
    }: Pick<
      BackendGetProjectsResponseEntry,
      | "loanAmount"
      | "loanType"
      | "budget_spent"
      | "totalTaskValue"
      | "totalActiveTaskValue"
      | "project_id"
      | "disbursedAtClosing"
    >) => {
      const topLine = effectiveAmount({
        loanAmount,
        loanType,
        totalTaskValue,
        totalActiveTaskValue,
      });
      const uglyLoCAdjustment =
        loanType === LoanType.LoC ? totalTaskValue - totalActiveTaskValue : 0;
      const balance = isNaN(topLine)
        ? NaN
        : topLine +
          uglyLoCAdjustment -
          Number(budget_spent) -
          (disbursedAtClosing || 0);
      return {
        value: balance,
        formatted: Number.isNaN(balance) ? (
          <LoanInfoTooltip
            projectId={project_id}
            description="Remaining balance"
            hasLoan={!isNaN(loanAmount ?? Number.NaN)}
          >
            ---
          </LoanInfoTooltip>
        ) : (
          MoneyFormatter.format(balance)
        ),
        csv: Number.isNaN(balance) ? "" : MoneyFormatter.format(balance),
      };
    },
    numeric: true,
    summable: true,
  }),
  RemainingLoanBalance: Column({
    label: "Loan Balance",
    derive: ({ loanAmount, budget_spent, project_id }) => {
      const safeLoanAmount = loanAmount ?? Number.NaN;
      const value = isNaN(safeLoanAmount)
        ? NaN
        : safeLoanAmount - Number(budget_spent);
      return {
        value,
        formatted: Number.isNaN(value) ? (
          <LoanInfoTooltip
            projectId={project_id}
            description="Balance"
            hasLoan={!isNaN(loanAmount ?? Number.NaN)}
          >
            ---
          </LoanInfoTooltip>
        ) : (
          MoneyFormatter.format(value)
        ),
        csv: MoneyFormatter.format(value),
      };
    },
    numeric: true,
    summable: true,
  }),
  RemainingTaskBalance: Column({
    label: "Task Balance",
    derive: ({
      totalTaskValue,
      totalActiveTaskValue,
      loanType,
      budget_spent,
      project_id,
    }) => {
      const actingTotalTaskValue = adjustedTaskValue({
        totalTaskValue,
        totalActiveTaskValue,
        loanType,
      });
      const value = isNaN(actingTotalTaskValue)
        ? NaN
        : actingTotalTaskValue - Number(budget_spent);
      return {
        value,
        formatted: Number.isNaN(value) ? (
          <LoanInfoTooltip
            projectId={project_id}
            description="Tasks"
            hasLoan={!isNaN(actingTotalTaskValue)}
          >
            ---
          </LoanInfoTooltip>
        ) : (
          MoneyFormatter.format(value)
        ),
        csv: MoneyFormatter.format(value),
      };
    },
    numeric: true,
    summable: true,
  }),
  LoanIdentifier: Column({
    derive: ({ loanIdentifier }) => ({
      value: loanIdentifier ?? "",
      formatted: loanIdentifier ?? "---",
      csv: loanIdentifier ?? "",
    }),
    label: "Loan Id",
    numeric: false,
    linkToProjectView: InfoView.Tabs.Loan,
    sortFormatted: true,
    alignHeader: "right",
  }),
  LoanType: Column({
    derive: ({ loanType }) => ({ value: loanType, formatted: loanType }),
    label: "Loan Type",
    numeric: false,
    linkToProjectView: InfoView.Tabs.Loan,
    sortFormatted: true,
  }),
  ProjectId: Column({
    derive: ({ project_id }) => ({ value: project_id, formatted: project_id }),
    label: "Project Id",
    numeric: false,
    linkToProjectView: ProjectViewTabs.Tasks,
    sortFormatted: true,
  }),
  Status: Column({
    derive: ({ project_status }) => ({
      value: project_status,
      formatted: project_status,
    }),
    label: "Project Status",
    numeric: false,
    linkToProjectView: ProjectViewTabs.Tasks,
    sortFormatted: true,
  }),
  Link: Column({
    derive: ({ project_id }, { urlBase, prefix }) => {
      const value = `${urlBase}${prefix ?? ""}/projectview/${project_id}`;
      return { value, formatted: value };
    },
    label: "Link",
    numeric: false,
    sortFormatted: true,
    // deriveCSV: ({ project_id }, { protocol, host }) =>
    //   `=HYPERLINK(""${protocol}//${host}${prefix ?? ""}/projectview/${project_id}"")`,
  }),
  Actions: Column({
    label: <HistoryIcon />,
    derive: deriveNextAction,
    numeric: false,
    align: "left",
    alignHeader: "center",
    csvHeader: "Action",
  }),
  Custom1: Column({
    label: "Investor", // TODO: feature flag sensitive
    derive: ({ custom_1 }) => ({
      value: custom_1 ?? "",
      formatted: custom_1 ?? "",
    }),
    align: "center",
    alignHeader: "center",
    numeric: false,
  }),
  DotDotDot: Column({
    label: "More",
    alignHeader: "right",
    derive: (
      { project_id, project_status, summary },
      { onRequestDelete, onUpdateStatus, permissions }
    ) => ({
      value: "-",
      formatted: (
        <DotDotDotMenu
          projectId={project_id}
          status={project_status as ProjectStatus}
          onRequestDelete={
            permissions.canDeleteProjects ? onRequestDelete : undefined
          }
          onUpdateStatus={onUpdateStatus}
          pending={summary?.p ?? []}
        />
      ),
      csv: "(you should not be seeing this)",
    }),
    numeric: false,
    sortable: false,
  }),
  Delete: Column({
    label: " ",
    derive: ({ project_id }, { onRequestDelete }) => ({
      value: "-",
      formatted: (
        <IconButton onClick={() => onRequestDelete?.(project_id)} size="large">
          <DeleteForeverIcon />
        </IconButton>
      ),
      csv: "(you should not be seeing this)",
    }),
    numeric: false,
  }),
  SearchAttributes: Column({
    derive: ({
      address,
      city,
      custom_1,
      loanContact,
      loanBorrowerOverride,
      loanIdentifier,
      name,
      project_id,
      zipcode, // spell-checker:ignore zipcode
      users,
    }) => {
      const value = [
        address,
        city,
        custom_1,
        loanContact,
        loanBorrowerOverride,
        loanIdentifier,
        name,
        project_id,
        zipcode,
      ]
        .concat(users)
        .join(":");
      return {
        value,
        formatted: value,
      };
    },
    label: "search",
    numeric: false,
  }),
  DaysSinceLastDraw: Column({
    derive: ({ summary }) => {
      const { mri } = summary || {};
      const lastDraw = mri && lastDrawDate(mri);
      const lastDrawTime = lastDraw?.getTime() ?? Number.NaN;
      if (Number.isNaN(lastDrawTime)) {
        return { value: Number.NaN, formatted: "-", csv: "" };
      }

      const now = Date.now();
      return {
        value: now - lastDrawTime,
        formatted: String(differenceInDays(lastDrawTime)(now)),
        csv: differenceInDays(lastDrawTime)(now),
      };
    },
    label: <Box style={{ wordWrap: "break-word" }}>Days Since Last Draw</Box>,
    csvHeader: "Days since last Draw",
    numeric: true,
  }),
  DaysInDataSinceLastDraw: Column({
    derive: ({ summary }) => {
      const { mri } = summary || {};
      const lastDraw = mri && lastDrawDate(mri);
      const lastDrawTime = lastDraw?.getTime() ?? Number.NaN;
      if (Number.isNaN(lastDrawTime)) {
        return { value: Number.NaN, formatted: "-" };
      }

      const now = Date.now();
      return {
        value: now - lastDrawTime,
        formatted: String(formatDistanceStrict(lastDrawTime)(now)),
      };
    },
    label: <Box style={{ wordWrap: "break-word" }}>Last Draw</Box>,
    numeric: true,
  }),
  DaysAgoInDataSinceLastDraw: Column({
    derive: ({ summary }) => {
      const { mri } = summary || {};
      const lastDraw = mri && lastDrawDate(mri);
      const lastDrawTime = lastDraw?.getTime() ?? Number.NaN;
      if (Number.isNaN(lastDrawTime)) {
        return { value: Number.NaN, formatted: "-" };
      }

      const now = Date.now();
      return {
        value: now - lastDrawTime,
        formatted: String(
          formatDistanceStrictWithOptions({ addSuffix: true })(now)(
            lastDrawTime
          )
        ),
      };
    },
    label: <Box style={{ wordWrap: "break-word" }}>Last Draw</Box>,
    numeric: true,
  }),
};

type ColumnId = keyof typeof availableColumns;

function lastDrawDate(
  mri: { i: Parameters<typeof effectiveInvoiceTimestamp>[0] }[]
) {
  const lastDrawAction = mri?.slice(0, 1)[0]?.i;
  const lastDraw = new Date(
    lastDrawAction && effectiveInvoiceTimestamp(lastDrawAction)
  );
  return lastDraw;
}

function putOrDrawWord({
  isPut,
  status,
  delegate,
}: {
  isPut: boolean | null;
  status?: ApprovalStatus;
  delegate?: string | null;
}) {
  if (status === ApprovalStatus.Pending && delegate) {
    return "Inspection";
  }
  return isPut ? "Put" : "Draw";
}

function TableHeadWithSorters(props: {
  headCells: ColumnId[];
  order?: "asc" | "desc";
  orderBy?: ColumnId;
  onRequestSort: (column: ColumnId) => void;
  extraCellsLeft?: number;
}): JSX.Element {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (column: ColumnId) => () => {
    if (availableColumns[column].sortable) {
      onRequestSort(column);
    }
  };

  const headCellMap = props.headCells.map((columnName) => ({
    columnName,
    columnDefinition: availableColumns[columnName],
  }));

  return (
    <TableHead>
      <TableRow>
        {/* {Array(props.extraCellsLeft).map((_, i) => ( */}
        {NESTED_FEATURE ? <TableCell key={`expander`} /> : null}
        {/* ))} */}
        {headCellMap.map(
          ({ columnDefinition: { sortable, ...headCell }, columnName }) => (
            <TableCell
              // key={headCell.id}
              // sortDirection={orderBy === headCell.id ? order : false}
              key={columnName}
              align={
                headCell.alignHeader || (headCell.numeric ? "right" : "left")
              }
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={sortable && orderBy === columnName ? order : false}
            >
              <TableSortLabel
                active={sortable && orderBy === columnName}
                hideSortIcon={!sortable}
                direction={orderBy === columnName ? order : "asc"}
                onClick={createSortHandler(columnName)}
              >
                {headCell.label}
              </TableSortLabel>
            </TableCell>
          )
        )}
      </TableRow>
    </TableHead>
  );
}

/**
 * What you get from applying a DisplayColumn definition to a project
 */
type DerivedRowContent = Record<
  ColumnId,
  {
    formatted: string | JSX.Element;
    value: number | string;
    // key: string;
    display: string | JSX.Element; // `formatted`, but might be wrapped in a link; can't sort on it
    csv: string | number | undefined;
  }
>;

const replaceNaNWithMIN_VALUE = (x: number | string | JSX.Element) =>
  typeof x === "number" && isNaN(x) ? Number.MIN_VALUE : x;

function descendingComparator(
  a: DerivedRowContent,
  b: DerivedRowContent,
  orderBy: ColumnId
) {
  const { sortFormatted = false } = availableColumns[orderBy];
  // const sortColumn = availableColumns.find((col) => orderBy === col.id);
  // const sortFormatted = sortColumn?.sortFormatted;
  const aVal = replaceNaNWithMIN_VALUE(
    sortFormatted ? a[orderBy].formatted : a[orderBy].value
  );
  const bVal = replaceNaNWithMIN_VALUE(
    sortFormatted ? b[orderBy].formatted : b[orderBy].value
  );
  // const aVal = sortFormatted ? a[orderBy].formatted : a[orderBy].value;
  // const bVal = sortFormatted ? b[orderBy].formatted : b[orderBy].value;
  if (bVal < aVal) {
    return -1;
  }
  if (bVal > aVal) {
    return 1;
  }

  return 0;
}

function getComparator(order: "asc" | "desc", orderBy: ColumnId) {
  return order === "desc"
    ? (a: DerivedRowContent, b: DerivedRowContent) =>
        descendingComparator(a, b, orderBy)
    : (a: DerivedRowContent, b: DerivedRowContent) =>
        -descendingComparator(a, b, orderBy);
}

function stableSort(
  array: DisplayRow[],
  comparator: (a: DisplayRow, b: DisplayRow) => number
) {
  const stabilizedThis: [DisplayRow, number][] = array.map((el, index) => [
    el,
    index,
  ]);
  stabilizedThis.sort((a, b) => {
    const order: number = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface View {
  description: string;
  columns: ColumnId[];
  useDates?: boolean;
  filter: (
    row: DisplayRow,
    extra: {
      email: string;
      role: Role;
      isConciergeInspector: (arg: { email: string }) => boolean;
    }
  ) => boolean;
}

// identity function gets us lots of type stuff without having to be explicit
const makeView = (view: View) => view;

// const Columns: (
//   columns: ColumnName[]
// ) => { columnName: ColumnName; columnDefinition: DisplayColumn }[] = (
//   columns
// ) => columns;
/*
 * each "view" has a description and a list of columns, in display order. For now, there is only one and we don't
 * show a control to switch it, but that is coming. (see also `currentView` in render function)
 */

export type ViewID =
  | "all"
  | "active"
  | "debug"
  | "todo"
  | "pending"
  | "delayed"
  | "complete";

const createViews: (arg: {
  features: ReturnType<typeof useFeatures>;
  permissions: ReturnType<typeof usePermissions>;
}) => Record<ViewID, View> = ({
  features: { dhlcCustom },
  permissions: { canDeleteProjects, canOpenCloseProjects },
}) => {
  const views = {
    todo: makeView({
      columns: standardColumns({
        useCustom1Column: dhlcCustom,
        canDeleteProjects,
        useDotDotDotMenu: canOpenCloseProjects,
      }),
      description: "My Todo",
      filter: (
        { project: { summary } },
        { email, role, isConciergeInspector }
      ) => {
        return (
          summary?.p.some(
            ({ currentApprover }) =>
              currentApprover === email ||
              (role === Role.SuperAdmin &&
                (isConciergeInspector({ email: currentApprover }) ||
                  (summary.pci?.length ?? 0) > 0))
          ) ?? false
        );
      },
    }),
    pending: makeView({
      columns: standardColumns({
        useCustom1Column: dhlcCustom,
        canDeleteProjects,
        useDotDotDotMenu: canOpenCloseProjects,
      }),
      description: "Draw Pending",
      filter: ({ Actions, Status, project: { summary } }) =>
        Actions.value !== "Delayed" &&
        (summary?.pci?.length || summary?.p?.length ? true : false),
    }),
    delayed: makeView({
      columns: standardColumns({
        useCustom1Column: dhlcCustom,
        canDeleteProjects,
        useDotDotDotMenu: canOpenCloseProjects,
      }),
      description: "Delayed",
      filter: ({ Actions }) => Actions.value === "Delayed",
    }),
    all: makeView({
      columns: standardColumns({
        useCustom1Column: dhlcCustom,
        canDeleteProjects,
        useDotDotDotMenu: canOpenCloseProjects,
      }),
      description: "All",
      filter: (x) => Boolean(x),
    }),
    complete: makeView({
      columns: standardColumns({
        useCustom1Column: dhlcCustom,
        canDeleteProjects,
        useDotDotDotMenu: canOpenCloseProjects,
      }),
      description: "Completed",
      filter: ({ Status }) => Status.value === ProjectStatus.Complete,
    }),
    debug: makeView({
      columns: standardColumns({
        useCustom1Column: dhlcCustom,
        canDeleteProjects,
        useDotDotDotMenu: canOpenCloseProjects,
      }).concat("SearchAttributes"),
      description: "Search Debug",
      filter: (x) => Boolean(x),
    }),
    active: makeView({
      columns: standardColumns({
        useCustom1Column: dhlcCustom,
        canDeleteProjects,
        useDotDotDotMenu: canOpenCloseProjects,
      }),
      description: "Active",
      filter: ({ Status }) => Status.value !== ProjectStatus.Complete,
    }),
    // {
    //   description: "Slim",
    //   columns: ["Name", "Amount", "LoanMaturityDate"],
    //   filter: (x) => Boolean(x),
    // },
    // {
    //   description: "All",
    //   columns: Object.keys(availableColumns) as ColumnId[],
    //   // useDates: true,
    //   filter: (x) => Boolean(x),
    // },
  };

  return views;
};

const viewOrdering: ViewID[] = [
  "todo",
  "pending",
  "active",
  "delayed",
  "all",
  "complete",
  // "debug",
];

interface EmptyViewConfig {
  line1: string;
  line2: string;
  next?: ViewID;
}

const emptyViewActions: Partial<Record<ViewID, EmptyViewConfig>> = {
  todo: {
    line1: "Congratulations; you are all caught up!",
    line2: 'Your "To Do" view is empty.',
    next: "active",
  },
  active: {
    line1: "No Active Projects found",
    line2: "",
    next: "all",
  },
  complete: {
    line1: "No Completed Projects found",
    line2: "",
    next: "active",
  },
  delayed: {
    line1: "Things are looking good!",
    line2: "No Delayed projects found.",
    next: "pending",
  },
  pending: {
    line1: "There doesn't seem to be anything waiting.",
    line2: "No projects have pending Draws",
    next: "active",
  },
};

const dhlcOnlyCSVColumns: ColumnId[] = ["Custom1"];
const superAdminOnlyCSVColumns: ColumnId[] = [
  "FullAddress",
  "NumDraws",
  "ProjectId",
  "DaysSinceLastDraw",
];

const csvColumns: (arg: {
  features: Pick<Features, "dhlcCustom">;
  role: Role;
}) => ColumnId[] = ({ features: { dhlcCustom }, role }) =>
  (
    [
      "Name",
      "Amount",
      "EffectiveBudget",
      "EndDate",
      "LoanIdentifier",
      "LoanAmount",
      "LoanMaturityDate",
      "LoanStartDate",
      "PercentBudgetRemaining",
      "PercentComplete",
      "RemainingLoanBalance",
      "RemainingLooserBalance",
      "RemainingTaskBalance",
      "TotalTaskValue",
      "TotalActiveTaskValue",
      "Health",
      "HealthMetric",
      "LoanType",
      "Actions",
      "Status",
    ] as ColumnId[]
  )
    .concat(dhlcCustom ? dhlcOnlyCSVColumns : [])
    .concat(role === Role.SuperAdmin ? superAdminOnlyCSVColumns : []);

const csvHeaders = (arg: {
  features: Pick<Features, "dhlcCustom">;
  role: Role;
}) =>
  csvColumns(arg).map(
    (column) =>
      availableColumns[column].csvHeader ?? availableColumns[column].label
  );

type DisplayRow = DerivedRowContent & {
  key: string;
  project: BackendGetProjectsResponseEntry;
};

const ProjectsAndLoansHeader = ({
  projectCount,
  totalLoans,
  totalBalance,
}: {
  projectCount: number;
  totalLoans: number;
  totalBalance: number;
}) => {
  const { formatMoney } = useMoney();
  const { useNewUI } = useNewUIHook();

  return (
    <TileContainer>
      <Tile>
        <ProjectCount projectCount={projectCount} offerCreate={!useNewUI} />
      </Tile>
      <Tile>
        <Grid container direction="column" alignContent="center">
          <Grid item>
            <Typography variant="tile">
              {formatMoney(totalLoans, { short: true })}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="tile">Loans</Typography>
          </Grid>
        </Grid>
      </Tile>
      <Tile>
        <Grid container direction="column" alignContent="center">
          <Grid item>
            <Typography variant="tile">
              {formatMoney(totalBalance, { short: true })}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="tile">Balance</Typography>
          </Grid>
        </Grid>
      </Tile>
    </TileContainer>
  );
};

const columnName = (n: number) => "ABCDEFGHIJKLMNOPQRSTUVWXYZ".charAt(n); // spell-checker:ignore ABCDEFGHIJKLMNOPQRSTUVWXYZ

function standardColumns({
  useCustom1Column,
  canDeleteProjects,
  useDotDotDotMenu,
}: {
  useCustom1Column: boolean;
  canDeleteProjects: boolean;
  useDotDotDotMenu: boolean;
}): ColumnId[] {
  return [
    "Name",
    "Amount",
    "RemainingLooserBalance",
    "LoanIdentifier",
    "LoanMaturityDate",
    "Health",
    "Actions",
    // "DaysSinceLastDraw",
    "DaysInDataSinceLastDraw",
    // "DaysAgoInDataSinceLastDraw",
    ...(useCustom1Column ? ["Custom1" as ColumnId] : []),
    ...(useDotDotDotMenu ? ["DotDotDot" as ColumnId] : []),
    ...(canDeleteProjects && !useDotDotDotMenu ? ["Delete" as ColumnId] : []),
    // "Status",
  ];
}

const ExportRow = styled("div")({
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
  alignItems: "end",
  marginTop: "8vh",
});

const LeftGapDiv = styled("div")(({ theme: { spacing } }) => ({
  marginLeft: spacing(1),
}));

function CollapsibleRow({
  row,
  currentView,
  prefix,
}: {
  row: DisplayRow;
  currentView: View;
  prefix: string;
}): JSX.Element {
  const [primaryColumn, ...restColumns] = currentView.columns;
  const keyRoot = row.ProjectId.value;
  const [open, setOpen] = React.useState(false);
  const [desiredOperation, setDesiredOperation] =
    React.useState<InvoiceActions>(InvoiceActions.None);

  const pendingInvoices = row.project.summary?.p ?? [];
  return (
    <>
      <TableRow
        hover
        role="checkbox"
        tabIndex={-1}
        key={keyRoot}
        sx={
          row.Status.value === ProjectStatus.Complete
            ? ({ palette }) => ({
                "& > *": {
                  color: palette.text.disabled,
                },
              })
            : undefined
        }
      >
        {NESTED_FEATURE ? (
          <TableCell>
            {pendingInvoices.length > 0 ? (
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpen(!open)}
              >
                {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
            ) : null}
          </TableCell>
        ) : null}
        {/* !!!
        <TableCell component="th" id={labelId} scope="row">
          {primaryColumn.display} */}
        <TableCell key={`${keyRoot}-${primaryColumn}`}>
          {/* {row[primaryColumn.columnName].display} */}
          {row[primaryColumn].display}
        </TableCell>
        {restColumns.map((columnName) => (
          <TableCell
            align={availableColumns[columnName].align || "right"}
            key={`${keyRoot}-${columnName}`}
          >
            {row[columnName].display}
          </TableCell>
        ))}
      </TableRow>
      {pendingInvoices && NESTED_FEATURE ? (
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box>
                {/* <Typography variant="h6" gutterBottom component="div">
                Pending Invoices
              </Typography> */}
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>Invoice</TableCell>
                      <TableCell>Something</TableCell>
                      <TableCell align="right">Something Else</TableCell>
                      <TableCell align="right">Something More</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {pendingInvoices.map((invoice) => (
                      <TableRow key={invoice.timestamp}>
                        <TableCell component="th" scope="row">
                          <StealthyLink
                            to={`${prefix ?? ""}/projectview/${
                              row.ProjectId.value
                            }/${ProjectViewTabs.Tasks}`}
                          >
                            {invoice.timestamp}
                          </StealthyLink>
                        </TableCell>
                        <TableCell>{invoice.currentApprover}</TableCell>
                        <TableCell>
                          <FormControl variant="standard" fullWidth>
                            <InputLabel id="update-select-label">
                              Update
                            </InputLabel>
                            <Select
                              variant="standard"
                              labelId="update-select-label"
                              id="update-select"
                              value={
                                desiredOperation === InvoiceActions.None
                                  ? undefined
                                  : desiredOperation
                              }
                              onChange={({ target: { value } }) => {
                                setDesiredOperation(value as InvoiceActions);

                                const control =
                                  document.activeElement as unknown as {
                                    blur: () => void;
                                  };
                                control.blur && control.blur();
                              }}
                            >
                              <MenuItem value="None">
                                <em>---</em>
                              </MenuItem>
                              <MenuItem value="Approve">Approve</MenuItem>
                              <MenuItem value="Reject">Reject</MenuItem>
                              <MenuItem value="Edit">Edit</MenuItem>
                            </Select>
                          </FormControl>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      ) : null}
    </>
  );
}

function ProjectGrid(props: {
  data: BackendGetProjectsResponse;
  loading: boolean;
  onRequestDelete: (projectId: string) => void;
  onUpdateStatus: (projectId: string, status: ProjectStatus) => void;
  viewId?: ViewID;
  prefix: string;
}): JSX.Element {
  const { role } = useAppContext();
  const [order, setOrder] = React.useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = React.useState<ColumnId>("Name"); // column id
  const [page, setPage] = React.useState(0);
  const [rows, setRows] = React.useState<DisplayRow[]>([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);
  const [attributeFilter, setAttributeFilter] = React.useState("");

  const features = useFeatures();
  const entityFeatures = useEntityFeatures();
  const permissions = usePermissions();
  const { protocol, host } = window.location;
  const analytics = useAnalytics();

  const skinny = useMediaQuery("(max-width:400px)");

  // one-off on page load
  React.useEffect(function onMount() {
    analytics.track("page:grid");
    // we intentionally want to run this just on mount, so squelch the warning:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { onRequestDelete, onUpdateStatus, viewId: forcedView } = props;
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const progressSpinner = searchParams.get("spinner");
  const viewId = forcedView || (searchParams.get("view") as ViewID) || null;
  const {
    isConciergeInspector,
    isFullConciergeInspector,
    availableInspectors: inspectorsList,
  } = useInspectors();
  const availableInspectors = useMemo(
    () =>
      Object.fromEntries(
        inspectorsList.map((inspector) => [inspector.email, inspector])
      ),
    [inspectorsList]
  );
  const availableRows = React.useMemo(() => {
    return stableSort(rows, getComparator(order, orderBy));
  }, [rows, order, orderBy]);

  const handleRequestSort = (column: ColumnId) => {
    const isSortColumn = orderBy === column;
    const isAsc = isSortColumn && order === "asc";
    // update rows so that when we re-sort, stability is based on state just prior
    setRows(availableRows);
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(column);
  };

  const { enqueueSnackbar } = useSnackbar();
  const { prefix } = props;
  useEffect(() => {
    /*
     * go through passed-in `props.data` (project list) and build intermediate representation for sort and display
     */
    const newRows: DisplayRow[] = [];
    for (let i = 0; i < props.data.length; i++) {
      // const response = await ProjectAPIs.getProjectTasks(
      //   props.data[i].project_id
      // );

      const project = props.data[i];
      // store as { name: { value: ..., formatted: "...", sortFormatted: <from column definition> }, loanAmount: { value: ..., formatted: "xxx", ... }, ...}
      const newRow = Object.entries(availableColumns).reduce(
        (result, [columnId, column]) => {
          const derivationIngredients = {
            ...project,
            // __response: response,
          };
          const { value, formatted, csv } = column.derive(
            derivationIngredients,
            {
              urlBase: `${protocol}//${host}`,
              permissions,
              entityFeatures,
              onRequestDelete,
              onUpdateStatus: (id, newStatus, pending) => {
                if (newStatus === ProjectStatus.Complete) {
                  // setViewRefreshNeeded(true); // "Complete" view is dynamic
                  if (pending.length > 0) {
                    enqueueSnackbar(
                      "The project you just marked complete still has Pending Draws",
                      { variant: "warning" }
                    );
                  }
                }
                onUpdateStatus(id, newStatus);
              },
              isConciergeInspector,
              isFullConciergeInspector,
              availableInspectors,
              prefix,
            }
          );

          if (
            config.nonProdEnv &&
            typeof formatted !== "string" &&
            csv === undefined
          ) {
            throw new Error(
              `Can't export elements: define a CSV flavor for ${columnId}`
            );
          }

          result[columnId as ColumnId] = {
            value,
            formatted,
            csv,
            display: column.linkToProjectView ? (
              <StealthyLink
                to={`${prefix ?? ""}/projectview/${
                  derivationIngredients.project_id
                }/${column.linkToProjectView}`}
              >
                {formatted}
              </StealthyLink>
            ) : (
              formatted
            ),
          };
          result.key = derivationIngredients.project_id;

          return result;
        },
        {
          project,
        } as DisplayRow
      );
      newRows.push(newRow);
    }

    setRows(newRows);
  }, [
    availableInspectors,
    host,
    isConciergeInspector,
    isFullConciergeInspector,
    onRequestDelete,
    onUpdateStatus,
    permissions,
    props.data,
    protocol,
    enqueueSnackbar,
    entityFeatures,
    prefix,
  ]);

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: { target: { value: string } }) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const availableViews = useMemo(() => {
    return createViews({ features, permissions });
  }, [features, permissions]);
  const [currentViewID, setCurrentViewID] = React.useState<ViewID>(
    viewId && availableViews[viewId] ? viewId : viewOrdering[0]
  );
  React.useEffect(() => {
    if (forcedView) {
      setCurrentViewID(forcedView);
    }
  }, [forcedView]);

  const currentView = useMemo(
    () => availableViews[currentViewID],
    [currentViewID, availableViews]
  );
  const navigate = useNavigate();
  useEffect(() => {
    if (!forcedView && viewId !== currentViewID) {
      navigate({
        search: `${qs.stringify({ view: currentViewID })}`,
      });
    }
  }, [currentViewID, forcedView, navigate, viewId]);

  useEffect(() => {
    setPage(0);
  }, [currentView]);

  const email = throwIfNot(useAuth().currentUserEmail, "email is required");
  const filteredRows = React.useMemo(
    () =>
      availableRows.filter((row) => {
        if (attributeFilter) {
          return (
            (row.SearchAttributes.value as string)
              .toUpperCase()
              .indexOf(attributeFilter.toUpperCase()) >= 0
          );
        }

        return currentView.filter(row, {
          email,
          role,
          isConciergeInspector,
        });
      }),
    [
      attributeFilter,
      availableRows,
      currentView,
      email,
      isConciergeInspector,
      role,
    ]
  );

  const { setAdminData, setAdminChildren } = useAdminData();
  React.useEffect(() => {
    setAdminData((data) => ({
      ...data,
      projects: filteredRows.map(({ project }) => project),
    }));
    return () =>
      setAdminData(({ filteredRows, ...rest }) => {
        return rest;
      });
  }, [filteredRows, setAdminData]);

  React.useEffect(() => {
    setAdminChildren((children) => ({
      ...children,
      bulkUserAdder: (
        <BulkUserAdder
          projects={filteredRows.map(({ project }) => ({
            ...project,
            users: project.users as EmailAddress[],
            img: project.img as string,
            location: project.location || null,
          }))}
        />
      ),
    }));
    return () => setAdminChildren(({ bulkUserAdder, ...children }) => children);
  }, [filteredRows, setAdminChildren]);

  const numEmptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, filteredRows.length - page * rowsPerPage);

  const projectsWithLoans = props.data.filter((p) => p.loanAmount);
  const totalLoans = projectsWithLoans.reduce(
    (sum, project) => sum + (project.loanAmount ?? 0),
    0
  );

  const csvExportData = React.useMemo(() => {
    const data = rows.map((row) =>
      csvColumns({ features, role }).map(
        (columnId) => row[columnId].csv ?? row[columnId].formatted
      )
    );
    return data.concat([
      csvColumns({ features, role }).map((columnId, idx) =>
        availableColumns[columnId].summable
          ? `=SUM(${columnName(idx)}2:${columnName(idx)}${data.length + 1})`
          : ""
      ),
    ]);
  }, [rows, features, role]);

  const whenEmpty = emptyViewActions[currentViewID];
  return props.loading && progressSpinner === "circle" ? (
    <CircularProgress />
  ) : (
    <Grid
      container
      sx={{ flexDirection: "row" }}
      direction="column"
      alignItems="center"
    >
      <Grid item container width="100%" sx={{ height: "1vh" }}>
        <ProjectsAndLoansHeader
          totalLoans={totalLoans}
          totalBalance={
            totalLoans -
            projectsWithLoans.reduce(
              (sum, project) => sum + Number(project.budget_spent),
              0
            )
          }
          projectCount={props.data.length}
        />
      </Grid>

      <Grid
        item
        container
        direction={"column"}
        width="100%"
        marginBottom={"-10vh"}
      >
        {/* <div className={classes.filtersContainer}>
            <FormControl>
              <InputLabel shrink id="grid-filter-report-type-label">
                View
              </InputLabel>
              <Select
                label="View"
                labelId="grid-filter-report-type-label"
                id="grid-filter-report-type"
                value={availableViews.findIndex(
                  ({ description }) => description === currentView.description
                )}
                onChange={({ target: { value } }) =>
                  setCurrentView(availableViews[value as number])
                }
              >
                {availableViews.map((view, idx) => (
                  <MenuItem key={view.description} value={idx}>
                    {view.description}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {currentView.useDates && (
              <div className={classes.dateFiltersContainer}>
                <DatePicker
                  className={classes.datePicker}
                  label="From"
                  value={fromDate}
                  format="MM/dd/yyyy"
                  onChange={(newDate) => setFromDate(newDate)}
                ></DatePicker>
                <DatePicker
                  className={classes.datePicker}
                  label="To"
                  value={toDate}
                  format="MM/dd/yyyy"
                  onChange={(newDate) => setToDate(newDate)}
                ></DatePicker>
              </div>
            )}
          </div> */}
        <ExportRow>
          <Tooltip
            title="Search by project or user email"
            // sx={{ position: "fixed", marginBottom: 5, zIndex: 1 }}
          >
            <TextField
              id="filter-by-attributes"
              label={skinny ? undefined : "Search all projects"}
              // InputLabelProps={{
              //   shrink: true,
              // }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              variant="standard"
              value={attributeFilter}
              onChange={({ target: { value } }) => {
                setAttributeFilter(value);
              }}
            />
          </Tooltip>

          <div
            style={{
              display: "flex",
              alignItems: "flex-end",
            }}
          >
            {attributeFilter || forcedView ? null : (
              <FormControl
                variant="standard"
                sx={({ spacing }) => ({
                  minWidth: spacing("Draw Pending".length * 1.4), // HACK: longest view description; really ought to measure
                })}
              >
                <InputLabel
                  style={{ whiteSpace: "nowrap" }}
                  shrink
                  id="grid-filter-report-type-label"
                >
                  Filter by Status
                </InputLabel>
                <Select
                  variant="standard"
                  label="Filter by Status"
                  labelId="grid-filter-report-type-label"
                  id="grid-filter-report-type"
                  value={currentViewID}
                  onChange={({ target: { value } }) => {
                    setCurrentViewID(value as ViewID);
                  }}
                  disabled={Boolean(attributeFilter)}
                >
                  {viewOrdering.map((id) => (
                    <MenuItem key={id} value={id}>
                      {availableViews[id].description}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            <LeftGapDiv>
              <CSVExport
                data={csvExportData}
                headers={csvHeaders({ features, role }) as string[]}
                filename={sanitizeFilename(
                  `Portfolio - ${filenameDateFormat(new Date())}.csv`
                )}
              />
            </LeftGapDiv>
          </div>
        </ExportRow>
        <TableContainer
          style={{
            width: "100%",
            height: "65vh",
          }}
        >
          {filteredRows.length === 0 ? (
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              {!attributeFilter && whenEmpty ? (
                <>
                  <p>
                    {whenEmpty.line1}
                    <br />
                    {whenEmpty.line2}
                  </p>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      setCurrentViewID(whenEmpty.next ?? "all");
                    }}
                  >
                    View {availableViews[whenEmpty.next ?? "all"].description}
                  </Button>
                </>
              ) : (
                <p>No matching projects found</p>
              )}
            </div>
          ) : (
            <Table
              aria-labelledby="tableTitle"
              aria-label="enhanced table"
              stickyHeader
              // size="small"
            >
              <TableHeadWithSorters
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                headCells={currentView.columns}
                extraCellsLeft={1}
              />

              <TableBody
                sx={{
                  "& .MuiTableCell-root": {
                    paddingTop: 0,
                    paddingBottom: 0,
                    // fontSize: 32,
                  },
                }}
              >
                {filteredRows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <CollapsibleRow
                      row={row}
                      key={row.ProjectId.value}
                      currentView={currentView}
                      prefix={prefix}
                    />
                  ))}
                {numEmptyRows > 0 && (
                  <TableRow>
                    <TableCell style={{ padding: 0 }} colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          )}
        </TableContainer>
        {currentViewID === "todo" && filteredRows.length === 0 ? null : (
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            component="div"
            count={filteredRows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
        {!props.loading ? null : (
          <>{progressSpinner !== "none" ? <LinearProgress /> : null}</>
        )}
      </Grid>
    </Grid>
  );
}

// enabling this makes a bunch of noise with useSWR
// ProjectGrid.whyDidYouRender = true;

export default /* observer */ ProjectGrid;
