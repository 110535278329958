import React from "react";
import { styled } from '@mui/material/styles';
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import logo from "./../Assets/Images/logo_house_pc.svg";

const PREFIX = 'LoggedOutHeader';

const classes = {
  grow: `${PREFIX}-grow`,
  logoImg: `${PREFIX}-logoImg`
};

const Root = styled('div')(({
  theme: { mixins }
}) => ({
  [`&.${classes.grow}`]: {
    flexGrow: 1,
  },

  [`& .${classes.logoImg}`]: {
    height: mixins.toolbar.minHeight,
    filter: "invert(1)",
    scale: "60%",
  }
}));

export default function Header(): JSX.Element {


  return (
    <Root className={classes.grow}>
      <AppBar position="static">
        <Toolbar>
          <a href="/">
            <img
              className={classes.logoImg}
              src={logo}
              alt="Project Centerline"
            />
          </a>
          <div className={classes.grow} />
        </Toolbar>
      </AppBar>
    </Root>
  );
}
