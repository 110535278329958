import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import clsx from "clsx"; // spell-checker:ignore clsx
import { useAppContext } from "@lib/UserContext";
import { useTheme } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import AddIcon from "@mui/icons-material/Add";
import AddChatRoom, { AddChatRoomProps } from "./AddChatRoom";
import IndividualChat from "./IndividualChat";
import { CircularProgress } from "@mui/material";
import { logError } from "@lib/ErrorLogging";
import { ChatRoom, getProjectUsers } from "@lib/API";
import { useBackend } from "@lib/hooks";
import { throwIfNot } from "@lib/util/throwIfNot";
import { useAuth } from "@lib/hooks/useAuth";

const PREFIX = "ChatView";

const classes = {
  appBar: `${PREFIX}-appBar`,
  appBarShift: `${PREFIX}-appBarShift`,
  menuButton: `${PREFIX}-menuButton`,
  hide: `${PREFIX}-hide`,
  drawer: `${PREFIX}-drawer`,
  drawerPaper: `${PREFIX}-drawerPaper`,
  drawerHeader: `${PREFIX}-drawerHeader`,
  content: `${PREFIX}-content`,
  contentShift: `${PREFIX}-contentShift`,
  cardView: `${PREFIX}-cardView`,
};

const Root = styled("div")(({ theme: { transitions, spacing, mixins } }) => ({
  [`& .${classes.appBar}`]: {
    transition: transitions.create(["margin", "width"], {
      easing: transitions.easing.sharp,
      duration: transitions.duration.leavingScreen,
    }),
    backgroundColor: "none !important",
  },

  [`& .${classes.appBarShift}`]: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: transitions.create(["margin", "width"], {
      easing: transitions.easing.easeOut,
      duration: transitions.duration.enteringScreen,
    }),
  },

  [`& .${classes.menuButton}`]: {
    marginRight: spacing(2),
  },

  [`& .${classes.hide}`]: {
    display: "none",
  },

  [`& .${classes.drawer}`]: {
    width: drawerWidth,
    flexShrink: 0,
  },

  [`& .${classes.drawerPaper}`]: {
    marginTop: "64px",
    width: drawerWidth,
    height: "90vh",
    marginBottom: "75px",
    overflowY: "scroll",
  },

  [`& .${classes.drawerHeader}`]: {
    display: "flex",
    alignItems: "center",
    padding: spacing(0, 1),
    // necessary for content to be below app bar
    ...mixins.toolbar, // spell-checker:ignore mixins
    justifyContent: "flex-end",
  },

  [`& .${classes.content}`]: {
    flexGrow: 1,
    padding: 0,
    transition: transitions.create("margin", {
      easing: transitions.easing.sharp,
      duration: transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
  },

  [`& .${classes.contentShift}`]: {
    transition: transitions.create("margin", {
      easing: transitions.easing.easeOut,
      duration: transitions.duration.enteringScreen,
    }),
    marginLeft: drawerWidth,
  },

  [`& .${classes.cardView}`]: {
    padding: "40px",
  },
}));

const drawerWidth = 240;

export default function ChatView(props: {
  data: { project_id: string };
}): JSX.Element {
  const { role, getPresignedWebsocketUrl } = useAppContext();
  const { direction } = useTheme();
  const [open, setOpen] = React.useState(true);
  const [addRoom, setAddRoom] = React.useState(false);
  const [chat, setChat] = React.useState<ChatRoom | undefined>();
  const [chatName, setChatName] = React.useState("My Chats");
  const [chatRooms, setChatRooms] = React.useState<ChatRoom[]>([]);
  const [users, setUsers] = React.useState<AddChatRoomProps["users"]>([]);
  const [socket, setSocket] = React.useState<WebSocket | undefined>();
  const { getChatRooms } = useBackend();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleAddRoom = () => {
    setAddRoom(true);
  };

  const handleClose = () => {
    setAddRoom(false);
  };

  const handleOpenChat = (chatRoom: ChatRoom) => {
    (async () => {
      const signedWebsocketUrl = await getPresignedWebsocketUrl();
      setSocket(new WebSocket(signedWebsocketUrl));
    })();

    setChat(chatRoom);
    setChatName(chatRoom.room_name);
  };
  const projectId = props.data.project_id;

  const email = throwIfNot(useAuth().currentUserEmail, "email is required");
  useEffect(() => {
    getProjectUsers(projectId)
      .then((response) => {
        setUsers(response);
      })
      .catch((error) => {
        logError(error);
        console.log(error);
      });
    getChatRooms({ email, role, projectId })
      .then((response) => {
        setChatRooms(response);
      })
      .catch((error) => {
        logError(error);
        console.log(error);
      });
  }, [email, role, projectId, getChatRooms]);

  const callBack = () => {
    getChatRooms({ email, role, projectId })
      .then((response) => {
        setChatRooms(response);
      })
      .catch((error) => {
        logError(error);
        console.log(error);
      });
  };

  return (
    <Root>
      <CssBaseline />
      <AppBar
        color="default"
        position="static"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
            size="large"
          >
            <MenuIcon />
          </IconButton>
          <h3>{chatName}</h3>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose} size="large">
            {direction === "ltr" ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </div>
        <Divider />
        <List
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            margin: "10px",
          }}
        >
          <h2>Rooms</h2>
          {role !== "Subcontractor" ? (
            <AddIcon onClick={handleAddRoom} style={{ cursor: "pointer" }} />
          ) : null}
        </List>
        <Divider />
        <List>
          {chatRooms.map((chatRoom, index) => (
            <ListItem
              button
              key={index}
              onClick={(e) => handleOpenChat(chatRoom)}
            >
              <ListItemText primary={chatRoom.room_name} />
            </ListItem>
          ))}
        </List>
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        {chat &&
        chatRooms.find(
          (element) => element.chat_room_id === chat.chat_room_id
        ) ? (
          <div className={classes.cardView}>
            {socket ? (
              <IndividualChat
                socket={socket}
                chat={chat}
                // users={users}
                project={projectId}
              />
            ) : (
              <CircularProgress />
            )}
          </div>
        ) : null}
      </main>
      <AddChatRoom
        open={addRoom}
        project={projectId}
        users={users}
        callBack={callBack}
        handleCloseCallback={handleClose}
      />
    </Root>
  );
}
