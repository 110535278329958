import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";
//import { useLocation } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import SearchIcon from "@mui/icons-material/Search";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import { Divider, Typography } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { useMoney } from "@lib/hooks/useMoney";
import { ifEnterKey } from "@lib/boilerplate";
import {
  BackendGetSearchRawChat,
  BackendGetSearchRawDecision,
  BackendGetSearchRawDraw,
  BackendGetTaskResponse,
} from "@lib/APITypes";
import { invertedButtonStyle } from "../../../projectCenterlineTheme";
import { logError } from "@lib/ErrorLogging";
import { useBackend } from "@lib/hooks";

const PREFIX = "Search";

const classes = {
  root: `${PREFIX}-root`,
  card: `${PREFIX}-card`,
  formWrapper: `${PREFIX}-formWrapper`,
  buttonSearch: `${PREFIX}-buttonSearch`,
  buttonCTA: `${PREFIX}-buttonCTA`,
};

const Root = styled("div")(({ theme: { palette } }) => ({
  [`&.${classes.root}`]: {
    margin: "20px",
    marginBottom: "100px",
  },

  [`& .${classes.card}`]: {
    width: 350,
    display: "block",
    margin: "10px",
  },

  [`& .${classes.formWrapper}`]: {
    width: "60vw",
    margin: "auto",
    display: "flex",
    justifyContent: "center",
  },

  [`& .${classes.buttonSearch}`]: {
    ...invertedButtonStyle({ palette }),
    width: "250px",
  },

  [`& .${classes.buttonCTA}`]: {
    ...invertedButtonStyle({ palette }),
    width: "100%",
  },
}));

export default function Search(props: {
  data: { project_id: string };
}): JSX.Element {
  const { formatMoney } = useMoney();
  const { performSearch } = useBackend();

  const [search, setSearch] = React.useState("");
  const [project, setProject] = React.useState("");
  //let location = useLocation();
  const [tasks, setTasks] = React.useState<BackendGetTaskResponse[]>([]);
  const [decisions, setDecisions] = React.useState<
    BackendGetSearchRawDecision[]
  >([]);
  const [noResults, setNoResults] = React.useState(false);
  const [chats, setChats] = React.useState<BackendGetSearchRawChat[]>([]);
  const [draws, setDraws] = React.useState<BackendGetSearchRawDraw[]>([]);

  const handleSearch = () => {
    if (search === "") {
      alert("Please enter a search query.");
      return;
    }
    setTasks([]);
    setDecisions([]);
    setDraws([]);
    performSearch({ projectId: project, query: search })
      .then((response) => {
        console.log(response);
        if (
          response.decisions.length === 0 &&
          response.tasks.length === 0 &&
          response.draws.length === 0
        ) {
          setNoResults(true);
        } else {
          console.log(response);
          setTasks(response.tasks);
          setDecisions(response.decisions);
          setDraws(response.draws);
          setNoResults(false);
          setChats(response.chats);
        }
      })
      .catch((error) => {
        logError(error);
        console.log(error);
      });
  };

  const handleSearchChange = (event: { target: { value: string } }) => {
    setSearch(event.target.value);
  };

  useEffect(() => {
    setProject(props.data.project_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Root className={classes.root}>
      <div>
        <div className={classes.formWrapper}>
          <TextField
            variant="standard"
            fullWidth
            label="Search This Project"
            onChange={handleSearchChange}
            onKeyDown={ifEnterKey(handleSearch)}
          />
          <Button className={classes.buttonSearch} onClick={handleSearch}>
            Search <SearchIcon />
          </Button>
        </div>
        {noResults ? <p>No search results</p> : null}
        {tasks.length > 0 ? (
          <div>
            <h3>Tasks</h3>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
              }}
            >
              {tasks.map((task, key) => {
                return (
                  <Card className={classes.card} key={key}>
                    <CardHeader title={task.title} />
                    <Divider />
                    <CardContent>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <p>Description:</p>
                        <p>{task.description}</p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <p>Division:</p>
                        <p>{task.division}</p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        {task.start && task.end_date ? (
                          <>
                            <p>Schedule:</p>
                            <p>
                              {task.start.toISOString().split("T").shift() +
                                " - " +
                                task.end_date.toISOString().split("T").shift()}
                            </p>
                          </>
                        ) : null}
                      </div>

                      <Link
                        to={project}
                        state={{
                          value: "Schedule",
                        }}
                        style={{ textDecoration: "none" }}
                      >
                        <Button
                          className={classes.buttonCTA}
                          // target="_blank"
                          fullWidth
                        >
                          Show in Calendar
                        </Button>
                      </Link>
                    </CardContent>
                  </Card>
                );
              })}
            </div>
            <Divider />
          </div>
        ) : null}
        {draws.length > 0 ? (
          <div>
            <h3>Draws</h3>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
              }}
            >
              {draws.map((draw, key) => {
                return (
                  <Card className={classes.card} key={key}>
                    <CardContent>
                      <div
                        style={{
                          display: "flex",
                          // justifyContent: "space-around",
                          alignItems: "center",
                        }}
                      >
                        <h3>{draw.timestamp.split("T").shift()}</h3>
                      </div>
                      <Divider />
                      {draw.draws.map((draw, key) => {
                        return (
                          <div
                            key={key}
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <p>{draw[0]}</p>
                            <p>
                              -
                              {
                                formatMoney(
                                  Number(draw[1])
                                ) /* TODO: sanitize on input */
                              }
                            </p>
                          </div>
                        );
                      })}
                      <span
                        style={{
                          width: "100%",
                        }}
                      >
                        {draw.approval_status === "Approved" ? (
                          <p>Final Approver: {draw.final_approver}</p>
                        ) : draw.approval_status === "Rejected" ? (
                          <p>Rejected by: {draw.final_approver}</p>
                        ) : (
                          <p>Current Approver: {draw.current_approver}</p>
                        )}
                      </span>
                      <Link
                        to={project}
                        state={{
                          value: "Draw Request",
                        }}
                        style={{ textDecoration: "none" }}
                      >
                        <Button
                          className={classes.buttonCTA}
                          // target="_blank"
                          fullWidth
                        >
                          See Invoice
                        </Button>
                      </Link>
                    </CardContent>
                  </Card>
                );
              })}
            </div>
            <Divider />
          </div>
        ) : null}
        {decisions.length > 0 ? (
          <div>
            <h3>Decisions</h3>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
              }}
            >
              {decisions.map((decision, key) => {
                return (
                  <Card style={{ margin: "20px", width: "40vw" }} key={key}>
                    <CardHeader title={decision.task_name} />

                    <Divider />
                    <CardContent>
                      <div>
                        <p>Need by Date</p>
                        <FormControl variant="standard" fullWidth>
                          <TextField
                            variant="standard"
                            required
                            value={
                              decision.timeframe
                                .split("T")
                                .shift() /* spell-checker:ignore timeframe */
                            }
                          />
                        </FormControl>
                      </div>
                      <div>
                        <p>Description</p>
                        <FormControl variant="standard" fullWidth>
                          <TextField
                            required
                            multiline
                            variant="outlined"
                            rows={4}
                            value={decision.description}
                            name="description"
                          />
                        </FormControl>
                      </div>
                      {decision.approval_status === "Pending" ? (
                        <p>Current Approver: {decision.current_approver}</p>
                      ) : (
                        <p>Final Approver: {decision.final_approver}</p>
                      )}
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        <Typography variant="body2">
                          Current Status: {decision.approval_status}
                        </Typography>
                      </div>
                      <Divider />
                      <Link
                        to={project}
                        state={{
                          value: "Decision",
                        }}
                        style={{ textDecoration: "none" }}
                      >
                        <Button
                          className={classes.buttonCTA}
                          // target="_blank"
                          fullWidth
                        >
                          {" "}
                          See Decision{" "}
                        </Button>
                      </Link>
                    </CardContent>
                  </Card>
                );
              })}
            </div>
          </div>
        ) : null}

        {chats.length > 0 ? (
          <div>
            <h3>Chats</h3>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
              }}
            >
              {chats.map((chat, key) => {
                return (
                  <Card className={classes.card} key={key}>
                    <CardHeader title={chat.sender_name} />
                    <Divider />
                    <CardContent>
                      <div>
                        <p>Message:</p>
                        <p>{chat.message}</p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <p>Time:</p>
                        <p>{chat.created_at.split("T").shift()}</p>
                      </div>

                      <Link
                        to={project}
                        state={{
                          value: "Chat",
                        }}
                        style={{ textDecoration: "none" }}
                      >
                        <Button
                          className={classes.buttonCTA}
                          // target="_blank"
                          fullWidth
                        >
                          {" "}
                          See Chat{" "}
                        </Button>
                      </Link>
                    </CardContent>
                  </Card>
                );
              })}
            </div>
            <Divider />
          </div>
        ) : null}
      </div>
    </Root>
  );
}
