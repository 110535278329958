import {
  StoredFile,
  LinkedFile,
} from "@project-centerline/project-centerline-api-types";
import { PersistentProjectFile } from "@lib/APITypes";

export function extractFileTitle(
  victim: StoredFile | LinkedFile | PersistentProjectFile
): string {
  return LinkedFile.is(victim) ? victim.title : victim.path;
}
