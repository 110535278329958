import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tooltip,
  Grid,
  Checkbox,
  FormControlLabel,
  Typography,
} from "@mui/material";
import { styled, SxProps, Theme } from "@mui/material/styles";
import React from "react";
import {
  Invoice,
  NotPendingInvoice,
  PendingInvoice,
  Task,
} from "../../../Models";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useFeatures, useMoney, usePermissions } from "@lib/hooks";
import config from "../../../config";
import { useAppContext } from "@lib/UserContext";
import { Code as CodeIcon } from "@mui/icons-material";
import pdfDownloadIcon from "../../../Assets/Images/pdfDownload.png";
import {
  bigAssIcon,
  invertedButtonStyle,
} from "../../../projectCenterlineTheme";
// import { Storage } from "aws-amplify";
// import { useAsyncEffect } from "@react-hook/async";
import { ApprovalStatus, PersistentProjectFile } from "@lib/APITypes";
import { useProjectInvoices } from "@lib/hooks/useProjectInvoices";
import { useEntityFeatures } from "@lib/hooks/useEntityFeatures";
import { TaskRenderFileAffordance } from "./TaskRenderFileAffordance";
import {
  ProjectId,
  StoredFile,
  LinkedFile,
} from "@project-centerline/project-centerline-api-types";
import { pipe } from "fp-ts/lib/function";
import { drawForTask, extractAmount } from "./taskUtils";
import { byProp } from "@lib/misc";
import { logAnomaly } from "@lib/ErrorLogging";
import { useAuth } from "@lib/hooks/useAuth";
import { throwIfNot } from "@lib/util/throwIfNot";

export interface CommonInvoicesProps<
  I extends PendingInvoice | NotPendingInvoice
> {
  /**
   * invoices to display
   */
  invoices: ReadonlyArray<I>;

  /** incorporated into filename of CSV downloads */
  filenameHint: string;

  /**
   * Tasks against which invoices are drawn
   */
  tasks: Task[];

  /** project ID */
  projectId: ProjectId;

  onTitleClicked: (arg: { invoice: Invoice; draw: CalculatedDraw }) => void;

  /// project files, some of which may be for line items (if they have invoice id and task id)
  files: PersistentProjectFile[];
}

export interface CalculatedDraw {
  task: Task;
  drawRequestAmount: number;
  value: number;
  funded: number;
  remaining: number;
}

export interface CalculatedDisplayInfo {
  invoice: Readonly<Invoice>;
  displayDraws: readonly CalculatedDraw[];
  storedFiles: readonly (StoredFile | LinkedFile)[];
}

export function calculateDrawInfo(
  {
    drawItems,
    invoice_id: invoiceId,
  }: Pick<Readonly<Invoice>, "drawItems"> & Pick<Invoice, "invoice_id">,
  tasks: ReadonlyArray<Task>,
  files: ReadonlyArray<PersistentProjectFile>
): CalculatedDraw[] {
  // if at least one has a task, we will not try to match on title.
  // https://projectcenterline.slack.com/archives/G01D11FMKB8/p1695340916470799
  // TODO: There are 3 places this pattern repeats; DRY
  const modern = drawItems.some(({ task }) => task);
  if (!modern && drawItems.length > 0) {
    const tasksByTitle = byProp(tasks)("title");
    if (Object.keys(tasksByTitle).length !== tasks.length) {
      logAnomaly(
        new Error("Old draw with duplicate task names; possible loss of tasks"),
        {
          tasksByTitle,
          tasks,
          duplicates: tasks.filter(
            (item, index) =>
              tasks.findIndex(({ title }) => title === item.title) !== index
          ),
          drawItems,
        }
      );
    }
  }
  return tasks
    .map((task) => ({
      task,
      drawRequestAmount: pipe(
        drawItems,
        drawForTask(task, { matchTitles: !modern }),
        extractAmount(() => 0)
      ),
      //   ({ task: { id, taskTitle, ...task } = {}, amount, title }) =>
      //     id ? id === task.id : title === task.title
      // )?.amount ?? 0,
      value: task.task_value,
      funded: task.invoiced_amount,
      remaining: task.task_value - task.invoiced_amount,
      // approvalStatus: invoice.approval_status,
      files: files.filter(
        ({ invoice_id, task_id }) =>
          invoice_id === invoiceId && task_id === task.id
      ),
    }))
    .filter(({ drawRequestAmount }) => drawRequestAmount);
}

interface InvoiceDisplayInfo {
  // approvalStatus: ApprovalStatus;
  invoice: Readonly<Invoice>;
  displayDraws: Readonly<CalculatedDraw[]>;
}

export function deriveDisplayData(
  tasks: Task[],
  invoices: ReadonlyArray<Invoice>,
  files: PersistentProjectFile[]
): InvoiceDisplayInfo[] {
  if (tasks.length === 0 || invoices.length === 0) {
    return [];
  }

  const derived = invoices.map((invoice) => ({
    invoice,
    // approvalStatus: invoice.approval_status,
    displayDraws: calculateDrawInfo(invoice, tasks, files),
  }));
  return derived;
}

export const csvHeaders: (
  putMode: boolean | null
) => { label: string; key: string }[] = (putMode) => [
  { label: "Task", key: "task" },
  { label: "Value", key: "value" },
  { label: "Funded", key: "funded" },
  { label: "Remaining", key: "remaining" },
  {
    label: putMode ? "Put Amount" : "Draw Request Amount",
    key: "drawRequestAmount",
  },
  { label: "Approval Status", key: "approvalStatus" },
];

const isEdited = (invoice: Pick<Invoice, "ancestors">) =>
  (invoice.ancestors?.length ?? 0) > 0;

export function InvoiceTotal({
  displayInfo: {
    invoice: { ancestors },
    displayDraws,
  },
}: {
  displayInfo: Pick<CalculatedDisplayInfo, "invoice" | "displayDraws">;
}): JSX.Element {
  const { formatMoney } = useMoney();

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <p>Total{isEdited({ ancestors }) ? " (Edited)" : ""}</p>
      <p>
        {formatMoney(
          displayDraws.reduce(
            (sum, draw) => sum + Number(draw.drawRequestAmount),
            0
          )
        )}
      </p>
    </div>
  );
}

export function DrawLines({
  displayInfo: {
    invoice: { is_put },
    displayDraws,
  },
  formatMoney,
  maxDrawLines,
  onTitleClicked,
  renderFileAffordance,
  leftSideWidget,
  tasks,
}: {
  displayInfo: Readonly<{
    invoice: Readonly<Pick<Invoice, "is_put">>;
    displayDraws: Readonly<CalculatedDraw[]>;
  }>;
  formatMoney: (n: number) => string;
  maxDrawLines: number;
  onTitleClicked: (draw: CalculatedDraw) => void;
  renderFileAffordance?: TaskRenderFileAffordance;
  leftSideWidget?: (
    draw: CalculatedDraw,
    key: number
  ) => JSX.Element | undefined;
  tasks: Task[];
}): JSX.Element {
  const drawLines = displayDraws.map((draw, key) => {
    const spacesLeft = leftSideWidget ? 2 : 0;
    const spacesRight = renderFileAffordance ? 2 : 0;
    const extraSpaces = spacesLeft + spacesRight;

    return (
      <Grid container justifyContent="space-between" key={key}>
        {leftSideWidget ? (
          <Grid item xs={spacesLeft}>
            {leftSideWidget(draw, key)}
          </Grid>
        ) : null}

        <Grid item xs={7 - extraSpaces / 2}>
          <span
            style={{ cursor: "pointer" }}
            onClick={() => onTitleClicked(draw)}
          >
            <p>{draw.task.title}</p>
          </span>
        </Grid>
        <Grid item xs={5 - extraSpaces / 2} style={{ textAlign: "end" }}>
          {is_put ? (
            <p>{formatMoney(-draw.drawRequestAmount)}</p>
          ) : (
            <p>-{formatMoney(draw.drawRequestAmount)}</p>
          )}
        </Grid>
        {renderFileAffordance ? (
          <Grid item xs={spacesRight}>
            {renderFileAffordance(draw.task)}
          </Grid>
        ) : null}
      </Grid>
    );
  });

  const first = drawLines.slice(0, maxDrawLines);
  const rest = drawLines.slice(maxDrawLines);
  return (
    <>
      {first}
      {rest.length > 0 ? (
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="body2">({rest.length} more)</Typography>
          </AccordionSummary>
          <AccordionDetails style={{ display: "initial", padding: 0 }}>
            {rest}
          </AccordionDetails>
        </Accordion>
      ) : null}
    </>
  );
}

const PDFIcon = styled("img")(({ theme }) => bigAssIcon(theme));

export function DrawReportButton({
  invoiceId,
  preview,
}: {
  invoiceId: string;
  preview?: boolean;
}): JSX.Element | null {
  const { drawReportHTML } = useFeatures();
  const { signUrl } = useAppContext();
  const email = throwIfNot(useAuth().currentUserEmail, "email is required");
  const [url, setUrl] = React.useState<string | undefined>();

  React.useEffect(() => {
    (async () => {
      setUrl(
        drawReportHTML || !preview
          ? await signUrl(
              `${
                config.apiGateway.URL
              }drawReport?invoiceId=${invoiceId}&tz=${encodeURIComponent(
                Intl.DateTimeFormat().resolvedOptions().timeZone
              )}${preview ? "&html=1" : ""}&callerEmail=${encodeURIComponent(
                email
              )}`
            )
          : undefined
      );
    })();
  }, [drawReportHTML, email, invoiceId, preview, signUrl]);

  if (url) {
    return preview ? (
      <Tooltip title="Preview report" aria-label="preview report">
        <a aria-label="preview-report" href={url}>
          <CodeIcon sx={bigAssIcon} />
        </a>
      </Tooltip>
    ) : (
      <Tooltip title="Download report" aria-label="download report">
        <a aria-label="download-report" href={url}>
          <PDFIcon src={pdfDownloadIcon} alt="Download PDF draw report" />
        </a>
      </Tooltip>
    );
  }

  return null;
}

export const commonStyles: Record<
  "root" | "primary" | "wrapper" | "headerIcons",
  SxProps<Theme>
> = {
  root: {
    width: 350,
    maxWidth: "90vw",
    display: "block",
    margin: "10px",
    "& .MuiCardContent-root": {
      paddingTop: 0,
    },
  },
  primary: ({ palette }) => ({
    ...invertedButtonStyle({ palette }),
    textAlign: "center",
    width: "150px",
  }),
  wrapper: {
    // margin: "20px",
    marginBottom: "10px",
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    justifyContent: "space-around",
  },
  headerIcons: bigAssIcon,
};

const InvoiceContent = styled("div")(({ theme: { spacing } }) => ({
  display: "flex",
  justifyContent: "space-around",
  alignItems: "center",
  flexDirection: "column",
  "& h3": {
    margin: 0,
  },
  paddingTop: spacing(1),
}));

const InvoiceHeader = styled("div")({
  display: "flex",
  flexDirection: "row",
  flexWrap: "nowrap",
  justifyContent: "space-between",
  width: "100%",
});

const DrawReportButtons = styled("div")(({ theme: { spacing } }) => ({
  display: "flex",
  flexDirection: "row",
  flexWrap: "nowrap",
  alignItems: "center",
  justifyContent: "flex-end",
  "& *": {
    paddingTop: 0,
    paddingBottom: 0,
  },
  // marginTop: -1 * spacing(3),
  marginBottom: 0,
  gap: spacing(1),
}));

const InvoiceList = styled("div")({
  // margin: "20px",
  marginBottom: "10px",
  display: "flex",
  flexWrap: "wrap",
  width: "100%",
  justifyContent: "space-around",
});
export { InvoiceContent, InvoiceHeader, DrawReportButtons, InvoiceList };

export function invoiceDescription(
  invoice: Invoice,
  sequence: number,
  invoiceDate: Date | undefined
): string {
  const justTheDate = invoiceDate?.toISOString().split("T").shift();
  return invoice.is_put
    ? invoice.identifier || `#${sequence + 1}: (Put) ${justTheDate}`
    : invoice.identifier || `#${sequence + 1}: ${justTheDate}`;
}

export function MarkPaidCheckbox({
  invoice,
}: {
  invoice: Invoice;
}): JSX.Element {
  const { project_id: projectId } = invoice;
  const invoiceFn = useProjectInvoices(projectId).project.invoice;
  const { paidInvoiceCheckbox } = useEntityFeatures();
  const { canModifyDrawPaidStatus } = usePermissions();

  return paidInvoiceCheckbox ? (
    <FormControlLabel
      control={
        <Checkbox
          color="primary"
          checked={invoice.paid}
          onChange={({ target: { checked } }) => {
            invoiceFn(invoice.invoice_id)?.markPaid(checked);
          }}
          disabled={
            !canModifyDrawPaidStatus ||
            invoice.approval_status === ApprovalStatus.Rejected
          }
          name="paid"
        />
      }
      label="Paid"
    />
  ) : (
    <div />
  );
}

export const useDisplayDraws = ({
  files,
  invoice,
  tasks,
}: {
  files: ReadonlyArray<PersistentProjectFile>;
  invoice: Readonly<Parameters<typeof calculateDrawInfo>>[0];
  tasks: ReadonlyArray<Task>;
}) =>
  React.useMemo(
    () => calculateDrawInfo(invoice, tasks, files),
    [files, invoice, tasks]
  );
