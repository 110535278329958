import useSWR, { mutate as globalMutate, useSWRConfig } from "swr";
import { useBackend } from ".";
// import { InspectionCompanies } from "../../Pages/ProjectView/Invoice/PendingInvoice";
import { CreateDecisionInputs, deleteDecision, FetchError } from "../API";
import {
  ApprovalStatus,
  legacyBackendGetProjectDetailsResponse,
  BackendGetProjectItemsDecisionsResponseEntry,
  BackendUserResponse,
  User,
} from "../APITypes";
import { StoredFile } from "../StoredFile";
import { useAppContext } from "../UserContext";
import { throwIfNot } from "../util/throwIfNot";
import { useAuth } from "@lib/hooks/useAuth";
import { SWRKeys } from "../swrKeys";
import { ProjectId } from "@project-centerline/project-centerline-api-types";

interface InspectionRequestParams {
  comments: string;
  forInvoice?: string;
  preferredInspector?: BackendUserResponse;
}

interface UseProjectDecisionsAPI {
  project: {
    decisions?: Readonly<BackendGetProjectItemsDecisionsResponseEntry[]>;
    addDecision: (
      inputs: CreateDecisionInputs
    ) => Promise<BackendGetProjectItemsDecisionsResponseEntry>;
    removeDecision: (decisionId: string) => Promise<unknown>;
    requestPCInspection: ({
      comments,
      forInvoice,
      preferredInspector,
    }: InspectionRequestParams) => Promise<BackendGetProjectItemsDecisionsResponseEntry>;
    requestFeasibilityReport: (files: StoredFile[]) => // {
    // comments,
    // forInvoice,
    // preferredInspector,
    // }: InspectionRequestParams
    Promise<BackendGetProjectItemsDecisionsResponseEntry>;
    updateRouting: (
      approvers: ReadonlyArray<Pick<User, "email">>
    ) => Promise<void>;

    approveDecision: (params: {
      projectId: string;
      decisionId: string;
      approval: ApprovalStatus;
    }) => Promise<void>;
  };
  errors?: {
    decisions?: Readonly<FetchError>;
  };
}

export function useProjectDecisions(projectId: string): UseProjectDecisionsAPI {
  const {
    createDecision,
    getProjectDecisions,
    updateDecisionRouting,
    approveDecision: backendApproveDecision,
  } = useBackend();
  const { role } = useAppContext();
  const { currentUserEmail } = useAuth();

  const { cache } = useSWRConfig();
  const {
    data: decisions,
    error: decisionsError,
    mutate,
  } = useSWR<BackendGetProjectItemsDecisionsResponseEntry[], FetchError>(
    SWRKeys.project(projectId as ProjectId).decisions,
    (_url: unknown) =>
      getProjectDecisions(
        projectId,
        role,
        throwIfNot(currentUserEmail, "email is required")
      )
  );

  const addDecision = (decision: CreateDecisionInputs) =>
    createDecision(decision).then((newDecision) => {
      mutate((decisions ?? []).concat(newDecision), false);
      return newDecision;
    });

  const email = throwIfNot(useAuth().currentUserEmail, "email is required");
  const approveDecision: UseProjectDecisionsAPI["project"]["approveDecision"] =
    ({ projectId, decisionId, approval }) =>
      backendApproveDecision({
        projectId,
        decisionId,
        approval,
        email,
        role,
      }).then((/* raw newDecision */) => {
        // mutate((decisions ?? []).concat(newDecision), false);
        // return newDecision;
        mutate();
      });

  const removeDecision = (decisionId: string) =>
    /* optimistic */
    mutate(
      (decisions ?? []).filter(({ decision_id }) => decision_id !== decisionId),
      false
    )
      /* do it */
      .then(() => deleteDecision({ decision_id: decisionId }))
      .then(() => {
        if (!(cache instanceof Map)) {
          throw new Error("this not gonna work");
        }
        // clean up e.g. /api/decisions/foo/comments
        return Promise.all(
          Array.from(cache.keys())
            .filter((key: string) =>
              key.startsWith(SWRKeys.decision(decisionId).self())
            )
            .map((key) => globalMutate(key))
        );
      })
      /* make sure / refresh */
      .finally(mutate);

  const updateRouting = (approvers: ReadonlyArray<Pick<User, "email">>) =>
    // optimistic
    globalMutate(
      SWRKeys.project(projectId as ProjectId).self,
      async ({
        decision_routing,
        ...rest
      }: legacyBackendGetProjectDetailsResponse) => ({
        ...rest,
        decision_routing: approvers.map(({ email }) => email),
      }),
      false
    ).then(() =>
      updateDecisionRouting({ projectId, approvers }).then(
        ({ approverChanges }) => {
          if (approverChanges && approverChanges.length) {
            const changesByDecisionId = approverChanges.reduce(
              (
                result: Record<string, string>,
                change: { decision_id: string; current_approver: string }
              ) => {
                result[change.decision_id] = change.current_approver;
                return result;
              },
              {}
            );
            return Promise.all([
              mutate(
                (decisions) =>
                  (decisions ?? []).map(
                    ({ current_approver, decision_id, ...rest }) => ({
                      ...rest,
                      current_approver: changesByDecisionId[decision_id],
                      decision_id,
                    })
                  ),
                false
              ),
              // refresh, just in case
              globalMutate(SWRKeys.project(projectId as ProjectId).self),
            ]).then(() => {
              // we said we return void
            });
          } else {
            return;
          }
        }
      )
    );

  const errors = decisionsError
    ? {
        errors: {
          decisions: decisionsError || undefined,
        },
      }
    : {};

  return {
    project: {
      decisions,
      addDecision,
      removeDecision,
      requestPCInspection: ({
        comments: description,
      }: InspectionRequestParams) =>
        addDecision({
          description,
          email,
          files: [],
          type: "full_concierge_inspection",
          projectId,
          timeFrame: new Date(),
        }),
      requestFeasibilityReport: (files) =>
        addDecision({
          description: "Feasibility Report",
          email,
          files,
          type: "feasibility_report",
          projectId,
          timeFrame: new Date(),
        }),
      updateRouting,
      approveDecision,
    },
    ...errors,
  };
}
