import { useProjectDetails } from "@lib/hooks/useProjectDetails";
import React from "react";
import { useSnackbar } from "notistack";
import { Grid, Typography } from "@mui/material";
import { ErrorOrProgressOr } from "../../../Components/ErrorOrProgressOr";

import { CSVExport } from "../../../Components/CSVExport";
import sanitizeFilename from "sanitize-filename";
import { filenameDateFormat } from "@lib/util/filenameDateFormat";
import { useProjectInvoiceActivity } from "@lib/hooks/useProjectInvoiceActivity";
import { useMoney } from "@lib/hooks";

export interface ProjectReportingTabProps {
  /** We'll fetch with SWR but hopefully it's already ready :-) */
  // TODO: make getprojects and getprojectdetails cross-update each other - as long as they currently return the same content //spell-checker: ignore getprojectdetails
  projectId: string;
}

/**
 * Show a project. Fetches and updates via useProjectDetails
 */
export function ProjectReportingTab({
  projectId,
}: ProjectReportingTabProps): JSX.Element {
  const { formatMoney } = useMoney();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { activity, errors } = useProjectInvoiceActivity(projectId);
  const { project } = useProjectDetails(projectId);
  React.useEffect(() => {
    // console.log({ errors });
    const errorString =
      errors?._tag === "RequestError"
        ? ` ${errors.error.message}`
        : "Something went wrong";
    const snackbar =
      errors &&
      enqueueSnackbar(`Oops! ${errorString}.`, { variant: "warning" });
    if (!errors) {
      closeSnackbar(snackbar);
    }
  }, [closeSnackbar, enqueueSnackbar, errors]);

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Typography variant="h3"></Typography>
      <ErrorOrProgressOr
        title="Fetching some history..."
        progress={!(activity && project?.details)}
        render={() => (
          <Grid container item spacing={2} alignItems="center">
            <Grid item>
              <Typography>Approved Draw Activity:</Typography>
            </Grid>
            <Grid item>
              <CSVExport
                data={
                  activity?.map(({ totalAmount, ...activity }) => ({
                    ...activity,
                    totalAmount: formatMoney(totalAmount),
                  })) ?? []
                }
                headers={[
                  { label: "Identifier", key: "identifier" },
                  { label: "Amount", key: "totalAmount" },
                  { label: "Created", key: "created" },
                  { label: "Approved", key: "approved" },
                  { label: "ID", key: "id" },
                ]}
                filename={sanitizeFilename(
                  `Draw Activity - ${
                    project?.details?.name
                  } - ${filenameDateFormat(new Date())} .csv`
                )}
              />
            </Grid>
          </Grid>
        )}
      />
    </Grid>
  );
}
