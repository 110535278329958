import { useMemo } from "react";
import { EntityFeatures, EntityFeaturesZodSchema } from "../APITypes";
import { logAnomaly } from "../ErrorLogging";
import { useEntity } from "@lib/hooks/useEntity";

export const useEntityFeatures: () => EntityFeatures = () => {
  const myEntity = useEntity().myEntity;
  const features = useMemo(
    () => EntityFeaturesZodSchema.parse(myEntity?.features ?? {}),
    [myEntity?.features]
  );

  const strictParse = EntityFeaturesZodSchema.strict().safeParse(features);
  if (!strictParse.success) {
    logAnomaly(new Error("unexpected feature flag"), {
      error: strictParse.error,
      message: strictParse.error.message,
    });
  }

  return useMemo(() => {
    const { perLineItemAttachments, digitalInspections } = features;

    return {
      ...features,
      perLineItemAttachments: perLineItemAttachments || digitalInspections,
      digitalInspections,
    };
  }, [features]);
};
