import React from "react";

import { styled } from "@mui/material/styles";

import {
  AppBar,
  Box,
  Button,
  Dialog,
  IconButton,
  LinearProgress,
  // Slide,
  Switch,
  Toolbar,
  Typography,
} from "@mui/material";
import { MUIDataTableColumn } from "mui-datatables"; // spell-checker: ignore datatables
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { BackendGetTaskResponse, CreateTaskInputs } from "@lib/APITypes";

import { observer } from "mobx-react-lite";

import { tron } from "../../../reactotronConfig";
import {
  ITaskImportData,
  TaskImportDataModel,
  ITask,
} from "./ImportTasksModels";
import { chooseParser } from "./importTaskParsers";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import { useMoney } from "@lib/hooks/useMoney";
import { DropzoneAreaBase, FileObject } from "react-mui-dropzone";
import * as PapaParse from "papaparse"; // spell-checker:ignore papaparse
import { useFeatures } from "@lib/hooks";
import { invertedButtonStyle } from "../../../projectCenterlineTheme";
import { MUIDataTable } from "../../../Components/MUIDataTableWithTypeHack";
import { Task } from "../../../Models";
import { useAuth } from "@lib/hooks/useAuth";
import { throwIfNot } from "@lib/util/throwIfNot";

const PREFIX = "ImportTasksDialog";

const classes = {
  appBar: `${PREFIX}-appBar`,
  title: `${PREFIX}-title`,
  error: `${PREFIX}-error`,
  importButton: `${PREFIX}-importButton`,
};

const Root = styled("div")(({ theme: { spacing, palette } }) => ({
  [`& .${classes.appBar}`]: {
    position: "relative",
  },

  [`& .${classes.title}`]: {
    marginLeft: spacing(2),
    flex: 1,
  },

  [`& .${classes.error}`]: {
    color: "red",
  },

  [`& .${classes.importButton}`]: {
    ...invertedButtonStyle({ palette }),
    marginRight: spacing(1),
  },
}));

export enum EditMode {
  NONE = "none",
  INLINE = "inline",
  BUTTON = "button",
}

interface ImportTasksBaseProps {
  /**
   * Hook to create new task (extracted to prop to make this thing more testable / storybook-able)
   * @param arg0 New task ingredients
   */
  createTask(
    project_id: string,
    retention: number,
    email: string,
    taskInputs: CreateTaskInputs
  ): Promise<Task>;

  /**
   * Callback for when all requested task has been created. You might want to refetch, or close dialog, etc.
   */
  onDoneCreatingTasks?(newTasks: BackendGetTaskResponse[]): void;

  /**
   * present editors inline?
   */
  edit: EditMode;
}
export interface ImportTasksProps extends ImportTasksBaseProps {
  /**
   * the project to which we will add tasks
   */
  project_id: string;

  /**
   * What percentage should be held back, as a number from 0 to 100?
   *
   * https://www.levelset.com/blog/retainage/
   */
  retention: number;
}

export interface ImportTasksDialogProps extends ImportTasksBaseProps {
  editModel: ITaskImportData;
  open: boolean;
  onRequestClose: () => void;
}

/**
 * Get the fields the table control will want.
 *
 * ATM, it just returns a copy. But we do have to dereference the model in order to let
 * the grid know about changes. In fact, this makes it re-render too much. If we eventually
 * need to optimize, we could memoize.
 *
 * @param row task row model
 */
const extractTableColumns = (row: ITask) => ({ ...row });
export const ImportTasksDialog = observer(
  ({
    editModel,
    onRequestClose,
    open,
    onDoneCreatingTasks,
    createTask,
    edit,
  }: ImportTasksDialogProps) => {
    const { formatMoney } = useMoney();

    const columns: MUIDataTableColumn[] = [
      // {
      //   name: "id",
      //   label: "#",
      // },
      {
        name: "hasBeenImported",
        label: "Imported",
        options: {
          customBodyRenderLite: (dataIndex) => {
            const { hasBeenImported } = editModel.rows[dataIndex] || {};
            return hasBeenImported ? ( // ignore table and go to model
              <CheckCircleIcon style={{ fill: "#4caf50" }} />
            ) : null;
          },
          filter: true,
          filterType: "dropdown",
          filterOptions: {
            renderValue: (v) => (v ? "Yes" : "No"),
          },
          customFilterListOptions: {
            render: (v) => (v ? "Imported" : "Not Imported"),
          },
          display: editModel.rows.some(
            ({ hasBeenImported }) => hasBeenImported
          ),
        },
      },
      {
        name: "title",
        label: "Name",
      },
      {
        name: "value",
        label: "Value",
        options: {
          customBodyRenderLite: (dataIndex) => {
            const { value } = editModel.rows[dataIndex] || {};
            return formatMoney(value);
          },
        },
      },
      {
        name: "description",
        label: "Description",
        options: {
          display:
            edit === EditMode.INLINE ||
            editModel.rows.some(({ description }) => !!description),
        },
      },
      {
        name: "critical",
        label: "Critical",
        options: {
          customBodyRenderLite: (dataIndex) => {
            const { critical } = editModel.rows[dataIndex] || {};
            return edit === EditMode.INLINE ? (
              <Switch
                checked={critical}
                color="secondary"
                onChange={(_, newValue) => {
                  editModel.rows[dataIndex].setCritical(newValue);
                }}
              />
            ) : critical ? (
              <PriorityHighIcon />
            ) : null;
          },
          filter: true,
          filterType: "dropdown",
          filterOptions: {
            renderValue: (v) => (v ? "Yes" : "No"),
          },
          customFilterListOptions: {
            render: (v) => (v ? "Critical" : "Not Critical"),
          },
          display:
            edit === EditMode.INLINE ||
            editModel.rows.some(({ critical }) => critical),
        },
      },
      {
        name: "start",
        label: "Start",
        options: {
          display:
            edit === EditMode.INLINE ||
            editModel.rows.some(({ start }) => start),
        },
      },
      {
        name: "end",
        label: "End",
        options: {
          display:
            edit === EditMode.INLINE || editModel.rows.some(({ end }) => end),
        },
      },
      {
        name: "dummy_edit",
        label: " ",
        options: {
          display: edit === EditMode.BUTTON ? true : "excluded",
          filter: false,
          customBodyRenderLite: (dataIndex) => (
            <Button
              onClick={() =>
                alert(
                  "Coming soon (maybe?). If it seems useful, it could be doable. And/or we could edit inline"
                )
              }
            >
              Edit
            </Button>
          ),
        },
      },
    ];

    interface MUICustomToolbarSelectProps {
      setSelectedRows: (rows: number[]) => void;
    }

    const CustomToolbarSelect = ({
      setSelectedRows,
    }: MUICustomToolbarSelectProps) => (
      <Root>
        {/* <Button onClick={onRequestClose}>Close</Button> */}
        <Button
          className={classes.importButton}
          variant="outlined"
          color="primary"
          onClick={
            () =>
              editModel
                .createTasksFromSelected(createTask)
                .then((newTasks) => {
                  setSelectedRows([]);
                  console.log("newTasks", { newTasks, onDoneCreatingTasks });
                  return newTasks;
                })
                .then(onDoneCreatingTasks)
                .catch((err) => {
                  console.log("Error importing tasks", { err });
                  alert(
                    `An error occurred and some tasks were not imported: ${err.message}`
                  );
                })
            //  setTasksToCreate(new Set(editModel.selectedRows))
            // new Set(rows.filter(({ id }) => selectedTaskIds.has(String(id))))
            // )
          }
          disabled={
            // !!error ||
            editModel.rows.length < 1 ||
            // tasksToCreate.size > 0 ||
            editModel.taskCreationInProgress
          }
        >
          Import
        </Button>
      </Root>
    );

    return (
      <div>
        <Dialog open={open} scroll="paper" onClose={onRequestClose}>
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={onRequestClose}
                aria-label="close"
                disabled={editModel.taskCreationInProgress}
                size="large"
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                Import Tasks
              </Typography>
              {/* <Button autoFocus color="inherit" onClick={handleClose}>
                save
              </Button> */}
            </Toolbar>
          </AppBar>
          <div style={{ height: "100%", overflow: "hidden" }}>
            <MUIDataTable
              title="Choose tasks to import"
              data={editModel.rows.map(extractTableColumns)}
              columns={columns}
              // checkboxSelection
              // autoHeight
              options={{
                viewColumns: false, // https://trello.com/c/Qv3NScyp/222-add-mvp-csv-import-to-new-budget-tab
                filter: false, // https://trello.com/c/Qv3NScyp/222-add-mvp-csv-import-to-new-budget-tab
                pagination: false,
                rowsSelected: editModel.selectedRowIndices,
                onRowSelectionChange: (
                  _currentRowsSelected,
                  allRowsSelected,
                  _rowsSelected
                ) =>
                  editModel.selectRowsByIndex(
                    allRowsSelected.map(({ dataIndex }) => dataIndex)
                  ),
                onRowsDelete: (selectedRows) => {
                  console.log(selectedRows);
                  // return false;
                },
                customToolbarSelect: (
                  _selectedRows,
                  _displayData,
                  setSelectedRows
                ) => (
                  <CustomToolbarSelect
                    // selectedRows={selectedRows}
                    // displayData={displayData}
                    setSelectedRows={setSelectedRows}
                  />
                ),
                download: false,
                print: false,
                responsive: "simple",
                tableBodyMaxHeight: "80vh",
                fixedHeader: true,
              }}
            />
            <Dialog open={editModel.taskCreationInProgress}>
              <Box display="flex" alignItems="center" m={1}>
                <Box width="100%" m={1}>
                  <LinearProgress
                    variant="determinate"
                    value={
                      (100 * editModel.tasksCreated.length) /
                      (editModel.tasksToCreate.length +
                        editModel.tasksCreated.length)
                    }
                  />
                </Box>
                <Box minWidth={35}>
                  <Typography variant="body2" color="textSecondary">{`${
                    editModel.tasksCreated.length
                  }/${
                    editModel.tasksToCreate.length +
                    editModel.tasksCreated.length +
                    1
                  }`}</Typography>
                </Box>
              </Box>
            </Dialog>
          </div>
        </Dialog>
      </div>
    );
  }
);

export const ImportTasks = observer(
  ({
    project_id,
    retention,
    createTask,
    onDoneCreatingTasks,
    edit,
  }: ImportTasksProps) => {
    const [importFile, setImportFile] = React.useState<FileObject[]>([]);
    // const [rows, setRows] = React.useState<RowData[]>([]);
    const [error, setError] = React.useState<Error | undefined>(undefined);
    // const [selectedRows, setSelectedRows] = React.useState(new Set<IRow>());
    // const [tasksToCreate, setTasksToCreate] = React.useState(new Set<IRow>());
    // const [taskCreationInProgress, setTaskCreationInProgress] = React.useState(
    // false
    // );
    // const [tasksCreated, setTasksCreated] = React.useState([]);
    const [importTableOpen, openImportTable] = React.useState(false);

    // const [taskCreationRequested, setTaskCreationRequested] = React.useState(
    // false
    // );
    const { currentUserEmail: email } = useAuth();
    const { maxFileUploadSize } = useFeatures();

    const [resetRequested, requestReset] = React.useState(false);
    const editModel = React.useMemo(() => {
      if (resetRequested) {
        requestReset(false);
      }
      // throw it away and start clean
      const editModel = TaskImportDataModel.create({
        projectId: project_id,
        retention,
        email: throwIfNot(email, "email is required"),
        rows: [],
      });
      tron && tron.trackMstNode && tron.trackMstNode(editModel);
      return editModel;
    }, [resetRequested, project_id, retention, email]);

    const handleNewFile = (data: string[][]) => {
      try {
        editModel.replaceTasks(chooseParser(data)[0].parseRawRowData(data));
        openImportTable(true);
        setError(undefined);
      } catch (err) {
        setError(err as Error);
      } finally {
        setImportFile([]);
      }
    };

    return (
      <div>
        <DropzoneAreaBase
          filesLimit={1}
          maxFileSize={maxFileUploadSize}
          fileObjects={importFile}
          acceptedFiles={["text/csv", ".csv"]}
          showAlerts={false}
          onAdd={(files) => {
            if (files.length === 1) {
              const reader = new FileReader();
              const file = files[0].file;
              reader.onload = () => {
                PapaParse.parse(file, {
                  complete: (results) =>
                    handleNewFile(results.data as string[][]),
                  error: (err) => {
                    setImportFile([]);
                  },
                });
              };

              reader.readAsText(file);
            }
          }}
          dropzoneText="Click or drop a CSV to import tasks"
          getFileAddedMessage={(fileName) => (`File ${fileName} queued for import.`)}
          />

        {error ? (
          <div>
            <p className={classes.error}>{error.message}</p>
          </div>
        ) : (
          <div style={{ flexGrow: 1 }}>
            <ImportTasksDialog
              editModel={editModel}
              open={importTableOpen}
              onRequestClose={() => {
                openImportTable(false);
                editModel.replaceTasks([]);
                setImportFile([]);
              }}
              createTask={createTask}
              onDoneCreatingTasks={onDoneCreatingTasks}
              edit={edit}
            />
          </div>
        )}
      </div>
    );
  }
);
