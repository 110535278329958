import useSWR from "swr";
import { allUsers } from "@lib/API";
import { BackendGetUserDetailsResponse } from "@lib/APITypes";

export function useAllUsers(): {
  users?: BackendGetUserDetailsResponse[] | undefined;
  error?: unknown;
} {
  const { data, error } = useSWR(["/user/*"], (_url: unknown) => allUsers(), {
    revalidateOnFocus: false,
    // revalidateOnMount: false,
    revalidateOnReconnect: false,
  });

  return {
    users: data?.users,
    error,
  };
}
