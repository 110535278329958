import { CircularProgress, Grid } from "@mui/material";
import React from "react";

/**
 * Handle loading state
 *
 * @param props.error if something bad happened, something we can display, else falsy
 * @param props.progress truthy = show a spinner
 * @returns error, or progress, or render()
 */
export function ErrorOrProgressOr({
  error,
  progress,
  render,
  title,
}: {
  error?: string | React.ReactElement;
  progress: boolean;
  render: () => JSX.Element;
  title?: string;
}): JSX.Element {
  return (
    <>
      {error ? (
        <p>Error: {error}</p>
      ) : progress ? (
        <Grid container direction="column" textAlign="center">
          <Grid item>
            <CircularProgress />
          </Grid>
          {title ? (
            <Grid item>
              <p>{title}</p>
            </Grid>
          ) : null}
        </Grid>
      ) : (
        render()
      )}
    </>
  );
}
