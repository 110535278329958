import React from "react";
import { Auth } from "aws-amplify";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import config from "../config";
import { ifEnterKey } from "@lib/boilerplate";
import { useFocus } from "@lib/hooks/useFocus";
import { ConfirmDialog } from "../Components/ConfirmDialog";
import { requestTempPassword } from "@lib/API";
import { logAnomaly } from "@lib/ErrorLogging";
import { Link } from "react-router-dom";
import { useSnackbar } from "notistack";

const RequestCodeForm = ({
  handleSendCodeClick,
  handleChange,
}: {
  handleSendCodeClick: (event: { preventDefault: () => void }) => void;
  handleChange: (event: { target: { name: string; value: string } }) => void;
}) => {
  return (
    <div className="recovery-parent-div">
      <h3 className="recoveryHeader">Forgot Password</h3>
      <form className="password-recover-form" onSubmit={handleSendCodeClick}>
        <p className="recovery-email-label">
          Enter your email address. If it matches an existing user you will
          receive a code at that address.
        </p>
        <TextField
          variant="standard"
          name="email"
          label="Email"
          fullWidth
          required
          onChange={handleChange}
        />
        <div>
          <Button color="primary" variant="outlined" type="submit">
            Continue
          </Button>
        </div>
      </form>
    </div>
  );
};

const ConfirmationForm = ({
  handleConfirmClick,
  handleChange,
  handleResendClick,
  email,
}: {
  handleConfirmClick: (event: { preventDefault: () => void }) => void;
  handleResendClick: (event?: { preventDefault: () => void }) => void;
  handleChange: (event: { target: { name: string; value: string } }) => void;
  email: string;
}) => {
  const [passwordRef, focusPassword] = useFocus();
  const [confirmPasswordRef, focusConfirmPassword] = useFocus();
  const { enqueueSnackbar } = useSnackbar();

  return (
    <div className="recovery-parent-div">
      <h3 className="recovery-header">Forgot Password</h3>
      <form className="recovery-form" onSubmit={handleConfirmClick}>
        <p>
          If {email} matched an existing user email, a confirmation code was
          sent. Please enter it below and choose a new password. (Didn't receive
          the email? We can{" "}
          <Link
            to="#"
            onClick={() => {
              enqueueSnackbar("New code sent (if email exists)", {
                variant: "info",
              });
              handleResendClick();
            }}
          >
            send another
          </Link>
          .)
        </p>
        <TextField
          variant="standard"
          name="code"
          label="Code"
          fullWidth
          required
          onChange={handleChange}
          onKeyDown={ifEnterKey(focusPassword)}
        />
        <p>Password</p>
        <TextField
          variant="standard"
          name="password"
          type="password"
          label="Password"
          fullWidth
          required
          onChange={handleChange}
          onKeyDown={ifEnterKey(focusConfirmPassword)}
          inputRef={passwordRef}
        />
        <p> Confirm Password</p>
        <TextField
          variant="standard"
          name="confirmpassword"
          type="password"
          label="Confirm Password"
          fullWidth
          required
          onChange={handleChange}
          inputRef={confirmPasswordRef}
        />
        <div className="recovery-button-div">
          <Button color="primary" variant="outlined" type="submit">
            Continue
          </Button>
        </div>
      </form>
    </div>
  );
};

const SuccessMessage = ({ handleClose }: { handleClose: () => void }) => {
  return (
    <div className="success">
      <p>Your password has been reset.</p>
      <p onClick={handleClose}>Close to login with your new credentials.</p>
    </div>
  );
};

export default function ForgotPassword(props: {
  callBack: () => void;
  open: boolean;
}): JSX.Element {
  const [formData, setFormData] = React.useState<{
    email: string;
    code: string;
    password: string;
  }>({ email: "", code: "", password: "" });
  const [codeSent, setCodeSent] = React.useState(false);
  const [confirmed, setConfirmed] = React.useState(false);
  const [tempPasswordState, setTempPasswordState] = React.useState<
    undefined | "ready to send" | "sending" | "sent" | "too many" | "error"
  >();

  const handleChange = (event: {
    target: { name: string; value: unknown };
  }) => {
    setFormData({
      ...formData,
      [event.target.name]:
        event.target.name === "email"
          ? (event.target.value as string).trim().toLowerCase()
          : event.target.value,
    });
  };
  const handleClose = () => {
    setTempPasswordState(undefined);
    props.callBack();
  };

  const handleSendCodeClick = async (event?: {
    preventDefault: () => void;
  }) => {
    event?.preventDefault();

    try {
      await Auth.forgotPassword(formData.email);
      setCodeSent(true);
    } catch (e) {
      const { code, message } = e as Error & { code?: string };
      if (
        code === "NotAuthorizedException" &&
        message === "User password cannot be reset in the current state."
      ) {
        setTempPasswordState("ready to send");
      } else if (
        code === "InvalidParameterException" &&
        message.includes(
          "Value at 'username' failed to satisfy constraint: Member must satisfy regular expression pattern: "
        )
      ) {
        alert("Email format is invalid");
      } else if (code === "LimitExceededException") {
        alert(
          "Too many failed logins or reset attempts. Please try again later."
        );
      } else if (code === "UserNotFoundException") {
        // fake it for the sake of https://trello.com/c/IQTkfLlS/217-should-have-vague-error-login-messages
        setCodeSent(true);
        // alert(
        //   `${formData.email} is not a recognized email. Did you type it correctly?`
        // );
      } else {
        logAnomaly(new Error("unhandled login error"), { e, code, message });
        alert(message);
      }
    }
  };

  const handleConfirmClick = async (event: { preventDefault: () => void }) => {
    event.preventDefault();

    try {
      await Auth.forgotPasswordSubmit(
        formData.email,
        formData.code,
        formData.password
      );
      setConfirmed(true);
    } catch (e) {
      const { message } = e as Error;
      alert(message);
    }
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={props.open}
      maxWidth={"xl"}
    >
      <div style={{ padding: "30px" }}>
        {tempPasswordState === "sent" ? (
          <div className="success">
            <p>A new temporary password has been sent.</p>
            <p onClick={handleClose}>
              Close to login with your new credentials.
            </p>
          </div>
        ) : tempPasswordState ? (
          // (tempPasswordState === "ready to send" || tempPasswordState === "sending") ?
          <ConfirmDialog
            title="Request New Temporary Password?"
            onConfirm={() => {
              setTempPasswordState("sending");
              requestTempPassword({ email: formData.email })
                .then(() => {
                  alert(
                    `A new temporary password has been sent to ${formData.email}. Please check your email.`
                  );
                  handleClose();
                })
                .catch((err) => {
                  if (
                    err.message.includes("Request failed with status code 429")
                  ) {
                    alert("Too many reset requests. Please try again later");
                    return handleClose();
                  }
                  alert(
                    `An error ocurred while attempting to reset your password. Please try again later or contact ${config.contact.customerSuccess}`
                  );
                  setTempPasswordState("error");
                });
            }}
            open={Boolean(tempPasswordState)}
            setOpen={(open) => {
              // if (!open && tempPasswordState !== "sending") {
              //   handleClose();
              // }
            }}
            cancelActionWords="Never Mind"
            confirmActionWords="Request New Temp Password"
            defaultAction="confirm"
            busy={tempPasswordState === "sending"}
            onCancel={handleClose}
          >
            <p>
              Your password cannot currently be reset because your username has
              not been verified.
            </p>
            <p>
              If you have misplaced your temporary password, you can request a
              new one.
            </p>
            <p>
              Passwords will be emailed and may take up to an hour to arrive.
            </p>
          </ConfirmDialog>
        ) : // <RequestNewTempPassword
        //   email={formData.email}
        //   onClose={() => {
        //     setTempPasswordState(false);
        //   }}
        // />
        !codeSent ? (
          <RequestCodeForm
            handleSendCodeClick={handleSendCodeClick}
            handleChange={handleChange}
          />
        ) : !confirmed ? (
          <ConfirmationForm
            handleConfirmClick={handleConfirmClick}
            handleChange={handleChange}
            email={formData.email}
            handleResendClick={handleSendCodeClick}
          />
        ) : (
          <SuccessMessage handleClose={handleClose} />
        )}
      </div>
    </Dialog>
  );
}

// spell-checker:ignore confirmpassword
