import { Instance, onSnapshot, types } from "mobx-state-tree";
import { createContext, useContext } from "react";
import { DeviceSettings } from "./DeviceSettings";
import { isObservable } from "mobx";

const RootModel = types.model({
  deviceSettings: DeviceSettings,
});

let initialState = RootModel.create({
  deviceSettings: {
    useNewUI: false,
  },
});

const data = localStorage?.getItem("rootState");
// console.log("local storage", { data });
if (data) {
  const json = JSON.parse(data);
  if (RootModel.is(json)) {
    initialState = RootModel.create(json);
  }
}

export const rootStore = initialState;

onSnapshot(rootStore, (snapshot) => {
  // console.log("Snapshot to local storage: ", snapshot);
  localStorage.setItem("rootState", JSON.stringify(snapshot));
});

export type RootInstance = Instance<typeof RootModel>;
const RootStoreContext = createContext<null | RootInstance>(null);

export const MSTProvider = RootStoreContext.Provider;
export function useMst() {
  const store = useContext(RootStoreContext);
  if (store === null) {
    throw new Error("Store cannot be null, please add a context provider");
  }
  return store;
}

export const useDeviceSettings = () => {
  const { deviceSettings } = useMst();
  if (!isObservable(deviceSettings)) {
    throw new Error("useDeviceSettings must be wrapped in observer");
  }
  return deviceSettings;
};
