import React from "react";
import { SxProps, Theme } from "@mui/material/styles";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { useMoney } from "@lib/hooks/useMoney";
import {
  // Menu,
  // MenuItem,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { MoneyEditor } from "../../../Components/MoneyEditor";
import { usePermissions } from "@lib/hooks";
import { Task } from "../../../Models";
import { wholePercentageFormatter } from "@lib/wholePercentageFormatter";
import { useEntityFeatures } from "@lib/hooks/useEntityFeatures";
import { TaskRenderFileAffordance } from "./TaskRenderFileAffordance";

// TODO: I'm starting to think this doesn't make sense as a separate component
export interface DrawRequestRowProps {
  task: Task;
  retention: number;
  onCompleteRequested?: (complete: boolean) => Promise<Task>;
  onEditRequested?: () => void;
  onTitleClicked?: () => void;
  onDeleteRequested?: () => void;
  initialAmount?: Readonly<number>;
  amountRequested: number;
  onAmountRequestedChange: (newAmount: number) => void;
  amountRequestedEditorProps?: Record<string, unknown>;
  autoFocus?: boolean;
  putMode: boolean;
  inputRef?: React.Ref<unknown> | undefined;
  renderFileAffordance?: TaskRenderFileAffordance;
}

export function DrawRequestRow({
  task,
  retention,
  onCompleteRequested,
  onEditRequested,
  onTitleClicked,
  onDeleteRequested,
  initialAmount,
  amountRequested,
  onAmountRequestedChange,
  autoFocus,
  amountRequestedEditorProps,
  putMode,
  inputRef,
  renderFileAffordance,
}: DrawRequestRowProps): JSX.Element {
  const {
    perLineItemAttachments: canSeePerLineItemAttachments,
    restrictInspectors,
  } = useEntityFeatures();
  const { formatMoney } = useMoney();
  const { id: taskId } = task;

  // This was too aggressive because canSee was coming true while we still were mounted with no render affordance
  // console.log({ renderFileAffordance, canSeePerLineItemAttachments });
  // if (Boolean(renderFileAffordance) !== Boolean(canSeePerLineItemAttachments)) {
  //   const error = new Error(
  //     "Per Line item requires render files (and vice versa)"
  //   );
  //   logError(error, { renderFileAffordance, canSeePerLineItemAttachments });
  //   throw error;
  // }
  const activeRenderFile = canSeePerLineItemAttachments
    ? renderFileAffordance
    : undefined;

  // const [
  //   // taskMenuAnchor,
  //   setTaskMenuAnchor,
  // ] = React.useState<null | HTMLElement>(null);
  // const openTaskMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
  //   setTaskMenuAnchor(event.currentTarget);
  // };
  // const closeTaskMenu = () => {
  //   setTaskMenuAnchor(null);
  // };

  const { canCreateDrawRequests, canDrawAgainstTask } = usePermissions();

  /*
   * for puts, amounts come in negative and should leave negative, even though MoneyEditor doesn't like that.
   * The logic here is mixed; in draw mode everything is positive. In put mode, `available` is positive but
   * most other amounts remain negative and are converted (inverted) at the point they are displayed or sent to
   * or from `MoneyEditor`.
   */
  const available = putMode
    ? task.invoiced_amount
    : task.task_value - task.invoiced_amount;
  const invertIfPut = (x: number) => (putMode ? -x : x);

  const disableEditing =
    task.completed || available <= 0 || !canDrawAgainstTask(task);

  const possiblyDisabledText: SxProps<Theme> | undefined = task.completed
    ? ({ palette }) => ({
        disabled: {
          color: palette.text.disabled,
        },
      })
    : undefined;

  // console.log(task.title, {
  //   putMode,
  //   amountRemaining: amountRemaining(task, putMode),
  //   amountRequested,
  //   effective: invertIfPut(amountRequested),
  //   initialAmount,
  //   available,
  // });

  const theme = useTheme();
  const narrowScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <TableRow
      hover
      role="checkbox"
      tabIndex={-1}
      key={taskId}
      sx={({ palette, typography }) => /* TODO: why just here? */ ({
        "& .MuiTableCell-root": {
          fontFamily: typography.fontFamily,
          ...(task.completed && {
            color: palette.text.disabled,
          }),
        },
      })}
    >
      <TableCell sx={possiblyDisabledText}>
        {task.description ? (
          <Tooltip title={task.description}>
            <span
              style={{ cursor: "pointer" }}
              onClick={() => onTitleClicked?.()}
            >
              {task.title}
            </span>
          </Tooltip>
        ) : (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => onTitleClicked?.()}
          >
            {task.title}
          </span>
        )}
      </TableCell>
      <TableCell sx={possiblyDisabledText}>
        {formatMoney(task.task_value)}
      </TableCell>
      {canCreateDrawRequests({
        restrictInspectors,
      }) ? (
        <>
          {initialAmount !== undefined ? (
            <TableCell>
              {initialAmount ? formatMoney(invertIfPut(initialAmount)) : ""}
            </TableCell>
          ) : null}
          <TableCell>
            <MoneyEditor
              autoFocus={autoFocus}
              onChange={(value: number) =>
                onAmountRequestedChange(invertIfPut(value))
              }
              value={disableEditing ? 0 : invertIfPut(amountRequested) || ""}
              sx={possiblyDisabledText}
              disabled={disableEditing}
              maximumValue={available}
              {...(amountRequestedEditorProps || {})}
              ref={inputRef}
              endAdornmentText={
                putMode
                  ? undefined
                  : amountRequested && !narrowScreen
                  ? wholePercentageFormatter.format(
                      Math.min(fractionRemaining(task, amountRequested), 1)
                    )
                  : undefined
              }
            />
          </TableCell>
        </>
      ) : null}
      <TableCell sx={possiblyDisabledText}>
        {formatMoney(amountRemaining(task, putMode))}
      </TableCell>
      {/* <TableCell className={className}>
        {formatMoney(task.task_value / (1 - retention / 100) - task.task_value)}
      </TableCell> */}
      <TableCell sx={possiblyDisabledText}>
        {wholePercentageFormatter.format(Math.min(fractionRemaining(task), 1))}
      </TableCell>
      {canSeePerLineItemAttachments ? (
        <TableCell>{activeRenderFile?.(task)}</TableCell>
      ) : null}
    </TableRow>
  );
}

export function fractionRemaining(
  task: Pick<Task, "invoiced_amount" | "task_value">,
  requested?: number
): number {
  return (task.invoiced_amount + (requested || 0)) / task.task_value;
}

export function amountRemaining(
  task: Pick<Task, "invoiced_amount" | "requested_amount" | "task_value">,
  putMode: boolean
): number {
  return putMode
    ? task.invoiced_amount
    : task.task_value - task.invoiced_amount - task.requested_amount;
}
