import { Autocomplete, Button, TextField } from "@mui/material";
import React, { PropsWithChildren, useEffect } from "react";
import { ConfirmDialog } from "./ConfirmDialog";
import { useAllUsers } from "@/Pages/Admin/useAllUsers";
import { BackendGetUserDetailsResponse, Project } from "@/Lib/APITypes";
import { prettyName } from "@/Pages/Admin/prettyName";
import { useProjectUsers } from "@/Lib/hooks/useProjectUsers";
import { ProgressDialog, useProgress } from "./ProgressDialog";
import { ProjectId } from "@project-centerline/project-centerline-api-types";

type UserAttributes = Pick<
  BackendGetUserDetailsResponse,
  "firstName" | "lastName" | "email" | "id" | "projects"
>;

export const BulkUserAdder = ({
  projects,
}: PropsWithChildren<{ projects: Project[] }>) => {
  const [userToAdd, setUserToAdd] = React.useState<UserAttributes | null>(null);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const { users = [] } = useAllUsers();
  const [projectsToProcess, setProjectsToProcess] = React.useState<Project[]>(
    []
  );
  const [projectBeingAddedTo, setProjectBeingAddedTo] = React.useState<
    Project | undefined
  >(undefined);
  const {
    project: { addExistingUser },
  } = useProjectUsers(projectBeingAddedTo?.project_id);

  const [progress, setProgress] = useProgress();

  useEffect(() => {
    if (projectsToProcess.length) {
      if (!projectBeingAddedTo) {
        setProgress({
          current: projectsToProcess[0].name,
          loaded: projects.length - projectsToProcess.length,
          total: projects.length,
        });
        setProjectBeingAddedTo(projectsToProcess[0]);
        setProjectsToProcess((ptp) => ptp.slice(1));
      }
    } else {
      if (!projectBeingAddedTo) {
        setDialogOpen(false);
      }
    }
  }, [projectBeingAddedTo, projects.length, projectsToProcess, setProgress]);

  useEffect(() => {
    if (projectBeingAddedTo && addExistingUser && userToAdd) {
      if (
        userToAdd.projects.includes(projectBeingAddedTo.project_id as ProjectId)
      ) {
        console.log(
          `${userToAdd.email} is already a member of ${projectBeingAddedTo.project_id} (${projectBeingAddedTo.name}); skipping`
        );
        setProjectBeingAddedTo(undefined);
        return;
      }
      addExistingUser(userToAdd.id, { quiet: true }).then(() => {
        setProjectBeingAddedTo(undefined);
      });
    }
  }, [addExistingUser, projectBeingAddedTo, userToAdd]);

  return (
    <div>
      <Button onClick={() => setDialogOpen(true)}>
        Add User To {projects.length} Projects
      </Button>
      <ConfirmDialog
        onConfirm={() => userToAdd && setProjectsToProcess(projects)}
        open={dialogOpen}
        setOpen={setDialogOpen}
        title={`Add User to ${projects.length} projects`}
        cancelActionWords="Cancel"
        confirmActionWords="Add User"
      >
        <Autocomplete
          fullWidth
          disablePortal
          id="user-to-add"
          options={users}
          getOptionLabel={prettyName}
          renderInput={(params) => <TextField {...params} label="User" />}
          onChange={(_, u) => setUserToAdd(u)}
        />
      </ConfirmDialog>
      <ProgressDialog
        progress={progress}
        open={Boolean(projectsToProcess.length || projectBeingAddedTo)}
      />
    </div>
  );
};
