import useSWR, { mutate as globalMutate } from "swr";
import { useBackend } from ".";
import { AddUserInfo, EditUserInfo, FetchError } from "../API";
import { BackendUserResponse } from "../APITypes";
import {
  EmailAddress,
  ProjectId,
  UserId,
} from "@project-centerline/project-centerline-api-types";
import { useAuth } from "@lib/hooks/useAuth";
import { throwIfNot } from "../util/throwIfNot";
import { logAndThrow } from "../ErrorLogging";
import React from "react";
import { SWRKeys } from "../swrKeys";

interface UseProjectUsersReturnType {
  project: {
    users?: Readonly<BackendUserResponse[]>;
    addUser: (
      user: AddUserInfo,
      opt?: { quiet?: boolean }
    ) => Promise<BackendUserResponse>;
    addExistingUser: (
      existingUserId: UserId,
      opt?: { quiet?: boolean }
    ) => Promise<void>; // really returns { user, project } but I'm feeling lazy with types RN
    removeUser: (user: EmailAddress) => Promise<void>;
    updateUser: (user: EditUserInfo) => Promise<void>;
  };
  errors?: {
    users?: Readonly<FetchError>;
  };
  isValidating: boolean;
}

export function useProjectUsers(projectId?: string): UseProjectUsersReturnType {
  const {
    getProjectUsers,
    removeUserFromProject,
    addUserToProject,
    editUser,
    addUserToProject_projectVariant,
  } = useBackend();
  const { currentUserEmail: email } = useAuth();

  const {
    data: users,
    error: usersError,
    mutate,
    isValidating,
  } = useSWR<BackendUserResponse[], FetchError>(
    SWRKeys.project(projectId as ProjectId).users,
    (_url: unknown) =>
      projectId
        ? getProjectUsers(projectId)
        : (() => {
            throw new Error("should not be able to get here");
          })()
  );

  const addUser: UseProjectUsersReturnType["project"]["addUser"] = async (
    user,
    opt
  ) => {
    if (!projectId) {
      logAndThrow(
        new Error("Should not be able to add user to undefined project")
      );
    }
    // TODO: optimistic insertion into users list?

    // add user (and wait)
    const createdUser = await addUserToProject(
      projectId,
      throwIfNot(email, "email is required"),
      user,
      opt
    );

    globalMutate(SWRKeys.project(projectId as ProjectId).self); // just in case someone is using users from project details

    // just refetch the list
    // TODO: make addUserToProject return something useful so we don't have to make this call
    await mutate();

    return createdUser;
  };

  const addExistingUser: UseProjectUsersReturnType["project"]["addExistingUser"] =
    React.useCallback(
      async (existingUserId, opt) => {
        // add user (and wait)

        if (!projectId) {
          logAndThrow(
            new Error(
              "Should not be able to add (existing) user to undefined project"
            )
          );
        }
        await addUserToProject_projectVariant(
          { projectId, type: "Add Existing", existingUserId },
          opt
        );

        globalMutate(SWRKeys.project(projectId as ProjectId).self); // just in case someone is using users from project details
      },
      [addUserToProject_projectVariant, projectId]
    );

  const removeUser = React.useCallback(
    async (victimEmail: EmailAddress) => {
      if (!projectId) {
        logAndThrow(
          new Error("Should not be able to remove user from undefined project")
        );
      }

      // optimistic update
      mutate(
        users?.filter(({ email }) => email !== victimEmail),
        false
      );

      // ask the backend to do it too
      await removeUserFromProject(projectId, victimEmail);

      // // reload list just to be sure
      // mutate();
    },
    [mutate, projectId, removeUserFromProject, users]
  );

  const updateUser = React.useCallback(
    async (u: EditUserInfo) => {
      // optimistic update
      mutate(
        users?.map((existing) =>
          existing.id && existing.id === u.id
            ? Object.assign({}, existing, u)
            : existing
        ),
        false
      );

      // ask the backend to do it too
      await editUser(u);

      // reload list just to be sure
      mutate();
    },
    [editUser, mutate, users]
  );

  const errors = usersError
    ? {
        errors: {
          users: usersError || undefined,
        },
      }
    : {};

  return {
    project: {
      users,
      addUser,
      addExistingUser,
      removeUser,
      updateUser,
    },
    ...errors,
    isValidating,
  };
}
