import React, { CSSProperties, useEffect } from "react";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { divisions } from "../../../Data/divisions";
import InputLabel from "@mui/material/InputLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { DatePicker } from "@mui/x-date-pickers";
import { CircularProgress } from "@mui/material";
import { useProjectDetails } from "@lib/hooks/useProjectDetails";
import { BackendUserResponse } from "@lib/APITypes";
import { useProjectUsers } from "@lib/hooks/useProjectUsers";
import { Role } from "@lib/roles";
import { MoneyEditor } from "../../../Components/MoneyEditor";
import { logError } from "@lib/ErrorLogging";
import { Task } from "../../../Models";
import { useSnackbar } from "notistack";

const buttonWrapper: CSSProperties = {
  display: "flex",
  justifyContent: "center",
};

const BigSpacer = styled("div")(({ theme: { spacing } }) => ({
  marginBottom: spacing(4),
}));

const emptyTaskData = {
  title: "",
  description: "",
  value: Number.NaN,
  division: "",
  subdivision: "",
  weekend: false,
  critical: false,
  lenderOnly: false,
  startDate: new Date(),
  endDate: new Date(),
  taskvacating: false,
  daysVacatingStartDate: new Date(),
  daysVacatingEndDate: new Date(),
  addSubcontractor: false,
};
type TaskData = typeof emptyTaskData;

export interface AddTaskPanelProps {
  projectId: string;
  onTaskAdded?: (response: Task) => void;
  onSubdivisionSelected?: (subdivision: string) => void;
}

export function AddTaskPanel({
  projectId,
  onTaskAdded,
  onSubdivisionSelected,
}: AddTaskPanelProps): JSX.Element {
  const { project } = useProjectDetails(projectId);
  const {
    project: { users },
  } = useProjectUsers(projectId);

  const [selectedStartDate, setSelectedStartDate] = React.useState(new Date());
  const [selectedEndDate, setSelectedEndDate] = React.useState(new Date());
  const [subdivision, setSubdivision] = React.useState<string[]>([]);
  const [newTask, setNewTask] = React.useState<TaskData>(emptyTaskData);
  const [loading, setLoading] = React.useState(false);
  const [daysVacating, setDaysVacating] = React.useState(false);
  const [daysVacatingStartDate, setDaysVacatingStartDate] = React.useState(
    new Date()
  );
  const [daysVacatingEndDate, setDaysVacatingEndDate] = React.useState(
    new Date()
  );
  const [addSubcontractor, setAddSubcontractor] = React.useState(false);
  const [subcontractors, setSubcontractors] = React.useState<
    BackendUserResponse[]
  >([]);

  useEffect(() => {
    setSubcontractors(
      users?.filter(({ role }) => role === Role.Subcontractor) ?? []
    );
  }, [users]);

  useEffect(() => {
    onSubdivisionSelected?.(subdivision[0] || "");
  }, [onSubdivisionSelected, subdivision]);

  const handleStartDateChange = (date: Date) => {
    if (date) {
      setSelectedStartDate(date);
      setNewTask({ ...newTask, startDate: date });
    }
  };

  const handleEndDateChange = (date: Date) => {
    if (date) {
      setSelectedEndDate(date);
      setNewTask({ ...newTask, endDate: date });
    }
  };

  const handleDaysVacatingStartDateChange = (date: Date) => {
    if (date) {
      setDaysVacatingStartDate(date);
      setNewTask({ ...newTask, daysVacatingStartDate: date });
    }
  };

  const handleDaysVacatingEndDateChange = (date: Date) => {
    if (date) {
      setDaysVacatingEndDate(date);
      setNewTask({ ...newTask, daysVacatingEndDate: date });
    }
  };

  const handleWeekendChange = (event: { target: { checked: boolean } }) => {
    setNewTask({ ...newTask, weekend: event.target.checked });
  };

  const handleCriticalChange = (event: { target: { checked: boolean } }) => {
    setNewTask({ ...newTask, critical: event.target.checked });
  };

  const handleLenderOnly = (event: { target: { checked: boolean } }) => {
    setNewTask({ ...newTask, lenderOnly: event.target.checked });
  };

  const handleChange = (event: {
    target: { name?: string; value: unknown };
  }) => {
    if (event.target.name) {
      setNewTask({ ...newTask, [event.target.name]: event.target.value });
    } else {
      throw new Error("no name?");
    }
  };

  const handleSubdivision = (option: { values: string[] }) => {
    console.log(option);
    setSubdivision(option.values);
  };

  const handleDaysVacatingChange = (event: {
    target: { checked: boolean };
  }) => {
    setDaysVacating(event.target.checked);
    setNewTask({ ...newTask, taskvacating: event.target.checked });
  };

  const handleAddSubcontractor = (event: { target: { checked: boolean } }) => {
    setAddSubcontractor(event.target.checked);
    setNewTask({ ...newTask, addSubcontractor: event.target.checked });
  };

  const isFormValid = () => {
    return newTask.title && !isNaN(newTask.value);
  };

  const { enqueueSnackbar } = useSnackbar();
  const handleSubmit = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    setLoading(true);
    project
      ?.addTask(newTask)
      .then((response) => {
        enqueueSnackbar({
          message: "Congrats! Your task was added!",
          variant: "success",
          autoHideDuration: 1750,
        });
        setNewTask(emptyTaskData);
        onTaskAdded?.(response);
      })
      .catch((error) => {
        logError(error);
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <FormControl variant="standard" fullWidth>
          <TextField
            variant="standard"
            required
            fullWidth
            label="Task Name"
            name="title"
            onChange={handleChange}
          />
        </FormControl>
        <FormControl variant="standard" fullWidth>
          <TextField
            variant="standard"
            id="description"
            label="Description"
            multiline
            rows={4}
            name="description"
            onChange={handleChange}
          />
        </FormControl>

        {/* required inputs: */}

        <FormControl variant="standard" fullWidth>
          <MoneyEditor
            id="value"
            name="value"
            label="Value"
            value={newTask.value}
            placeholder="0"
            onChange={(value: number) => {
              setNewTask({ ...newTask, value });
            }}
          />
        </FormControl>

        <FormControl variant="standard" fullWidth>
          <FormControlLabel
            control={
              <Switch
                //checked={state.checkedA}
                onChange={handleLenderOnly}
                name="lenderonly"
                color="primary"
              />
            }
            label="Lender Only"
            labelPlacement="top"
            style={{ display: "block", margin: 0 }}
          />
        </FormControl>

        {/* optionals: */}
        <BigSpacer />

        <FormControl variant="standard" fullWidth>
          <InputLabel id="task-division-label">Division</InputLabel>
          <Select
            variant="standard"
            labelId="task-division-label"
            id="task-division"
            style={{ textAlign: "left" }}
            name="division"
            value={newTask.division}
            onChange={handleChange}
          >
            {divisions.map((option, key) => (
              <MenuItem
                key={key}
                value={option.name}
                onClick={(e) => handleSubdivision(option)}
              >
                {option.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {subdivision.length > 0 ? (
          <FormControl variant="standard" fullWidth>
            <InputLabel id="task-subdivision-label">Subdivision</InputLabel>
            <Select
              variant="standard"
              labelId="task-subdivision-label"
              id="task-subdivision"
              name="subdivision"
              style={{ textAlign: "left" }}
              //value={age}
              onChange={handleChange}
            >
              {subdivision.map((option, key) => (
                <MenuItem key={key} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : null}

        {
          <>
            <FormControl variant="standard" fullWidth>
              <DatePicker
                renderInput={(params) => (
                  <TextField
                    variant="standard"
                    {...params}
                    margin="normal"
                    id="start-date"
                    name="startdate"
                  />
                )}
                // disableToolbar
                // initialFocusedDate={null}
                // variant="inline"
                inputFormat="yyyy/MM/dd"
                // margin="normal"
                // id="start-date"
                // name="startdate"
                label="Start Date"
                value={selectedStartDate}
                onChange={(value) => {
                  handleStartDateChange(value as Date);
                }}
                // KeyboardButtonProps={{
                //   "aria-label": "change date",
                // }}
              />
            </FormControl>

            <FormControl variant="standard" fullWidth>
              <DatePicker
                renderInput={(params) => (
                  <TextField
                    variant="standard"
                    {...params}
                    margin="normal"
                    name="enddate"
                    id="end-date"
                  />
                )}
                // disableToolbar
                // variant="inline"
                inputFormat="yyyy/MM/dd"
                // defaultValue={new Date()}

                label="End Date"
                value={selectedEndDate}
                onChange={(value) => {
                  handleEndDateChange(value as Date);
                }}

                // KeyboardButtonProps={{
                //   "aria-label": "change date",
                // }}
              />
            </FormControl>
          </>
        }
        <FormControl variant="standard" fullWidth>
          <FormControlLabel
            value="top"
            control={
              <Switch
                color="primary"
                checked={daysVacating}
                onChange={handleDaysVacatingChange}
                name="daysvacating"
              />
            }
            label="Days Requiring Vacating"
            labelPlacement="top"
            style={{ display: "block", margin: 0 }}
          />

          {daysVacating ? (
            <div>
              <>
                <DatePicker
                  // disableToolbar
                  // variant="inline"
                  inputFormat="yyyy/MM/dd"
                  renderInput={(params) => (
                    <TextField
                      variant="standard"
                      {...params}
                      margin="normal"
                      name="daysVacatingStartDate"
                      id="daysVacatingStartDate"
                    />
                  )}
                  label="Start Date"
                  value={daysVacatingStartDate}
                  onChange={(value) => {
                    handleDaysVacatingStartDateChange(value as Date);
                  }}

                  // KeyboardButtonProps={{
                  //   "aria-label": "change date",
                  // }}
                />
                <DatePicker
                  // disableToolbar
                  // variant="inline"
                  inputFormat="yyyy/MM/dd"
                  renderInput={(params) => (
                    <TextField
                      variant="standard"
                      {...params}
                      margin="normal"
                      name="daysVacatingEndDate"
                      id="daysVacatingEndDate"
                    />
                  )}
                  label="End Date"
                  value={daysVacatingEndDate}
                  onChange={(value) => {
                    handleDaysVacatingEndDateChange(value as Date);
                  }}

                  // KeyboardButtonProps={{
                  //   "aria-label": "change date",
                  // }}
                />
              </>
            </div>
          ) : null}
        </FormControl>

        <FormControl variant="standard" fullWidth>
          <FormControlLabel
            value="top"
            control={
              <Switch
                color="primary"
                checked={addSubcontractor}
                onChange={handleAddSubcontractor}
                name="addsubcontractor"
              />
            }
            label="Add Subcontractor"
            labelPlacement="top"
            style={{ display: "block", margin: 0 }}
          />

          {addSubcontractor ? (
            <div>
              <FormControl variant="standard" fullWidth>
                <InputLabel id="subcontractor-label">Subcontractor</InputLabel>
                <Select
                  variant="standard"
                  labelId="subcontractor-label"
                  id="subcontractor"
                  name="task_subcontractor"
                  style={{ textAlign: "left" }}
                  onChange={({ target: { name, value } }) => {
                    handleChange({
                      target: {
                        name: "task_subcontractor",
                        value: subcontractors.find(
                          (sub) => sub.email === value
                        ),
                      },
                    });
                  }}
                >
                  {subcontractors.map((subcontractor, key) => (
                    <MenuItem
                      key={key}
                      value={subcontractor.email}
                      onClick={() =>
                        setNewTask((newTask) => ({
                          ...newTask,
                          subcontractor,
                        }))
                      }
                    >
                      {subcontractor.first_name + " " + subcontractor.last_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          ) : null}
        </FormControl>
        <div style={buttonWrapper}>
          <FormControlLabel
            control={
              <Switch
                //checked={state.checkedA}
                onChange={handleWeekendChange}
                name="ignoreweekends"
                color="primary"
              />
            }
            label="Work Weekends"
          />

          <FormControlLabel
            control={
              <Switch
                //checked={state.checkedA}
                onChange={handleCriticalChange}
                name="critical"
                color="secondary"
              />
            }
            label="Critical"
          />
        </div>

        <div style={buttonWrapper}>
          <Button
            type="submit"
            disabled={!isFormValid}
            sx={({ palette }) => ({
              color: palette.cta.main,
              fontSize: "1.5em !important",
              textDecoration: "none",
              textAlign: "center",
            })}
          >
            {loading ? <CircularProgress /> : "SUBMIT TASK"}
          </Button>
        </div>
      </form>
    </>
  );
}

/*
 * api endpoint spellings
 *
 * these are all used in the backend api and are used as "name" attributes in this file
 *
 * spell-checker:ignore lenderonly startdate enddate
 * spell-checker:ignore daysvacating addsubcontractor ignoreweekends
 */
