import { useGetUserRole } from "./useGetUserRole";
import { User } from "../APITypes";
import { useUser } from "@/Components/useUser";

export const usePrefill = (): {
  prepopulateLenderUser: User | undefined;
  isLoading: boolean;
} => {
  const { user: userRoleResponse, error: userError } = useGetUserRole();
  const userLoading = !userError && !userRoleResponse;
  const { user: prepopulateLenderUser, error } = useUser({
    id: userRoleResponse?.prepopulateLenderId,
  });

  return {
    prepopulateLenderUser,
    isLoading:
      userLoading ||
      Boolean(
        !userRoleResponse?.loaded ||
          (userRoleResponse?.prepopulateLenderId &&
            !error &&
            !prepopulateLenderUser)
      ),
  };
};
