/**
 * Until we start handling retention correctly, there are times we need to "back it out" and this is a bottleneck for that.
 * @param value typically task_value
 * @param retention retention percentage, 0-100
 */
export const backOutRetention: (
  value: string | number,
  retention: string | number
) => number = (value, retention) => {
  const valueAsNumber = Number(value);
  const retentionAsNumber = Number(retention);
  return valueAsNumber / ((100 - retentionAsNumber) / 100);
};
