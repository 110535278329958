import { useUser } from "@/Components/useUser";
import { useDeviceSettings } from "@/Models/Root";
import React from "react";
import { useEntityFeatures } from "./useEntityFeatures";

export const useNewUIHook = () => {
  const { setUseNewUI: setUseNewUIOnUser, user } = useUser("me");
  const fromUser = user?.settings?.frontend?.useNewUI;
  const { useNewUI: fromDevice, setUseNewUI: setUseNewUIOnDevice } =
    useDeviceSettings();
  const {
    frontend: { newUI: featureFlag },
  } = useEntityFeatures();

  // if user changes, change device settings
  // TODO: useNewUI renders too often; figure out why
  React.useEffect(() => {
    if (fromUser !== undefined) {
      // console.log("store new ui setting on device", { fromUser });
      setUseNewUIOnDevice(fromUser);
    }
    return undefined;
  }, [fromUser, setUseNewUIOnDevice]);

  // React.useEffect(() => {
  //   console.log("fromUser => ", fromUser);
  // }, [fromUser]);
  // React.useEffect(() => {
  //   console.log("fromDevice => ", fromDevice);
  // }, [fromDevice]);
  // React.useEffect(() => {
  //   console.log("setUseNewUIOnDevice => ", setUseNewUIOnDevice);
  // }, [setUseNewUIOnDevice]);
  // React.useEffect(() => {
  //   console.log("setUseNewUIOnUser => ", setUseNewUIOnUser);
  // }, [setUseNewUIOnUser]);

  // console.log({ fromUser, fromDevice, setUseNewUIOnDevice, setUseNewUIOnUser });
  return {
    useNewUI:
      featureFlag === "off"
        ? false
        : featureFlag === "on"
        ? true
        : fromUser ?? fromDevice,
    setUseNewUI: setUseNewUIOnUser,
    featureFlag,
  };
};
