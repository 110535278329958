import { ApprovalStatus } from "@lib/APITypes";
import { throwIfNot } from "@lib/util/throwIfNot";
import { Link, useParams } from "react-router-dom";
import { useProjectInvoices } from "@lib/hooks/useProjectInvoices";
import { Typography } from "@mui/material";
import { ProjectId } from "@project-centerline/project-centerline-api-types";
import React from "react";
import { FinalizedDrawsPage } from "./FinalizedDrawsPage";

export const RejectedDrawsPage = () => {
  const projectId = throwIfNot(
    useParams<"projectId">().projectId,
    "Missing project id"
  ) as ProjectId;
  const {
    project: { invoices },
  } = useProjectInvoices(projectId, { status: ApprovalStatus.Rejected });

  return invoices ? (
    <FinalizedDrawsPage
      status="Rejected"
      projectId={projectId}
      invoices={invoices}
      ifNone={
        <Typography>
          There are no Rejected draws. See{" "}
          <Link to="../pending" relative="path">
            Pending
          </Link>
          {" or "}
          <Link to="../approved" relative="path">
            Approved
          </Link>{" "}
          ones?
        </Typography>
      }
    />
  ) : null;
};

export default RejectedDrawsPage;
