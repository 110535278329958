import React from "react";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAuth } from "@lib/hooks/useAuth";

export const LogoutPage = () => {
  const { signOut } = useAuth();
  const navigate = useNavigate();

  React.useEffect(() => {
    signOut().then(() => navigate("/"));
  }, [navigate, signOut]);

  return <Typography>Logging you out....</Typography>;
};

export default LogoutPage;
