import React from "react";
import { styled } from "@mui/material/styles";

import { Tabs, Tab, Box, Grid } from "@mui/material";
import { FilesTab } from "./FilesTab";
import { LoanTab } from "./LoanTab";
import { StatementsTab } from "./StatementsTab";
import { useProjectDetails } from "@lib/hooks/useProjectDetails";
import * as z from "zod";
import { useAnalytics, useFeatures, usePermissions } from "@lib/hooks";
import { Link, Navigate, Route, Routes, useParams } from "react-router-dom";
import { BudgetTab } from "./BudgetTab";
import { FeasibilityTab } from "./FeasibilityTab";
import { UserGrid } from "./UserGrid";
import { ProjectInfoTab } from "./ProjectInfoTab";
import { ProjectReportingTab } from "./ProjectReportingTab";
import { useEntityFeatures } from "@lib/hooks/useEntityFeatures";

// TODO: #189 deep link to tabs
// TODO: #190 deep link to Reporting should redirect if there is no reporting tab but not until we know.
// todo can we zod what tabs are allowed? reach() + state? type TabsSansStatements = Tabs.omit({Statements: true})?

const InfoViewRoot = styled("div")({
  margin: "20px",
  marginBottom: "100px",
  display: "flex",
  flexGrow: 1,
});

interface TabPanelProps<SelectorT> {
  children?: React.ReactNode;
  dir?: string;
  selector: SelectorT;
  value: SelectorT;
}

export enum TabNames {
  Files = "Files",
  Loan = "Loan",
  Statements = "Statements",
  Budget = "Budget",
  Feasibility = "Feasibility",
  Users = "Users",
  ProjectInfo = "ProjectInfo",
  Reporting = "Reporting",
}
const TabNameEnum = z.nativeEnum(TabNames);
// eslint-disable-next-line @typescript-eslint/no-redeclare
type TabNameEnum = z.infer<typeof TabNameEnum>;

function TabPanel<SelectorT>(props: TabPanelProps<SelectorT>) {
  const { children, value, selector, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== selector}
      id={`tabpanel-${selector}`}
      aria-labelledby={`full-width-tab-${selector}`}
      {...other}
      style={{ width: "100%" }}
    >
      {value === selector && <Box p={1}>{children}</Box>}
    </div>
  );
}

/**
 * Info "Page" in Project View
 *
 * @param projectId Id of the project; we'll {@link useProject()} it but hopefully it's already there
 * @returns Project Info element that contains sub-tabs
 */
export default function InfoView({
  projectId,
}: {
  projectId: string;
}): JSX.Element {
  const candidateTabName = useParams<{ subTopic?: string }>().subTopic;
  const selectedTab = TabNameEnum.safeParse(candidateTabName).success
    ? candidateTabName
    : TabNames.Files;

  // const { path: parentPath, url: parentUrl } = routeMatch;

  // status so we can show/hide tabs
  const { project } = useProjectDetails(projectId);
  const { canSeeLoanInfo } = usePermissions();
  const { reportingFeature } = useFeatures();
  const {
    frontend: { reportingTab: reportingTabFeature },
  } = useEntityFeatures();

  const analytics = useAnalytics();

  return (
    <InfoViewRoot>
      <Tabs
        centered
        textColor="primary"
        indicatorColor="primary"
        value={selectedTab}
        orientation="vertical"
        sx={({ palette }) => ({
          borderRight: `1px solid ${palette.divider}`,
        })}
        onChange={(_, tab) => {
          analytics.track(`info-view:tab:${tab}`);
        }}
      >
        <Tab
          label="Files"
          value={TabNames.Files}
          component={Link}
          to={TabNames.Files}
        ></Tab>
        {canSeeLoanInfo ? (
          <Tab
            label="Loan"
            value={TabNames.Loan}
            component={Link}
            to={TabNames.Loan}
          ></Tab>
        ) : null}
        <Tab
          label="Budget"
          value={TabNames.Budget}
          component={Link}
          to={TabNames.Budget}
        ></Tab>
        <Tab
          label="Feasibility"
          value={TabNames.Feasibility}
          component={Link}
          to={TabNames.Feasibility}
        ></Tab>
        {reportingFeature && project?.details?.reporting?.enabled ? (
          <Tab
            label="Statements"
            value={TabNames.Statements}
            component={Link}
            to={TabNames.Statements}
          ></Tab>
        ) : null}
        <Tab
          label="Users"
          value={TabNames.Users}
          component={Link}
          to={TabNames.Users}
        ></Tab>
        <Tab
          label="Info"
          value={TabNames.ProjectInfo}
          component={Link}
          to={TabNames.ProjectInfo}
        ></Tab>
        {reportingTabFeature ? (
          <Tab
            label="Reporting"
            value={TabNames.Reporting}
            component={Link}
            to={TabNames.Reporting}
          ></Tab>
        ) : null}
      </Tabs>
      <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
        <Routes>
          {/* <Route path="*" element={<Navigate replace to={TabNames.Files} />} /> */}
          <Route index element={<Navigate to={TabNames.Loan} replace />} />
          <Route
            path={TabNames.Loan}
            element={
              <TabPanel selector={TabNames.Loan} value={selectedTab}>
                <LoanTab projectId={projectId} />
              </TabPanel>
            }
          />
          <Route
            path={TabNames.Statements}
            element={
              <TabPanel selector={TabNames.Statements} value={selectedTab}>
                <StatementsTab projectId={projectId} />
              </TabPanel>
            }
          />
          <Route
            path={TabNames.Budget}
            element={
              <TabPanel selector={TabNames.Budget} value={selectedTab}>
                <BudgetTab projectId={projectId} toLoan={TabNames.Loan} />
              </TabPanel>
            }
          />
          <Route
            path={TabNames.Feasibility}
            element={
              <TabPanel selector={TabNames.Feasibility} value={selectedTab}>
                <FeasibilityTab projectId={projectId} />
              </TabPanel>
            }
          />
          <Route
            path={TabNames.Users}
            element={
              <TabPanel selector={TabNames.Users} value={selectedTab}>
                <UserGrid projectId={projectId} />
              </TabPanel>
            }
          />
          <Route
            index
            path={TabNames.Files}
            element={
              <TabPanel selector={TabNames.Files} value={selectedTab}>
                <FilesTab projectId={projectId} />
              </TabPanel>
            }
          />
          <Route
            path={TabNames.ProjectInfo}
            element={
              <TabPanel selector={TabNames.ProjectInfo} value={selectedTab}>
                <ProjectInfoTab projectId={projectId} />
              </TabPanel>
            }
          />
          <Route
            path={TabNames.Reporting}
            element={
              <TabPanel selector={TabNames.Reporting} value={selectedTab}>
                <Grid container justifyContent="center">
                  <Grid item>
                    <ProjectReportingTab projectId={projectId} />
                  </Grid>
                </Grid>
              </TabPanel>
            }
          />
        </Routes>
      </div>
    </InfoViewRoot>
  );
}

/** Available tabs */
InfoView.Tabs = TabNames;
