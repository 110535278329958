import { Grid, Paper } from "@mui/material";
import React from "react";

export type TileProps = { children?: React.ReactNode };

export const Tile: (props: TileProps) => JSX.Element = ({ children }) => {
  return (
    <Grid
      sx={{
        padding: 0,
        maxWidth: "none",
      }}
      item
      xs={2}
    >
      <Paper
        sx={{
          padding: "1vw",
        }}
      >
        {children}
      </Paper>
    </Grid>
  );
};
