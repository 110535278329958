import { styled, Tooltip } from "@mui/material";

export const HtmlTooltip = styled(Tooltip)(({ theme }) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));
