import React from "react";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions/transition";

export const SlideUp = React.forwardRef(function SlideUp(
  props: TransitionProps & { children: React.ReactElement },
  ref: React.Ref<unknown>
) {
  const { children, ...nonChildProps } = props;
  return (
    <Slide direction="up" ref={ref} {...nonChildProps}>
      {children}
    </Slide>
  );
});
