import React from "react";
import { Invoice, PendingInvoice, Task } from "../../../Models";
import { CommonInvoicesProps, InvoiceList } from "./InvoicesCommon";
import { PendingInvoiceComponent, PendingInvoiceProps } from "./PendingInvoice";
import { logAnomaly, logError } from "@lib/ErrorLogging";
import { useProjectInvoices } from "@lib/hooks/useProjectInvoices";
import { useSnackbar } from "notistack";
import { ConfirmDialog } from "../../../Components/ConfirmDialog";

export interface PendingInvoicesProps
  extends CommonInvoicesProps<PendingInvoice> {
  /** General purpose callback: We uploaded files, approved, etc. */
  onSomethingChanged?: () => void;

  onEditLineItemRequested: ({
    task,
    invoice,
  }: {
    task: Task;
    invoice: PendingInvoice;
  }) => void;
  // TODO: Make file handling sane and regularized
  onDeleteLineItemFile: PendingInvoiceProps["onDeleteLineItemFile"];
  onDeleteInvoiceFile: PendingInvoiceProps["onDeleteInvoiceFile"];
}

export {};

export default function PendingInvoices(
  props: PendingInvoicesProps
): JSX.Element {
  const {
    filenameHint,
    tasks,
    invoices,
    projectId,
    onSomethingChanged,
    onEditLineItemRequested,
    files,
    onDeleteLineItemFile,
    onDeleteInvoiceFile,
  } = props;

  const invoicesDisplayed = React.useMemo(
    () =>
      invoices
        .slice()
        .sort((a, b) =>
          (a.identifier || a.timestamp.toISOString()).localeCompare(
            b.identifier || b.timestamp.toISOString()
          )
        ),
    [invoices]
  );

  const [invoiceProposedForDeletion, setInvoiceProposedForDeletion] =
    React.useState<Invoice | undefined>(undefined);
  const cancelDeletion = () => setInvoiceProposedForDeletion(undefined);

  const {
    project: { invoice: invoiceDetails },
  } = useProjectInvoices(projectId);
  const handleDeleteDraw = () => {
    const victim =
      invoiceProposedForDeletion &&
      invoiceDetails?.(invoiceProposedForDeletion.invoice_id);
    if (victim) {
      victim
        .delete()
        .then(() => {
          cancelDeletion();
          onSomethingChanged?.(); // TODO: this is probably unnecessary. But We keep pushing stuff out so fast I'm afraid to change it.
        })
        .catch((error: unknown) => {
          logError(error);
          enqueueSnackbar("Error deleting draw", { variant: "error" });
          cancelDeletion();
          onSomethingChanged?.(); // TODO: should not need to do this
        });
    } else {
      logError(new Error("deleting invoice but it went away"), {
        invoiceProposedForDeletion,
        invoiceDetails,
        promise:
          invoiceProposedForDeletion &&
          invoiceDetails?.(invoiceProposedForDeletion.invoice_id),
      });
      // enqueueSnackbar?
      cancelDeletion();
    }
  };

  const { enqueueSnackbar } = useSnackbar();
  const handleTaskViewRequested = ({
    invoice,
    taskId,
  }: {
    invoice: PendingInvoice;
    taskId: string;
  }) => {
    const task = tasks.find(({ id }) => id === taskId);
    if (!task) {
      logAnomaly(new Error("Can't find task to edit"), { tasks, taskId });
      enqueueSnackbar("Sorry, something went wrong", { variant: "error" });
      return;
    }

    onEditLineItemRequested({ invoice, task });
  };

  return (
    <div className="invoices">
      {invoicesDisplayed.length === 0 ? null : (
        <InvoiceList>
          {invoicesDisplayed.map((invoice, idx) => (
            <PendingInvoiceComponent
              invoice={invoice}
              key={invoice.invoice_id}
              invoiceDisplayNumber={idx}
              onDeleteRequested={setInvoiceProposedForDeletion}
              filenameHint={filenameHint}
              onSomethingChanged={onSomethingChanged}
              tasks={tasks}
              projectId={projectId}
              onEditLineItemRequested={onEditLineItemRequested}
              onTaskViewRequested={(taskId) =>
                handleTaskViewRequested({ invoice, taskId })
              }
              files={files}
              onDeleteInvoiceFile={onDeleteInvoiceFile}
              onDeleteLineItemFile={onDeleteLineItemFile}
            />
          ))}
        </InvoiceList>
      )}
      <ConfirmDialog
        open={!!invoiceProposedForDeletion}
        setOpen={(wantsToBeOpen) => {
          if (!wantsToBeOpen) {
            cancelDeletion();
          }
        }}
        onConfirm={handleDeleteDraw}
        title="Delete this Draw Request?"
        confirmActionWords="Delete"
      >
        Are you sure you want to delete this draw? THERE IS NO UNDO AND IT WILL
        BE DELETED PERMANENTLY
      </ConfirmDialog>
    </div>
  );
}
