import { Typography } from "@mui/material";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import DateRangeIcon from "@mui/icons-material/DateRange";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import HouseIcon from "@mui/icons-material/House";
import PageviewIcon from "@mui/icons-material/Pageview"; // spell-checker:ignore pageview
import PersonPinIcon from "@mui/icons-material/PersonPin";
import SearchIcon from "@mui/icons-material/Search";
import React from "react";
import { Role } from "./roles";

export const roleAttributes: Record<
  Role,
  {
    label: string;
    icon: JSX.Element;
  }
> = {
  [Role.LenderAdmin]: {
    label: "Lender Admin",
    icon: <PersonPinIcon />,
  },

  [Role.Lender]: {
    label: "Lender",
    icon: <AccountBalanceIcon />,
  },
  [Role.Contractor]: {
    label: "Contractor",
    icon: <HomeWorkIcon />,
  },
  [Role.Owner]: {
    label: "Borrower",
    icon: <HouseIcon />,
  },
  [Role.InspectorAdmin]: {
    label: "Inspector Admin",
    icon: <PageviewIcon />,
  },
  [Role.Inspector]: {
    label: "Inspector",
    icon: <SearchIcon />,
  },
  [Role.Approver]: {
    label: "Approver",
    icon: <DateRangeIcon />,
  },
  [Role.Subcontractor]: {
    label: "Subcontractor",
    icon: <AssignmentIndIcon />,
  },
  [Role.SuperAdmin]: {
    label: "Super Admin",
    icon: (
      <Typography variant="h1">
        THIS SHOULD NOT BE VISIBLE AT THE MOMENT
      </Typography>
    ),
  },
};
