import { ApprovalStatus } from "@lib/APITypes";
import { throwIfNot } from "@lib/util/throwIfNot";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useProjectInvoices } from "@lib/hooks/useProjectInvoices";
import { Typography } from "@mui/material";
import { ProjectId } from "@project-centerline/project-centerline-api-types";
import React from "react";
import { FinalizedDrawsPage } from "./FinalizedDrawsPage";

export const ApprovedDrawsPage = () => {
  const navigate = useNavigate();
  const projectId = throwIfNot(
    useParams<"projectId">().projectId,
    "Missing project id"
  ) as ProjectId;
  const {
    project: { invoices, invoice: invoiceFns },
  } = useProjectInvoices(projectId, { status: ApprovalStatus.Approved });

  return invoices ? (
    <FinalizedDrawsPage
      status="Approved"
      projectId={projectId}
      invoices={invoices}
      ifNone={
        <Typography>
          There are no Approved draws. See{" "}
          <Link to="../pending" relative="path">
            Pending
          </Link>
          {" or "}
          <Link to="../rejected" relative="path">
            Rejected
          </Link>{" "}
          ones?
        </Typography>
      }
      reverseInvoice={({ invoiceId }) =>
        invoiceFns(invoiceId)
          ?.reverse()
          .then(() => {
            navigate("../pending");
          }) ?? Promise.reject("not ready")
      }
    />
  ) : null;
};

export default ApprovedDrawsPage;
