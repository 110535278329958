import { Task } from "../Models";
import { pipe } from "fp-ts/lib/function";
import { BackendRawGetTaskResponse } from "./APITypes";
import { getOrThrowDecodeError } from "./util/typeUtils";

function cleanupNumberString(s: string | number) {
  return !s || s === "NaN" ? 0 : Number(s);
}

export function sanitizeIndividualTask({
  task_id: id,
  ...task
}: BackendRawGetTaskResponse): Task {
  return pipe(
    {
      ...task,
      id,
      task_value: cleanupNumberString(task.task_value),
      invoiced_amount: cleanupNumberString(task.invoiced_amount),
      requested_amount: cleanupNumberString(task.requested_amount || "0"),
      lender_only: !!task.lender_only,
      task_subcontractor: task.task_subcontractor || "",
      taskvacating: !!(
        task.taskvacating && task.taskvacating.toLowerCase() === "true"
      ),
      description: task.description || "",
      start: new Date(task.start),
      end_date: task.end_date ? new Date(task.end_date) : null,
    },
    Task.decode,
    getOrThrowDecodeError
  );
}
