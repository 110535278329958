import useSWR from "swr";
import { useBackend } from ".";
import {
  NotificationTypeEnum,
  BackendGetUserRoleResponse,
  Notification,
} from "../APITypes";
import { Role } from "../roles";
import { FetchError } from "../API";
import { identifyUser as identifyUserForErrors } from "../ErrorLogging";
import { useAnalytics } from "./useAnalytics";
import {
  MakeEmailAddress,
  UserId,
} from "@project-centerline/project-centerline-api-types";
import { useAuth } from "@lib/hooks/useAuth";
import { useAppContext } from "../UserContext";
import { SWRKeys } from "../swrKeys";

export function useGetUserRole(): {
  user?: Omit<BackendGetUserRoleResponse, "role" | "version"> & {
    loaded: boolean;
    notifications?: Readonly<Notification[]>;
    role?: Readonly<Role>;
    id: Readonly<UserId>;
    resetNotifications: (
      projectId: string,
      type: NotificationTypeEnum
    ) => Promise<void>;
  };
  isValidating: boolean;
  error?: Readonly<FetchError>;
} {
  const { currentUserEmail } = useAuth();
  const { setBackendVersion } = useAppContext();
  const { identifyUser: identifyUserForAnalytics } = useAnalytics();

  const key = SWRKeys.userByEmail(currentUserEmail).role();

  const { getUserRoleAndNotificationsByEmail, removeNotifications } =
    useBackend();

  const { data, error, mutate, isValidating } = useSWR<
    BackendGetUserRoleResponse,
    FetchError
  >(
    () => currentUserEmail && key,
    (_url: unknown) =>
      getUserRoleAndNotificationsByEmail(
        currentUserEmail ??
          MakeEmailAddress("garbage should never be able to get here")
      ),
    {
      onSuccess: (response) => {
        identifyUserForErrors({ id: response.id, email: currentUserEmail });
        identifyUserForAnalytics({
          id: response.id,
          email: currentUserEmail,
          role: response.role,
        });
        // TODO: move backend to /config endpoint
        setBackendVersion(response.version);
      },
    }
  );

  if (!currentUserEmail || !data) {
    return { error, isValidating };
  }

  const { role, notifications, id } = data || {};

  const resetNotifications = async (
    victimProjectId: string,
    victimType: NotificationTypeEnum
  ) => {
    // optimistic update
    if (data) {
      mutate(
        {
          ...data,
          notifications: (notifications ?? []).filter(
            ({ type, project_id }) =>
              type !== victimType || project_id !== victimProjectId
          ),
        },
        false
      );
    }

    // wait for backend to do it
    await removeNotifications(victimProjectId, currentUserEmail, victimType);

    // trigger refetch
    mutate();
  };

  return {
    user: {
      loaded: Boolean(data),
      notifications: notifications || [],
      resetNotifications,
      ...(data?.prepopulateLenderId && {
        prepopulateLenderId: data.prepopulateLenderId,
      }),
      role,
      id,
    },
    error,
    isValidating,
  };
}
