import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  Fab,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React from "react";
import { mutate as globalMutate } from "swr";
import { usePermissions } from "@lib/hooks";
import { useProjectUsers } from "@lib/hooks/useProjectUsers";
import AddUser from "./AddUser";
import EditUser, { EditUserProps } from "./EditUser";
import { UserDetailsView } from "../../../Components/UserDetailsView";
import { UserSummaryView } from "../../../Components/UserSummaryView";
import { useInspectors } from "@lib/hooks/useInspectors";
import { logAnomaly, logError } from "@lib/ErrorLogging";
import { Role } from "@lib/roles";
import {
  EmailAddress,
  ProjectId,
} from "@project-centerline/project-centerline-api-types";
import { BackendUserResponse } from "@/Lib/APITypes";
import { SWRKeys } from "@/Lib/swrKeys";

export const UserView = ({
  user,
  onDelete,
  onEdit,
  suppressAdmin,
}: {
  user: BackendUserResponse;
  onDelete?: (email: EmailAddress) => void;
  onEdit?: (arg0: Omit<BackendUserResponse, "projects">) => void;
  suppressAdmin?: boolean;
}) => {
  const { isConciergeInspector } = useInspectors();

  return (
    <Accordion style={{ margin: "10px" }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <UserSummaryView user={user} />
      </AccordionSummary>
      <AccordionDetails style={{ flexDirection: "column" }}>
        <UserDetailsView
          user={user}
          hidePhone={
            isConciergeInspector(user) ||
            (user.tags.includes("one-click") && user.role === Role.Inspector)
          }
          onDeleteUser={onDelete}
          onEditUser={user.tags.includes("external") ? undefined : onEdit}
          suppressAdmin={suppressAdmin}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export function UserGrid({ projectId }: { projectId: string }): JSX.Element {
  const {
    project: { users, removeUser },
  } = useProjectUsers(projectId);

  // const [users, setUsers] = React.useState([emptyUserInfo]);
  const [addUser, setAddUser] = React.useState(false);
  type UserInfo = EditUserProps["editUser"];

  const [userBeingEdited, setUserBeingEdited] = React.useState<
    UserInfo | undefined
  >();
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [email, setEmail] = React.useState<EmailAddress | undefined>();

  const { canAddUsers } = usePermissions();

  const handleEditUser = (user: UserInfo) => {
    setUserBeingEdited(user);
  };
  const handleAddUser = () => {
    setAddUser(true);
  };
  const handleRemoveUser = () => {
    setLoading(true);
    if (!email) {
      logAnomaly(new Error("Should have a user here"));
      return;
    }
    removeUser(email)
      .then(() => {
        setOpen(false);
      })
      .catch((error) => {
        logError(error);
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleDialogOpen = (userEmail: EmailAddress) => {
    setEmail(userEmail);
    setOpen(true);
  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  const toggleAddUserPopup = () => {
    setAddUser(!addUser);
  };

  // TODO: rename doneEditingUser
  const toggleEditUserPopup = () => {
    setUserBeingEdited(undefined);
    globalMutate(SWRKeys.project(projectId as ProjectId).users);
  };

  return (
    <div role="presentation">
      <Grid container direction="row" justifyContent="center">
        {(users || []).map((user, index) => (
          <Grid item key={index}>
            <UserView
              onDelete={handleDialogOpen}
              onEdit={handleEditUser}
              user={user}
            />
          </Grid>
        ))}
      </Grid>
      {canAddUsers ? (
        <>
          <Grid
            container
            direction="row"
            justifyContent="center"
            sx={({ spacing }) => ({
              padding: spacing(4),
            })}
          >
            <Grid item>
              <Fab
                onClick={handleAddUser}
                color="primary"
                aria-label="add user"
              >
                <AddIcon />
              </Fab>
            </Grid>
          </Grid>
          <AddUser
            open={addUser}
            projectId={projectId}
            callBack={toggleAddUserPopup}
          />
        </>
      ) : null}
      {userBeingEdited ? (
        <EditUser
          open={true}
          editUser={userBeingEdited}
          callBack={toggleEditUserPopup}
          projectId={projectId}
        />
      ) : null}
      <Dialog
        open={open}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Remove User from Project?"}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Cancel
          </Button>
          <Button
            color="primary"
            autoFocus
            onClick={handleRemoveUser}
            disabled={loading}
          >
            {loading ? <CircularProgress /> : "Remove"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
