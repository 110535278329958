import { useUser } from "@/Components/useUser";
import { BackendUserResponse, User } from "@/Lib/APITypes";
import { useNewUIHook } from "@/Lib/hooks/useNewUI";
import {
  CircularProgress,
  FormControlLabel,
  Grid,
  Switch,
} from "@mui/material";
import React from "react";
import { UserView } from "./ProjectView/Info/UserGrid";
import { Link, useNavigate } from "react-router-dom";
import MDButton from "@tim/components/MDButton";

const LegacyBackendUserAdapter: (u: User) => BackendUserResponse = ({
  company,
  firstName: first_name,
  lastName: last_name,
  phone,
  created_at,
  ...rest
}) => ({
  ...rest,
  company: company ?? "",
  created_at: created_at?.toISOString() ?? "",
  first_name,
  last_name,
  phone_number: phone ?? "",
  tags: [],
});

export const NewUISwitch = () => {
  const { useNewUI, setUseNewUI, featureFlag } = useNewUIHook();
  const [switchingUI, setSwitchingUI] = React.useState(false);
  const navigate = useNavigate();

  return switchingUI ? (
    <CircularProgress />
  ) : setUseNewUI ? (
    <FormControlLabel
      sx={{ ml: 1 }}
      label="Use New UI"
      control={
        <Switch
          checked={useNewUI}
          onChange={(_, checked) => {
            setSwitchingUI(true);

            return setUseNewUI(checked)
              .finally(() => setSwitchingUI(false))
              .then(() => navigate("/"));
          }}
          color="secondary"
          disabled={featureFlag !== "offer" || switchingUI}
        />
      }
    />
  ) : (
    <CircularProgress />
  );
};

export const ProfilePage = () => {
  const { user } = useUser("me");
  const { featureFlag } = useNewUIHook();
  const legacyUser = React.useMemo(
    () => user && LegacyBackendUserAdapter(user),
    [user]
  );

  return (
    <Grid container direction={"column"}>
      <Grid item>
        {legacyUser ? (
          <UserView user={legacyUser} suppressAdmin />
        ) : (
          <CircularProgress />
        )}
      </Grid>
      {featureFlag !== "no-offer" ? (
        <Grid item>
          <NewUISwitch />
        </Grid>
      ) : null}
      <Grid item>
        <MDButton component={Link} to="/auth/logout">
          Logout
        </MDButton>
      </Grid>
    </Grid>
  );
};
