import {
  Invoice,
  // PendingInvoice,
  Task,
} from "../../../Models";
import { flow, pipe } from "fp-ts/lib/function";
import * as A from "fp-ts/lib/Array";
import * as O from "fp-ts/lib/Option";

/**
 * Find the drawItem corresponding to a given task.
 *
 * Search first by id, then by title
 *
 * @param task task id and title to search for
 * @returns function to choose Option<drawItem> that matches the provided task
 */
export const drawForTask =
  (
    { id: myId, title: myTitle }: Pick<Task, "id" | "title">,
    opt: { matchTitles?: boolean } = { matchTitles: false }
  ) =>
  (drawItems: Invoice["drawItems"]) =>
    pipe(
      drawItems,
      // prefer task matches
      A.findFirst(({ task }) => task && task.id === myId),
      // then try by title
      O.alt(() =>
        opt?.matchTitles
          ? pipe(
              drawItems,
              A.findFirst(({ title }) => title === myTitle)
            )
          : O.none
      )
    );

export const extractAmount = (onNone: () => number) =>
  flow(
    O.map(({ amount }: { amount: number }) => amount),
    O.getOrElse(onNone)
  );
