import React from "react";
import { useLocation, useMatches, useRouteError } from "react-router-dom";
import { logAnomaly } from "@lib/ErrorLogging";
import config from "../config";

export default function ErrorPage() {
  const error = useRouteError() as {
    statusText?: string;
    message: string;
    stack?: string;
  };
  const matches = useMatches();
  const location = useLocation();

  logAnomaly(new Error("Error page"), { error, matches, location });
  return (
    <div id="error-page">
      <h1>Oops!</h1>
      <p>
        Sorry, an unexpected error has occurred. It has been logged and we will
        look into it.
      </p>
      <p>
        <i>{error.statusText || error.message}</i>
      </p>
      {config.nonProdEnv ? <p>{error.stack}</p> : null}
    </div>
  );
}
