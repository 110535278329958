import React from "react";
import { styled } from "@mui/material/styles";
import { Link, useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import CircularProgress from "@mui/material/CircularProgress";
import config from "../config";
import { ifEnterKey } from "@lib/boilerplate";
import { useFocus } from "@lib/hooks/useFocus";
import { useBackend } from "@lib/hooks";
import { logError } from "@lib/ErrorLogging";
import { useSnackbar } from "notistack";
import { BusinessPersonInputs } from "@lib/APITypes";

const PREFIX = "LenderAdminSetup";

const classes = {
  formWrapper: `${PREFIX}-formWrapper`,
  margin: `${PREFIX}-margin`,
  navBarContainer: `${PREFIX}-navBarContainer`,
  buttonNav: `${PREFIX}-buttonNav`,
  icon: `${PREFIX}-icon`,
};

const Root = styled("div")({
  [`& .${classes.formWrapper}`]: {
    paddingRight: "5vw",
    paddingLeft: "5vw",
    textAlign: "center",
  },
  [`& .${classes.margin}`]: {
    marginTop: "20px",
  },
  [`& .${classes.navBarContainer}`]: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "10px",
  },
  [`& .${classes.buttonNav}`]: {
    marginLeft: "10px",
    marginRight: "10px",
  },
  [`& .${classes.icon}`]: {
    marginLeft: "5px",
    marginRight: "5px",
  },
});

export default function LenderAdminSetup(): JSX.Element {
  const navigate = useNavigate();
  const [lender, setLenderData] = React.useState<Partial<BusinessPersonInputs>>(
    {}
  );
  const [loading, setLoading] = React.useState(false);
  const { addLenderAdmin } = useBackend();
  const { enqueueSnackbar } = useSnackbar();
  const handleChange = (event: {
    target: { name: string; value: unknown };
  }) => {
    setLenderData({ ...lender, [event.target.name]: event.target.value });
  };

  const handleSubmit = () => {
    const validatedLender = BusinessPersonInputs.safeParse(lender);
    if (validatedLender.success) {
      setLoading(true);
      addLenderAdmin({ lender: validatedLender.data })
        .then(() => {
          setLoading(false); // not in finally because when we navigate it will try to update unmounted component
          navigate("/dashboard");
        })
        .catch((error) => {
          logError(error);
          setLoading(false);
          enqueueSnackbar(
            `There was an error adding this Lender Admin. Please contact ${config.contact.customerSuccess} for assistance.`,
            { variant: "error" }
          );
        });
    } else {
      logError(validatedLender.error);
      enqueueSnackbar(
        "Something unexpected happened. Lender not added. Refresh and try again.",
        { variant: "error" }
      );
    }
  };

  const [firstNameRef, focusFirstName] = useFocus();
  const [lastNameRef, focusLastName] = useFocus();
  const [emailRef, focusEmail] = useFocus();
  const [phoneRef, focusPhone] = useFocus();

  return (
    <Root>
      <form>
        <div className={classes.formWrapper}>
          <h1>Lender Admin Information</h1>
          <div>
            <FormControl
              variant="standard"
              fullWidth
              className={classes.margin}
            >
              <TextField
                variant="standard"
                required
                fullWidth
                id="lendercompany"
                label="Company"
                name="company"
                onChange={handleChange}
                autoFocus
                onKeyDown={ifEnterKey(focusFirstName)}
              />
            </FormControl>
            <FormControl
              variant="standard"
              fullWidth
              className={classes.margin}
            >
              <TextField
                variant="standard"
                required
                fullWidth
                id="lenderfirstname"
                label="First Name"
                name="first_name"
                onChange={handleChange}
                inputRef={firstNameRef}
                onKeyDown={ifEnterKey(focusLastName)}
              />
            </FormControl>
            <FormControl
              variant="standard"
              fullWidth
              className={classes.margin}
            >
              <TextField
                variant="standard"
                required
                fullWidth
                id="lenderlastname"
                label="Last Name"
                name="last_name"
                onChange={handleChange}
                inputRef={lastNameRef}
                onKeyDown={ifEnterKey(focusEmail)}
              />
            </FormControl>
            <FormControl
              variant="standard"
              fullWidth
              className={classes.margin}
            >
              <TextField
                variant="standard"
                fullWidth
                required
                id="lenderemail"
                label="Email"
                name="email"
                onChange={handleChange}
                inputRef={emailRef}
                onKeyDown={ifEnterKey(focusPhone)}
              />
            </FormControl>
            <FormControl
              variant="standard"
              fullWidth
              className={classes.margin}
            >
              <TextField
                variant="standard"
                fullWidth
                required
                id="lenderphone"
                label="Phone"
                name="phone_number"
                onChange={handleChange}
                onKeyDown={ifEnterKey(handleSubmit)}
                inputRef={phoneRef}
              />
            </FormControl>
          </div>
        </div>
        <div className={classes.navBarContainer}>
          <Link to="/dashboard" style={{ textDecoration: "none" }}>
            <Button
              className={classes.buttonNav}
              variant="outlined"
              color="secondary"
              size="large"
            >
              <CancelPresentationIcon className={classes.icon} /> Cancel{" "}
            </Button>
          </Link>
          <Button
            className={classes.buttonNav}
            variant="outlined"
            size="large"
            color="primary"
            disabled={loading}
            type="submit"
            onClick={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            {loading ? <CircularProgress /> : "Submit"}
          </Button>
        </div>
      </form>
    </Root>
  );
}
