import {
  Button,
  CircularProgress,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import { useForm, Controller } from "react-hook-form";
import {
  BackendEditTaskInputs,
  BackendGetTaskResponse,
  PersistentProjectFile,
} from "@lib/APITypes";
import { MoneyEditor } from "../Components/MoneyEditor";
import { useFeatures, useFocus, usePermissions } from "@lib/hooks";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  DropzoneArea,
  // FileObject,
  // PreviewIconProps,
} from "react-mui-dropzone";
import { Storage } from "aws-amplify";
import { Invoice } from "../Models";
import { wholePercentageFormatter } from "@lib/wholePercentageFormatter";
import {
  amountRemaining,
  fractionRemaining,
} from "../Pages/ProjectView/Invoice/DrawRequestRow";
import { useEntityFeatures } from "@lib/hooks/useEntityFeatures";
import { multiRef } from "@lib/misc";
import { ifEnterKey } from "@lib/boilerplate";
import { DatePicker } from "@mui/x-date-pickers";
import { defaultDatePickerProps } from "@lib/boilerplate";
import EventIcon from "@mui/icons-material/Event";
import { LinkedFile } from "@project-centerline/project-centerline-api-types";

const FileZoneWrapper = styled("div")(({ theme: { spacing } }) => ({
  marginBottom: spacing(4),
}));
export const Inputs = z.object({
  description: z.string(),
  lender_only: z.boolean(),
  task_value: z.number().nonnegative(),
  title: z.string().nonempty(),
  completed: z.boolean(),
  start: z.date(),
  end_date: z.date().nullable(),
});
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type Inputs = z.infer<typeof Inputs>;

const DrawModeInputs = z.object({
  amountRequested: z.number().nonnegative(),
  originalRequest: z.number().nonnegative(),
});

// export type EditTaskFormTaskInputs = Pick<
//   BackendGetTaskResponse,
//   "description" | "lender_only" | "task_value" | "title"
// >;

type EditTaskFormTaskOutputs = Pick<
  BackendEditTaskInputs,
  "title" | "description" | "lender_only" | "task_value" | "start" | "end_date"
> & {
  completed: boolean;
  amountRequested: number;
};
type DrawModeOutputs = z.infer<typeof DrawModeInputs>;

// export function convertBackendTaskResponseToEditTaskFormInputs(
//   backend: BackendGetTaskResponse
// ): EditTaskFormTaskInputs {
//   return { ...backend };
// }
export interface EditTaskFormProps {
  // handleSubmit: ((event: React.FormEvent<HTMLFormElement>) => void) | undefined;
  taskData?: Inputs &
    Pick<BackendGetTaskResponse, "invoiced_amount" | "requested_amount">;
  draw?: z.infer<typeof DrawModeInputs> & { invoice: Pick<Invoice, "is_put"> };
  onSubmit: ({
    taskData,
    dirtyFields,
    files,
  }: {
    taskData: EditTaskFormTaskOutputs;
    drawData?: DrawModeOutputs;
    dirtyFields: (
      | keyof EditTaskFormTaskOutputs
      | "completed"
      | keyof DrawModeOutputs
    )[];
    files: File[];
  }) => Promise<void>;
  onDelete?: () => void;
  onCancel: () => void;
  disabled?: boolean;
  existingFiles: PersistentProjectFile[];
  scheduleMode?: boolean;
  taskTitleEditable: boolean;
}

function FileZone({
  existingFiles,
  onChange,
}: {
  existingFiles?: PersistentProjectFile[];
  onChange: (files: File[]) => void;
}) {
  const { maxFileUploadCount, maxFileUploadSize } = useFeatures();

  const [initialFiles, setInitialFiles] = React.useState<
    string[] | undefined
  >();
  // React.useEffect(() => console.log("initialFiles changed", { initialFiles }), [
  //   initialFiles,
  // ]);
  React.useEffect(() => {
    // console.log("existingFiles changed", { existingFiles });
    if (!existingFiles) {
      // setInitialFiles([]);
      return;
    }

    let neverMind = false;
    Promise.all(
      existingFiles.map((f) =>
        LinkedFile.is(f) ? f.href : Storage.get(f.storageKey)
      )
    ).then((urls) => {
      if (!neverMind) {
        // console.log("initialFiles =>", urls);
        setInitialFiles(urls as string[]);
      }
    });

    return () => {
      neverMind = true;
    };
  }, [existingFiles]);

  return initialFiles ? (
    // return (
    <DropzoneArea
      maxFileSize={maxFileUploadSize}
      filesLimit={maxFileUploadCount}
      onChange={onChange}
      alertSnackbarProps={{ autoHideDuration: 1500 }}
      useChipsForPreview
      dropzoneText="Drag and drop or click here to add more files"
      getFileAddedMessage={(fileName) => `File ${fileName} queued for upload.`}
    />
  ) : (
    // );
    <CircularProgress />
  );
}

export function EditTaskForm({
  taskData,
  draw,
  onSubmit,
  onDelete,
  onCancel: parentOnCancel,
  disabled,
  existingFiles,
  scheduleMode,
  taskTitleEditable,
}: // subcontractors, // TODO: #93 Is subcontractor a single thing or a list or both depending where?

EditTaskFormProps): JSX.Element {
  const { canChangeLenderOnly, canCompleteTasks } = usePermissions();
  const { perLineItemAttachments: canSeePerLineItemAttachments } =
    useEntityFeatures();
  const schema = draw ? Inputs.merge(DrawModeInputs) : Inputs;

  const defaultValues = { ...taskData, ...draw };

  const updateTaskMode = !disabled && !draw;
  const updateDrawMode = !disabled && Boolean(draw);

  const [files, setFiles] = React.useState<File[]>([]);

  const invertIfPut = (x: number) => (draw?.invoice.is_put ? -x : x);

  // form hook magic
  const {
    handleSubmit,
    control,
    formState: { dirtyFields, errors, isDirty },
    watch,
  } = useForm<EditTaskFormTaskOutputs>({
    defaultValues,
    resolver: zodResolver(schema),
  });

  const { completed, task_value, amountRequested } = watch();

  // TODO: the buttons (and these) should really be the parent's. Or we should make this the dialog
  const [busySubmitting, setBusySubmitting] = React.useState(false);

  const onCancel = () => {
    if (isDirty) {
      if (
        !window.confirm(
          "You have unsaved changes. Are you sure you want to leave?"
        )
      ) {
        return;
      }
    }

    if (files.length > 0) {
      if (
        !window.confirm(
          "Your files are not yet saved. Are you sure you want to leave?"
        )
      ) {
        return;
      }
    }

    parentOnCancel();
  };

  const [descriptionRef, focusDescription] = useFocus<HTMLElement>();
  const [valueRef, focusValue] = useFocus<HTMLElement>();
  const [amountRef, focusAmount] = useFocus<HTMLElement>();
  const [lenderOnlyRef, focusLenderOnly] = useFocus<HTMLElement>();
  const [completedRef, focusCompleted] = useFocus<HTMLElement>();
  const [cancelRef, focusCancel] = useFocus<HTMLButtonElement>();
  const [submitRef, focusSubmit] = useFocus<HTMLButtonElement>();
  const [startRef, focusStart] = useFocus<HTMLElement>();
  const [endRef, focusEnd] = useFocus<HTMLElement>();

  const taskFieldsDisabled = disabled || completed;
  const taskInfo: Record<
    keyof EditTaskFormTaskOutputs | "originalAmount" | "available",
    {
      visible: boolean;
      editable: boolean;
      focus: ReturnType<typeof useFocus>[1] | null;
      nextFocus: keyof EditTaskFormTaskOutputs | "submit" | "cancel";
    }
  > = {
    title: {
      visible: true,
      editable: !taskFieldsDisabled && taskTitleEditable,
      focus: null,
      nextFocus: "description",
    },
    description: {
      visible: true,
      editable: !taskFieldsDisabled,
      focus: focusDescription,
      nextFocus: "task_value",
    },
    task_value: {
      visible: true,
      editable: !taskFieldsDisabled,
      focus: focusValue,
      nextFocus: "amountRequested",
    },
    amountRequested: {
      visible: updateDrawMode,
      editable: !disabled && !completed,
      focus: focusAmount,
      nextFocus: "lender_only",
    },
    lender_only: {
      visible: canChangeLenderOnly && !draw,
      editable: !taskFieldsDisabled,
      focus: focusLenderOnly,
      nextFocus: "completed",
    },
    completed: {
      visible: canCompleteTasks && !draw,
      editable: !disabled && updateTaskMode,
      focus: focusCompleted,
      nextFocus: "start",
    },
    originalAmount: {
      editable: false,
      focus: null,
      nextFocus: "amountRequested",
      visible: updateDrawMode && draw?.amountRequested !== undefined,
    },
    available: {
      editable: false,
      focus: null,
      nextFocus: "amountRequested",
      visible: updateDrawMode,
    },
    start: {
      editable: true,
      focus: focusStart,
      nextFocus: "end_date",
      visible: !!scheduleMode,
    },
    end_date: {
      editable: true,
      focus: focusEnd,
      nextFocus: isDirty ? "submit" : "cancel",
      visible: !!scheduleMode,
    },
  };

  function pickNextFocus(
    field: keyof EditTaskFormTaskOutputs
  ): ReturnType<typeof useFocus>[1] {
    const myInfo = taskInfo[field];
    if (myInfo.nextFocus === "submit") {
      return focusSubmit;
    }
    if (myInfo.nextFocus === "cancel") {
      return focusCancel;
    }
    const next = taskInfo[myInfo.nextFocus];
    const choice =
      next.visible && next.editable && next.focus
        ? next.focus
        : pickNextFocus(myInfo.nextFocus);

    return choice;
  }

  const theme = useTheme();
  const narrowScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <form
      onSubmit={handleSubmit((editedData) => {
        setBusySubmitting(true);
        return onSubmit({
          taskData: editedData,
          drawData: updateDrawMode
            ? { ...editedData, originalRequest: draw?.originalRequest || 0 }
            : undefined,
          dirtyFields: Object.keys(dirtyFields) as (
            | keyof EditTaskFormTaskOutputs
            | "completed"
          )[],
          files,
        }).finally(() => setBusySubmitting(false));
      })}
      style={{ padding: "20px", paddingTop: 0 }}
    >
      <Controller
        name="title"
        rules={{ required: true }}
        control={control}
        render={({ field: { ref, ...rest } }) => (
          <TextField
            variant="standard"
            inputRef={ref}
            {...rest}
            disabled={!taskInfo.title.editable || !taskTitleEditable}
            fullWidth
            label="Task Name"
            helperText={errors.title?.message}
            error={Boolean(errors.title?.message)}
            onKeyDown={ifEnterKey(pickNextFocus("title"))}
          />
        )}
      />

      <Controller
        name="description"
        control={control}
        render={({ field: { ref, ...rest } }) => (
          <TextField
            variant="standard"
            fullWidth
            label="Description"
            multiline
            rows={4}
            inputRef={multiRef([ref, descriptionRef])}
            {...rest}
            disabled={!taskInfo.description.editable}
            helperText={errors.description?.message}
            error={Boolean(errors.description?.message)}
          />
        )}
      />
      <Grid container>
        <Grid item xs={6}>
          <Controller
            name="task_value"
            render={({ field: { ref, ...rest } }) => (
              <MoneyEditor
                {...rest}
                ref={multiRef([ref, valueRef])}
                label="Value"
                disabled={!taskInfo.task_value.editable}
                helperText={errors.task_value?.message}
                error={Boolean(errors.task_value?.message)}
                onKeyDown={ifEnterKey(pickNextFocus("task_value"))}
                // onKeyDown={ifEnterKey(pickNextFocus("title"))}
                // onKeyDown={ifEnterKey(focusSubmit)}
              />
            )}
            control={control}
          />
        </Grid>
      </Grid>
      <Grid container direction="row">
        <Grid item xs={6}>
          <Grid
            container
            direction="column"
            justifyContent="flex-start"
            sx={({ spacing }) => ({
              marginTop: spacing(2),
            })}
          >
            {taskInfo.originalAmount.visible && draw ? (
              <Grid item>
                <MoneyEditor
                  label="Requested"
                  value={invertIfPut(draw.originalRequest)}
                  disabled
                />
              </Grid>
            ) : null}

            <Grid item>
              {taskInfo.amountRequested.visible ? (
                <Controller
                  name="amountRequested"
                  render={({ field: { onChange, value, ref, ...rest } }) => (
                    <MoneyEditor
                      {...rest}
                      ref={multiRef([ref, amountRef])}
                      autoFocus={true}
                      label="Funding"
                      onChange={(value: number) => onChange(invertIfPut(value))}
                      value={invertIfPut(value)}
                      disabled={!taskInfo.amountRequested.editable}
                      helperText={errors.amountRequested?.message}
                      error={Boolean(errors.amountRequested?.message)}
                      maximumValue={task_value}
                      endAdornmentText={
                        draw?.invoice.is_put
                          ? undefined
                          : amountRequested && !narrowScreen
                          ? taskData &&
                            wholePercentageFormatter.format(
                              Math.min(
                                fractionRemaining(taskData, amountRequested),
                                1
                              )
                            )
                          : undefined
                      }
                      onKeyDown={ifEnterKey(pickNextFocus("amountRequested"))}
                    />
                  )}
                  control={control}
                />
              ) : undefined}
            </Grid>
            {taskInfo.available.visible && taskData ? (
              <Grid item>
                <MoneyEditor
                  label="Remaining"
                  value={amountRemaining(
                    taskData,
                    Boolean(draw?.invoice.is_put)
                  )}
                  disabled
                />
              </Grid>
            ) : null}

            <Grid item>
              <Grid container direction="column" justifyContent="center">
                {taskInfo.lender_only.visible ? (
                  <Grid item>
                    <Controller
                      name="lender_only"
                      render={({
                        field: { value, onChange, ref, ...rest },
                      }) => (
                        <FormControlLabel
                          label="Lender Only"
                          control={
                            <Switch
                              {...rest}
                              checked={value}
                              onChange={(_, checked) => onChange(checked)}
                              inputRef={multiRef([ref, lenderOnlyRef])}
                              color="secondary"
                              disabled={!taskInfo.lender_only.editable}
                              onKeyDown={ifEnterKey(
                                pickNextFocus("lender_only")
                              )}
                            />
                          }
                        />
                      )}
                      control={control}
                    />
                  </Grid>
                ) : null}
              </Grid>
              <Grid item>
                {taskInfo.completed.visible ? (
                  <Controller
                    name="completed"
                    render={({ field: { value, onChange, ref, ...rest } }) => (
                      <FormControlLabel
                        label="Completed"
                        control={
                          <Switch
                            {...rest}
                            checked={value}
                            onChange={(_, checked) => onChange(checked)}
                            inputRef={multiRef([ref, completedRef])}
                            color="secondary"
                            disabled={!taskInfo.completed.editable}
                            onKeyDown={ifEnterKey(pickNextFocus("completed"))}
                          />
                        }
                      />
                    )}
                    control={control}
                  />
                ) : null}
              </Grid>
              {taskInfo.start.visible || taskInfo.end_date.visible ? (
                <Grid item container>
                  {taskInfo.start.visible ? (
                    <Grid item>
                      <Controller
                        name="start"
                        render={({ field: { ref, ...rest } }) => (
                          <DatePicker
                            {...defaultDatePickerProps}
                            {...rest}
                            components={{ OpenPickerIcon: EventIcon }}
                            renderInput={(params) => (
                              <TextField
                                variant="standard"
                                {...params}
                                inputRef={ref}
                                InputProps={{
                                  ...params.InputProps,
                                  inputRef: multiRef([ref, startRef]),
                                }}
                                disabled={!taskInfo.start.editable}
                                helperText={errors.start?.message}
                                error={Boolean(errors.start?.message)}
                                id="start-date"
                                fullWidth
                              />
                            )}
                            label="Start Date"
                          />
                        )}
                        control={control}
                      />
                    </Grid>
                  ) : undefined}
                  {taskInfo.end_date.visible ? (
                    <Grid item>
                      <Controller
                        name="end_date"
                        render={({ field: { value, ref, ...rest } }) => (
                          <DatePicker
                            {...defaultDatePickerProps}
                            {...rest}
                            components={{ OpenPickerIcon: EventIcon }}
                            label="End Date"
                            value={value || null}
                            renderInput={(params) => (
                              <TextField
                                variant="standard"
                                {...params}
                                inputRef={ref}
                                InputProps={{
                                  ...params.InputProps,
                                  inputRef: multiRef([ref, endRef]),
                                }}
                                fullWidth
                                disabled={!taskInfo.end_date.editable}
                                helperText={errors.end_date?.message}
                                error={Boolean(errors.end_date?.message)}
                              />
                            )}
                          />
                        )}
                        control={control}
                      />
                    </Grid>
                  ) : undefined}
                </Grid>
              ) : undefined}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <FileZoneWrapper>
            {disabled || !canSeePerLineItemAttachments ? null : (
              <FileZone
                onChange={(files) => {
                  setFiles(files);
                }}
                existingFiles={existingFiles}
              />
            )}
          </FileZoneWrapper>
        </Grid>
      </Grid>

      <Grid
        container
        justifyContent="space-between"
        direction="row"
        alignItems="center"
      >
        <Grid item>
          {updateTaskMode && onDelete ? (
            <Button color="muted" variant="contained" onClick={onDelete}>
              Delete
            </Button>
          ) : (
            <div />
          )}
        </Grid>
        <Grid item>
          {disabled ? (
            <Button
              color="muted"
              onClick={onCancel}
              variant="contained"
              ref={cancelRef}
            >
              Close
            </Button>
          ) : (
            <Grid container justifyContent="flex-end" direction="row">
              <Button
                color="muted"
                sx={({ spacing }) => ({
                  marginLeft: spacing(2),
                })}
                variant="contained"
                onClick={onCancel}
                ref={cancelRef}
              >
                Cancel
              </Button>
              {busySubmitting ? (
                <CircularProgress />
              ) : (
                <Button
                  sx={({ spacing }) => ({
                    marginLeft: spacing(2),
                  })}
                  type="submit"
                  variant="contained"
                  ref={submitRef}
                  color="primary"
                  // autoFocus
                >
                  Update
                </Button>
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
    </form>
  );
}
