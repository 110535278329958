import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  CircularProgress,
  FormControl,
  TextField,
} from "@mui/material";
import React from "react";
import { Invoice } from "../../../Models";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { BackendGetCommentsResponse } from "../../../Lib/APITypes";
import { CommentEntry } from "@/Components/Comment";

export function CommentsSection({
  readonly,
  comments,
  addComment,
  removeComment,
}: {
  invoiceId: Invoice["invoice_id"];
  readonly?: boolean;
  comments: readonly BackendGetCommentsResponse[] | undefined;
  addComment: (message: string) => Promise<void>;
  removeComment:
    | ((
        commentId: BackendGetCommentsResponse["comment_id"]
      ) => Promise<unknown>)
    | false;
}): JSX.Element {
  const [editComment, setEditComment] = React.useState("");

  return (
    <Accordion style={{ marginTop: "10px" }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="comment-content"
        id="comment-header"
      >
        <p>Comments ({comments?.length ?? <CircularProgress size="1rem" />})</p>
      </AccordionSummary>
      <AccordionDetails style={{ flexDirection: "column" }}>
        {(comments ?? []).map((comment) => (
          <CommentEntry comment={comment} onRemoveComment={removeComment} />
        ))}
        {readonly ? null : (
          <form>
            <FormControl variant="standard" fullWidth>
              <TextField
                required
                multiline
                variant="outlined"
                value={editComment}
                rows={4}
                name="comment"
                onChange={({ target: { value } }) => setEditComment(value)}
              />
            </FormControl>
            <Button
              disabled={!editComment}
              onClick={(e) =>
                addComment(editComment).then(() => setEditComment(""))
              }
              color="primary"
            >
              Submit
            </Button>
          </form>
        )}
      </AccordionDetails>
    </Accordion>
  );
}
