import { flow, Instance, types } from "mobx-state-tree";
import { BackendGetTaskResponse } from "@lib/APITypes";

// This is legit and, other than `hasBeenImported` will probably be its own centralized model eventually.
export const TaskModel = types
  .model("Task", {
    id: types.identifierNumber,
    title: types.refinement(types.string, (s) => s.length > 0),
    description: types.maybe(types.string),
    start: types.maybe(types.Date),
    end: types.maybe(types.Date),
    value: types.refinement(types.number, (n) => n >= 0),
    critical: types.optional(types.boolean, false),
    hasBeenImported: types.optional(types.boolean, false),
  })
  .actions((self) => ({
    setDone: (done: boolean) => (self.hasBeenImported = done),
    setCritical: (critical: boolean) => {
      self.critical = critical;
    },
  }));
export type ITask = Instance<typeof TaskModel>;

// this is specific to the import tasks dialog and may move there.
export const TaskImportDataModel = types
  .model("TaskImportData", {
    projectId: types.string,
    retention: types.refinement(types.number, (n) => n >= 0 && n <= 100),
    email: types.string,
    rows: types.array(TaskModel),
    selectedRowIndices: types.optional(types.array(types.number), []),
  })
  .actions((self) => ({
    replaceTasks(rows: ITask[]) {
      self.rows.replace(rows);
      self.selectedRowIndices.replace(
        rows.reduce(
          (selected, row, index) =>
            /\s*TOTAL[\s:]*/.test(row.title) || row.value <= 0
              ? selected
              : selected.concat([index]),
          [] as number[]
        )
      );
    },
    removeTask(task: ITask) {
      const victimIndex = self.rows.indexOf(task);
      if (victimIndex >= 0) {
        self.rows.remove(task);
        // keep selections in the right place
        if (self.selectedRowIndices.remove(victimIndex)) {
          self.selectedRowIndices.replace(
            self.selectedRowIndices.map((idx) =>
              idx >= victimIndex ? idx - 1 : idx
            )
          );
        }
      }
    },
    selectRowsByIndex(rows: number[]) {
      self.selectedRowIndices.replace(rows);
    },
  }))
  .views((self) => ({
    get selectedRows() {
      return self.selectedRowIndices.map((dataIndex) => self.rows[dataIndex]);
    },
  }))
  .volatile((self) => ({
    taskCreationInProgress: false,
    tasksToCreate: [] as ITask[],
    tasksCreated: [] as BackendGetTaskResponse[],
  }))
  .actions((self) => ({
    createTasksFromSelected: flow(function* createTasks(createTask) {
      // TODO: get createTask from env?
      self.tasksCreated = [];
      self.tasksToCreate = self.selectedRows.slice();
      self.taskCreationInProgress = true;

      try {
        // for now, do this serially because I don't trust the backend to be atomic
        while (self.tasksToCreate.length > 0) {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const task = self.tasksToCreate.shift()!;
          const newTask = yield createTask(
            self.projectId,
            self.retention,
            self.email,
            task
          );
          task.hasBeenImported = true;
          self.tasksCreated.push(newTask);
        }
      } finally {
        self.taskCreationInProgress = false;
      }

      return self.tasksCreated;
    }),
  }));
export type ITaskImportData = Instance<typeof TaskImportDataModel>;
