import { useMemo } from "react";
import { Invoice, Task } from "../../Models";
import { Role } from "../roles";
import { useAppContext } from "../UserContext";
import { useAuth } from "@lib/hooks/useAuth";
import { useInspectors } from "./useInspectors";
import { Decision } from "@/Pages/ProjectView/DecisionMatrix/DisplayDecisions";

export interface AppPermissions {
  canDoLenderAdminThings: boolean;
  canDoLenderThings: boolean;
  canSeeUserAdminInfo: boolean;
  canReverseDrawRequests: boolean;
  canSeeDrawRequests: boolean;
  canCreateDrawRequests: (arg: { restrictInspectors: boolean }) => boolean;
  canModifyDrawRequests: (arg: { restrictInspectors: boolean }) => boolean;
  canModifyDrawPaidStatus: boolean;
  canDeleteDrawRequests: boolean;
  canDrawAgainstTask: (task: {
    lender_only: boolean;
    completed: boolean;
  }) => boolean;
  canApproveDrawRequests: (
    invoice: Readonly<Pick<Invoice, "current_approver">>
  ) => boolean;
  canEditInvoiceRouting: boolean;
  canCompleteTasks: boolean;
  canAddTasks: boolean;
  canEditTasks: boolean;
  canDeleteTasks: boolean;
  canCreateProjects: boolean;
  canDeleteProjects: boolean;
  canOpenCloseProjects: boolean;
  canChangeLenderOnly: boolean;
  canSeeLoanInfo: boolean;
  canEditLoanInfo: boolean;
  canAddUser: (role: Role) => boolean;
  canAddExternalUser: (role: Role) => boolean;
  /** Can add at least one class of users (so makes sense to show an add button, for instance) */
  canAddUsers: boolean;
  canModifyUser: (user: { role: Role; email: string }) => boolean;
  canAddDecisionRequests: boolean;
  canEditDecisionRouting: boolean;
  canSeeDecision: (decision: { inspector: string | null }) => boolean;
  canRequestConciergeInspection: boolean;
  /// RN this means feasibility; probably same group as concierge thought
  canSeeSpecialDecisions: boolean;
  canSeeVendorInspectionLinks: boolean;
  canRemoveDecisionComment: (
    decision: Pick<Decision, "approval_status">
  ) => boolean;
  canRemoveInvoiceComment: (
    invoice: Pick<Invoice, "approval_status">
  ) => boolean;

  // TODO: We should not care if permissions come from code or Entity - but RN it hangs us with recursion
  // canSeePerLineItemAttachments: boolean;
}

const allRoles = Object.values(Role);

// Is this more expressive?
// const everybody = new Set(allRoles);
// const nobody = new Set<Role>();
// const everybodyExcept = (exclude: Iterable<Role>) =>
//   new Set(Array.from(everybody).filter((role) => !Array.from(exclude).includes(role)));
const lenderLike = new Set([Role.Lender, Role.LenderAdmin, Role.SuperAdmin]);
const lenderAdminLike = new Set([Role.LenderAdmin, Role.SuperAdmin]);
const lenderLikeAndContractors = new Set(
  Array.from(lenderLike.values()).concat(Role.Contractor)
);
const lenderLikeAndOwners = new Set(
  Array.from(lenderLike.values()).concat(Role.Owner)
);

const roleCanDoLenderThings = (role: Role) => lenderLike.has(role);
const roleCanDoLenderAdminThings = (role: Role) => lenderAdminLike.has(role);

// const isLenderLike = (role: Role) => lenderLike.has(role);
// // [Role.Lender, Role.LenderAdmin, Role.SuperAdmin].includes(role);

// or if we were worried about speed, could pre-calculate them all once for the module:
// type DecisionLookup = {
//   [actor in Role]: boolean;
// };
// const toLookup = (cohort: Set<Role>) =>
//   allRoles.reduce(
//     (soFar, role) => ({
//       ...soFar,
//       [role]: cohort.has(role),
//     }),
//     {} as DecisionLookup
//   );
// const lenderLookup = toLookup(lenderLike);

export function usePermissions(): AppPermissions {
  const { role: actualRole } = useAppContext(); // so we don't have to protect lots of points against undef/null. Role has become optional but that's hard
  const { currentUserEmail } = useAuth();
  // const {
  //   perLineItemAttachments: canSeePerLineItemAttachments,
  // } = useEntityFeatures();
  const role = actualRole ?? ("no-role" as Role);
  const { isConciergeInspector } = useInspectors();
  const [canDoLenderAdminThings, canDoLenderThings] = [
    roleCanDoLenderAdminThings(role),
    roleCanDoLenderThings(role),
  ];

  const perms = useMemo(
    () =>
      ({
        canDoLenderAdminThings,
        canDoLenderThings,
        canSeeUserAdminInfo:
          role === Role.SuperAdmin || role === Role.LenderAdmin,
        canReverseDrawRequests: canDoLenderAdminThings,
        canSeeDrawRequests: role !== Role.Subcontractor,
        canCreateDrawRequests: ({ restrictInspectors }) =>
          lenderLikeAndContractors.has(role) ||
          role === Role.Owner ||
          (!restrictInspectors &&
            (role === Role.Inspector || role === Role.InspectorAdmin)),
        canModifyDrawRequests: ({ restrictInspectors }) =>
          lenderLike.has(role) ||
          (!restrictInspectors &&
            (role === Role.Inspector || role === Role.InspectorAdmin)), // https://trello.com/c/gS9idA6W/527-allow-inspector-to-edit-draws
        canModifyDrawPaidStatus: lenderLike.has(role), // https://projectcenterline.slack.com/archives/G01EQ90SC65/p1668046339297979
        canDeleteDrawRequests: canDoLenderAdminThings,
        canDrawAgainstTask: ({
          lender_only,
          completed,
        }: Pick<Task, "lender_only" | "completed">) =>
          !completed && // nobody gets to modify without un-completing
          (lender_only // for lender-only you must be lender(-like)
            ? lenderLike.has(role)
            : true),

        canApproveDrawRequests: (invoice: Pick<Invoice, "current_approver">) =>
          role === Role.SuperAdmin ||
          (role === Role.LenderAdmin &&
            !isConciergeInspector({ email: invoice.current_approver || "" })) ||
          (currentUserEmail !== undefined &&
            currentUserEmail === invoice.current_approver),

        canEditInvoiceRouting: canEditInvoiceRouting(role),
        canSeeVendorInspectionLinks: lenderLike.has(role),

        canCompleteTasks: lenderLikeAndContractors.has(role),

        canAddTasks: lenderLikeAndContractors.has(role),

        canEditTasks: lenderLike.has(role),
        canDeleteTasks: lenderLike.has(role),

        canCreateProjects: lenderLike.has(role),
        canDeleteProjects: role === Role.SuperAdmin,
        canOpenCloseProjects: lenderLike.has(role),
        // role === Role.SuperAdmin || role === Role.LenderAdmin, (when every project has an admin)

        canChangeLenderOnly: lenderLike.has(role),

        canSeeLoanInfo: lenderLikeAndOwners.has(role),

        canEditLoanInfo: lenderLike.has(role),

        // TODO: should/can this be the same as canModifyUser?
        canAddUser: (roleToBeAdded: Role) =>
          (spreadsheetUserCRUDRules[role] || []).includes(roleToBeAdded) ||
          (role === Role.Lender && roleToBeAdded === Role.LenderAdmin) ||
          (role === Role.Lender && roleToBeAdded === Role.InspectorAdmin) ||
          (role === Role.LenderAdmin && roleToBeAdded === Role.InspectorAdmin),

        canAddExternalUser: (roleToBeAdded: Role) =>
          role === Role.SuperAdmin ||
          (roleToBeAdded === Role.Owner && lenderLike.has(role)),

        canModifyUser: ({
          role: userRole,
          email,
        }: {
          role: Role;
          email: string;
        }) =>
          (spreadsheetUserCRUDRules[role] || []).includes(userRole) &&
          !(role !== Role.SuperAdmin && isConciergeInspector({ email })),

        /** Can add at least one class of users (so makes sense to show an add button, for instance) */
        canAddUsers: (spreadsheetUserCRUDRules[role] || []).length > 0,

        canAddDecisionRequests: lenderLikeAndContractors.has(role),
        canEditDecisionRouting: lenderLike.has(role),
        canSeeDecision: ({ inspector }: { inspector: string | null }) =>
          !inspector ||
          currentUserEmail === inspector ||
          role === Role.SuperAdmin,
        canRequestConciergeInspection:
          false /* https://trello.com/c/3tTthoef/932-ui-hide-show-name-changes, previously lenderLike.has(role) */,
        canSeeSpecialDecisions: role === Role.SuperAdmin,
        // canSeePerLineItemAttachments,
        canRemoveDecisionComment: ({ approval_status }) =>
          approval_status === "Pending" || role === Role.SuperAdmin,
        canRemoveInvoiceComment: ({ approval_status }) =>
          approval_status === "Pending" || role === Role.SuperAdmin,
      } as AppPermissions),
    [
      canDoLenderAdminThings,
      canDoLenderThings,
      currentUserEmail,
      isConciergeInspector,
      role,
    ]
  );
  // premature optimization?
  // const isLenderLike = lenderLike.has(role);
  // const lenderLikeOrContractor = lendersAndContractors.has(role);

  return perms;
}

const lenderCanCRUDRoles = [
  Role.Inspector,
  Role.Owner,
  Role.Contractor,
  Role.Approver,
];

const spreadsheetUserCRUDRules: { [actor in Role]: Role[] } = {
  "Super Admin": allRoles, // aka "everything"
  LenderAdmin: [...lenderCanCRUDRoles, Role.Lender, Role.LenderAdmin],
  Lender: lenderCanCRUDRoles,
  Contractor: [Role.Subcontractor, Role.Contractor],
  InspectorAdmin: [Role.Inspector, Role.InspectorAdmin],
  Owner: [Role.Owner, Role.Approver],
  Inspector: [],
  Approver: [],
  Subcontractor: [],
};
export function canEditInvoiceRouting(role: Role): boolean {
  return lenderLike.has(role);
}
