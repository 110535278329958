import { throwIfNot } from "@lib/util/throwIfNot";
import { useParams } from "react-router-dom";
import { Button, Grid } from "@mui/material";
import { PeopleRoutingForm } from "@/Components/PeopleRoutingEditor";
import React from "react";
import { useProjectUsers } from "@lib/hooks/useProjectUsers";
import { BackendUserResponse } from "@lib/APITypes";
import { validateInvoiceRouting } from "../Invoice/validateInvoiceRouting";
import { useSnackbar } from "notistack";
import { useProjectInvoices } from "@lib/hooks/useProjectInvoices";
import { useProjectDetails } from "@lib/hooks/useProjectDetails";
import { logError } from "@lib/ErrorLogging";
import { byProp } from "@lib/misc";
import { ErrorOrProgressOr } from "@/Components/ErrorOrProgressOr";

export const ProjectInvoiceRoutingPage = () => {
  const { enqueueSnackbar } = useSnackbar();
  const projectId = throwIfNot(
    useParams<"projectId">().projectId,
    "Missing project id"
  );

  const {
    project: { users: projectUsers },
    isValidating: fetchingProjectUsers,
  } = useProjectUsers(projectId);
  const usersByEmail = projectUsers && byProp(projectUsers)("email");

  const {
    project: { updateRouting: updateProjectInvoiceRouting },
    isValidating: updateRoutingInProgress,
  } = useProjectInvoices(projectId);

  const {
    project: { details: { invoice_routing = undefined } = {} } = {},
    isValidating: projectDetailsValidating,
  } = useProjectDetails(projectId);

  const [approvers, setApprovers] = React.useState<
    ReadonlyArray<BackendUserResponse>
  >([]);
  const resetApprovers = React.useCallback(() => {
    if (invoice_routing && usersByEmail) {
      setApprovers(invoice_routing.map((email) => usersByEmail[email]));
    }
  }, [invoice_routing, usersByEmail]);

  React.useEffect(() => {
    if (invoice_routing && usersByEmail) {
      resetApprovers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoice_routing, JSON.stringify(usersByEmail)]);
  const dirty = React.useMemo(
    () =>
      invoice_routing &&
      JSON.stringify(invoice_routing) !==
        JSON.stringify(approvers.map(({ email }) => email)),
    [approvers, invoice_routing]
  );

  React.useEffect(() => {
    console.log({ dirty });
  }, [dirty]);
  const handleSubmitRouting = (
    newRouting: ReadonlyArray<BackendUserResponse>
  ) => {
    if (
      !validateInvoiceRouting(newRouting, projectUsers ?? [], enqueueSnackbar)
    ) {
      return;
    }

    updateProjectInvoiceRouting(newRouting.map(({ email }) => ({ email })))
      .then(() => {
        setApprovers(newRouting);
      })
      .catch((error) => {
        enqueueSnackbar("Error updating routing. Please try again later.", {
          variant: "error",
        });
        logError(error);
      });
  };

  return (
    <ErrorOrProgressOr
      progress={!approvers || !projectUsers || !usersByEmail}
      render={() => (
        <div
          style={{
            paddingRight: "5vw",
            paddingLeft: "5vw",
            textAlign: "center",
            width: "60%",
            margin: "10px auto",
          }}
        >
          <PeopleRoutingForm
            people={approvers}
            addablePeople={projectUsers ?? []}
            onChange={setApprovers}
            title="Edit Default Draw Routing"
          />
          <Grid container pt={3} justifyContent="space-around">
            <Grid item>
              <Button disabled={!dirty} onClick={resetApprovers}>
                Reset
              </Button>
            </Grid>
            <Grid item>
              <Button
                disabled={
                  !dirty ||
                  fetchingProjectUsers ||
                  updateRoutingInProgress ||
                  projectDetailsValidating
                }
                onClick={() => handleSubmitRouting(approvers)}
              >
                Save Changes
              </Button>
            </Grid>
          </Grid>
        </div>
      )}
    />
  );
};

// TODO: dirty check

export default ProjectInvoiceRoutingPage;
