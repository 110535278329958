import React from "react";
import { StealthyLink } from "./StealthyLink";
import { Typography } from "@mui/material";
import InfoView from "../Pages/ProjectView/Info/InfoView";
import { HtmlTooltip } from "./HtmlTooltip";

export const LoanInfoTooltip: (props: {
  projectId: string;
  description: string;
  hasLoan: boolean;
  children: React.ReactNode;
}) => JSX.Element = ({ projectId, description, hasLoan, children }) => (
  <HtmlTooltip
    disableFocusListener
    disableTouchListener
    title={
      <StealthyLink to={`/projectview/${projectId}/${InfoView.Tabs.Loan}`}>
        <Typography color="inherit">{`${
          hasLoan ? "Incomplete" : "Add"
        } Loan Info`}</Typography>
        {`${
          hasLoan
            ? "The loan information on this project is incomplete"
            : "There is no loan on this project"
        } so ${description} cannot be calculated. Click this message to ${
          hasLoan ? "update the" : "add a"
        } loan`}
      </StealthyLink>
    }
  >
    <div>{children}</div>
  </HtmlTooltip>
);
