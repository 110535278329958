/* TODO: UploadProgress should be implemented in terms of ProgressDialog instead of the other way around */

import React from "react";
import {
  useProgress as useFileProgress,
  UploadProgressDialog,
} from "./UploadProgressDialog";
import { UploadProgress } from "@/Lib/API";

export {
  UploadProgressDialog,
  openAccordingToProgress,
} from "./UploadProgressDialog";

export interface Progress {
  current: string;
  loaded: number;
  total: number;
}

const fromFileProgress = (fileProgress?: UploadProgress) => {
  if (!fileProgress) {
    return;
  }

  const { file, ...rest } = fileProgress;
  return { ...rest, current: fileProgress.file.name };
};

const toFileProgress = (progress?: Progress) => {
  if (!progress) {
    return;
  }

  const { current, ...rest } = progress;
  return { ...rest, file: { name: current } };
};

export const useProgress: () => [
  Progress | undefined,
  (p?: Progress) => void
] = () => {
  const [fileProgress, setFileProgress] = useFileProgress();
  const progress = React.useMemo(
    () => fromFileProgress(fileProgress),
    [fileProgress]
  );
  const setProgress = React.useCallback(
    (p?: Progress) => setFileProgress(toFileProgress(p)),
    [setFileProgress]
  );
  return [progress, setProgress];
};

export const ProgressDialog = ({
  progress,
  open,
}: {
  progress?: Progress;
  open: boolean;
}) => (
  <UploadProgressDialog
    open={open}
    progress={toFileProgress(progress)}
    numberFormatter={(x) => x.toString()}
  />
);
