import { Link } from "react-router-dom";
import { Typography } from "@mui/material";
import { FinalizedInvoices, FinalizedInvoicesProps } from "./FinalizedInvoices";
import { useProjectTasks } from "@lib/hooks/useProjectTasks";
import { useProjectFiles } from "@lib/hooks/useProjectFiles";
import Center from "@/Components/Center";
import { useProjectName } from "@lib/hooks/misc";
import { Invoice, NotPendingInvoice, Task } from "@/Models";
import { ProjectId } from "@project-centerline/project-centerline-api-types";
import { ViewTaskDialog, ViewTaskDialogProps } from "./ViewTaskDialog";
import React from "react";

export const FinalizedDrawsPage = ({
  status,
  ifNone,
  reverseInvoice,
  projectId,
  invoices,
}: {
  status: "Approved" | "Rejected";
  ifNone: JSX.Element | undefined;
  reverseInvoice?: FinalizedInvoicesProps["reverseInvoice"];
  projectId: ProjectId;
  invoices: ReadonlyArray<Invoice>;
}) => {
  const {
    project: { tasks },
  } = useProjectTasks(projectId);
  const projectName = useProjectName(projectId) ?? "unknown project";
  const qualifiedInvoices = invoices?.filter(
    ({ approval_status }) => approval_status === status
  );
  const {
    project: { files: { userFiles = [] } = {} },
  } = useProjectFiles({ projectId });

  const [taskBeingViewed, setTaskBeingViewed] = React.useState<
    Task | undefined
  >();
  const [currentDraw, setCurrentDraw] = React.useState<
    ViewTaskDialogProps["draw"] | undefined
  >();

  if (tasks?.tasks?.length === 0) {
    return (
      <Center>
        <Typography>
          There is no budget yet.{" "}
          <Link to="../../more/budget" relative="path">
            Create one?
          </Link>
        </Typography>
      </Center>
    );
  }

  if (qualifiedInvoices?.length === 0) {
    return (
      <Center>
        {ifNone || <Typography>There are no {status} draws.</Typography>}
      </Center>
    );
  }

  return (
    <>
      <FinalizedInvoices
        invoices={(qualifiedInvoices ?? []) as Array<NotPendingInvoice>}
        filenameHint={projectName}
        tasks={tasks?.tasks ?? []}
        projectId={projectId}
        onTitleClicked={({ draw, invoice }) => {
          setTaskBeingViewed(draw.task);
          setCurrentDraw({
            ...draw,
            amountRequested: 0,
            invoice,
            originalRequest: 0,
          });
        }}
        files={userFiles}
        reverseInvoice={reverseInvoice}
      />
      <ViewTaskDialog
        task={taskBeingViewed}
        draw={currentDraw}
        done={() => {
          setTaskBeingViewed(undefined);
          setCurrentDraw(undefined);
        }}
      />
    </>
  );
};

export default FinalizedDrawsPage;
