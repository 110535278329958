import {
  CircularProgress,
  FormControl,
  Grid,
  TextField,
  TextFieldVariants,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import {
  dummyKeyboardEvent,
  ifEnterKey,
  IfEnterKeyEventHandler,
} from "@lib/boilerplate";
import { useFocus } from "@lib/hooks";
import React, { MutableRefObject } from "react";
import { multiRef } from "@lib/misc";
import { z } from "zod";
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import ErrorPage from "@/Pages/ErrorPage";
import { throwIfNot } from "@/Lib/util/throwIfNot";

const mapsApiKey = throwIfNot(
  process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  "Missing Google Maps API Key"
);

export const ZodSchema = z.object({
  address: z.string().min(1),
  city: z.string().min(1),
  state: z.string().min(1),
  zipcode: z.string().min(1),
  unit: z.string().nullable().optional(),
});

export type AddressInputs = z.infer<typeof ZodSchema>;

const renderGoogleNotReady = (status: Status) => {
  if (status === Status.FAILURE) {
    return <ErrorPage />;
  }

  return <CircularProgress />;
};

const getFullAddress = async (
  autoComplete: google.maps.places.Autocomplete
) => {
  const place = autoComplete.getPlace();

  let address1,
    locality,
    adminArea1Short,
    adminArea1Long,
    countryShort,
    countryLong,
    postalCode = "";

  // Get each component of the address from the place details,
  for (const component of place.address_components || []) {
    const componentType = component.types[0];

    if (componentType === "street_number") {
      address1 = component.long_name;
    }
    if (componentType === "route") {
      address1 = `${address1} ${component.long_name}`;
    }
    if (componentType === "locality") {
      locality = component.long_name;
    }
    if (componentType === "administrative_area_level_1") {
      adminArea1Short = component.short_name;
      adminArea1Long = component.long_name;
    }
    if (componentType === "postal_code") {
      postalCode = component.long_name;
    }
    if (componentType === "postal_code_suffix") {
      postalCode = `${postalCode}-${component.long_name}`;
    }
    if (componentType === "country") {
      countryShort = component.short_name;
      countryLong = component.long_name;
    }
  }

  const resAddress = {
    address1: address1,
    locality: locality,
    adminArea1Short: adminArea1Short,
    adminArea1Long: adminArea1Long,
    postalCode: postalCode,
    countryShort: countryShort,
    countryLong: countryLong,
    formatted: place.formatted_address,
  };

  return resAddress;
};

const supportedCountryCodeRestrictions = {
  country: ["us", "ca", "vi", "vg", "pr"],
};

const placesOptions = {
  componentRestrictions: supportedCountryCodeRestrictions,
  fields: ["address_component", "geometry", "formatted_address"],
  types: ["address"],
};

/**
 * Component for a magic google auto-completing address form.
 * @param arg.onLastFieldEntered In case you want to do something on last enter key
 * @param arg.addressRef A ref you can jump focus to
 * @returns a (set of fields for a) self-contained mini form
 */
export function AddressForm({
  onLastFieldEntered,
  addressRef,
  expanded,
  oneLine,
  // future expansion: don't build it til we need ti but this is how I picture doing an "address-only":
  // /** Hide unit number */
  // addressOnly = false,
  variant = "standard",
}: {
  onLastFieldEntered?: IfEnterKeyEventHandler;
  addressRef: MutableRefObject<HTMLInputElement>;
  expanded?: boolean;
  oneLine?: boolean;
  variant?: TextFieldVariants;
  // addressOnly?: boolean;
}) {
  const {
    control,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext<AddressInputs>();
  const { address, city, state, zipcode } = watch();

  const [unitRef, focusUnit] = useFocus();
  const [cityRef, focusCity] = useFocus();
  const [stateRef, focusState] = useFocus();
  const [zipRef, focusZip] = useFocus();

  const autoCompleteRef = React.useRef<google.maps.places.Autocomplete>();
  const currentAddressRef = addressRef.current;
  React.useEffect(() => {
    const autocomplete =
      currentAddressRef &&
      new window.google.maps.places.Autocomplete(
        currentAddressRef,
        placesOptions
      );

    if (autocomplete) {
      const handleAddressSelect = async () => {
        const completionResult = await getFullAddress(autocomplete);
        const addressLine = completionResult?.formatted || address || "";
        addressRef.current.value = addressLine;
        setValue("address", addressLine);
        setValue("city", completionResult?.locality || city || "");
        setValue("state", completionResult?.adminArea1Long || state || "");
        setValue("zipcode", completionResult?.postalCode || zipcode || "");
        onLastFieldEntered?.(dummyKeyboardEvent());
      };

      autocomplete &&
        autocomplete.addListener("place_changed", handleAddressSelect);
    }

    autoCompleteRef.current = autocomplete;
  }, [
    address,
    addressRef,
    city,
    currentAddressRef,
    onLastFieldEntered,
    setValue,
    state,
    zipcode,
  ]);

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item container direction="row" spacing={1}>
        <Grid item xs={10}>
          <Controller
            name="address"
            control={control}
            render={({ field: { ref, ...rest } }) => (
              <FormControl variant={variant} fullWidth>
                <Wrapper
                  render={renderGoogleNotReady}
                  apiKey={mapsApiKey}
                  version="weekly"
                  libraries={["places"]}
                >
                  <TextField
                    variant={variant}
                    fullWidth
                    required
                    id="address"
                    InputLabelProps={{ shrink: !!watch("address") }}
                    label="Project Address"
                    inputRef={multiRef([ref, addressRef])}
                    {...rest}
                    onKeyDown={ifEnterKey(focusUnit)}
                    helperText={errors[rest.name]?.message}
                    error={Boolean(errors[rest.name]?.message)}
                  />
                </Wrapper>
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xs={2}>
          <Controller
            name="unit"
            render={({ field: { ref, ...rest } }) => (
              <FormControl>
                <TextField
                  {...rest}
                  variant={variant}
                  label="Unit #"
                  inputRef={multiRef([ref, unitRef])}
                  helperText={errors[rest.name]?.message}
                  error={Boolean(errors[rest.name]?.message)}
                  onKeyDown={
                    oneLine
                      ? onLastFieldEntered
                        ? ifEnterKey(onLastFieldEntered)
                        : undefined
                      : ifEnterKey(focusCity)
                  }
                />
              </FormControl>
            )}
          />
        </Grid>
      </Grid>
      <Grid
        item
        container
        spacing={2}
        direction={expanded !== undefined ? "column" : "row"}
      >
        <Grid item hidden={oneLine}>
          <Controller
            name="city"
            control={control}
            render={({ field: { ref, ...rest } }) => (
              <FormControl variant={variant} fullWidth>
                <TextField
                  variant={variant}
                  fullWidth
                  required
                  id="city"
                  InputLabelProps={{ shrink: !!watch("city") }}
                  label="City"
                  inputRef={multiRef([ref, cityRef])}
                  {...rest}
                  onKeyDown={ifEnterKey(focusState)}
                  helperText={errors[rest.name]?.message}
                  error={Boolean(errors[rest.name]?.message)}
                />
              </FormControl>
            )}
          />
        </Grid>
        <Grid item hidden={oneLine}>
          <Controller
            name="state"
            control={control}
            render={({ field: { ref, ...rest } }) => (
              <FormControl variant={variant} fullWidth>
                <TextField
                  variant={variant}
                  fullWidth
                  required
                  id="state"
                  InputLabelProps={{ shrink: !!watch("state") }}
                  label="State"
                  inputRef={multiRef([ref, stateRef])}
                  {...rest}
                  onKeyDown={ifEnterKey(focusZip)}
                  helperText={errors[rest.name]?.message}
                  error={Boolean(errors[rest.name]?.message)}
                />
              </FormControl>
            )}
          />
        </Grid>
        <Grid item hidden={oneLine}>
          <Controller
            name="zipcode"
            control={control}
            render={({ field: { ref, ...rest } }) => (
              <FormControl variant={variant} fullWidth>
                <TextField
                  variant={variant}
                  fullWidth
                  required
                  id="zipcode"
                  InputLabelProps={{ shrink: !!watch("zipcode") }}
                  label="Zip Code"
                  inputRef={multiRef([ref, zipRef])}
                  {...rest}
                  onKeyDown={
                    onLastFieldEntered
                      ? ifEnterKey(onLastFieldEntered)
                      : undefined
                  }
                  helperText={errors[rest.name]?.message}
                  error={Boolean(errors[rest.name]?.message)}
                />
              </FormControl>
            )}
          />
        </Grid>
        <Grid item /* spacer */ />
      </Grid>
    </Grid>
  );
}

//spell-checker:ignore googlemaps
