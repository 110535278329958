import React from "react";
import { ReactSession } from "react-client-session";

export function initPrefs(): void {
  ReactSession.setStoreType("localStorage");
}

export function usePrefs<X>(path: string): [X, (x: X) => void] {
  const value = ReactSession.get(path);
  const [observable, setObservable] = React.useState(value);

  return [
    observable,
    (v: X) => {
      ReactSession.set(path, v);
      setObservable(v);
    },
  ];
}
