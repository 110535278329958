import { legacyBackendGetProjectDetailsResponse } from "@lib/APITypes";
import { styled } from "@mui/material/styles";
// import WarningIcon from "@mui/icons-material/Warning";
import React from "react";
import { Typography } from "@mui/material";
import { Unarray } from "@lib/misc";
import { HtmlTooltip } from "./HtmlTooltip";
import { LoanInfoTooltip } from "./LoanInfoTooltip";
import RedHealth from "../Assets/Images/redHealth.png";
import GreenHealth from "../Assets/Images/greenHealth.png";
import YellowHealth from "../Assets/Images/yellowHealth.png";
import WhiteHealth from "../Assets/Images/whiteHealth.png";

const HealthIcon = styled("img")(({ theme: { spacing } }) => ({
  width: spacing(22 / 8),
}));

type Health = legacyBackendGetProjectDetailsResponse["health"];
type HealthEntry = Unarray<Health>;

const noIssues: HealthEntry = {
  issue: {
    oneLine: "No issues detected",
  },
  severity: 0,
  color: "green",
};

export const HealthLabel: () => JSX.Element = () => {
  return <HealthIcon src={WhiteHealth} alt="white health icon" />;
};

export function HealthIndicator({
  health,
  projectId,
  hasLoan,
}: {
  health?: Health;
  projectId: string;
  hasLoan: boolean;
}): JSX.Element {
  const iconChooser = {
    red: (
      <div>
        <HealthIcon src={RedHealth} alt="red health icon" />
      </div>
    ),
    yellow: (
      <div>
        <HealthIcon src={YellowHealth} alt="yellow health icon" />
      </div>
    ),
    green: (
      <div>
        <HealthIcon src={GreenHealth} alt="green health icon" />
      </div>
    ),
  };

  if (!health) {
    return (
      <LoanInfoTooltip
        projectId={projectId}
        description="Health"
        hasLoan={hasLoan}
      >
        ---
      </LoanInfoTooltip>
    );
  }

  const effectiveHealth = health[0] || noIssues;
  const {
    issue: { title, desc, oneLine },
  } = effectiveHealth;
  return (
    <HtmlTooltip
      disableFocusListener
      disableTouchListener
      title={
        <React.Fragment>
          {title ? <Typography color="inherit">{title}</Typography> : null}
          {desc || oneLine}
        </React.Fragment>
      }
    >
      {iconChooser[effectiveHealth.color]}
    </HtmlTooltip>
  );
}
