import { EditTaskForm, EditTaskFormProps } from "@/Forms";
import { SlideUp } from "@/Lib/Transitions";
import { Invoice } from "@/Models";
import { Dialog, DialogTitle } from "@mui/material";
import React from "react";
import { useTaskFiles } from "../../../Lib/hooks/useTaskFiles";
import { usePermissions } from "@/Lib/hooks";
import { useEntityFeatures } from "@/Lib/hooks/useEntityFeatures";
import { useInvoiceAncestors } from "@/Lib/hooks/useProjectInvoices";
import { ProjectId } from "@project-centerline/project-centerline-api-types";

export const EditInvoiceLineItemDialog = ({
  done,
  invoice,
  item,
  projectId,
  onSubmit,
  taskTitleEditable,
}: {
  item?: Invoice["drawItems"][0];
  invoice?: Pick<Invoice, "invoice_id" | "is_put">;
  done: () => void;
  projectId: ProjectId;
  taskTitleEditable: boolean;
} & Pick<EditTaskFormProps, "onSubmit">) => {
  const { amount, task } = item ?? {};
  const existingFiles = useTaskFiles(task);
  const { restrictInspectors } = useEntityFeatures();
  const { canModifyDrawRequests } = usePermissions();
  const drawsModifiable = canModifyDrawRequests({ restrictInspectors });

  const { fetchInitialRequest } = useInvoiceAncestors({ projectId });
  const originalRequest =
    (task && invoice && fetchInitialRequest(invoice.invoice_id)(task)) ?? 0;

  return (
    <Dialog
      open={Boolean(task)}
      onClose={done}
      TransitionComponent={SlideUp}
      disableEscapeKeyDown
    >
      <DialogTitle>Item Details</DialogTitle>
      <EditTaskForm
        taskTitleEditable={taskTitleEditable}
        existingFiles={existingFiles}
        onCancel={done}
        onSubmit={onSubmit}
        taskData={task}
        disabled={!drawsModifiable}
        draw={
          invoice && { amountRequested: amount ?? 0, originalRequest, invoice }
        }
      />
    </Dialog>
  );
};

export default EditInvoiceLineItemDialog;
