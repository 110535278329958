import { useParams } from "react-router-dom";
import { BudgetTab } from "./BudgetTab";
import { throwIfNot } from "@lib/util/throwIfNot";

export const BudgetPage = () => {
  const projectId = throwIfNot(
    useParams<"projectId">().projectId,
    "Missing project id"
  );

  return <BudgetTab projectId={projectId} />;
};

export default BudgetPage;
