import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import PersonForm, { commonLayouts } from "../../../Forms/PersonForm";
import { InspectorInfo, useInspectors } from "@lib/hooks/useInspectors";
import { IfEnterKeyEventHandler } from "@lib/boilerplate";
import { sharedStyles } from "./AddUser";
import { AllPersonInputs } from "@lib/APITypes";
import { MakeEmailAddress } from "@project-centerline/project-centerline-api-types";

// TODO: Appropriate messaging in inspector form for automated

export function InspectorForm({
  onLastFieldEntered,
  onChange,
  variant,
}: {
  onLastFieldEntered?: IfEnterKeyEventHandler;
  onChange: (arg: InspectorInfo & { external: boolean }) => void;
  variant: "addUser" | "inspectorsAndOneClicks";
}): JSX.Element {
  const { availableInspectors = [], availableOneClicks = [] } = useInspectors();

  const bringYourOwnInspector = {
    short: "New",
    long: "You should never see this message",
    email: "",
  };

  const inspectorsAndOneClicks =
    variant === "addUser"
      ? availableOneClicks
          .map(({ short, long }) => ({ short, long }))
          .concat(availableInspectors)
      : availableInspectors
          .map(({ short, long }) => ({ short, long }))
          .concat(availableOneClicks);
  const [inspectorType, setInspectorType] = React.useState(
    variant === "addUser"
      ? bringYourOwnInspector.short
      : inspectorsAndOneClicks[0].short
  );

  const availableOptions: { short: string; long: string }[] = (
    variant === "addUser" ? [bringYourOwnInspector] : []
  )
    .map(({ short, long }) => ({ short, long }))
    .concat(inspectorsAndOneClicks);

  const currentOneClick = availableOneClicks?.find(
    ({ short }) => short === inspectorType
  );

  const formContext = useFormContext<AllPersonInputs>();
  const {
    formState: { errors },
    control,
    watch,
    reset,
  } = formContext || {};

  const email = watch?.("email");
  useEffect(() => {
    if (inspectorType) {
      reset?.();
    }
  }, [inspectorType, reset]);

  if (!formContext) {
    return <div />;
  }

  return (
    <div>
      <FormControl variant="standard" sx={sharedStyles.wide}>
        <InputLabel id="inspector-type-label">Inspector Type</InputLabel>
        <Select
          variant="standard"
          labelId="inspector-type-label"
          id="inspector-type"
          style={{ textAlign: "initial" }}
          value={inspectorType}
          onChange={({
            target: { value: newInspectorType },
          }: {
            target: { value: unknown };
          }) => {
            setInspectorType(newInspectorType as string);
            const selectedInspector = availableInspectors?.find(
              ({ short }) => short === newInspectorType
            );
            if (selectedInspector) {
              onChange({ ...selectedInspector, external: false });
            }
            const newOneClick = availableOneClicks?.find(
              ({ short }) => short === newInspectorType
            );
            if (newOneClick) {
              onChange({
                ...newOneClick,
                email,
                external: true,
              });
            }
            reset();
          }}
        >
          {availableOptions.map(({ short }) => (
            <MenuItem value={short} key={short}>
              {short}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {inspectorType === "New" ? (
        <PersonForm
          fields={commonLayouts.companyLast}
          header="Inspector Information"
          onLastFieldEntered={onLastFieldEntered}
        />
      ) : currentOneClick ? (
        <div>
          <h1>One-Click Inspection</h1>
          <Typography>
            {currentOneClick.long.concat(
              ` ${
                variant === "addUser"
                  ? "when this user is asked to review a draw, and approval automatically passes to the next-in-line"
                  : "via email"
              }. Enter your point of contact at ${currentOneClick.vendor}`
            )}
          </Typography>
          <Controller
            defaultValue={MakeEmailAddress(
              `${currentOneClick.prefix ?? ""}${currentOneClick.suffix}`
            )}
            name="email"
            control={control}
            render={({
              field: { value, onChange: onControlChange, name, ...rest },
            }) => {
              const variablePart = (v?: string) =>
                v?.slice(
                  // form gets fully formed email; we only display part
                  currentOneClick.prefix &&
                    v?.startsWith(currentOneClick.prefix)
                    ? currentOneClick.prefix?.length
                    : 0,
                  currentOneClick.suffix && v?.endsWith(currentOneClick.suffix)
                    ? -currentOneClick.suffix?.length
                    : undefined
                );

              return (
                <div
                  style={{
                    display: "flex",
                    alignItems: "baseline",
                    justifyContent: "center",
                  }}
                >
                  {currentOneClick.prefix}
                  <FormControl variant="standard" sx={sharedStyles.margin}>
                    <TextField
                      variant="standard"
                      {...rest}
                      value={variablePart(value)}
                      name={`${name}-part`}
                      onChange={(event) => {
                        const derived = MakeEmailAddress(
                          `${currentOneClick.prefix ?? ""}${variablePart(
                            event.target.value
                          )}${currentOneClick.suffix}`
                        );
                        onControlChange({
                          ...event,
                          // form gets fully formed email; we only display part
                          target: {
                            ...event.target,
                            value: derived,
                          },
                        });
                        // fuck this is getting ugly
                        onChange({
                          ...currentOneClick,
                          email: derived,
                          external: true,
                          first_name: `${currentOneClick.vendor} One-Click Inspection`,
                          last_name: `(${variablePart(event.target.value)})`,
                        });
                      }}
                      required
                      fullWidth
                      // label="Contact"
                      // placeholder="email"
                      autoFocus
                      helperText={errors.email?.message}
                      error={Boolean(errors.email)}
                      inputProps={{
                        style: { textAlign: "right" },
                      }}
                    />
                  </FormControl>
                  {currentOneClick?.suffix}
                </div>
              );
            }}
          />
          <Controller
            defaultValue={currentOneClick.first_name}
            name="first_name"
            control={control}
            render={({ field }) => <input type="hidden" {...field} />}
          />
        </div>
      ) : (
        <div>
          <h1>
            {availableInspectors?.find(({ short }) => short === inspectorType)
              ?.heading || "Concierge Inspection"}
          </h1>
          <Typography>
            {availableInspectors
              ?.find(({ short }) => short === inspectorType)
              ?.long.concat(" when this user is asked to review a draw.")}
          </Typography>
        </div>
      )}
    </div>
  );
}
