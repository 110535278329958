import React, { useState } from "react";
import {
  Navigate,
  Outlet,
  Route,
  IndexRouteObject,
  NonIndexRouteObject,
  Routes,
  useMatches,
  useNavigation,
  useParams,
  useSearchParams,
  useLocation,
  Link,
} from "react-router-dom";

import AuthenticatedRoute from "./Components/AuthenticatedRoute"; //} // AdminRoute, // , {

import Home from "./Pages/Dashboard/Home";
import Login from "./Pages/Login";
import QuickSetup from "./Pages/QuickSetup";
import LenderSetup from "./Pages/LenderSetup";
import LenderAdminSetup from "./Pages/LenderAdminSetup";
import { default as ProjectView } from "./Pages/ProjectView";
import { AdminPage } from "./Pages/Admin/Admin";
// import { EntityEditor } from "./Pages/Admin/EntityEditor";
import { EntityList } from "./Pages/Admin/EntityList";
import { useAppContext } from "@lib/UserContext";
import { Role } from "@lib/roles";
import { DispatchInspection } from "./Pages/DispatchInspection";
import FileRedirector from "./Pages/FileRedirector";
import { UserList } from "./Pages/Admin/UserList";
import ErrorPage from "./Pages/ErrorPage";
import EditEntity from "./Pages/Admin/EditEntity";
import CreateEntity from "./Pages/Admin/CreateEntity";
import NotFound from "./Pages/NotFound";
// import Search from "./Pages/ProjectView/Search/Search";
//import NotFound from "./containers/NotFound";

import brandDark from "@/Assets/Images/logo_house_pc_letters_only.svg";
import brandWhite from "@/Assets/Images/logo_house_pc_letters_only.svg";

import Sidenav, { RouteEntry } from "@tim/examples/Sidenav"; // spell-checker:word sidenav
import { useMaterialUIController, setMiniSidenav } from "@tim/context";
import DashboardLayout from "@tim/examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "@tim/examples/Navbars/DashboardNavbar";
import { loadProject } from "@lib/hooks/useProjectDetails";
import CreateDrawRequestPage from "./Pages/ProjectView/Invoice/CreateDrawRequestPage";
import PendingDrawsPage from "./Pages/ProjectView/Invoice/PendingDrawsPage";
import ApprovedDrawsPage from "./Pages/ProjectView/Invoice/ApprovedDrawsPage";
import RejectedDrawsPage from "./Pages/ProjectView/Invoice/RejectedDrawsPage";
import BudgetPage from "./Pages/ProjectView/Info/BudgetPage";
import LoanInfoPage from "./Pages/ProjectView/Info/LoanInfoPage";
import PropertyInfoPage from "./Pages/ProjectView/Info/PropertyInfoPage";
import UsersPage from "./Pages/ProjectView/Info/UsersPage";
import ProjectReportingPage from "./Pages/ProjectView/Info/ProjectReportingPage";
import ProjectFilesPage from "./Pages/ProjectView/Info/ProjectFilesPage";
import { canEditInvoiceRouting } from "@lib/hooks";
import ProjectInvoiceRoutingPage from "./Pages/ProjectView/Info/ProjectInvoiceRoutingPage";
import { Project } from "@project-centerline/project-centerline-api-types";
// import ScienceIcon from "@mui/icons-material/Science";
// import { Box } from "@mui/material";
import Logout from "./Pages/Logout";
import { requireAuthenticatedUser, useAuth } from "./Lib/hooks/useAuth";
import Configurator from "@tim/examples/Configurator";
import { AdminView } from "./Pages/ProjectView/Admin/ProjectAdminView";
import { legacyBackendGetProjectDetailsResponse } from "./Lib/APITypes";
import { ProfilePage } from "./Pages/ProfilePage";
import { LinearProgress, Typography } from "@mui/material";
import { useGetUserRole } from "./Lib/hooks/useGetUserRole";
import { CrumbFunction } from "@tim/examples/Breadcrumbs";
import { CreateProjectPage } from "./Pages/CreateProjectPage";
import { AdminViewContainer } from "./Components/AdminViewContainer";
// import CreativeTimNotificationItem from "@tim/examples/Items/NotificationItem";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import { CreateProjectFromInspectionPage } from "./Pages/CreateProjectFromInspectionPage";
import { CreateProjectCheckInPage } from "./Pages/CreateProjectCheckInPage";
import NewRequestPage from "./Pages/NewRequestPage";
import { useAdminData } from "./Lib/AdminContext";
// import { groupBy } from "./Lib/util/groupBy";
// import { useProjectList } from "./Lib/hooks/useProjectList";
// import { byProp } from "./Lib/misc";
// import type { Notification } from "@lib/APITypes";
// import { groupBy } from "./Lib/util/groupBy";
import config from "./config";

/**
 * Where to go when someone clicks the home icon or PC logo etc.
 * Typically we would get here via redirects anyway but going direct is faster and avoids flashes
 */
const home = "/portfolio/todo";

type RouteObject =
  | IndexRouteObject
  | (Omit<NonIndexRouteObject, "handle" | "children"> & {
      // Omit<ReactRouterRouteObject, "handle"> & {
      handle?: string | HandleStuff;
      children?: Array<RouteObject>;
    });

function RequireRole({ role: requiredRole }: { role: Role }) {
  const { role } = useAppContext();
  return role !== requiredRole ? <Navigate replace to="/401" /> : <Outlet />;
}

const AdminRoute = ({ prefix }: { prefix: string }) => (
  <AuthenticatedRoute prefix={prefix}>
    <RequireRole role={Role.SuperAdmin} />
  </AuthenticatedRoute>
);

const timPortfolioRoutes: (arg: {
  expanded: boolean;
}) => CreativeTimRouteEntry[] = ({ expanded }) => [
  // Nav Container for new UI
  {
    type: "collapse",
    name: "Create",
    key: "create",
    // styleAsTitle: "open", allows for container to be collapseable instead of listed all of the time
    title: "Create",
    collapse: [
      {
        name: "Project",
        key: "create-project",
        route: "/portfolio/new",
        component: <CreateProjectPage />,
        icon: <AddCircleOutlineIcon fontSize="large" />,
        type: "collapse",
        noCollapse: true,
        visibleTimRoutes: () => timPortfolioRoutes({ expanded: true }),
      },
      {
        name: "Inspection",
        key: "create-inspection",
        route: "/portfolio/new-inspection",
        component: <CreateProjectFromInspectionPage />,
        icon: <AddCircleOutlineIcon fontSize="large" />,
        type: "collapse",
        noCollapse: true,
        visibleTimRoutes: () => timPortfolioRoutes({ expanded: true }),
        // routerChildren: [
        //   {
        //     index: true,
        //     element: <Navigate to="todo" replace />,
        //   },
        // ],
      },
      {
        name: "Check In",
        key: "create-check-in",
        route: "/portfolio/new-checkin", // spell-checker:ignore checkin
        component: <CreateProjectCheckInPage />,
        icon: <AddCircleOutlineIcon fontSize="large" />,
        type: "collapse",
        noCollapse: true,
        visibleTimRoutes: () => timPortfolioRoutes({ expanded: true }),
      },
      {
        name: "Budget Review",
        key: "create-Budget-Review",
        route: "/portfolio/new-Budget-Review",
        type: "collapse",
        noCollapse: true,
        visibleTimRoutes: () => timPortfolioRoutes({ expanded: true }),
      },
      {
        name: "New Request",
        key: "create-New-Request",
        route: "/portfolio/NewRequest",
        component: <NewRequestPage />,
        icon: <AddCircleOutlineIcon fontSize="large" />,
        type: "collapse",
        noCollapse: true,
        visibleTimRoutes: () => timPortfolioRoutes({ expanded: true }),
      },
    ],
  },
  {
    type: "collapse",
    name: "Portfolio",
    key: "portfolio",
    route: "portfolio",
    styleAsTitle: expanded ? "open" : "closed",
    title: "Portfolio",
    icon: "yo I need icon",
    visibleTimRoutes: () => timPortfolioRoutes({ expanded: true }),
    collapse: [
      {
        name: "To Do",
        key: "todo",
        route: "/portfolio/todo",
        component: <Home portfolio view="todo" />,
      },
      {
        name: "Draw Pending",
        key: "pending",
        route: "/portfolio/pending",
        component: <Home portfolio view="pending" />,
      },
      {
        name: "Active",
        key: "active",
        route: "/portfolio/active",
        component: <Home portfolio view="active" />,
      },
      {
        name: "Delayed",
        key: "delayed",
        route: "/portfolio/delayed",
        component: <Home portfolio view="delayed" />,
      },
      {
        name: "All",
        key: "all",
        route: "/portfolio/all",
        component: <Home portfolio view="all" />,
      },
      {
        name: "Completed",
        key: "completed",
        route: "/portfolio/completed",
        component: <Home portfolio view="complete" />,
      },

      // { type: "divider", key: "before-cards" },
      // {
      //   name: "Cards",
      //   key: "cards",
      //   route: "/portfolio/cards",
      //   component: <Home cards />,
      // },
    ],
    routerChildren: [
      {
        index: true,
        element: <Navigate to="todo" replace />,
      },
    ],
  },
];

// Tab With DropDown for Routes when Viewing a project
const timProjectRoutes: (role?: Role) => Array<CreativeTimRouteEntry> = (
  role
) => [
  { type: "divider", key: "divider-projects", name: "divider-projects" },
  {
    type: "title",
    title: "Project",
    key: "title-project",
    name: "title-projects",
  },
  {
    key: "draws",
    name: "Draws",
    route: "draws",
    type: "collapse",
    // component: <Navigate to="pending" replace />,
    icon: <MonetizationOnOutlinedIcon fontSize="medium" />,
    crumb: ({ pathname }) => ({
      text: "Draws",
      link: pathname,
    }),
    collapse: [
      {
        name: "New",
        key: "new",
        route: "new",
        component: <CreateDrawRequestPage />,
        // crumb: () => ({
        //   link: "hmm what to put here",
        //   text: "New Draw",
        // }),
      },
      {
        name: "Pending",
        key: "pending",
        route: "pending",
        component: <PendingDrawsPage />,
        // crumb: ({ data }: { data: Project }) => ({
        //   link: "hmm what to put here",
        //   text: "Pending Draws",
        // }),
      },
      {
        name: "Approved",
        key: "approved",
        route: "approved",
        component: <ApprovedDrawsPage />,
        // crumb: () => ({
        //   link: "hmm what to put here",
        //   text: "Approved Draws",
        // }),
      },
      {
        name: "Rejected",
        key: "rejected",
        route: "rejected",
        component: <RejectedDrawsPage />,
        // crumb: () => ({
        //   link: "hmm what to put here",
        //   text: "Rejected Draws",
        // }),
      },
    ],
    routerChildren: [
      {
        index: true,
        element: <Navigate to="pending" replace />,
      },
    ],
  },
  // More Routes Page, went ahead and attached to existing routes with new tab and dropdown
  {
    key: "more",
    name: "More",
    route: "more",
    type: "collapse",
    // component: <Navigate to="pending" replace />,
    crumb: ({ pathname }) => ({
      text: "Draws",
      link: pathname,
    }),
    collapse: [
      {
        name: "Budget",
        key: "Budget",
        route: "budget",
        component: <BudgetPage />,
      },
      {
        name: "Loan Info",
        key: "LoanInfo",
        route: "loanInfo",
        component: <LoanInfoPage />,
      },
      {
        name: "Property Info",
        key: "PropertyInfo",
        route: "propertyInfo",
        component: <PropertyInfoPage />,
      },
      {
        name: "Users",
        key: "Users",
        route: "users",
        component: <UsersPage />,
      },
      {
        name: "Reporting",
        key: "Reporting",
        route: "reporting",
        component: <ProjectReportingPage />,
      },
      {
        name: "Files",
        key: "Files",
        route: "files",
        component: <ProjectFilesPage />,
      },
    ].concat(
      !(role && !canEditInvoiceRouting(role))
        ? [
            {
              key: "routing",
              name: "Default Routing",
              route: "routing",
              component: <ProjectInvoiceRoutingPage />,
              // crumb: () => ({
              //   link: "hmm what to put here",
              //   text: "Edit Default Draw Routing",
              // }),
            },
          ]
        : []
    ),
    routerChildren: [
      {
        index: true,
        element: <Navigate to="pending" replace />,
      },
    ],
  },
];

// interface CreativeTimRouteObject {
//   name: string;
//   type?: "collapse" | "title" | "divider";
//   icon?: string | JSX.Element;
//   collapse?: CreativeTimRouteObject[];
//   route?: string;
//   component?: React.ReactElement;
//   key: React.Key;
// }
interface HandleStuff {
  tag?: string;
  desc?: string;
  crumb?: CrumbFunction;
  visibleTimRoutes?: RoutesFunction;
}

type CreativeTimRouteEntry = Omit<RouteEntry, "collapse"> & {
  routerChildren?: NonIndexRouteObject["children"];
  collapse?: CreativeTimRouteEntry[];
} & HandleStuff;

const routeObjectsFromTim: (
  allRoutes: CreativeTimRouteEntry[]
) => RouteObject[] = (allRoutes) =>
  allRoutes.map((route) => {
    return {
      handle: {
        desc: `created from tim route`,
        crumb: () => ({
          link: ".",
          text: route.name,
        }),
        ...route,
      },
      path: route.route,
      element: route.component,
      key: route.key,
      children: (
        (route.collapse && routeObjectsFromTim(route.collapse)) ??
        []
      ).concat(route.routerChildren ?? []), //.concat(route.children ?? []),
    } as RouteObject;
  });

/* You might use this if you wanted to use tim routes with [non data router](https://reactrouter.com/en/main/routers/picking-a-router): */
// const expandTimRouteObjects: (
//   allRoutes: CreativeTimRouteObject[]
// ) => unknown = (allRoutes) =>
//   allRoutes.map((route) =>
//     route.collapse ? (
//       expandTimRouteObjects(route.collapse)
//     ) : route.route ? (
//       <Route path={route.route} element={route.component} key={route.key} />
//     ) : null
//   );

const SelfManagedSidenav = ({ routes }: { routes: Array<RouteEntry> }) => {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    // direction,
    // layout,
    // openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  return (
    <>
      <Sidenav
        brandName="Project Centerline"
        routes={routes}
        brand={
          (transparentSidenav && !darkMode) || whiteSidenav
            ? brandDark
            : brandWhite
        }
        color={sidenavColor}
        onMouseEnter={handleOnMouseEnter}
        onMouseLeave={handleOnMouseLeave}
        home={home}
      />
      <Configurator />
    </>
  );
};

const DashboardNavbarWithUser = () => {
  const { currentUserEmail } = useAuth();

  return (
    <>
      {/* <div id="prefetch" style={{ display: "none" }}>
        <NotificationsMenu open={false} />
      </div> */}
      <DashboardNavbar
        userName={currentUserEmail}
        userProfileRoute={"/profile"}
        home={home}
        // notificationCount={notifications.length}
        // NotificationsMenu={NotificationsMenu}
      />
    </>
  );
};

type RoutesFunction = (arg: {
  match: ReturnType<typeof useMatches>[0];
  matches: ReturnType<typeof useMatches>;
  role?: Role;
}) => Array<RouteEntry>;

const SuperSidenav = () => {
  const matches = useMatches();
  const { user: { role } = {} } = useGetUserRole();

  /* the thing to remember here is that we are setting up what routes are *visible* here, not what are reachable */
  // console.log("super sidenav", { matches, match: matches.slice(-1)[0] });
  const routes = matches
    .filter(({ handle }) => handle && (handle as HandleStuff).visibleTimRoutes)
    .flatMap(
      (match) =>
        (match.handle as HandleStuff).visibleTimRoutes?.({
          role,
          match,
          matches,
        }) ?? []
    );

  return <SelfManagedSidenav routes={routes} />;
};

const BackwardCompatiblePortfolioNav = () => {
  const { "*": splat } = useParams();
  const [search] = useSearchParams();
  const location = useLocation();
  const { pathname } = location;
  const crashVideo = React.useMemo(
    () =>
      location &&
      ((r: number) =>
        (r < 0.33 && "https://giphy.com/embed/5xaOcLQoRXgv1d8bsSQ") ||
        (r < 0.67 && "https://giphy.com/embed/MVgLEacpr9KVK172Ne") ||
        "https://giphy.com/embed/853jNve3ljqrYrcSOK")(Math.random()),
    [location]
  );
  // console.log("backward", { splat, search });
  if (splat === "portfolio") {
    const destination = `/portfolio/${search}`;
    // console.log({ destination });
    return <Navigate replace to={destination} />;
  }

  // return <Navigate to="/portfolio/todo" />;
  return (
    <NotFound>
      {config.nonProdEnv ? (
        <>
          <Typography>
            If you are John or a dev and think that this route should work, or
            you came from something that should have given you a valid link,
            please{" "}
            <Link
              to={`https://trello.com/add-card?source=${encodeURIComponent(
                pathname
              )}&mode=popup&url=${encodeURIComponent(
                location.pathname
              )}&name=${encodeURIComponent(
                `Broken link: "${pathname}"`
              )}&idBoard=5faed9ae13713c07c37bddcc`}
              target="_blank"
              rel="noopener noreferrer"
            >
              create a card
            </Link>
          </Typography>
          <iframe
            src={crashVideo}
            width="480"
            height="293"
            frameBorder="0"
            allowFullScreen
            title="oh snap"
          ></iframe>
        </>
      ) : null}
    </NotFound>
  );
};

const logoutRoute = "auth/logout";
const timProfileRoutes: Array<CreativeTimRouteEntry> = [
  {
    key: "profile",
    name: "Profile",
    component: <ProfilePage />,
    route: "profile",
  },
];

const MaybeAdminView = () => {
  const matches = useMatches();
  const project = matches.find(({ id }) => id === "projectview")
    ?.data as legacyBackendGetProjectDetailsResponse;
  const { showAdmin, requestAdminView } = useAppContext();
  const { adminData, adminChildren } = useAdminData();

  return showAdmin ? (
    <AdminViewContainer
      initialPosition="bottom"
      isVisible={showAdmin}
      onVisibleChange={() => requestAdminView(!showAdmin)}
      dimMode="transparent"
    >
      <AdminView
        project={project}
        {...matches.reduce((admins, { handle }) => {
          const admin = (handle as { admin?: Record<string, unknown> }).admin;
          return admin ? { ...admins, ...admin } : admins;
        }, {} as Record<string, unknown>)}
        routes={routes()}
        matches={matches}
        {...adminData}
      >
        {Object.entries(adminChildren).map(([key, node]) => node)}
      </AdminView>
    </AdminViewContainer>
  ) : null;
};

const LoadingProgress = () => {
  return useNavigation().state === "loading" ? <LinearProgress /> : null;
};

const prefixRoutes: (
  r: Array<CreativeTimRouteEntry>,
  pathname: string
) => Array<CreativeTimRouteEntry> = (r, pathname) =>
  r.map(({ route, collapse, ...rest }) => ({
    ...rest,
    collapse: collapse && prefixRoutes(collapse, `${pathname}/${route}`),
    route: route && `${pathname}/${route}`,
  }));

export const routes: () => Array<
  IndexRouteObject | NonIndexRouteObject
> = () => {
  return [
    {
      errorElement: <ErrorPage />,
      handle: "error page layout route",
    },
    { index: true, element: <Navigate to="portfolio" />, handle: "index" },

    /* public routes */
    { path: "login", handle: "login", element: <Login /> },
    {
      path: "newuser",
      handle: "newuser",
      element: <Login newUserMode />,
    },
    {
      path: "dispatchinspector",
      handle: "dispatchinspector",
      element: <DispatchInspection />,
    },

    /* everything else authenticated */
    {
      element: <AuthenticatedRoute prefix="/new" />,
      // loader: () => useGetUserRole(),
      id: "authenticated",
      handle: {
        tag: "authenticated new layout route",
        routes: () => timProfileRoutes,
      },
      children: [
        {
          path: "quicksetup",
          element: <Navigate to="/portfolio/new" replace />,
        },
        {
          element: (
            <>
              <SuperSidenav />
              <DashboardLayout>
                <DashboardNavbarWithUser />
                <Outlet />
                <LoadingProgress />
                <MaybeAdminView />
              </DashboardLayout>
            </>
          ),
          handle: {
            tag: "self managed sidenav layout",
            // routes: () => timPortfolioRoutes,
            // crumb: () => ({
            //   link: ".",
            //   text: "Portfolio",
            // }),
          },
          // path: "portfolio/*",
          children: [
            ...routeObjectsFromTim(
              timProfileRoutes.concat(timPortfolioRoutes({ expanded: false }))
            ).map((route) => ({
              ...route,
              // handle: {
              //   ...route.handle,
              //   crumb: () => ({ link: ".", text: route.handle?.name ?? "" }),
              // },
            })),
            {
              path: "portfolio/cards",
              element: <Home cards />,
              handle: "cards",
              crumb: () => ({
                link: "hmm what to put here",
                text: "Card View",
              }),
            },
            // {
            //   path: "profile",
            //   handle: "Profile",
            //   element: <ProfilePage />,
            //   crumb: () => ({
            //     link: "profile",
            //     text: "Profile",
            //   }),
            // },
            {
              path: "projectview",
              // element: (
              //   <div>
              //     <SideNavWithProject />
              //     <Outlet />
              //   </div>
              // ),
              handle: "projectview",
              children: [
                {
                  id: "projectview",
                  path: ":projectId",
                  loader: ({ params }) =>
                    /*
                     * loaders are eager (https://github.com/remix-run/react-router/discussions/9327) even though
                     * our component will refuse to render, so if we're not ready just fail early,
                     * quietly, with garbage
                     */
                    requireAuthenticatedUser()
                      .then(() => loadProject(params))
                      .catch((err) => {
                        if (err === "The user is not authenticated") {
                          console.log("not authenticated yet; skipping loader");
                          return {};
                        }
                        throw err;
                      }),
                  handle: {
                    desc: "project detail",
                    visibleTimRoutes: ({
                      role,
                      match: { pathname },
                    }: {
                      match: ReturnType<typeof useMatches>[0];
                      matches: ReturnType<typeof useMatches>;
                      role?: Role;
                    }) => {
                      return timPortfolioRoutes({ expanded: false }).concat(
                        prefixRoutes(timProjectRoutes(role), pathname)
                      );
                    },
                    crumb: ({ data }: { data: Project }) => {
                      const { name, project_id } = data as Project;
                      return {
                        link: `projectview/${project_id}`,
                        text: name,
                      };
                    },
                  },
                  // /:topic
                  children: routeObjectsFromTim(timProjectRoutes())
                    .map((route) => ({ ...route }))
                    .concat([
                      {
                        index: true,
                        element: <Navigate to="draws" replace />,
                      },
                      // {
                      //   path: "*",
                      //   handle: "not found under projectview w/id",
                      // },
                      // Need redirect to new route
                      {
                        path: "Tasks",
                        element: (
                          <Navigate
                            to="../draws/pending"
                            replace
                            relative="path"
                          />
                        ),
                        handle: "backward compatibility for /Tasks",
                      },
                      {
                        path: "Budget",
                        element: (
                          <Navigate
                            to="../more/budget"
                            replace
                            relative="path"
                          />
                        ),
                        handle: "backward compatibility for /Budget",
                      },
                    ]),
                },
              ],
            },

            {
              element: (
                <div>
                  {/* <Typography>Backward Compatibility</Typography> */}
                  <BackwardCompatiblePortfolioNav />
                </div>
              ),
              path: "*",
              handle: "no match under new",
            },

            { path: "lendersetup", element: <LenderSetup /> },
            { path: "lenderadminsetup", element: <LenderAdminSetup /> },

            {
              path: "admin",
              element: <AdminRoute prefix="" />,
              children: [
                { path: "", element: <AdminPage /> },
                { path: "entity", element: <EntityList /> },
                { path: "entity/:entityId", element: <EditEntity /> },
                { path: "entity/new", element: <CreateEntity /> },
                { path: "users/*", element: <UserList /> },
                /* <AdminRoute path: "entity/:id">
    <EntityList />
  </AdminRoute>
  <EntityList /> */
              ],
            },
            // { path: "*", element:<NotFound/>, handle:"not found"},
            /* {>
    <NotFound />
  </Route> */
            { path: "file", element: <FileRedirector /> },
            { path: logoutRoute, element: <Logout /> },

            // compatibility routes new/old UI
            {
              path: "portfolio?view=active",
              element: <Navigate replace to={"/portfolio/active"} />,
            },
          ],
        },
      ],
    },
  ];
};

export default function RoutesComponent(): JSX.Element {
  return (
    <Routes>
      <Route errorElement={<ErrorPage />} handle="error page layout route">
        {/* public */}
        <Route path="login" handle="login" element={<Login />} />
        <Route
          path="/newuser"
          handle="newuser"
          element={<Login newUserMode />}
        />
        <Route
          path="/dispatchinspector"
          handle="dispatchinspector"
          element={<DispatchInspection />}
        />

        {/* everything else authenticated */}
        <Route
          element={
            <AuthenticatedRoute
              prefix=""
              handle="authenticated layout route old school"
            />
          }
        >
          <Route
            path="/"
            element={<Navigate replace to="/portfolio" />}
            errorElement={<ErrorPage />}
            handle="/"
          />
          <Route path="portfolio" element={<Home portfolio />}></Route>
          {/* Compatibility routes for switching between new and old: */}
          <Route
            path="portfolio/todo"
            element={<Navigate to={"/portfolio?view=todo"} replace />}
          ></Route>{" "}
          <Route
            path="portfolio/pending"
            element={<Navigate to={"/portfolio?view=pending"} replace />}
          ></Route>{" "}
          <Route
            path="portfolio/active"
            element={<Navigate to={"/portfolio?view=active"} replace />}
          ></Route>{" "}
          <Route
            path="portfolio/delayed"
            element={<Navigate to={"/portfolio?view=delayed"} replace />}
          ></Route>{" "}
          <Route
            path="portfolio/completed"
            element={<Navigate to={"/portfolio?view=completed"} replace />}
          ></Route>
          <Route
            path="dashboard"
            element={<Navigate replace to="/portfolio" />}
          ></Route>
          <Route path="cards" element={<Home cards />}></Route>
          <Route path="lendersetup" element={<LenderSetup />} />
          <Route path="lenderadminsetup" element={<LenderAdminSetup />} />
          <Route path="quicksetup" element={<QuickSetup />} />
          <Route path="projectview/:projectId/*" element={<ProjectView />}>
            <Route
              path=":topic/*"
              element={/* hack handled by ProjectView */ null}
            >
              <Route
                path=":subTopic/*"
                element={/* hack handled by ProjectView */ null}
              ></Route>
            </Route>
          </Route>
          <Route path="admin" element={<AdminRoute prefix="" />}>
            <Route path="" element={<AdminPage />} />
            <Route path="entity" element={<EntityList />} />
            <Route path="entity/:entityId" element={<EditEntity />} />
            <Route path="entity/new" element={<CreateEntity />} />
            <Route path="users/*" element={<UserList />} />
            {/* <AdminRoute path="entity/:id">
        <EntityList />
      </AdminRoute> */}
            {/* <Route>
        <NotFound />
      </Route> */}
          </Route>
          <Route path="/file" element={<FileRedirector />} />
        </Route>
        {/* <Route exact path="/signup">
        <Login signupMode />
      </Route> */}
        {/* <Route
        path={["/dashboard"].concat(HomeRoutes)}
        element={
          <AuthenticatedRoute>
            <Home />
          </AuthenticatedRoute>
        }
      /> */}
        {/* <AuthenticatedRoute roleMustBeNull exact path="/firstTimeLogin">
        <LenderSetup firstTimeLoginMode />
      </AuthenticatedRoute> */}

        {/* <AuthenticatedRoute
        path="/search"
        element={
          {
            <Search />
          }
        }
      /> */}

        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
}

// spell-checker:ignore newuser dispatchinspector
