import { Grid, GridSpacing } from "@mui/material";
import React from "react";

export type TileContainerProps = {
  children?: React.ReactNode;
  minWidth?: number;
  spacing?: GridSpacing;
};
export const TileContainer: ({
  children,
  minWidth,
}: TileContainerProps) => JSX.Element = ({ children, minWidth, spacing }) => {
  return (
    <Grid
      sx={({ spacing }) => ({
        margin: spacing(1),
        minWidth,
        flexGrow: 1,
      })}
      container
      wrap="nowrap"
      justifyContent="space-evenly"
      alignItems="center"
      // className={classes.root}
      spacing={spacing}
    >
      {children}
    </Grid>
  );
};
