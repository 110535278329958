import { ProviderContext } from "notistack";
import { BackendUserResponse } from "@lib/APITypes";
import { Role } from "@lib/roles";

type OnFail = ProviderContext["enqueueSnackbar"]; // suspiciously like enqueueSnackbar :-) but no promises

export const isOneClick = ({
  tags,
}: Pick<BackendUserResponse, "tags">): boolean => tags.includes("one-click");

export function validateInvoiceRouting(
  newRouting: readonly BackendUserResponse[],
  projectUsers: readonly BackendUserResponse[],
  onFail: OnFail
): boolean {
  const oneClickParticipant = newRouting.find(isOneClick);
  if (oneClickParticipant) {
    const owner = projectUsers.find(({ role }) => role === Role.Owner);
    if (!owner) {
      onFail(
        "One-Click inspectors can only be used if the project has a Borrower (so we know who to tell the inspector to contact)",
        { variant: "warning" }
      );
      return false;
    }
  }

  const last = newRouting[newRouting.length - 1];
  if (isOneClick(last)) {
    onFail(
      "One-Click inspectors cannot be last in the approval order, because they need someone to send results to.",
      { variant: "warning" }
    );
    return false;
  }

  return true;
}
