import { zodResolver } from "@hookform/resolvers/zod";
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  FormControlLabel,
  Typography,
} from "@mui/material";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import PersonForm, {
  commonLayouts,
  PossibleFields,
  PersonWrapper,
} from "../../../Forms/PersonForm";
import { AllPersonInputs, AllPersonInputsSchema } from "@lib/APITypes";
import { logError } from "@lib/ErrorLogging";
import { useFocus, usePermissions } from "@lib/hooks";
import { InspectorInfo } from "@lib/hooks/useInspectors";
import { useProjectUsers } from "@lib/hooks/useProjectUsers";
import { roleAttributes } from "@lib/RoleDisplay";
import { Role } from "@lib/roles";
import { useSnackbar } from "notistack";
import { IfEnterKeyEventHandler } from "@lib/boilerplate";
import { InspectorForm } from "./InspectorForm";
import { EmailAddress } from "@project-centerline/project-centerline-api-types";

export const sharedStyles = {
  wide: {
    width: "100%",
  },
  margin: {
    marginTop: "20px",
  },
};

function UserEditor({
  projectId,
  role,
  onClose,
  onLastFieldEntered,
  setLoading,
  external,
}: {
  projectId: string;
  role: Role;
  onClose: () => void;
  onLastFieldEntered: IfEnterKeyEventHandler;
  setLoading: (loading: boolean) => void;
  external: boolean;
}): JSX.Element {
  const {
    project: { addUser },
  } = useProjectUsers(projectId);

  const { enqueueSnackbar } = useSnackbar();

  // function InspectorChooser(): JSX.Element {
  const [cannedInspector, setCannedInspector] = React.useState<
    (InspectorInfo & { external: boolean }) | undefined
  >(undefined);

  // TODO: consider refactoring roleProps for ODR
  const roleProps: Partial<
    Record<
      Role,
      {
        fields?: PossibleFields[];
        customForm?: <P extends Record<string, unknown>>(
          props: P
        ) => JSX.Element;
      }
    >
  > = {
    "Super Admin": { fields: [] },
    Lender: { fields: commonLayouts.companyFirst },
    LenderAdmin: { fields: commonLayouts.companyFirst },
    Contractor: { fields: commonLayouts.companyFirst },
    Subcontractor: { fields: commonLayouts.companyFirst },
    InspectorAdmin: { fields: commonLayouts.companyLast },
    Inspector: {
      fields: commonLayouts.companyLast,
      customForm: (props) => (
        <PersonWrapper>
          <InspectorForm
            variant="addUser"
            {...props}
            onLastFieldEntered={onLastFieldEntered}
            onChange={setCannedInspector}
          />
        </PersonWrapper>
      ),
    },
  };

  const { customForm, ...formProps } = roleProps[role] || {};
  const fieldsToUse = formProps?.fields || commonLayouts.default;
  const formMethods = useForm<AllPersonInputs>({
    defaultValues: Object.fromEntries(
      fieldsToUse.map((field) => [
        field,
        cannedInspector?.[field as keyof InspectorInfo] ?? "",
      ])
    ),
    resolver: zodResolver(
      AllPersonInputsSchema.pick(
        fieldsToUse.reduce(
          (result, curr) => ({ ...result, [curr]: true }),
          {} as Record<PossibleFields, true>
        )
      )
    ),
  });

  const handleSubmit = (values: AllPersonInputs) => {
    setLoading(true);

    addUser({
      external,
      ...((role === Role.Inspector && cannedInspector) || values),
      email: values.email.trim().toLowerCase() as EmailAddress,
      role,
    })
      .then(() => {
        handleClose();
      })
      .catch((error) => {
        logError(error);
        enqueueSnackbar(`Could not add user. ${error.message}`, {
          variant: "error",
        });
      })
      .finally(() => {
        setLoading(false);
        setCannedInspector(undefined);
      });
  };

  const handleClose = () => {
    formMethods.reset();
    onClose();
  };

  return (
    <div
      style={{
        marginTop: "20px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <FormProvider {...formMethods}>
        <form
          id="add-user-form"
          onSubmit={formMethods.handleSubmit(handleSubmit, (foo) => {
            // TODO: how hacky is this? We are using the error to submit. It probably blows up on invalid emails
            if (cannedInspector) {
              handleSubmit({
                ...cannedInspector,
                business_license: "",
              });
            }
          })}
        >
          <div>
            {customForm ? (
              customForm(formMethods)
            ) : (
              <PersonForm
                {...formProps}
                onLastFieldEntered={onLastFieldEntered}
              />
            )}
          </div>
        </form>
      </FormProvider>
    </div>
  );
}

const noRoleYet: unique symbol = Symbol();

export default function AddUser({
  callBack,
  ...props
}: {
  open: boolean;
  projectId: string;
  callBack: () => void;
}): JSX.Element {
  const { canAddUser, canAddExternalUser } = usePermissions();

  const [role, setRole] = React.useState<Role | typeof noRoleYet>(noRoleYet);
  const rolesOffered = [
    Role.LenderAdmin,
    Role.Lender,
    Role.Contractor,
    Role.Owner,
    Role.InspectorAdmin,
    Role.Inspector,
    Role.Approver,
    Role.Subcontractor,
  ];

  const [submitRef, focusSubmit] = useFocus<HTMLButtonElement>();
  const [loading, setLoading] = React.useState(false);
  const [external, setExternal] = React.useState(false);

  const handleClose = () => {
    setRole(noRoleYet);
    callBack();
  };

  return (
    <Dialog open={props.open} onClose={handleClose} maxWidth="lg">
      <DialogContent>
        <h1>Add A New User</h1>

        <BottomNavigation
          value={role}
          showLabels
          onChange={(_: unknown, newValue: Role) => {
            setRole(newValue);
          }}
          //className={classes.root}
        >
          {rolesOffered.map((role) =>
            canAddUser(role) ? (
              <BottomNavigationAction
                label={roleAttributes[role].label}
                value={role}
                icon={roleAttributes[role].icon}
                key={role}
              />
            ) : null
          )}
        </BottomNavigation>
        {role === noRoleYet ? (
          <div
            style={{
              display: "flex",
              alignContent: "center",
              justifyContent: "center",
              margin: 32,
            }}
          >
            <Typography>
              Select a role above to continue adding your new user
            </Typography>
          </div>
        ) : (
          <>
            <UserEditor
              {...props}
              onClose={handleClose}
              role={role}
              onLastFieldEntered={focusSubmit}
              setLoading={setLoading}
              external={external}
            />
            {role === Role.Owner && canAddExternalUser(role) ? (
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={({ target: { checked } }) => setExternal(checked)}
                    checked={external}
                  />
                }
                label="Create as External (no notifications, cannot log in)"
              />
            ) : undefined}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button
          type="submit"
          form="add-user-form"
          ref={submitRef}
          color="primary"
          disabled={loading || role === noRoleYet}
        >
          {loading ? <CircularProgress /> : "Submit"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

// These terms are all used in the API, so we can't fix their spelling here - at least not without establishing
// our own bespoke model and then converting it at API time (which may still be worth it, but for the moment we'll
// just ignore):
// spell-checker:ignore zipcode projecttype owneroccupied originalsquarefeet newsquarefeet originalvalue appraisedvalue
// spell-checker:ignore squarefeet workorder servicetype totalloanamount inspectoradmin lenderadmin
