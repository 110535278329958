import useSWR from "swr";
import { useBackend } from ".";
import { FetchError } from "../API";
import {
  BackendEditTaskInputs,
  BackendGetProjectTasksResponse,
} from "../APITypes";
import { logAnomaly } from "../ErrorLogging";
import { throwIfNot } from "../util/throwIfNot";
import { useAuth } from "@lib/hooks/useAuth";
import { SWRKeys } from "../swrKeys";
import { ProjectId } from "@project-centerline/project-centerline-api-types";

/**
 * Comparator for sorting by (task) `created_at`
 */
export const byCreatedAt = {
  _tag: "byCreatedAt",
  sortFn: <T extends { created_at: string }>(a: Readonly<T>, b: Readonly<T>) =>
    a.created_at < b.created_at ? -1 : a.created_at > b.created_at ? 1 : 0,
};

export function useProjectTasks(
  projectId: string,
  opt?: { sortBy?: typeof byCreatedAt }
): {
  project: {
    tasks?: Readonly<BackendGetProjectTasksResponse>;
    // addUser: (user: AddUserInfo) => Promise<void>;
    // removeUser: (user: EmailAddress) => Promise<void>;
    deleteTask: (taskId: string) => Promise<void>;
    completeTask: (taskId: string, completed: boolean) => Promise<void>;
    editTask: (
      taskId: string,
      values: Partial<BackendEditTaskInputs>
    ) => Promise<void>;
    forceRefreshTasks: () => Promise<unknown>;
  };
  errors?: {
    tasks?: Readonly<FetchError>;
  };
} {
  const {
    getProjectTasks,
    deleteTask: backendDeleteTask,
    markTaskCompleted,
    editTask: updateTask,
    // removeUserFromProject,
    // addUserToProject,
  } = useBackend();
  const { currentUserEmail: email } = useAuth();
  const { sortBy = byCreatedAt } = opt ?? {};

  const {
    data: tasksResponse,
    error: tasksError,
    mutate,
  } = useSWR<BackendGetProjectTasksResponse, FetchError>(
    SWRKeys.project(projectId as ProjectId).tasksSorted({ sortBy }),
    (_url: unknown) =>
      getProjectTasks(projectId).then((response) => ({
        ...response,
        tasks: response.tasks.sort(sortBy.sortFn),
      }))
  );

  const deleteTask = async (taskId: string) => {
    if (tasksResponse) {
      // optimistic
      mutate(
        {
          ...tasksResponse,
          tasks: tasksResponse.tasks.filter(({ id }) => id !== taskId),
        },
        false
      );

      // now actually do it
      await backendDeleteTask(taskId, projectId);

      // reload just to be sure
      mutate();
    }
  };

  const completeTask = async (taskId: string, completed: boolean) => {
    if (!tasksResponse) {
      logAnomaly(new Error("How do you complete a task you never fetched?"), {
        taskId,
        completed,
        tasksResponse,
      });
      return;
    }
    // optimistic
    mutate(
      {
        ...tasksResponse,
        tasks: tasksResponse.tasks.map((task) =>
          task.id !== taskId ? task : { ...task, completed }
        ),
      },
      false
    );

    // do it
    await markTaskCompleted(
      projectId,
      taskId,
      completed,
      throwIfNot(email, "email is required")
    );
    // refetch
    mutate();
  };

  const editTask: ReturnType<
    typeof useProjectTasks
  >["project"]["editTask"] = async (taskId, values) => {
    if (!tasksResponse) {
      logAnomaly(new Error("How do you complete a task you never fetched?"), {
        taskId,
        values,
        tasksResponse,
      });
      return;
    }
    // optimistic
    mutate(
      {
        ...tasksResponse,
        tasks: tasksResponse.tasks.map((task) =>
          task.id !== taskId
            ? task
            : {
                ...task,
                ...values,
              }
        ),
      },
      false
    );

    // do it
    await updateTask(projectId, taskId, values);
    // refetch
    mutate();
  };

  const errors = tasksError
    ? {
        errors: {
          tasks: tasksError || undefined,
        },
      }
    : {};

  return {
    project: {
      tasks: tasksResponse,
      //   addUser,
      //   removeUser,
      deleteTask,
      completeTask,
      editTask,
      forceRefreshTasks: mutate,
    },
    ...errors,
  };
}
