import { openDB, DBSchema, StoreValue, StoreKey } from "idb";
import { logAnomaly, logError } from "../ErrorLogging";
import { useAsyncEffect } from "@react-hook/async";
import { StoredFile } from "../StoredFile";
import { Invoice, Task } from "../../Models";
import { gps } from "exifr";
import { Position } from "@project-centerline/project-centerline-api-types";

// TODO: Move schema out of useIdb
export interface MyDB extends DBSchema {
  // TEMP
  tempFiles: {
    value: {
      invoiceId: Invoice["invoice_id"];
      taskId: Task["id"];
      file: File;
      gpsInfo?: Awaited<ReturnType<typeof gps>>;
      deviceLocation?: Position;
      validated: "pass" | "fail" | false;
      tags: string[];
    } & (
      | {
          uploaded: "uploading" | "failed" | false;
          destination: StoredFile;
        }
      | {
          uploaded: "done" | "added";
          destination: StoredFile;
        }
    );
    key: number;
  };
  //   'favorite-number': {
  //     key: string;
  //     value: number;
  //   };
  //   products: {
  //     value: {
  //       name: string;
  //       price: number;
  //       productCode: string;
  //     };
  //     key: string;
  //     indexes: { 'by-price': number };
  //   };
}

/** A browser-local file that we plan to store in the backend (and may already have done so) */
export type TempFile = StoreValue<MyDB, "tempFiles"> & {
  key: StoreKey<MyDB, "tempFiles">;
};
export function isTempFile(f: TempFile | unknown): f is TempFile {
  return (f as TempFile).file !== undefined;
}
export const useIndexedDB = (name: string, version?: number) => {
  const { value: db, ...rest } = useAsyncEffect(
    () =>
      openDB<MyDB>(name, version || 1, {
        upgrade(db, oldVersion, newVersion, transaction, event) {
          console.log("upgrade", {
            db,
            oldVersion,
            newVersion,
            transaction,
            event,
          });
          if (oldVersion < 1) {
            db.createObjectStore("tempFiles", {
              autoIncrement: true,
              keyPath: "key",
            });
          }

          if (newVersion !== 1) {
            logAnomaly(new Error("Unexpected idb version"), {
              db,
              oldVersion,
              newVersion,
              transaction,
              event,
            });
          }
        },
        blocked(currentVersion, blockedVersion, event) {
          console.log("blocked", { currentVersion, blockedVersion, event });
          const error = new Error("Database blocked due to old version open");
          logError(error, { event, currentVersion, blockedVersion });
          throw event;
        },
        blocking(currentVersion, blockedVersion, event) {
          console.log("blocking", { currentVersion, blockedVersion, event });
          const error = new Error("Database blocking new version");
          logAnomaly(error, { event, currentVersion, blockedVersion });
        },
        terminated() {
          console.log("terminated");

          logAnomaly(new Error("Unexpected DB termination"));
        },
      }),
    [name, version]
  );

  return { ...rest, db };
};
