import React from "react";
// import ReactRouterPrompt from "react-router-prompt";
import { usePrompt } from "@lib/hooks/usePrompt";

// import { ConfirmDialog } from "./ConfirmDialog";

export function Prompt({ message, when }: { message: string; when: boolean }) {
  usePrompt(message, when);

  return <div />;
  // (
  // <ReactRouterPrompt when={when}>
  //   {({ isActive, onConfirm, onCancel }) => (
  //     <ConfirmDialog
  //       title={message}
  //       open={isActive}
  //       onConfirm={() => onConfirm(true)}
  //       onCancel={() => onCancel(false)}
  //       setOpen={() => {
  //         /* no-op */
  //       }}
  //     >
  //       <p>{message}</p>
  //     </ConfirmDialog>
  //   )}
  // </ReactRouterPrompt>
  // );
}
