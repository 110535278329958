import { useContext, createContext } from "react";
import { NotificationTypeEnum } from "./APITypes";
import { Role } from "./roles";
// import { EmailAddress } from "@project-centerline/project-centerline-api-types";

export interface Notification {
  project_id: string;

  type: NotificationTypeEnum;

  message: string;
}

export interface AppContextType {
  /**
   * Current user's role
   */
  role: Role;

  showAdmin: boolean;

  requestAdminView: (show: boolean) => void;

  /**
   * New notifications for this user
   */
  notifications: Notification[];

  setNotifications(newList: Notification[]): void;

  backendVersion?: string;

  headerContent?: React.ReactNode;
  setHeaderContent(children?: React.ReactNode): void;

  signUrl: (s: string) => Promise<string>;
  getPresignedWebsocketUrl: () => Promise<string>;

  /* not for general use */
  setBackendVersion: (v: string) => void;
}

export const AppContext = createContext<AppContextType>({
  role: "**uninitialized**" as Role,
  notifications: [],
  setNotifications: throwUninitialized,
  showAdmin: false,
  requestAdminView: throwUninitialized,
  setHeaderContent: throwUninitialized,
  setBackendVersion: throwUninitialized,
  signUrl: () => Promise.reject("Unimplemented"),
  getPresignedWebsocketUrl: () => Promise.reject("Unimplemented"),
});

function throwUninitialized(): (newList: Notification[]) => never {
  throw new Error("Uninitialized");
}

export function useAppContext(): AppContextType {
  return useContext(AppContext);
}
