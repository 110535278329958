import { useParams } from "react-router-dom";
import { LoanTab } from "./LoanTab";
import { throwIfNot } from "@lib/util/throwIfNot";

export const LoanInfoPage = () => {
  const projectId = throwIfNot(
    useParams<"projectId">().projectId,
    "Missing project id"
  );

  return <LoanTab projectId={projectId} />;
};

export default LoanInfoPage;
