import { Fab, Grid, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Link } from "react-router-dom";
import { usePermissions } from "@lib/hooks";

export interface ProjectCountProps {
  projectCount: number;
  offerCreate?: boolean;
}

export function ProjectCount({
  projectCount,
  offerCreate,
}: ProjectCountProps): JSX.Element {
  const { canCreateProjects } = usePermissions();

  return (
    // <Root className={classes.root}>
    <Grid container alignItems="center" wrap="nowrap">
      <Grid item container direction="column" alignItems="center">
        <Grid item>
          <Typography variant="tile">{projectCount}</Typography>
        </Grid>
        <Grid item>
          <Typography variant="tile">Projects</Typography>
        </Grid>
      </Grid>
      {canCreateProjects && offerCreate ? (
        // <div className={classes.plus}>
        <Grid item>
          <Link to="/quicksetup">
            <Fab color="primary" size="small" aria-label="add">
              <AddIcon />
            </Fab>
          </Link>
        </Grid>
      ) : null}
    </Grid>
    // </Root>
  );
}
