import { Unarray, byProp } from "../misc";
import { BackendGetConfigResponse, Inspector } from "../APITypes";
import React from "react";
import { useConfig } from "./useConfig";
import { flow, pipe } from "fp-ts/lib/function";

const elaborateInspector = ({
  short,
  ...rest
}: Unarray<BackendGetConfigResponse["inspectors"]>) => ({
  ...rest,
  first_name: "Project Centerline",
  last_name: short,
  short,
  phone_number: "6508142562",
  company: "Project Centerline",
  isOneClick: false,
});

const elaborateOneClick = (
  vendorRecord: Unarray<BackendGetConfigResponse["oneClickVendors"]>
) => {
  const { short, vendor } = vendorRecord;
  const [first_name, last_name] = short.split(": ").reverse();
  return {
    ...vendorRecord,
    first_name,
    last_name,
    phone_number: "6508142562",
    company: vendor,
    isOneClick: true,
  };
};

export type InspectorInfo = Unarray<ReturnType<typeof elaborateInspector>>;
type OneClickInfo = Unarray<ReturnType<typeof elaborateOneClick>>;

type EmailBasedPredicate = (arg: { email: string }) => boolean;

interface UseInspectorsResult {
  availableInspectors: InspectorInfo[];
  isConciergeInspector: EmailBasedPredicate;
  isFullConciergeInspector: EmailBasedPredicate;
  isDigitalInspector: EmailBasedPredicate;
  isVirtualInspector: EmailBasedPredicate;
  isPhysicalInspector: EmailBasedPredicate;
  availableOneClicks: OneClickInfo[] | undefined;
}

const flavorIs = (flavor: string) => (i?: Inspector) =>
  Boolean(i && i.flavor === flavor);
const vendorIs = (vendor: string) => (i?: Inspector) =>
  Boolean(i && i.vendor === vendor);

export const useInspectors = (): UseInspectorsResult => {
  const { inspectors, oneClickVendors } = useConfig() || {};
  return React.useMemo(() => {
    const inspectorsByEmail = byProp(inspectors ?? [])("email");
    const byEmail = ({ email }: { email: string }) => inspectorsByEmail[email];

    const isVirtualInspector: UseInspectorsResult["isVirtualInspector"] = flow(
      byEmail,
      flavorIs("virtual")
    );
    const isPhysicalInspector: UseInspectorsResult["isPhysicalInspector"] =
      flow(byEmail, flavorIs("physical"));
    const isDigitalInspector: UseInspectorsResult["isDigitalInspector"] = flow(
      byEmail,
      flavorIs("digital")
    );

    const isConciergeInspector = (candidate: { email: string }): boolean =>
      byEmail(candidate) &&
      !isVirtualInspector(candidate) &&
      !isDigitalInspector(candidate);

    return {
      availableInspectors: inspectors?.map(elaborateInspector) ?? [],
      availableOneClicks: oneClickVendors?.map(elaborateOneClick) ?? [],
      isDigitalInspector,
      isPhysicalInspector,
      isVirtualInspector,
      isConciergeInspector,
      isFullConciergeInspector: (candidate) =>
        isConciergeInspector(candidate) &&
        pipe(candidate, byEmail, vendorIs("Project Centerline")),
    };
  }, [inspectors, oneClickVendors]);
};
