import React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import { BackendUserResponse } from "@lib/APITypes";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";

import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

type Mutable<T> = {
  -readonly [k in keyof T]: T[k];
};

export type PermissionsMode = "private" | "project" | "user-list";
type RequiredUserBits = Pick<
  BackendUserResponse,
  "email" | "first_name" | "last_name"
>;

export interface UserChooserProps<UserT extends RequiredUserBits> {
  className?: string;
  users: Readonly<UserT[]>;
  onChoice: ({
    permissionsMode,
    users,
  }: {
    permissionsMode: PermissionsMode;
    users: UserT[];
  }) => void;
}

/**
 * Choose users from a list provided in props, and/or an implied choice from PermissionsMode
 * This is derived from https://material-ui.com/components/autocomplete/#checkboxes
 * @param props.users list of users probably as received from backend api. They need to have first_name, last_name, email
 * @returns
 */
export function UserChooser<UserT extends RequiredUserBits>({
  users = [],
  onChoice,
  className,
}: UserChooserProps<UserT>): JSX.Element {
  const [permissionsMode, setPermissionsMode] =
    React.useState<PermissionsMode>("project");

  const handlePermissionsModeChange = (event: {
    target: { value: unknown };
  }) => {
    setPermissionsMode(event.target.value as PermissionsMode);
  };
  const [selectedUsers, setSelectedUsers] = React.useState<UserT[]>([]);

  React.useEffect(() => {
    onChoice?.({ permissionsMode, users: selectedUsers });
  }, [onChoice, permissionsMode, selectedUsers]);

  return (
    <div className={className}>
      <FormControl variant="standard" component="fieldset">
        <FormLabel component="legend">File Access</FormLabel>
        <RadioGroup
          row
          value={permissionsMode}
          onChange={handlePermissionsModeChange}
        >
          <FormControlLabel
            value="private"
            control={<Radio color="primary" />}
            label="Only Me"
          />
          <FormControlLabel
            value="project"
            control={<Radio color="primary" />}
            label="Current Project Members"
          />
          <FormControlLabel
            value="user-list"
            control={<Radio color="primary" />}
            label="Specific Users"
          />
        </RadioGroup>
      </FormControl>
      {permissionsMode === "user-list" ? (
        <Autocomplete
          multiple
          id="checkboxes-tags-demo"
          options={users as Mutable<typeof users>}
          disableCloseOnSelect
          getOptionLabel={(user: UserT) =>
            `${user.first_name} ${user.last_name}`
          }
          isOptionEqualToValue={(option, value) => option.email === value.email}
          value={selectedUsers}
          onChange={(_, newSelection) => {
            setSelectedUsers(
              users.filter(({ email }) =>
                newSelection.some((user) => user.email === email)
              )
            );
          }}
          renderOption={(props, user, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
                color="primary"
              />
              {`${user.first_name} ${user.last_name} <${user.email}>`}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Choose Users"
              placeholder="Type to Search"
            />
          )}
        />
      ) : null}
    </div>
  );
}
