import { useSnackbar } from "notistack";
import React from "react";
import useSWR, { mutate as globalMutate } from "swr";
import { useBackend } from ".";
import { Invoice, InvoiceActions } from "../../Models";
import { createInvoice, FetchError, UploadProgressFn } from "../API";
import { ApprovalStatus } from "../APITypes";
import { logError } from "../ErrorLogging";
import { useAppContext } from "../UserContext";
import {
  EmailAddress,
  InvoiceId,
  LinkedFile,
  ProjectId,
  StoredFile,
  User,
} from "@project-centerline/project-centerline-api-types";
import { useProjectTasks } from "@lib/hooks/useProjectTasks";

import { logAnomaly } from "@lib/ErrorLogging";
import { useAuth } from "@lib/hooks/useAuth";
import { throwIfNot } from "../util/throwIfNot";
import { byProp } from "../misc";
import { initialRequestFetcher } from "../initialRequestFetcher";
import { SWRKeys } from "../swrKeys";

interface UseProjectInvoicesAPI {
  project: {
    invoices?: Readonly<Invoice[]>;
    invoice: (id: InvoiceId) =>
      | {
          requestInspection: (params: {
            inspector: Pick<User, "email">;
            comments: string;
          }) => Promise<void>;
          updateRouting: (
            approvers: ReadonlyArray<{ email: EmailAddress }>,
            comment?: string
          ) => Promise<void>;
          setApproval: (action: InvoiceActions) => Promise<void>;
          markPaid: (paid: boolean) => Promise<void>;
          addFiles: (
            files: File[],
            progress: UploadProgressFn
          ) => Promise<void>;
          deleteFile: (arg: StoredFile | LinkedFile) => Promise<void>;
          updateDraws: (items: Invoice["drawItems"]) => Promise<void>;
          delete: () => Promise<void>;
          reverse: () => Promise<unknown>;
          details?: Readonly<Invoice>;
        }
      | undefined;
    addInvoice: (
      ...inputs: Parameters<typeof createInvoice>
    ) => Promise<Invoice>;
    updateRouting: (approvers: { email: EmailAddress }[]) => Promise<void>;
  };
  errors?: {
    invoices?: Readonly<FetchError>;
  };
  isValidating: boolean;
}

export function useProjectInvoices(
  projectId: string,
  opt?: { status?: ApprovalStatus }
): UseProjectInvoicesAPI {
  const {
    createInvoice,
    getProjectInvoices,
    addFilesToExistingInvoice,
    updateInvoiceStatus,
    deleteInvoice,
    reverseInvoice: reverseInvoiceAPI,
    updateProjectInvoiceRouting: updateProjectInvoiceRoutingAPI,
    updateIndividualInvoiceRouting,
    editInvoice,
  } = useBackend();
  const { role } = useAppContext();
  const { enqueueSnackbar } = useSnackbar();

  const {
    project: { tasks: { tasks } = {} },
  } = useProjectTasks(projectId);
  const email = throwIfNot(useAuth().currentUserEmail, "email is required");
  const {
    data: invoices,
    error: invoicesError,
    mutate,
    isValidating,
  } = useSWR<Invoice[], FetchError>(
    () =>
      projectId && SWRKeys.project(projectId as ProjectId).invoices() && tasks,
    (_url: unknown) => getProjectInvoices(projectId, email, tasks || [])
  );

  const addInvoice = (...inputs: Parameters<typeof createInvoice>) =>
    createInvoice(...inputs).then((newInvoices) => {
      mutate((invoices ?? []).concat(newInvoices), false);
      return newInvoices[0];
    });

  const mutateAccordingToReceivedChanges = ({
    approverChanges,
  }: {
    approverChanges: {
      invoice_id: InvoiceId;
      current_approver: EmailAddress;
    }[];
  }) => {
    if (approverChanges && approverChanges.length) {
      const changesByInvoiceId = approverChanges.reduce((result, change) => {
        result[change.invoice_id] = change.current_approver;
        return result;
      }, {} as Record<InvoiceId, EmailAddress>);
      return Promise.all([
        mutate(
          (invoices) =>
            (invoices ?? []).map(
              ({ current_approver, invoice_id, ...rest }) => ({
                ...rest,
                current_approver: changesByInvoiceId[invoice_id],
                invoice_id,
              })
            ),
          false
        ),
        // refresh, just in case
        globalMutate(SWRKeys.project(projectId as ProjectId).self),
      ]).then(() => {
        // we said we return void
      });
    } else {
      return;
    }
  };
  const updateProjectInvoiceRouting = (approvers: { email: EmailAddress }[]) =>
    updateProjectInvoiceRoutingAPI({
      approvers,
      projectId,
    }).then((updates) => mutateAccordingToReceivedChanges(updates));

  const errors = invoicesError
    ? {
        errors: {
          invoices: invoicesError || undefined,
        },
      }
    : {};

  const invoiceFunctions: UseProjectInvoicesAPI["project"]["invoice"] = (
    invoiceId
  ) => {
    if (!invoices) {
      return invoices;
    }

    const thisInvoice = invoices.find(
      ({ invoice_id }) => invoice_id === invoiceId
    );

    if (!thisInvoice || !tasks) {
      return;
    }

    const updateInvoice = (
      ingredients: {
        invoiceId: Invoice["invoice_id"];
        files?: Invoice["storedFiles"];
      } & Partial<Pick<Invoice, "drawItems" | "paid" /* TODO: temp */>>,
      etag: string
    ) => {
      const { invoiceId, drawItems, files, paid } = ingredients;

      // optimistic update - update existing and ignore obsoleted (we'll refresh)
      mutate(
        invoices.map((i) =>
          i.invoice_id === invoiceId
            ? {
                ...i,
                ...(drawItems && {
                  drawItems,
                  draws: drawItems.map(({ amount, title, task }) =>
                    task ? [task.title, amount, task.id] : [title, amount]
                  ),
                }),
                // ...(items && { items }),  just pretend to be old frontend for now till we figure out the rules
                // ...(draws && { draws }),
                ...(files && { files }),
                ...(paid !== undefined && { paid }),
              }
            : i
        ),
        false
      );

      return editInvoice({
        body: { ...ingredients, invoice_id: invoiceId, email },
        etag,
        tasks,
      })
        .then(({ updated, orphaned }) => {
          mutate(
            invoices
              .map((i) => (i.invoice_id === updated.invoice_id ? updated : i))
              .concat(orphaned),
            false
          );
        })
        .finally(() => {
          // refresh, just in case
          mutate();
          globalMutate(SWRKeys.project(projectId as ProjectId).self);
        });
    };

    // TODO: updateRouting sends *way* too much
    const updateRouting = (
      approvers: ReadonlyArray<{ email: EmailAddress }>,
      comment?: string
    ) =>
      updateIndividualInvoiceRouting({
        approvers,
        projectId,
        comment,
        invoiceId,
      }).then((updatedInvoice) => {
        mutate(
          invoices.map((i) =>
            i.invoice_id === updatedInvoice.invoice_id
              ? {
                  ...i,
                  current_approver:
                    (updatedInvoice.current_approver as EmailAddress) ||
                    (() => {
                      throw new Error("no current approver?");
                    })(),
                  remaining_approvers:
                    (updatedInvoice.remaining_approvers as EmailAddress[]) ||
                    [],
                }
              : i
          ),
          false
        );
        // refresh, just in case
        mutate();
        globalMutate(SWRKeys.project(projectId as ProjectId).self);
      });

    return {
      requestInspection({
        inspector,
        comments,
      }: {
        inspector: Pick<User, "email">;
        comments: string;
      }) {
        const existingRouting = (
          thisInvoice?.current_approver ? [thisInvoice.current_approver] : []
        ).concat(thisInvoice?.remaining_approvers || []);
        const newRouting = [inspector.email].concat(existingRouting);

        return updateRouting(
          newRouting.map((email) => ({ email })),
          comments
        );
      },
      updateRouting,
      updateDraws: (drawItems) =>
        updateInvoice(
          {
            invoiceId,
            drawItems,
          },
          thisInvoice.etag ?? "etag unavailable"
        ),
      markPaid: (paid: boolean) =>
        updateInvoice(
          {
            invoiceId,
            paid,
          },
          thisInvoice.etag ?? "etag unavailable"
        ),
      setApproval: async (approvalAction: InvoiceActions) => {
        // optimistic
        mutate(
          invoices.map((i) =>
            i.invoice_id === invoiceId
              ? {
                  ...thisInvoice,
                  approval_status:
                    approvalAction === InvoiceActions.APPROVE
                      ? ApprovalStatus.Approved
                      : ApprovalStatus.Rejected,
                  storedFiles: thisInvoice.storedFiles ?? [],
                }
              : i
          ),
          false
        );

        try {
          await updateInvoiceStatus(
            invoiceId,
            approvalAction,
            email,
            projectId,
            role
          );
        } finally {
          // refresh and pick up updated routing etc.
          mutate();
        }
      },

      addFiles: async (files: File[], progress: UploadProgressFn) => {
        await addFilesToExistingInvoice(files, thisInvoice, email, progress);
        mutate();
      },

      deleteFile: async (arg: StoredFile | LinkedFile) => {
        // optimist
        const files = thisInvoice.storedFiles.filter(
          (f) =>
            !(
              StoredFile.is(f) &&
              StoredFile.is(arg) &&
              f.storageKey === arg.storageKey
            ) &&
            !(LinkedFile.is(f) && LinkedFile.is(arg) && f.href === arg.href)
        );
        thisInvoice.storedFiles = files;
        mutate(
          invoices.map((i) =>
            i.invoice_id === thisInvoice.invoice_id ? thisInvoice : i
          )
        );

        // go do it real
        return updateInvoice(
          {
            invoiceId,
            files,
          },
          thisInvoice.etag ?? "eTag unavailable"
        );
      },

      delete: async () => {
        // optimistic
        mutate(
          invoices.filter(({ invoice_id }) => invoice_id !== invoiceId),
          false
        );

        await deleteInvoice(thisInvoice);

        // make sure
        mutate();
      },

      reverse: async () => {
        await mutate(
          invoices?.map((i) =>
            i.invoice_id === invoiceId
              ? {
                  ...i,
                  approval_status: ApprovalStatus.Pending,
                  current_approver:
                    i.current_approver ||
                    (() => {
                      /* since Pending have to have current approver, you broke it you bought it :-) */
                      logAnomaly(
                        new Error(
                          "Reversing a draw left it with no approver; assigning current user"
                        ),
                        { email, i }
                      );
                      return email;
                    })(),
                }
              : i
          ),
          false
        );

        // admire the evolutionary mix of async and Promise :-)
        return reverseInvoiceAPI({
          invoiceId: thisInvoice.invoice_id,
          projectId: thisInvoice.project_id,
          callerEmail: email,
        }).then((updates) =>
          Promise.all([
            mutate(
              (invoices ?? []).map((i) =>
                i.invoice_id === updates.updatedInvoice.invoice_id
                  ? updates.updatedInvoice
                  : i
              )
            ),
            globalMutate("/api/project"),
            globalMutate(SWRKeys.project(projectId as ProjectId).self),
            globalMutate(SWRKeys.project(projectId as ProjectId).tasks),
            globalMutate(SWRKeys.project(projectId as ProjectId).invoices),
          ])
            .then(() => {
              // said we would return void
            })
            .catch((err) => {
              logError(err);
              enqueueSnackbar(
                "Something didn't go quite right. We've been notified. We suggest you refresh the page soon",
                { variant: "warning" }
              );
            })
        );
      },

      details: thisInvoice,
    };
  };

  return {
    project: {
      invoices: opt?.status
        ? invoices?.filter(
            ({ approval_status }) => approval_status === opt.status
          )
        : invoices,
      addInvoice,
      updateRouting: updateProjectInvoiceRouting,
      invoice: React.useCallback(invoiceFunctions, [
        addFilesToExistingInvoice,
        deleteInvoice,
        editInvoice,
        email,
        enqueueSnackbar,
        invoices,
        mutate,
        projectId,
        reverseInvoiceAPI,
        role,
        tasks,
        updateIndividualInvoiceRouting,
        updateInvoiceStatus,
      ]),
    },
    ...errors,
    isValidating,
  };
}

export function useInvoiceAncestors({ projectId }: { projectId: ProjectId }) {
  const {
    project: { invoices },
  } = useProjectInvoices(projectId); // no filtering, intentionally, to get ancestors

  const fetchInitialRequest = React.useMemo(() => {
    const invoicesById = byProp(invoices ?? [])("invoice_id");
    const fetcher = initialRequestFetcher(invoicesById);
    return fetcher;
  }, [invoices]);

  return {
    fetchInitialRequest,
  };
}
