import { throwIfNot } from "@lib/util/throwIfNot";
import { useParams } from "react-router-dom";
import { FilesTab } from "./FilesTab";

export const ProjectFilesPage = () => {
  const projectId = throwIfNot(
    useParams<"projectId">().projectId,
    "Missing project id"
  );

  return <FilesTab projectId={projectId} />;
};

export default ProjectFilesPage;
