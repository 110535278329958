import React, { useRef } from "react";
import { Box, IconButton } from "@mui/material";
import AddPhotoIcon from "@mui/icons-material/AddAPhoto";
import { usePosition } from "@lib/hooks/usePosition";

import { isAndroid } from "react-device-detect";
import useIsMounted from "@lib/hooks/isMounted";
import { logAnomaly } from "@lib/ErrorLogging";

export type PhotoInputProps = {
  disabled?: boolean;
  // key: string | number;
  onNewPhotos: (arg: {
    newPhotos: FileList;
    currentPosition: GeolocationPosition | GeolocationPositionError;
    requestTimestamp: number;
  }) => void;
};

export const DIPhotoButton = ({
  onNewPhotos,
  disabled,
}: // key,
PhotoInputProps) => {
  const actualInputRef = useRef<HTMLInputElement>(null);
  const isMounted = useIsMounted();
  const { getPosition } = usePosition();
  const [currentPhotos, setCurrentPhotos] = React.useState<
    FileList | undefined
  >();
  const [currentPosition, setCurrentPosition] = React.useState<
    GeolocationPosition | GeolocationPositionError | undefined
  >();
  const [requestTimestamp, setRequestTimestamp] = React.useState<
    number | undefined
  >();

  React.useEffect(() => {
    if (currentPhotos && currentPosition) {
      if (!requestTimestamp) {
        throw new Error("Programmer error; no timestamp");
      }
      if (currentPosition instanceof GeolocationPosition) {
        if (requestTimestamp > currentPosition.timestamp) {
          logAnomaly(new Error("Location sample predates request?"), {
            requestTimestamp,
            currentPosition,
            currentPhotos: currentPhotos.length,
            photoStamp: currentPosition.timestamp,
            delta: currentPosition.timestamp - requestTimestamp,
          });
        }
      }
      onNewPhotos({
        currentPosition,
        newPhotos: currentPhotos,
        requestTimestamp,
      });
      if (actualInputRef.current) {
        actualInputRef.current.value = "";
      }
      setCurrentPhotos(undefined);
      setCurrentPosition(undefined);
    }
  }, [currentPhotos, currentPosition, onNewPhotos, requestTimestamp]);

  const fetchPosition = (trigger: string) => {
    console.log("request position", { trigger });
    return getPosition()
      .then((position) => {
        console.log("got position", { position, trigger });
        if (isMounted()) {
          setCurrentPosition(position);
        }
      })
      .catch((err) => {
        console.warn("error in position", { err, trigger });
        setCurrentPosition(err);
      });
  };

  /*
   * on android we can dispatch from the button and that makes everything happen in order. For other
   * platforms, we put the input in front and transparent, and use mousedown to try to get the location
   * request before/on top of the camera
   */
  const button = (
    <IconButton
      style={isAndroid ? undefined : { position: "absolute" }}
      size="small"
      disabled={disabled}
      onClick={(event) => {
        console.log("button click");
        if (isAndroid) {
          setRequestTimestamp(Date.now());
          fetchPosition("button").then(() => {
            actualInputRef?.current?.dispatchEvent(event.nativeEvent);
          });
        }
      }}

      // onClick={(reactEvent) => {
      //   // console.log("photo button click", { reactEvent });
      //   console.log("photo button onClick");
      //   getPosition()
      //     .then((position) => {
      //       // console.log("got position; time to synthetic click", {
      //       //   position,
      //       //   reactEvent,
      //       //   actualInputRef,
      //       // });

      //       actualInputRef?.current?.dispatchEvent(reactEvent.nativeEvent);
      //     })
      //     .catch((err) => {
      //       console.warn("error in handler", { err });
      //     });
      // }}
    >
      <AddPhotoIcon />
    </IconButton>
  );

  const input = disabled ? null : (
    <input
      style={
        isAndroid
          ? { display: "none" }
          : {
              // display: "block",
              position: "absolute",
              cursor:
                "pointer" /* WTF this is crazy!! https://stackoverflow.com/questions/14795944/jquery-click-events-not-working-in-ios/16006333#16006333 */,
              height: 50, // Super hack
              width: 50, // Super hack
              boxSizing: "border-box",
              opacity: 0,
            }
      }
      type="file"
      accept="image/*"
      capture="environment"
      onChange={({ target: { files } }) => {
        console.log("input onChange");
        setCurrentPhotos(files?.length ? files : undefined);
      }}
      onClick={() => {
        console.log("input onClick");
      }}
      ref={actualInputRef}
      onMouseDown={() => {
        console.log("input mousedown");
        if (!isAndroid) {
          setRequestTimestamp(Date.now());
          fetchPosition("input");
        }
      }}
    />
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      justifyContent="center"
      // key={key}
    >
      {isAndroid ? (
        <label>
          {input}
          {button}
        </label>
      ) : (
        <>
          {button}
          {input}
        </>
      )}
    </Box>
  );
};
