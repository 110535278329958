export const divisions = [
  {
    name: "0. Procurement and Contracting Requirements",
    values: [
      "Solicitation (001000)",
      "Instructions for Procurement (002000)",
      "Available Information (03000)",
      "Procurement Forms and Supplements (004000)",
      "Contracting Forms and Supplements (005000)",
      "Project Forms (006000)",
      "Conditions of the Contract (007000)",
      "Revisions, Clarifications, and Modifications (009000)",
    ],
  },
  {
    name: "1. General Requirements",
    values: [
      "Summary (011000)",
      "Price and Payment Procedures (022000)",
      "Administrative Requirements (033000)",
      "Quality Requirements (044000)",
      "Temporary Facilities and Controls (055000)",
      "Product Requirements (066000)",
      "Execution and Closeout Requirements (077000)",
      "Performance Requirements (088000)",
      "Life Cycle Activities (099000)",
    ],
  },
  {
    name: "2. Existing Conditions",
    values: [
      "Assessment	(022000)",
      "Subsurface Investigation (023000)",
      "Demolition and Structure Moving (024000)",
      "Site Remediation (025000)",
      "Contaminated Site Material Removal	(026000)",
      "Water Remediation	(027000)",
      "Facility Remediation (028000)",
    ],
  },
  {
    name: "3. Concrete",
    values: [
      "Concrete Forming and Accessories (031000)",
      "Concrete Reinforcing (032000)",
      "Cast-in-Place Concrete	(033000)",
      "Precast Concrete (034000)",
      "Cast Decks and Underlayment (035000)",
      "Grouting (036000)",
      "Mass Concrete (037000)",
      "Concrete Cutting and Boring (038000)",
    ],
  },
  {
    name: "4. Masonry",
    values: [
      "Unit Masonry (042000)",
      "Stone Assemblies (044000)",
      "Refractory Masonry	(045000)",
      "Corrosion-Resistant Masonry (046000)",
      "Manufactured Masonry (047000)",
    ],
  },
  {
    name: "5. Metals",
    values: [
      "Structural Metal Framing (051000)",
      "Metal Joists (052000)",
      "Metal Decking (053000)",
      "Cold-Formed Metal Framing (054000)",
      "Metal Fabrications	(055000)",
      "Decorative Metal (057000)",
    ],
  },
  {
    name: "6. Wood, Plastics, and Composites",
    values: [
      "Rough Carpentry (061000)",
      "Finish Carpentry (062000)",
      "Architectural Woodwork	(064000)",
      "Structural Plastics (065000)",
      "Plastic Fabrications (066000)",
      "Structural Composites (067000)",
      "Composite Fabrications	(068000)",
    ],
  },
  {
    name: "7. Thermal and Moisture Protection",
    values: [
      "Dampproofing and Waterproofing (071000)",
      "Thermal Protection	(072000)",
      "Steep Slope Roofing (073000)",
      "Roofing and Siding Panels (074000)",
      "Membrane Roofing (075000)",
      "Flashing and Sheet Metal (076000)",
      "Roof and Wall Specialties and Accessories (077000)",
      "Fire and Smoke Protection (078000)",
      "Joint Protection (079000)",
    ],
  },
  {
    name: "8. Openings",
    values: [
      "Doors and Frames (081000)",
      "Specialty Doors and Frames	(083000)",
      "Entrances, Storefronts, and Curtain Walls (084000)",
      "Windows (085000)",
      "Roof Windows and Skylights	(086000)",
      "Hardware (087000)",
      "Glazing (088000)",
      "Louvers and Vents (089000)",
    ],
  },
  {
    name: "9. Finishes",
    values: [
      "Plaster and Gypsum Board (092000)",
      "Tiling	(093000)",
      "Ceilings (095000)",
      "Flooring (096000)",
      "Wall Finishes (097000)",
      "Acoustic Treatment	(098000)",
      "Painting and Coating (099000)",
    ],
  },
  {
    name: "10. Specialties",
    values: [
      "Information Specialties (101000)",
      "Interior Specialties (102000)",
      "Fireplaces and Stoves (103000)",
      "Safety Specialties	(104000)",
      "Storage Specialties (105000)",
      "Exterior Specialties (107000)",
      "Other Specialties (108000)",
    ],
  },
  {
    name: "11. Equipment",
    values: [
      "Vehicle and Pedestrian Equipment (111000)",
      "Commercial Equipment (112000)",
      "Residential Equipment (113000)",
      "Foodservice Equipment (114000)",
      "Educational and Scientific Equipment (115000)",
      "Entertainment and Recreation Equipment	(116000)",
      "Healthcare Equipment (117000)",
      "Facility Maintenance and Operation Equipment (118000)",
      "Other Equipment (119000)",
    ],
  },
  {
    name: "12. Furnishings",
    values: [
      "Art (121000)",
      "Window Treatments (122000)",
      "Casework (123000",
      "Furnishings and Accessories (124000)",
      "Furniture (125000)",
      "Multiple Seating (126000)",
      "Other Furnishings (129000)",
    ],
  },
  {
    name: "13. Special Construction",
    values: [
      "Special Facility Components (131000)",
      "Special Purpose Rooms (132000)",
      "Special Structures	(133000)",
      "Integrated Construction (134000)",
      "Special Instrumentation (135000)",
    ],
  },
  {
    name: "14. Conveying Equipment",
    values: [
      "Dumbwaiters (141000)",
      "Elevators (142000)",
      "Escalators and Moving Walks (143000)",
      "Lifts (144000)",
      "Turntables	(147000)",
      "Scaffolding (148000)",
      "Other Conveying Equipment (149000)",
    ],
  },
  {
    name: "21. Fire Suppression",
    values: [
      "Water-Based Fire-Suppression Systems (211000)",
      "Fire-Extinguishing Systems	(212000)",
      "Fire Pumps	(213000)",
      "Fire-Suppression Water Storage	(214000)",
    ],
  },
  {
    name: "22. Plumbing",
    values: [
      "Plumbing Piping (221000)",
      "Plumbing Equipment	(223000)",
      "Plumbing Fixtures (224000)",
      "Pool and Fountain Plumbing Systems	(225000)",
      "Gas and Vacuum Systems for Laboratory and Healthcare Facilities (226000)",
    ],
  },
  {
    name: "23. Heating, Ventilating, and Air Conditioning (HVAC)",
    values: [
      "Facility Fuel Systems (231000)",
      "HVAC Piping and Pumps (232000)",
      "HVAC Air Distribution (233000)",
      "HVAC Air Cleaning Devices (234000)",
      "Central Heating Equipment (235000)",
      "Central Cooling Equipment (236000)",
      "Central HVAC Equipment	(237000)",
      "Decentralized HVAC Equipment (238000)",
    ],
  },
  {
    name: "25. Integrated Automation",
    values: [
      "Integrated Automation Network Equipment (251000)",
      "Integrated Automation Instrumentation and Terminal Devices	(253000)",
      "Integrated Automation Facility Controls (255000)",
      "Integrated Automation Control Sequences (259000)",
    ],
  },
  {
    name: "26. Electrical",
    values: [
      "Medium-Voltage Electrical Distribution	(261000)",
      "Low-Voltage Electrical Distribution	(262000)",
      "Facility Electrical Power Generating and Storing Equipment	(263000)",
      "Electrical Protection (264000)",
      "Lighting (265000)",
    ],
  },
  {
    name: "27. Communications",
    values: [
      "Structured Cabling	(271000)",
      "Data Communications (272000)",
      "Voice Communications (273000)",
      "Audio-Video Communications	(274000)",
      "Distributed Communications and Monitoring Systems (275000)",
    ],
  },
  {
    name: "28. Electronic Safety and Security",
    values: [
      "Access Control	(281000)",
      "Video Surveillance	(282000)",
      "Security Detection, Alarm, and Monitoring (283000)",
      "Life Safety (284000)",
      "Specialized Systems (285000)",
    ],
  },
  {
    name: "31. Earthwork",
    values: [
      "Site Clearing (311000)",
      "Earth Moving (312000)",
      "Earthwork Methods (313000)",
      "Shoring and Underpinning (314000)",
      "Excavation Support and Protection (315000)",
      "Special Foundations and Load-Bearing Elements (316000)",
      "Tunneling and Mining (317000)",
    ],
  },
  {
    name: "32. Exterior Improvements",
    values: [
      "Bases, Ballasts, and Paving (321000)",
      "Site Improvements (323000)",
      "Wetlands (327000)",
      "Irrigation	(328000)",
      "Planting (329000)",
    ],
  },
  {
    name: "33. Utilities",
    values: [
      "Water Utilities (331000)",
      "Sanitary Sewerage (333000)",
      "Stormwater Utilities (334000)",
      "Hydrocarbon Utilities (335000)",
      "Hydronic and Steam Energy Utilities (336000)",
      "Electrical Utilities (337000)",
      "Communications Utilities (338000)",
    ],
  },
  {
    name: "34. Transportation",
    values: [
      "Guideways/Railways	(341000)",
      "Traction Power	(342000)",
      "Transportation Signaling and Control Equipment	(344000)",
      "Transportation Fare Collection Equipment (345000)",
      "Transportation Construction and Equipment (347000)",
      "Bridges (348000)",
    ],
  },
  {
    name: "35. Waterway and Marine Construction",
    values: [
      "Waterway and Marine Signaling and Control Equipment (351000)",
      "Waterway and Marine Construction and Equipment	(352000)",
      "Coastal Construction (353000)",
      "Waterway Construction and Equipment (354000)",
      "Marine Construction and Equipment (355000)",
      "Dam Construction and Equipment	(357000)",
    ],
  },
  {
    name: "40. Process Interconnections",
    values: [
      "Gas and Vapor Process Piping and Ductwork	(401000)",
      "Liquids Process Piping	(402000)",
      "Solid and Mixed Materials Piping and Chutes (403000)",
      "Process Piping and Equipment Protection (404000)",
      "Process Control and Enterprise Management Systems (406000)",
      "Instrumentation for Process Systems (407000)",
      "Commissioning of Process Systems (408000)",
      "Primary Control Devices (409000)",
    ],
  },
  {
    name: "41. Material Processing and Handling Equipment",
    values: [
      "Bulk Material Processing Equipment	(411000)",
      "Piece Material Handling Equipment	(412000)",
      "Manufacturing Equipment (413000)",
      "Container Processing and Packaging	(414000)",
      "Material Storage (415000)",
      "Mobile Plant Equipment	(416000)",
    ],
  },
  {
    name: "42. Process Heating, Cooling, and Drying Equipment",
    values: [
      "Process Heating Equipment (421000)",
      "Process Cooling Equipment (422000)",
      "Process Drying Equipment (423000)",
    ],
  },
  {
    name:
      "43. Process Gas and Liquid Handling, Purification, and Storage Equipment",
    values: [
      "Gas Handling Equipment	(431000)",
      "Liquid Handling Equipment (432000)",
      "Gas and Liquid Purification Equipment (433000)",
      "Gas and Liquid Storage	(434000)",
    ],
  },
  {
    name: "44. Pollution and Waste Control Equipment",
    values: [
      "Air Pollution Control (441000)",
      "Noise Pollution Control (442000)",
      "Odor Control (443000)",
      "Water Pollution Control Equipment (444000)",
      "Solid Waste Control and Reuse (445000)",
      "Waste Thermal Processing Equipment	(446000)",
    ],
  },
  {
    name: "45. Industry-Specific Manufacturing Equipment",
    values: [],
  },
  {
    name: "46. Water and Wastewater Equipment",
    values: [
      "Water and Wastewater Preliminary Treatment Equipment (462000)",
      "Water and Wastewater Chemical Feed Equipment (463000)",
      "Water and Wastewater Clarification and Mixing Equipment (464000)",
      "Water and Wastewater Secondary Treatment Equipment	(465000)",
      "Water and Wastewater Advanced Treatment Equipment (466000)",
      "Water and Wastewater Residuals Handling and Treatment (467000)",
    ],
  },
  {
    name: "48. Electrical Power Generation",
    values: [
      "Electrical Power Generation Equipment (481000)",
      "Combined Heat and Power Generation	(483000",
      "Electrical Power Generation Testing (487000)",
    ],
  },
];

// spell-checker:ignore underlayment dampproofing foodservice hvac stormwater hydronic guideways ductwork
