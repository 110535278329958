import React from "react";
import Dialog from "@mui/material/Dialog";
import { Box, LinearProgress, Typography } from "@mui/material";
import filesize from "filesize";
import { UploadProgress } from "@/Lib/API";

/** state designed to work easily with @see uploadSupportingFiles and @see UploadProgressDialog */
export const useProgress = () => React.useState<UploadProgress | undefined>();

/** Similar to @see useProgress but takes a list of files and counts up cumulative progress */
export const useMultiFileProgress = (files: ReadonlyArray<File>) => {
  const [latestFile, setLatestFile] = React.useState<
    Pick<File, "name"> | undefined
  >();
  const [progresses, setProgresses] = React.useState<
    Record<string, UploadProgress>
  >({});

  const updateFileProgress = (progress: UploadProgress) => {
    setProgresses((progresses) => ({
      ...progresses,
      [progress.file.name]: progress,
    }));
    setLatestFile(progress.file);
  };

  React.useEffect(() => {
    // initialize
    files.forEach((file) =>
      updateFileProgress({ file, loaded: 0, total: file.size })
    );
  }, [files]);

  const summaryProgress = React.useMemo(() => {
    return Object.values(progresses).reduce(
      (result, p) => ({
        ...result,
        loaded: result.loaded + p.loaded,
        total: result.total + p.total,
      }),
      { loaded: 0, total: 0, file: latestFile }
    );
  }, [latestFile, progresses]);

  return {
    updateFileProgress,
    summaryProgress,
  };
};

/** open if there is any progress reported. We used this in a bunch of places so I DRYed it */
export const openAccordingToProgress = (
  progress: Pick<UploadProgress, "total"> | undefined
) => progress !== undefined && progress.total > 0; // && loaded !== total;

/** Displays a modal linear progress with human-readable sizes and, optionally, a "current" file name */
export function UploadProgressDialog({
  progress,
  open,
  numberFormatter = filesize,
}: {
  progress?: Omit<UploadProgress, "file"> &
    Partial<Pick<UploadProgress, "file">>;
  open: boolean;
  numberFormatter?: (arg: number) => string;
}): JSX.Element | null {
  if (!progress) {
    return null;
  }

  const { file, loaded, total } = progress;
  return (
    <Dialog open={open}>
      <Box display="flex" alignItems="center" m={1}>
        {file ? (
          <Box minWidth={35}>
            <Typography variant="body2" color="textSecondary">
              {file.name}
            </Typography>
          </Box>
        ) : null}
        <Box width="100%" m={1}>
          <LinearProgress
            variant="determinate"
            value={(100 * loaded) / total}
          />
        </Box>
        <Box minWidth={35}>
          <Typography variant="body2" color="textSecondary">
            {/* {mostRecentProgressFile} */}
            {numberFormatter(loaded)} / {numberFormatter(total)}
          </Typography>
        </Box>
      </Box>
    </Dialog>
  );
}

//spell-checker:ignore filesize
