import haversineDistance from "haversine-distance";
import { Location } from "@lib/APITypes";
import { gps } from "exifr";
import { Position } from "@project-centerline/project-centerline-api-types";
import { isIOS } from "react-device-detect";

export type GpsOutput = Awaited<ReturnType<typeof gps>>;

type GpsOrDevice = {
  gpsInfo?: GpsOutput;
  deviceLocation?: Position;
};

export const effectivePhotoLocation: (
  f: GpsOrDevice
) => GpsOutput | undefined = (f) =>
  (hasValidGpsInfo(f) && f.gpsInfo) ||
  (isIOS && hasValidDeviceLocation(f) && f.deviceLocation?.coords) ||
  undefined;

export function hasValidGpsInfo(arg: {
  gpsInfo?: GpsOutput;
}): arg is { gpsInfo: Location } {
  const { gpsInfo } = arg;
  return isValidLocation(gpsInfo);
}

export function hasValidDeviceLocation(arg: {
  deviceLocation?: Position;
}): arg is { deviceLocation: Position } {
  const { deviceLocation } = arg;
  return isValidLocation(deviceLocation?.coords);
}

export function hasLocation(arg: GpsOrDevice): arg is { gpsInfo: Location } {
  const location = effectivePhotoLocation(arg);
  return Boolean(location) && isValidLocation(location);
}

export function isValidLocation(
  gpsInfo?: Partial<GpsOutput> | null
): gpsInfo is Location {
  return Boolean(gpsInfo?.latitude && gpsInfo?.longitude);
}

export function isNear(a: Location, b: Location, thresholdInMeters = 100) {
  const meters = distance(a, b);
  // console.log({ a, b, distance: haversineDistance(a, b) });
  return meters !== undefined && meters <= thresholdInMeters;
}

export function distance(a?: Location, b?: Location) {
  return a && b && haversineDistance(a, b);
}
