import React from "react";
import { Dock } from "react-dock";
import { useHotkeys } from "react-hotkeys-hook";
import packageJson from "../../package.json";
import { useAppContext } from "@lib/UserContext";
import ReactMarkdown from "react-markdown";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";

import changelogLocation from "../CHANGELOG.md";

const backendChangelog = `
# TODO

Getting the backend CHANGELOG is harder
`;
type Position = "left" | "right" | "top" | "bottom";

export interface AdminViewContainerProps {
  isVisible: boolean;
  initialPosition?: Position;
  onVisibleChange?: (isVisible: boolean) => void;
  dimMode?: "opaque" | "transparent" | "none";
  children: JSX.Element;
}

export const AdminViewContainer = (
  props: AdminViewContainerProps
): JSX.Element => {
  const { children, isVisible, onVisibleChange, initialPosition, dimMode } =
    props;
  const [position, setPosition] = React.useState<Position>(
    initialPosition || "bottom"
  );

  useHotkeys(
    "ctrl+p,cmd+p",
    () => {
      if (isVisible) {
        setPosition((position) =>
          position === "bottom"
            ? "right"
            : position === "right"
            ? "top"
            : position === "top"
            ? "left"
            : "bottom"
        );
      }
    },
    {},
    [position, setPosition, isVisible]
  );

  const { version } = packageJson;
  const { backendVersion } = useAppContext();
  const [changelog, setChangelog] = React.useState("");
  React.useEffect(() => {
    fetch(changelogLocation)
      .then((response) => response.text())
      .then(setChangelog);
    return undefined;
  }, []);

  return (
    <Dock
      position={position}
      isVisible={isVisible}
      onVisibleChange={onVisibleChange}
      dimMode={dimMode}
    >
      <div>
        {children}
        <Accordion>
          <AccordionSummary>Frontend: {version}</AccordionSummary>
          <AccordionDetails>
            <div>
              <ReactMarkdown source={changelog} />
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary>Backend: {backendVersion}</AccordionSummary>
          <AccordionDetails>
            <div>
              <ReactMarkdown source={backendChangelog} />
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    </Dock>
  );
};
