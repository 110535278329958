import {
  Invoice as APIInvoice,
  HydratedDraws,
} from "@project-centerline/project-centerline-api-types";
import * as t from "io-ts";
import { InvoiceFile } from "@project-centerline/project-centerline-api-types";
import { Task } from "./Task";

/**
 * What we send down to the backend to take action on an invoice
 */
export enum InvoiceActions {
  APPROVE = "approve",
  REJECT = "reject",
}

const StoredFilesFromFiles = t.type(
  {
    storedFiles: t.array(InvoiceFile),
  },
  "Synthesized storedFiles"
);

export const Invoice = t.intersection(
  [
    APIInvoice,
    t.type({ drawItems: HydratedDraws(Task) }, "with hydrated drawItems"),
    StoredFilesFromFiles,
  ],
  "top level"
);

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type Invoice = t.TypeOf<typeof Invoice>;

export const PendingInvoice = t.brand(
  Invoice,
  (i): i is t.Branded<Invoice, { readonly PIBrand: unique symbol }> =>
    i.approval_status === "Pending",
  "PIBrand"
);
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type PendingInvoice = t.TypeOf<typeof PendingInvoice>;

export const NotPendingInvoice = t.brand(
  Invoice,
  (i): i is t.Branded<Invoice, { readonly NotPendingInvoice: unique symbol }> =>
    i.approval_status !== "Pending",
  "NotPendingInvoice"
);
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type NotPendingInvoice = t.TypeOf<typeof NotPendingInvoice>;
