import useSWR from "swr";
import { useBackend } from ".";
import { BackendGetConfigResponse } from "../APITypes";

export function useConfig(): BackendGetConfigResponse | undefined {
  const { getConfig } = useBackend();
  const { data } = useSWR("/config", getConfig, {
    focusThrottleInterval: 5 * 60 * 1000,
  });
  return data;
}
