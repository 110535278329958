import {
  Button,
  Chip,
  CircularProgress,
  Fab,
  IconButton,
  Snackbar,
  TextField,
} from "@mui/material";
import React from "react";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz"; // spell-checker:ignore horiz
import { MUIDataTableColumnDef } from "mui-datatables";
import { Alert, AlertTitle, Autocomplete } from "@mui/material";
import { logError } from "@lib/ErrorLogging";
import { BackendGetUserDetailsResponse, User } from "@lib/APITypes";
import { ConfirmDialog } from "../../Components/ConfirmDialog";
import { prettyName } from "./prettyName";
import { useAllUsers } from "./useAllUsers";
import { useParams } from "react-router-dom";
import { removeUserFromAllProjects, replaceUserInAllProjects } from "@lib/API";
import { useSnackbar } from "notistack";
import { MUIDataTable } from "../../Components/MUIDataTableWithTypeHack";
import { mutate as globalMutate } from "swr";
import { SWRKeys } from "@/Lib/swrKeys";

const Column = (x: MUIDataTableColumnDef) => x;

const staticColumns = [
  Column({
    name: "User",
  }),

  Column({
    name: "Email",
  }),

  Column({
    name: "Name",
  }),
  //   {
  //     name: "Projects",
  //   },
  //   {
  //     name: "etag",
  //     options: {
  //       searchable: false,
  //       display: "excluded",
  //     },
  //   },
];

export const UserList: () => JSX.Element = () => {
  const { users = [] } = useAllUsers();
  const usersByEmail: Record<string, BackendGetUserDetailsResponse> =
    React.useMemo(
      () =>
        users.reduce(
          (result, user) => ({ ...result, [user.email]: user }),
          {}
        ) ?? {},
      [users]
    );
  const usersByPretty: Record<string, BackendGetUserDetailsResponse> =
    React.useMemo(
      () =>
        users.reduce(
          (result, user) => ({ ...result, [prettyName(user)]: user }),
          {}
        ) ?? {},
      [users]
    );
  const { id } = useParams<{ id?: string | undefined }>();
  const { enqueueSnackbar } = useSnackbar();

  const [editingUser, setEditingUser] = React.useState<
    BackendGetUserDetailsResponse | undefined
  >();
  React.useEffect(() => {
    if (users && id !== undefined) {
      setEditingUser(users.find((u) => u.id === id));
    }
  }, [users, id]);

  const [replacingUser, setReplacingUser] = React.useState<
    BackendGetUserDetailsResponse | undefined
  >();
  const [replacementUser, setReplacementUser] = React.useState<
    BackendGetUserDetailsResponse | undefined
  >();
  React.useEffect(() => {
    setReplacementUser(replacingUser);
  }, [replacingUser]);

  const [usersToDelete, setUsersToDelete] = React.useState<User["id"][]>([]);
  const [creatingUser, setCreatingUser] = React.useState(false);
  const [complaint, setComplaint] = React.useState<
    | undefined
    | {
        message: string;
        link: string | false;
      }
  >();
  const [selectedRows, setSelectedRows] = React.useState<number[]>([]);

  //   const handleUserEditorClose = () => {
  //     setCreatingUser(false);
  //     setEditingUser(undefined);
  //     goBack();
  //   };

  React.useEffect(() => {
    if (editingUser) {
      enqueueSnackbar(
        "Sorry but the user editor is old and funky and needs an update before I can give it to you here",
        { variant: "error" }
      );
      setEditingUser(undefined);
    }
  }, [editingUser, enqueueSnackbar]);

  React.useEffect(() => {
    if (creatingUser) {
      enqueueSnackbar(
        "Sorry but the user editor is old and funky and needs an update before I can give it to you here",
        { variant: "error" }
      );
      setCreatingUser(false);
    }
  }, [creatingUser, enqueueSnackbar]);

  return !users || !users ? (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <CircularProgress />
    </div>
  ) : (
    <div>
      {creatingUser || editingUser ? (
        <Alert
          severity="error"
          onClose={() => {
            setEditingUser(undefined);
            setCreatingUser(false);
          }}
        >
          <AlertTitle>Old Code Alert</AlertTitle>
          Sorry but the user editor is old and funky and needs an update before
          I can give it to you here.
        </Alert>
      ) : undefined}{" "}
      <MUIDataTable
        data={[...users].map((user) => ({
          Email: user.email,
          Name: `${user.firstName} ${user.lastName}`,
          User: prettyName(user),
          //   etag: user.etag,
        }))}
        columns={[
          Column({
            name: "Edit",
            options: {
              download: false,
              filter: false,
              print: false,
              sort: false,
              customBodyRenderLite: (dataIndex) => (
                <IconButton
                  onClick={() => {
                    // push(`/admin/user/${String(users?.[dataIndex].id)}`);
                    setEditingUser(users?.[dataIndex]);
                  }}
                  size="large"
                >
                  <EditIcon />
                </IconButton>
              ),
            },
          }),
          Column({
            name: "Replace",
            options: {
              customBodyRenderLite: (dataIndex) => (
                <IconButton
                  onClick={() => {
                    setReplacingUser(users?.[dataIndex]);
                  }}
                  size="large"
                >
                  <SwapHorizIcon />
                </IconButton>
              ),
              filter: false,
              sort: false,
              download: false,
              hint: "Replace in all projects with another user",
              print: false,
              searchable: false,
            },
          }),
        ].concat(staticColumns)}
        title="Users"
        options={{
          sortOrder: {
            name: "User",
            direction: "asc",
          },
          responsive: "simple",
          // resizableColumns: true,
          rowsSelected: selectedRows,
          onRowSelectionChange: (rowsSelectedData, allRows, rowsSelected) => {
            setSelectedRows(rowsSelected ?? []);
          },
          onRowsDelete: (rowsDeleted: { data: { dataIndex: number }[] }) => {
            setUsersToDelete(
              rowsDeleted.data.map(({ dataIndex }) => users[dataIndex].id)
            );
            return false;
          },
        }}
      />
      {/* <EditUser
        open={Boolean(creatingUser || editingUser)}
        onClose={handleUserEditorClose}
        title={editingUser ? `Edit User` : "New User"}
        onSubmit={(values) => {
          const { newLogoFile, ...ingredients } = values;
          const uploadLogo = uploadSupportingFiles(
            newLogoFile ? [newLogoFile] : [],
            "e/(embryos)",
            setProgress
          );
          if (creatingUser) {
            uploadLogo
              .then((logoFiles) =>
                createUser({ ...ingredients, logo: logoFiles[0]?.storageKey })
              )
              .then(handleUserEditorClose)
              .catch((err) => {
                logError(err);
                setComplaint(
                  "Sorry; something went wrong and we could not create the new user"
                );
              });
          } else {
            if (!editingUser) {
              throw new Error("How did we get here?");
            }
            uploadLogo
              .then((logoFiles) =>
                updateUser({
                  ...editingUser,
                  ...ingredients,
                  logo: logoFiles[0]?.storageKey,
                })
              )
              .then(handleUserEditorClose)
              .catch((err) => {
                logError(err);
                setComplaint(
                  "Sorry; something went wrong and we could not update"
                );
              });
          }
        }}
      /> */}
      <Snackbar
        open={Boolean(complaint)}
        // autoHideDuration={6000}
        onClose={() => setComplaint(undefined)}
      >
        <Alert
          onClose={() => setComplaint(undefined)}
          severity="error"
          action={
            complaint?.link ? (
              <>
                <Button
                  color="secondary"
                  size="small"
                  onClick={() => {
                    complaint?.link && window.open(complaint.link);
                    setComplaint(undefined);
                  }}
                >
                  GO
                </Button>
                <IconButton
                  size="small"
                  aria-label="close"
                  color="inherit"
                  onClick={() => setComplaint(undefined)}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </>
            ) : undefined
          }
        >
          {complaint?.message}
        </Alert>
      </Snackbar>
      <ConfirmDialog
        title="Remove User From All Projects"
        open={Boolean(usersToDelete.length > 0)}
        setOpen={(open: boolean) => {
          if (!open) {
            setUsersToDelete([]);
          }
        }}
        onConfirm={() => {
          Promise.all(
            usersToDelete.map((id) =>
              removeUserFromAllProjects({ id }).catch((err) => {
                logError(err, {
                  user: id,
                });
                throw err;
              })
            ) ?? []
          )
            .then(() => {
              setSelectedRows([]);
            })
            .catch((err) => {
              const {
                reason,
                info: { errors },
              } = err.response?.data ?? {};
              const { project_id } = errors?.[0] ?? {};
              setComplaint({
                message: `Sorry; deletion failed${
                  reason ? `. ${reason}.` : ""
                }`,
                link: project_id && `/projectview/${project_id}`,
              });
            });
        }}
        onCancel={() => {
          setUsersToDelete([]);
        }}
        confirmActionWords="remove"
        // cancelActionWords="cancel"
        defaultAction="cancel"
      >
        <p>
          You asked to delete{" "}
          {usersToDelete && usersToDelete.length > 1 ? "users" : "user"} but we
          don't do that, at least not yet.
        </p>
        <p>
          What we can do is remove them from all projects. Is this what you want
          to do? THERE IS NO UNDO
        </p>
      </ConfirmDialog>
      <ConfirmDialog
        title="Replace User In All Projects"
        open={Boolean(replacingUser)}
        setOpen={(open: boolean) => {
          if (!open) {
            setReplacingUser(undefined);
          }
        }}
        onConfirm={() => {
          console.log({ replacingUser, replacementUser });
          replacingUser &&
            replacementUser &&
            replaceUserInAllProjects(replacingUser, replacementUser)
              .catch((err) => {
                logError(err, {
                  replacingUser,
                  replacementUser,
                });

                const {
                  reason,
                  info: { errors },
                } = err.response?.data ?? {};
                const { project_id } = errors?.[0] ?? {};
                setComplaint({
                  message: `Sorry; replacement failed${
                    reason ? `. ${reason}.` : ""
                  }`,
                  link: project_id && `/projectview/${project_id}`,
                });
              })
              .finally(() => globalMutate(SWRKeys.projects.all()));
        }}
        onCancel={() => {
          setReplacingUser(undefined);
        }}
        confirmActionWords="replace"
        // cancelActionWords="cancel"
        defaultAction="cancel"
      >
        <p>
          Choose a replacement user for{" "}
          {replacingUser && prettyName(replacingUser)}
        </p>
        <Autocomplete
          onChange={(_, emailOrPretty) => {
            setReplacementUser(
              emailOrPretty
                ? usersByPretty[emailOrPretty] || usersByEmail[emailOrPretty]
                : undefined
            );
          }}
          id="replacement-user"
          options={users.map(({ email }) =>
            usersByEmail[email] ? prettyName(usersByEmail[email]) : email
          )}
          defaultValue={
            (replacingUser?.email &&
              usersByEmail[replacingUser.email] &&
              prettyName(usersByEmail[replacingUser.email])) ||
            replacingUser?.email ||
            undefined
          }
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                variant="outlined"
                label={option}
                {...getTagProps({ index })}
              />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              variant="filled"
              label="Replace With"
              placeholder="Enter email"
            />
          )}
        />
      </ConfirmDialog>
      <Fab
        color="primary"
        aria-label="add"
        onClick={() => {
          setCreatingUser(true);
        }}
      >
        <AddIcon />
      </Fab>
    </div>
  );
};
