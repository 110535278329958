import * as z from "zod";
import * as t from "io-ts";
import { LoanType, LoanTypeC } from "@lib/LoanType";
import { DateFromDateOrISO, maybe } from "@lib/util/typeUtils";
// import { storedFileSchema } from "@lib/APITypes";

// TODO: share, don't copy
/**
 * This is what a fully-formed loan looks like to us
 */
export const loanDataSchema = z.object({
  retention: z.number().nonnegative().max(100).nullable(),
  maxDraws: z
    .number()
    .int({ message: "Should be a whole number" })
    .nonnegative()
    .nullable(),
  loanAmount: z.number().nonnegative().nullable(),
  disbursedAtClosing: z.number().nonnegative().nullable(),
  loanType: z.nativeEnum(LoanType),
  loanIdentifier: z.string().nullable(),
  loanContact: z.string().nullable(), // comes from backend as array but we carry around a newline delimited string b/c all we do it put it in a form
  loanBorrowerOverride: z.string().nullable(),
  startDate: z.date().nullable(),
  maturityDate: z.date().nullable(),
  interestRate: z.number().min(0).max(100).nullable(),
  reporting: z
    .object({
      enabled: z.boolean(),
      type: z.enum(["Interest Reserve", "Invoice"]),
      interestReserve: z.number().nonnegative().nullable(),
      statementReturnAddress: z.string(), // TODO: Should come from entity, most likely
    })
    .nullable(),
  // Can't make up my mind how to deal with these: how are they associated with the loan? do we always need them like we need to the other stuff? probably not.
  // files: z.array(storedFileSchema),
});

export type LoanModel = z.infer<typeof loanDataSchema>;

/**
 * io-ts version of what we have in @see loanDataSchema above. It's not as convenient to define things like min length and such (see e.g.
 * https://github.com/gcanti/io-ts/issues/487) so for now we're just doing a lenient bare-bones version in io-ts. What this means is that
 * you should use zod for the form resolver, for better messages, and also for stricter validation.
 *
 */
export const LoanModelC = t.type(
  {
    // retention: maybe(t.number),
    maxDraws: maybe(t.number),
    loanAmount: maybe(t.number),
    disbursedAtClosing: maybe(t.number),
    loanType: LoanTypeC,
    loanIdentifier: maybe(t.string),
    loanContact: maybe(t.string),
    loanBorrowerOverride: maybe(t.string),
    startDate: maybe(DateFromDateOrISO),
    maturityDate: maybe(DateFromDateOrISO),
    interestRate: maybe(t.number),
    reporting: maybe(
      t.union([
        t.type(
          {
            enabled: t.literal(false),
          },
          "(disabled)"
        ),
        t.type({
          enabled: t.boolean,
          type: t.union([t.literal("Interest Reserve"), t.literal("Invoice")]),
          interestReserve: maybe(t.number),
          statementReturnAddress: t.string,
        }),
      ])
    ),
  },
  "LoanModelC"
);
