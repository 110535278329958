import { FormatNumberOptions, useIntl } from "react-intl";

const moneyProps: FormatNumberOptions = {
  style: "currency",
  currency: "USD",
  currencySign: "accounting",
};

const shortOptions: FormatNumberOptions = {
  notation: "compact",
  compactDisplay: "short",
};

const wholeOptions: FormatNumberOptions = {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
};

export const useMoney: () => {
  formatMoney: (
    n: number,
    options?: { short?: boolean; wholeUnits?: boolean }
  ) => string;
} = () => {
  const { formatNumber } = useIntl();
  return {
    formatMoney: (n, options?: { short?: boolean; wholeUnits?: boolean }) =>
      formatNumber(n, {
        ...moneyProps,
        ...(options?.short ? shortOptions : undefined),
        ...(options?.wholeUnits ? wholeOptions : undefined),
      }),
  };
};
