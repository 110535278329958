import React from "react";
import { styled } from '@mui/material/styles';
const PREFIX = 'Terms';

const classes = {
  wrapper: `${PREFIX}-wrapper`
};

const Root = styled('div')({
  [`&.${classes.wrapper}`]: {
    paddingTop: "20px",
  },
});

export default function Terms(): JSX.Element {


  return (
    <Root className={classes.wrapper}>
      <p>
        <strong>Last updated July 1, 2020</strong>
      </p>{" "}
      <p>
        PROJECT CENTERLINE LLC (“PROJECT CENTERLINE LLC” and “We”) provides its
        website, www.projectcenterline.com, social media, and/or PROJECT
        CENTERLINE RESIDENTIAL LENDING SOFTWARE (“SaaS”), whether web or
        mobile-based (collectively, “Digital Property”) to users (hereinafter
        “You” and “Your”) subject to the following Terms of Use which govern
        your use of the Digital Property. Unless otherwise defined, Capitalized
        terms have the meaning as defined in the PROJECT CENTERLINE LLC Privacy
        Policy.
      </p>
      <p>
        This Terms of Use Agreement (this “Agreement”) applies to the Digital
        Property and any and all websites, apps, and online services associated
        with PROJECT CENTERLINE LLC and its affiliates, as well as any websites
        or location where this Agreement is posted (all collectively referred to
        herein as the “Sites”).
      </p>{" "}
      <p>
        By using the Sites, you agree to be bound by all the terms and
        conditions contained in this Terms of Use and any and all rules,
        guidelines and directions found throughout the Sites. Please read this
        Terms of Use carefully. This Terms of Use applies to all users of the
        Sites, including users who are viewers of material on the Sites and
        users who register for the Sites.
      </p>
      <p>
        {" "}
        Your use of the Sites constitutes your agreement to follow and be bound
        by the Terms of Use. We reserve the right to update or modify this Terms
        of Use at any time by posting revisions on the Sites without prior
        notice. Your use of the Sites after such changes have been posted shall
        constitute your acceptance of the revised Terms of Use. For this reason,
        we encourage you to review the Terms of Use whenever you use the Sites.
        If you do not agree to these Terms of Use, please do not use any of
        PROJECT CENTERLINE LLC Sites.
      </p>{" "}
      <p>
        BY ACCESSING OR USING THE SITES AND DIGITAL PROPERTY IN ANY WAY OR BY
        PROVIDING SUBMISSIONS TO PROJECT CENTERLINE LLC THROUGH THE SITES, YOU
        ARE AGREEING TO THE TERMS OF USE AND PRIVACY POLICY. IN ADDITION, WHEN
        USING PARTICULAR PARTS OF THE SITES, YOU AGREE TO ABIDE BY ALL EXPRESSLY
        STATED GUIDELINES FOR THOSE SERVICES. SHOULD YOU OBJECT TO ANY PART OF
        THIS AGREEMENT YOU MAY NOT ACCESS OR USE THE SITES. PLEASE REVIEW OUR
        PRIVACY POLICY
      </p>{" "}
      <p>
        <strong>Ownership of Site Content</strong>
        <br /> The Sites and its elements are protected by copyright, trademark
        and intellectual property rights laws and treaties. No right, title or
        interest in any content is transferred to you as a result of any
        downloading or copying. You may not reproduce, publish, transmit,
        distribute, display, modify, create derivative works from, sell or
        exploit in any way any of the Sites or its contents unless you have the
        express prior written permission of PROJECT CENTERLINE LLC.
      </p>
      <p>
        <strong>Submissions of User Content, Information, and/or Data</strong>
        <br /> You are responsible for any information, data, profiles,
        messages, text, files, images, photos, video, music, sounds, or other
        content or materials (“User Content”) that you submit, upload, post or
        otherwise provide or make available to PROJECT CENTERLINE LLC on or
        through the Sites or Digital Property (“Submissions”), including User
        Content Submissions from third party websites including Instagram,
        Twitter, and Facebook. PROJECT CENTERLINE LLC may use the Submissions in
        the Sites, Digital Property, and on other PROJECT CENTERLINE LLC
        content, products or marketing materials including emails, social media
        and television content.
      </p>{" "}
      <p>
        We are under no obligation to display, feature or use any Submission,
        and reserve the right, at our sole discretion, to edit any Submission
        and to choose to utilize such Submission or portion thereof on the Sites
        or in any other manner. You irrevocably waive any "moral rights" or
        other rights with respect to attribution of authorship of any Submission
        or portion thereof. All Submissions are non-confidential and will be
        treated as non-proprietary, except as specifically set forth herein. By
        making a Submission you represent and warrant that you have the right to
        grant, and do grant, PROJECT CENTERLINE LLC, a nonexclusive,
        royalty-free, worldwide, perpetual, transferable, irrevocable, and fully
        sublicensable right and license to use, reproduce, modify, adapt,
        publish, sell, assign, translate, create derivative works from,
        distribute, perform and display any Submission or portion thereof, alone
        or as part of other works, without further notice or any compensation to
        you.
      </p>{" "}
      <p>
        If you make a Submission, you represent and warrant that you own or
        otherwise control any rights to your Submission and any and all elements
        thereof; that you have the rights from any and all third parties
        appearing in such Submission to grant the license contained in these
        Terms of Use for such third parties' names, images or likenesses and any
        other third party-owned elements as necessary in and as part of your
        Submission; and that your Submission will not infringe or violate the
        rights of any third parties, including, but not limited to, copyrights,
        trademarks, rights of publicity/privacy, patent, trade secret or
        confidentiality. You further represent and warrant that your Submissions
        comply with all applicable laws, rules and regulations, and any
        third-party agreements to which you are subject.
      </p>{" "}
      <p>
        You further represent and warrant that your Submissions do not
        constitute or contain software viruses, malicious code commercial
        solicitation, chain letters, mass mailings, or any form of "spam." You
        may not use a false email address, impersonate any person or entity, or
        otherwise mislead as to the origin of any Submission. You expressly
        agree to indemnify PROJECT CENTERLINE LLC for all claims arising from or
        in connection with use of any Submission and any breach by you of any of
        these Terms of Use or applicable laws. You agree that PROJECT CENTERLINE
        LLC shall have no liability for any damages resulting from, the use
        (including, without limitation, re-publication) or misuse by any third
        party of any Submission.
      </p>{" "}
      <p>
        The Sites may include the opinions, statements and other content of
        third parties and any opinions, statements, or other materials made
        available by third parties through the Sites are those of such third
        parties and not of PROJECT CENTERLINE LLC, including its vendors, and
        PROJECT CENTERLINE LLC does not expressly or impliedly endorse any such
        opinions, statements, or materials.
      </p>{" "}
      <p>
        <strong>Feedback</strong>
        <br /> Any questions, comments, suggestions, or other information about
        PROJECT CENTERLINE LLC Sites, Digital Property, programs, campaigns,
        products or services submitted to PROJECT CENTERLINE LLC through the
        Sites (“Feedback”) shall be deemed non-confidential and non-proprietary.
        PROJECT CENTERLINE LLC is free to use, reproduce, disclose and
        distribute such Feedback in any manner without limitation. PROJECT
        CENTERLINE LLC is not responsible for Feedback made available through
        the Sites and that by using the Sites, you may be exposed to Feedback
        and User Content that is offensive, indecent, inaccurate, misleading, or
        otherwise objectionable. You acknowledge that PROJECT CENTERLINE LLC
        does not pre-screen or approve Feedback and User Content, but that
        PROJECT CENTERLINE LLC shall have the right (but not the obligation) in
        its sole discretion to refuse, delete or move any Feedback and User
        Content for any reason. You agree that you must evaluate, and bear all
        risks associated with, the use of or reliance on any Feedback and User
        Content. Under no circumstances will PROJECT CENTERLINE LLC be liable in
        any way for any Feedback and User Content or for any loss or damage of
        any kind incurred as a result of the use of any Feedback and User
        Content posted, emailed or otherwise made available.
      </p>
      <p>
        <strong>User Conduct</strong>
        <br /> You agree not to transmit or make any Submission or User Content
        that: (i) is unlawful, harmful, threatening, abusive, harassing,
        defamatory, libelous, invasive of another's privacy, or is harmful to
        minors in any way; (ii) is pornographic or depicts a human being engaged
        in actual sexual conduct; (iii) harasses, degrades, intimidates or is
        hateful toward an individual or group of individuals on the basis of
        religion, gender, sex, sexual orientation, race, ethnicity, age, or
        disability; (iv) impersonates any person or entity, including, but not
        limited to, a PROJECT CENTERLINE LLC employee, or falsely states or
        otherwise misrepresents your affiliation with a person or entity; (v)
        that includes personal or identifying information about another person
        without that person's explicit consent; (vi) is false, deceptive,
        misleading, or deceitful; (vii) infringes any patent, trademark, trade
        secret, copyright or other proprietary rights of any party, or
        Submissions that you do not have a right to make available under any law
        or under contractual or fiduciary relationships; (viii) that constitutes
        or contains "affiliate marketing," "link referral code," "junk mail,"
        "spam," "chain letters," "pyramid schemes," or unsolicited commercial
        advertisement; (ix) constitutes or contains any form of advertising or
        solicitation if posted in areas of the Sites which is not designated for
        such purposes or emailed to PROJECT CENTERLINE LLC users who have not
        indicated in writing that it is ok to contact them about other services,
        products or commercial interests; (x) advertises any illegal service;
        (xi) contains software viruses or any other computer code, files or
        programs designed to interrupt, destroy or limit the functionality of
        any computer software, hardware or telecommunications equipment; (xii)
        disrupts the normal flow of dialogue with an excessive amount of
        Submissions (flooding attack) to the Sites, or that otherwise negatively
        affects other users' ability to use the Sites; (xiii) that employs
        misleading email addresses, or forged headers or otherwise manipulated
        identifiers in order to disguise the origin of Submissions transmitted
        through the Sites.
      </p>{" "}
      <p>
        Additionally, you agree not to: (i) contact anyone who has asked not to
        be contacted, or make unsolicited contact with anyone for any commercial
        purpose; (ii) "stalk" or otherwise harass anyone through the Sites;
        (iii) collect personal data about other users for commercial or unlawful
        purposes; (iv) use automated means, including spiders, robots, crawlers,
        data mining tools, or the like to download data from the website; (v)
        post irrelevant User Content, repeatedly post the same or similar User
        Content or otherwise impose an unreasonable loads on our Sites
        infrastructure; (vi) post any deceptive events; or (vii) attempt to gain
        unauthorized access to PROJECT CENTERLINE LLC computer systems or engage
        in any activity that disrupts, diminishes the quality of, interferes
        with the performance of, or impairs the functionality of the Sites.
      </p>{" "}
      <p>
        <strong>Establishing an Account</strong>
        <br /> The Sites is not intended to be used by children under the age of
        13 and children under the age of 13 are not to submit any personally
        identifying information through the Sites. In addition, you may only
        establish an account if you are 18 years of age or over. In order to
        access/use some features on the Sites, you may be required to establish
        and use an account and to provide your name and contact information.
        When you register for an account you must (i) provide accurate and
        truthful information, and (ii) update such information from time to time
        as necessary to keep your registration information current and accurate.
        By establishing an account, you represent and warrant you have the right
        and are authorized to provide the information you provide when you
        register for the account. You are responsible for maintaining the
        confidentiality of your account information and password and for
        restricting access to such information and to your computer. All
        activities that occur under your account Or Password Shall Be Your
        Responsibility.
      </p>{" "}
      <p>
        <strong>
          Availability of Products, Services, Features and Content
        </strong>
        <br /> All Digital Property, Services, products, programs, campaigns,
        features and content available on or through the Sites are subject to
        change and discontinuation at any time, in our sole discretion, without
        notice, in accordance with Master Services Agreements.
      </p>{" "}
      <p>
        <strong>External Sites</strong>
        <br /> The Sites may contain links to other sites on the Internet that
        are owned and operated by third party vendors and other third parties
        (the “External Sites”). You acknowledge that PROJECT CENTERLINE LLC is
        not responsible for the unavailability of, or the content located on or
        through, any External Site. You should contact the site administrator or
        webmaster for those External Sites if you have any concerns regarding
        such links or the content located on such External Sites.
      </p>{" "}
      <p>
        <strong>Copyright and Trademark</strong>
        <br /> PROJECT CENTERLINE LLC trademarks displayed on the Sites are
        trademarks or registered trademarks of PROJECT CENTERLINE LLC and its
        affiliated companies in the United States and internationally (including
        but not limited to all logos). All trademarks used by PROJECT CENTERLINE
        LLC but not owned by PROJECT CENTERLINE LLC are used by permission and
        are the sole property of their respective owners. All unauthorized use
        trademarks displayed on this Sites is prohibited.
      </p>{" "}
      <p>
        The Sites are protected under laws and all rights are reserved except as
        expressly provided herein. Individual documents may contain different
        copyright notices and/or additional proprietary notices. If you believe
        that your work has been copied in a way that constitutes copyright
        infringement, please notify the PROJECT CENTERLINE LLC agent for notice
        of claims of copyright infringement ("Legal Department"), at
        legal@projectcenterline.com. You must provide our Legal Department with
        the following:
      </p>{" "}
      <ol>
        <li>
          Identify the material on the Sites that you claim is infringing, with
          enough detail so that PROJECT CENTERLINE LLC may locate it on the
          Sites;
        </li>
        <li>
          A statement by you that you have a good faith belief that the disputed
          use is not authorized by the copyright owner, its agent, or the law;
        </li>
        <li>
          A statement by you declaring that (a) the above information in your
          Notice is accurate, and (b) that you are the owner of the copyright
          interest involved or that you are authorized to act on behalf of that
          owner;
        </li>
        <li>Your address, telephone number, and email address; and</li>
        <li>Your physical or electronic signature.</li>
      </ol>
      <p>
        If determined to be valid, PROJECT CENTERLINE LLC will remove the
        infringing posting(s), subject to the procedures outlined in the Digital
        Millennium Copyright Act (DMCA).
      </p>{" "}
      <p>
        <strong>Notice of Infringement</strong>
        <br /> PROJECT CENTERLINE LLC respects and honors the intellectual
        property of others. If you believe that your work has been copied and is
        accessible on the Sites in a way that constitutes infringement, please
        provide PROJECT CENTERLINE LLC with the following information: (1)
        identification of the work claimed to have been infringed; (2)
        identification of the allegedly infringing material on the Sites that is
        requested to be removed; (3) your name, address, and daytime telephone
        number, and an email address, so that PROJECT CENTERLINE LLC may contact
        you if necessary; (4) a statement that you have a good faith belief that
        the use of the work is not authorized by the owner, its agent, or the
        law; (5) a statement that the information in the notification is
        accurate and, under penalty of perjury, that the signatory is authorized
        to act on behalf of the owner of an exclusive right that is allegedly
        infringed; and (6) an electronic or physical signature of the owner or
        someone authorized on the owner's behalf to assert infringement and to
        submit the statement.
      </p>{" "}
      <p>
        Pursuant to our rights under the Digital Millennium Copyright Act
        ("DMCA"), we have designated an agent to receive infringement notices.
        PROJECT CENTERLINE LLC agent for notice of claims of copyright or
        trademark infringement is legal@projectcenterline.com.
      </p>{" "}
      <p>
        <strong>Forums</strong>
        <br /> As used in these Terms of Use, "Forum" means an area, site, or
        feature (such as rooms, chat rooms, bulletin boards, PROJECT CENTERLINE
        LLC pages on the Sites, Digital Property, social media sites, and
        e-mail, communication or instant messaging features) offered as part of
        the Sites or Digital Property that enables visitors to submit, display
        and/or view content and/or to communicate, share or exchange content
        with other Sites visitors and members of the general public. Any
        submission may be routed through our servers, third party servers on our
        behalf, and the Internet (where it may be viewed by the general public),
        so you have no expectation of privacy for any submitted content. Since
        we cannot guarantee security of information on a Forum, your use is at
        your own risk.
      </p>{" "}
      <p>
        We are not responsible for the accuracy of any user content contained in
        any Forum or otherwise on our Sites.
      </p>{" "}
      <p>
        <strong>Indemnity</strong>
        <br /> You agree to defend, indemnify, and hold harmless PROJECT
        CENTERLINE LLC and its affiliates, vendors, successors and assigns, and
        its and their respective directors, officers, employees, agents,
        co-branders or other partners from and against any and all allegations,
        claims, demands, actions, causes of action, proceedings (whether
        threatened or pending), orders, damages, losses, liabilities, costs and
        expenses, including reasonable attorney's fees and other legal expenses,
        and judgments of any kind of nature, incurred by PROJECT CENTERLINE LLC
        arising out of or relating to your use or misuse, or anyone using your
        account’s use or misuse, of the Sites or Digital Property, your
        violation of these Terms of Use, or your violation, or anyone using your
        account’s violation, of any rights of another.
      </p>{" "}
      <p>
        <strong>Waiver and Severability</strong>
        <br /> No waiver of these Terms of Use by PROJECT CENTERLINE LLC shall
        be deemed a further or continuing waiver and any failure of PROJECT
        CENTERLINE LLC to assert a right or provision under these Terms of Use
        shall not constitute a waiver of such right or provision. If any
        provision of these Terms of Use is held by a court of competent
        jurisdiction to be invalid, illegal or unenforceable for any reason,
        such provision shall be eliminated or limited to the minimum extent such
        that the remaining provisions of the Terms of Use will continue in full
        force and effect.
      </p>{" "}
      <p>
        <strong>Entire Agreement</strong>
        <br /> These Terms of Use, together with any other agreements
        incorporated by reference therein, constitute the entire agreement, and
        supersede all prior written agreements, arrangements, communications and
        understandings and all prior and contemporaneous oral agreements,
        arrangements, communications and understandings, whether electronic,
        oral, or written, between you and PROJECT CENTERLINE LLC with respect to
        the Services, Sites, or Digital Property.
      </p>{" "}
      <p>
        <strong>General Terms</strong>
        <br /> PROJECT CENTERLINE LLC’s performance of these Terms of Use is
        subject to existing laws and legal process and nothing contained in this
        agreement is in derogation of PROJECT CENTERLINE LLC’s right to comply
        with law enforcement requests or requirements relating to your use of
        the Services, Sites, or Digital Property or information provided to or
        gathered by PROJECT CENTERLINE LLC with respect to such use.
      </p>{" "}
      <p>
        The section titles in these Terms of Use are for convenience only and
        have no legal or contractual effect.
      </p>{" "}
      <p>
        <strong>Governing Law</strong>
        <br /> To the fullest extent permitted by law, these Terms of Use and
        any claim or dispute arising from these Terms of Use will be governed by
        and construed in accordance with the State of California, without regard
        to its choice of law provisions. Any claim or dispute arising out of or
        relating to our content, products and services, these Terms of Use, the
        Privacy Policy or your use of the Sites, Service, and/or Digital
        Property shall be subject to the exclusive jurisdiction of state or
        federal courts in Santa Clara County, in the State of California and you
        hereby consent and submit to the personal jurisdiction of such courts.
        If any provision of these Terms of Use is held to be unlawful, or for
        any reason, unenforceable, that provision will be deemed severable and
        will not affect the validity and enforceability of the remaining
        provisions. These Terms of Use and the Privacy Policy constitute the
        entire agreement between you and PROJECT CENTERLINE LLC with respect to
        the subject matter of the Terms of Use and Privacy Policy.
      </p>
      <p>
        <strong>
          Contact If you have any questions about these Terms of Use or PROJECT
          CENTERLINE LLC, please contact us legal@projectcenterline.com.
        </strong>
      </p>
    </Root>
  );
}

// spell-checker:ignore sublicensable another's LLC’s
