import React, { PropsWithChildren } from "react";
import { styled } from "@mui/material/styles";
import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Switch,
  FormControlLabel,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { JSONTree } from "react-json-tree";
import Button from "@mui/material/Button";
import {
  legacyBackendGetProjectDetailsResponse,
  BackendGetProjectTasksResponse,
  BackendUserResponse,
} from "@lib/APITypes";
import { Features, useBackend, useFeatures } from "@lib/hooks";
import config from "../../../config";
import { onError } from "@lib/ErrorLogging";
import { useEntity } from "@lib/hooks/useEntity";
import { getProjectUsers } from "@lib/API";
import { useDeviceSelectors } from "react-device-detect";

const PREFIX = "ProjectAdminView";

const classes = {
  root: `${PREFIX}-root`,
  input: `${PREFIX}-input`,
  tree: `${PREFIX}-tree`,
  backdrop: `${PREFIX}-backdrop`,
  wide: `${PREFIX}-wide`,
};

const Root = styled("div")(({ theme: { zIndex } }) => ({
  [`&.${classes.root}`]: {
    width: "100%",
  },

  [`& .${classes.input}`]: {
    width: 42,
  },

  [`& .${classes.backdrop}`]: {
    zIndex: zIndex.drawer + 1,
    color: "#fff",
  },

  [`& .${classes.wide}`]: {
    width: "100%",
  },
}));

// cloneProject has been deleted; if we want it back we should do it on the backend instead, leveraging the demo project creator/cloner

type OverridableFeatures = Pick<
  Features,
  | "conciergeCreatedDraws"
  | "dhlcCustom"
  | "drawReportHTML"
  | "showMaxDraws"
  | "reportingFeature"
>;

const featureDescriptions: Record<keyof OverridableFeatures, string> = {
  conciergeCreatedDraws: "Concierge Created Draws",
  dhlcCustom: "DHLC Custom",
  drawReportHTML: "HTML Draw Reports",
  showMaxDraws: "Show Max Draws",
  reportingFeature: "reporting",
};

const overrideFeatures: (keyof OverridableFeatures)[] = [
  "conciergeCreatedDraws",
  "drawReportHTML",
  "dhlcCustom",
  "showMaxDraws",
  "reportingFeature",
];

export function AdminView({
  project,
  children,
  ...extraData
}: PropsWithChildren<
  {
    project?: legacyBackendGetProjectDetailsResponse;
  } & Record<string, unknown>
>): JSX.Element | null {
  const { project_id } = project ?? {};

  const { getProjectTasks } = useBackend();

  const features = useFeatures();

  const [overriddenFeatures, setOverriddenFeatures] = React.useState<
    Partial<
      Record<keyof OverridableFeatures, ReturnType<typeof features.override>>
    >
  >({});

  const [userDetails, setUserDetails] = React.useState<BackendUserResponse[]>(
    []
  );
  React.useEffect(() => {
    async function fetchUserDetails() {
      setUserDetails(project_id ? await getProjectUsers(project_id) : []);
    }

    fetchUserDetails();
  }, [project_id, setUserDetails]);

  const [taskDetails, setTaskDetails] = React.useState<
    BackendGetProjectTasksResponse | undefined
  >();
  React.useEffect(() => {
    async function fetchTaskDetails() {
      setTaskDetails(
        project_id === undefined ? undefined : await getProjectTasks(project_id)
      );
    }

    fetchTaskDetails();
  }, [getProjectTasks, project_id, setTaskDetails]);

  const [selectors, data] = useDeviceSelectors(window.navigator.userAgent);

  const { myEntity } = useEntity();
  return (
    <Root className={classes.root}>
      <Grid container justifyContent="space-around">
        <Grid item>
          <Button
            onClick={() => {
              window.open(`/admin/users`, "_blank");
            }}
          >
            Users
          </Button>
        </Grid>
        <Grid item>
          <Button
            onClick={() => {
              window.open(`/admin/entity`, "_blank");
            }}
          >
            Entities
          </Button>
        </Grid>
        <Grid item>
          <Button
            onClick={() => {
              window.open(`/admin/entity/${myEntity?.id}`, "_blank");
            }}
          >
            My Entity
          </Button>
        </Grid>
      </Grid>
      <Grid item>
        <hr />
      </Grid>
      <Grid container spacing={2}>
        {/* Clone and future controls go on the left */}
        <Grid item xs={3}>
          <Grid item margin={2}>
            {children}
          </Grid>
          <Grid container spacing={2} alignItems="center">
            <Accordion className={classes.wide}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Features</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container direction="row">
                  {overrideFeatures.map((f) => (
                    <Grid item key={f}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={features[f]}
                            onChange={({ target: { checked } }) => {
                              const over = overriddenFeatures[f];
                              if (over) {
                                if (over.value !== checked) {
                                  // undo
                                  over.done();
                                  setOverriddenFeatures((of) =>
                                    Object.fromEntries(
                                      Object.entries(of).filter(
                                        ([key]) => key !== f
                                      )
                                    )
                                  );
                                }
                              } else {
                                // we weren't overriding, so start
                                setOverriddenFeatures((of) => ({
                                  ...of,
                                  [f]: features.override(f, checked),
                                }));
                              }
                            }}
                            name={f}
                          />
                        }
                        label={featureDescriptions[f]}
                      />
                    </Grid>
                  ))}
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion className={classes.wide}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Device</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container direction="row">
                  <Grid item>
                    <JSONTree
                      theme={{
                        // spell-checker:disable
                        scheme: "monokai",
                        author: "wimer hazenberg (http://www.monokai.nl)",
                        // spell-checker: enable
                        base00: "#272822",
                        base01: "#383830",
                        base02: "#49483e",
                        base03: "#75715e",
                        base04: "#a59f85",
                        base05: "#f8f8f2",
                        base06: "#f5f4f1",
                        base07: "#f9f8f5",
                        base08: "#f92672",
                        base09: "#fd971f",
                        base0A: "#f4bf75",
                        base0B: "#a6e22e",
                        base0C: "#a1efe4",
                        base0D: "#66d9ef",
                        base0E: "#ae81ff",
                        base0F: "#cc6633",
                      }}
                      invertTheme={true}
                      hideRoot={true}
                      data={{
                        selectors,
                        data,
                      }}
                      // shouldExpandNodeInitially={(keyPath, data, level) => {
                      //   return level === 1 && keyPath[0] === "project";
                      // }}
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion className={classes.wide}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Other</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container direction="row">
                  <Grid item>
                    <Button
                      onClick={() => {
                        onError(
                          new Error("I'm about to throw (intentionally)")
                        );
                        throw new Error(`Intentional error... ${3 / 0}`); // lol
                      }}
                    >
                      Intentional Error
                    </Button>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
        {/* Data tree on the right */}
        <Grid item xs={9}>
          <Grid container direction="column">
            <Grid item>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                }}
              >
                <JSONTree
                  theme={{
                    // spell-checker:disable
                    scheme: "monokai",
                    author: "wimer hazenberg (http://www.monokai.nl)",
                    // spell-checker: enable
                    base00: "#272822",
                    base01: "#383830",
                    base02: "#49483e",
                    base03: "#75715e",
                    base04: "#a59f85",
                    base05: "#f8f8f2",
                    base06: "#f5f4f1",
                    base07: "#f9f8f5",
                    base08: "#f92672",
                    base09: "#fd971f",
                    base0A: "#f4bf75",
                    base0B: "#a6e22e",
                    base0C: "#a1efe4",
                    base0D: "#66d9ef",
                    base0E: "#ae81ff",
                    base0F: "#cc6633",
                  }}
                  invertTheme={true}
                  hideRoot={true}
                  data={{
                    ...(project && {
                      project: project && {
                        ...project,
                        loanAmount: String(project.loanAmount),
                      },
                      userDetails,
                      taskDetails,
                    }),
                    ...extraData,
                    config,
                  }}
                  shouldExpandNodeInitially={(keyPath, data, level) => {
                    return level === 1 && keyPath[0] === "project";
                  }}
                />
              </div>
            </Grid>
            {project ? (
              <Grid item>
                {/* <Accordion className={classes.wide}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Statement</Typography>
                  </AccordionSummary>
                  <AccordionDetails> */}
                <Grid container direction="row">
                  <Grid container spacing={2}>
                    <Grid item>
                      <a
                        rel="noopener noreferrer"
                        // spell-checker:ignore pdftest pdftestfromtemplate
                        href={`${config.apiGateway.URL}pdftest?projectId=${project_id}&html=1`}
                        target="_blank"
                      >
                        HTML Statement
                      </a>
                    </Grid>{" "}
                    <Grid item>
                      <a
                        rel="noopener noreferrer"
                        // spell-checker:ignore pdftest pdftestfromtemplate
                        href={`${config.apiGateway.URL}pdftest?projectId=${project_id}`}
                        target="_blank"
                      >
                        Statement
                      </a>
                    </Grid>{" "}
                    <Grid item>
                      <a
                        rel="noopener noreferrer"
                        href={`${config.apiGateway.URL}pdftestfromtemplate?projectId=${project_id}`}
                        target="_blank"
                      >
                        Fancy Statement
                      </a>
                    </Grid>
                  </Grid>
                </Grid>
                {/* </AccordionDetails>
                </Accordion> */}
              </Grid>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
    </Root>
  );
}

/**
 * Project Admin component intended only for Super Admins. Allows project inspection and cloning (so far)
 * @param {object} props props, most importantly containing `data` which should be a project as retrieved by "getproject"
 */
export default function ProjectAdminView(props: {
  data: legacyBackendGetProjectDetailsResponse;
  extraData?: Record<string, unknown>;
}): JSX.Element | null {
  return <AdminView project={props.data} {...props.extraData} />;
}

// spell-checker:words getproject getprojectitems addproject originalvalue
// spell-checker:words appraisedvalue originalsquarefeet newsquarefeet squarefeet owneroccupied
// spell-checker:words projecttype workorder servicetype totalloanamount timeframe
// spell-checker:words addtask taskvalue labelledby getuserfullinfo
