import React from "react";

/**
 * Create a ref and a callback to cause it to get focus
 *
 * Example:
 *   const [ref, focusIt] = useFocus()
 *    ...
 *    onClick={focusIt}
 *    ...
 *    <TextInput inputRef={ref}/>
 */

export const useFocus: <T extends { focus(): void } = HTMLElement>() => [
  React.MutableRefObject<T>,
  () => void
] = <T extends { focus(): void }>() => {
  const elementRef = React.useRef<T>(null) as React.MutableRefObject<T>;
  const setFocus = () => {
    if (elementRef.current) {
      elementRef.current.focus();
    }
  };

  return [elementRef, setFocus];
};
