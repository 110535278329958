import { Link, useParams } from "react-router-dom";

import CreateDrawRequest from "./CreateDrawRequest";
import { useProjectDetails } from "@lib/hooks/useProjectDetails";
import { throwIfNot } from "@lib/util/throwIfNot";
import React from "react";
import { LocalTaskFile } from "./LocalTaskFile";
import Center from "@/Components/Center";
import { Typography } from "@mui/material";
import { useProjectTasks } from "@/Lib/hooks/useProjectTasks";
import EditTaskDialog from "./EditTaskDialog";
import { usePermissions } from "@/Lib/hooks";
import { ConfirmDialog } from "@/Components/ConfirmDialog";
import { Task } from "@/Models";
import * as tt from "io-ts-types";
import { backOutRetention } from "@/Lib/hacks";
import { useProjectInvoices } from "@/Lib/hooks/useProjectInvoices";
import { hasBeenInvoiced } from "./hasBeenInvoiced";

const Component = () => {
  const projectId = throwIfNot(
    useParams<"projectId">().projectId,
    "Missing project id"
  );

  const { canCompleteTasks } = usePermissions();
  const { project } = useProjectDetails(projectId); // TODO: How to handle required react router params. e.g. https://github.com/remix-run/react-router/issues/8498
  const {
    project: { invoices },
  } = useProjectInvoices(projectId);
  const [files, setFiles] = React.useState<LocalTaskFile[]>([]);
  React.useEffect(() => {
    console.log("files =>", files);
  }, [files]);

  const [onFilesUpdated, setOnFilesUpdated] =
    React.useState<() => void | undefined>();
  React.useEffect(() => {
    if (onFilesUpdated) {
      onFilesUpdated();
      setOnFilesUpdated(undefined);
    }

    return undefined;
  }, [onFilesUpdated]);

  const [taskToDelete, setTaskToDelete] = React.useState<
    undefined | Task["id"]
  >();
  const [taskToEdit, setTaskToEdit] = React.useState<undefined | Task>();

  const {
    project: { tasks, forceRefreshTasks, completeTask, deleteTask },
  } = useProjectTasks(projectId);

  if (!project?.details) {
    return null;
  }

  if (tasks?.tasks?.length === 0) {
    return (
      <Center>
        <Typography>
          Can't create draws without a budget.{" "}
          <Link to="../../more/budget" relative="path">
            Create one now
          </Link>
          ?
        </Typography>
      </Center>
    );
  }

  return (
    <>
      <CreateDrawRequest
        project={project.details}
        files={files}
        onDeleteFile={(file) => {
          // changing files out from under the image gallery confuses things; this could be better
          setFiles((files) => files.filter((f) => f.href !== file.href));
          // setTaskToDelete(undefined);
          return new Promise((resolve) => {
            setOnFilesUpdated(resolve);
          });
        }}
        // project={
        //   {
        //     ...props.data,
        //     retention: Number(props.data.retention),
        //   } /* TODO: sanitize on input */
        // }
        onRefreshNeeded={(reason) => {
          if (reason === "Invoice Created") {
            setFiles([]);
            // setInvoiceTypeDisplayed(ApprovalStatus.Pending);
          } else if (reason === "Task Deleted") {
            forceRefreshTasks();
          }
        }}
        onTaskCompleteRequested={
          canCompleteTasks
            ? (taskId, completed) =>
                completeTask(taskId, completed).then(forceRefreshTasks)
            : undefined
        }
        onTaskDeleteRequested={setTaskToDelete}
        onTaskEditRequested={(taskId) =>
          setTaskToEdit(tasks?.tasks.find(({ id }) => id === taskId))
        }
        onTaskViewRequested={(taskId) =>
          setTaskToEdit(tasks?.tasks.find(({ id }) => id === taskId))
        }
      />
      {taskToEdit ? (
        <EditTaskDialog
          taskTitleEditable={!hasBeenInvoiced(invoices)(taskToEdit)}
          done={() => setTaskToEdit(undefined)}
          open={Boolean(taskToEdit)}
          task={{
            ...taskToEdit,
            task_value: backOutRetention(
              taskToEdit.task_value,
              project.details.retention || 0
            ),
          }}
          onNewFiles={(files) =>
            setFiles((existing) =>
              existing.concat(
                files.map((f) => ({
                  title: f.name as tt.NonEmptyString,
                  href: URL.createObjectURL(f) as tt.NonEmptyString,
                  id: taskToEdit.id,
                  file: f,
                }))
              )
            )
          }
        />
      ) : null}
      <ConfirmDialog
        onConfirm={() => {
          taskToDelete &&
            deleteTask(taskToDelete).then(() => setTaskToDelete(undefined));
        }}
        open={Boolean(taskToDelete)}
        title="Are You Sure?"
        setOpen={(wantsToBeOpen) => wantsToBeOpen || setTaskToDelete(undefined)}
      >
        <Typography>Delete task? There is no undo.</Typography>
      </ConfirmDialog>
    </>
  );
};

export default Component;
