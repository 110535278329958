import { SxProps, Theme } from "@mui/material";
import { styled } from "@mui/system";

export const alignHorizontalCenters = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};

// https://github.com/Project-Centerline/project-centerline-ac-frontend/issues/1400
export const buttonCTA: SxProps<Theme> = ({ palette }) => ({
  color: palette.cta.main,
  textDecoration: "none",
});
export const buttonCancel: SxProps<Theme> = ({ palette }) => ({
  color: palette.cancel.main,
  textDecoration: "none",
});

const HorizontallyAligned = styled("div")(({ theme: { unstable_sx } }) =>
  unstable_sx(alignHorizontalCenters)
);
export { HorizontallyAligned };
