import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React from "react";
import { ReactChild } from "react";

export const ConfirmDialog: (props: {
  title: string;
  confirmActionWords?: string;
  cancelActionWords?: string;
  children: ReactChild | ReactChild[];
  open: boolean;
  busy?: boolean;
  /** called with `false` when we think we should close */
  setOpen: (open: boolean) => void;
  onConfirm: () => void;
  onCancel?: () => void;
  defaultAction?: "cancel" | "confirm";
}) => JSX.Element = (props) => {
  const {
    title,
    children,
    open,
    busy,
    setOpen,
    onConfirm,
    onCancel,
    cancelActionWords,
    confirmActionWords,
    defaultAction,
  } = props;
  const focusCancel = defaultAction !== "confirm";

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="confirm-dialog"
    >
      <DialogTitle id="confirm-dialog">{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => {
            setOpen(false);
            onCancel?.();
          }}
          color={!focusCancel ? undefined : "primary"}
          autoFocus={focusCancel}
        >
          {`No${cancelActionWords ? `, ${cancelActionWords}` : ""}`}
        </Button>
        {busy ? (
          <CircularProgress />
        ) : (
          <Button
            variant="contained"
            onClick={() => {
              setOpen(false);
              onConfirm();
            }}
            color={focusCancel ? undefined : "primary"}
            autoFocus={!focusCancel}
          >
            {`Yes${confirmActionWords ? `, ${confirmActionWords}` : ""}`}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
