import React from "react";
import { styled } from "@mui/material/styles";
import Select from "@mui/material/Select";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import { ifEnterKey, IfEnterKeyEventHandler } from "@lib/boilerplate";
import { useFocus } from "@lib/hooks/useFocus";
import { logError } from "@lib/ErrorLogging";
import { StepCompleteFn } from "../Pages/QuickSetup";
import { AddressForm, ZodSchema as AddressFormSchema } from "./AddressForm";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import {
  PropertyTypeForm,
  ZodSchema as PropertyTypeFormSchema,
} from "./PropertyTypeForm";
import * as z from "zod";
import { AddProjectInputs } from "@lib/API";
import { Grid } from "@mui/material";
import { zodResolver } from "@hookform/resolvers/zod";

export const FormSchema = AddressFormSchema.merge(PropertyTypeFormSchema);
export type FormInputs = z.infer<typeof FormSchema>;

const Root = styled("div")({
  paddingRight: "5vw",
  paddingLeft: "5vw",
  textAlign: "center",
});

interface ProjectFormProps {
  data: {
    project: Omit<AddProjectInputs, keyof FormInputs>;
  };
  requiredOnly?: boolean;
  setStepComplete?: (complete: boolean) => void;
  addData: (data: string, value: unknown, record: "project") => void;
  onLastFieldEntered: IfEnterKeyEventHandler;
}

interface ProjectInfoStepProps
  extends Omit<ProjectFormProps, "setStepComplete"> {
  setStepComplete: StepCompleteFn;
  onSubmit: (formValues: FormInputs) => void;
}

/**
 * ProjectForm, with required `onStepComplete`
 */
export const ProjectInfoStep: (props: ProjectInfoStepProps) => JSX.Element = ({
  setStepComplete,
  onSubmit,
  ...restProps
}: ProjectInfoStepProps) => {
  const formMethods = useForm<FormInputs>({
    mode: "onChange",
    resolver: zodResolver(FormSchema),
    defaultValues: {
      address: "",
      city: "",
      state: "",
      zipcode: "",
      propertyType: null,
      units: null,
    },
  });

  return (
    <FormProvider {...formMethods}>
      <form
        id="project-data-form"
        onSubmit={formMethods.handleSubmit(onSubmit)}
      >
        <ProjectForm setStepComplete={setStepComplete} {...restProps} />
      </form>
    </FormProvider>
  );
};

export default function ProjectForm({
  data: { project },
  addData,
  onLastFieldEntered,
  setStepComplete,
  requiredOnly,
}: ProjectFormProps): JSX.Element {
  const [values, setValues] = React.useState({
    originalvalue: "",
    appraisedvalue: "",
    originalarea: "",
    appraisedarea: "",
    originalsquarefeet: "",
    newsquarefeet: "",
    squarefeet: "",
  });

  const [projecttype, setProjectType] = React.useState(project.projecttype);

  const { formState } = useFormContext<FormInputs>();
  const { errors } = formState;

  // TODO: these need work. Also, could be useEffect
  const stepComplete =
    !Object.values(errors).some(Boolean) &&
    project.projecttype &&
    project.projecttype === "renovation"
      ? (project.owneroccupied as string).length > 1 &&
        (project.originalsquarefeet as string).length >= 1 &&
        (project.newsquarefeet as string).length >= 1 &&
        (project.originalvalue as string).length >= 1 &&
        Number(project.appraisedvalue) >= 1
      : project.projecttype === "newconstruction"
      ? (project.squarefeet as string).length >= 1 &&
        (project.appraisedvalue as string).length >= 1
      : Boolean(requiredOnly); // renovation, new construction stuff is all optionals

  React.useEffect(() => {
    setStepComplete?.(stepComplete);
    return undefined;
  }, [setStepComplete, stepComplete]);

  // TODO: these type coercions are a mess
  const handleSelectChange = (event: {
    target: { value: unknown; name?: string };
  }) => {
    setProjectType(event.target.value as string);
    if (event.target.name !== undefined) {
      addData(event.target.name, event.target.value, "project");
    } else {
      logError(new Error("Event has no name"), { event });
    }
  };

  const handleChange = (event: {
    target: { name?: string; value: unknown };
  }) => {
    if (event.target.name !== undefined) {
      addData(event.target.name, event.target.value as string, "project");
    } else {
      logError(new Error("Event has no name"), { event });
    }
  };

  const handleControlChange = (event: {
    target: { name: string; value: number | string };
  }) => {
    setValues({ ...values, [event.target.name]: event.target.value });
    addData(event.target.name, event.target.value, "project");
  };

  const [addressRef /* , focusAddress */] = useFocus<HTMLInputElement>();
  const [projectTypeRef, focusProjectType] = useFocus();
  const [ownerOccupiedRef, focusOwnerOccupied] = useFocus();
  const [originalSquareFeetRef, focusOriginalSquareFeet] = useFocus();
  const [newSquareFeetRef, focusNewSquareFeet] = useFocus();
  const [originalValueRef, focusOriginalValue] = useFocus();
  const [appraisedValueRef, focusAppraisedValue] = useFocus();
  const [newConstSquareFeetRef, focusNewConstSquareFeet] = useFocus();
  const [newConstAppraisedValueRef, focusNewConstAppraisedValue] = useFocus();

  return (
    <Root>
      <h1>Property Information</h1>
      <Grid container spacing={2} direction="column">
        <Grid item>
          <AddressForm
            expanded
            addressRef={addressRef}
            onLastFieldEntered={ifEnterKey(
              requiredOnly ? onLastFieldEntered : focusProjectType
            )}
          />
        </Grid>

        <Grid item alignSelf="center">
          <PropertyTypeForm />
        </Grid>

        {requiredOnly ? null : (
          <>
            <Grid item>
              <FormControl variant="standard" fullWidth>
                <InputLabel shrink id="project-type-label">
                  Is the project a Renovation or New Construction?
                </InputLabel>
                <Select
                  variant="standard"
                  required
                  labelId="project-type-label"
                  id="project-type-id"
                  value={project.projecttype}
                  name="projecttype"
                  onChange={handleSelectChange}
                  inputRef={projectTypeRef}
                  onKeyDown={
                    projecttype === "renovation"
                      ? ifEnterKey(focusOwnerOccupied)
                      : projecttype === "newconstruction"
                      ? ifEnterKey(focusNewConstSquareFeet)
                      : undefined
                  }
                  style={{ textAlign: "left" }}
                >
                  <MenuItem value="Select A Type">
                    <em>Select Type</em>
                  </MenuItem>
                  <MenuItem value={"renovation"}>Renovation</MenuItem>
                  <MenuItem value={"newconstruction"}>
                    New Construction
                  </MenuItem>
                </Select>
                <FormHelperText>
                  New construction includes complete tear down with no remaining
                  walls or foundation. Renovation includes if one wall were left
                  intact.
                </FormHelperText>
              </FormControl>
            </Grid>

            {projecttype === "renovation" ? (
              <Grid container direction="row">
                <Grid item>
                  <FormControl variant="standard" fullWidth>
                    <InputLabel shrink id="owneroccupied-label">
                      Will the property be owner-occupied during construction?{" "}
                    </InputLabel>
                    <Select
                      variant="standard"
                      required
                      labelId="owneroccupied-label"
                      id="owneroccupied"
                      name="owneroccupied"
                      value={project.owneroccupied}
                      onChange={handleChange}
                      inputRef={ownerOccupiedRef}
                      onKeyDown={ifEnterKey(focusOriginalSquareFeet)}
                      style={{ textAlign: "left" }}
                    >
                      <MenuItem value="">
                        <em>Select Value..</em>
                      </MenuItem>
                      <MenuItem value={"yes"}>Yes</MenuItem>
                      <MenuItem value={"no"}>No</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl variant="standard" fullWidth>
                    <InputLabel htmlFor="original-area">
                      Original Square Feet
                    </InputLabel>
                    <Input
                      required
                      id="original-area"
                      value={project.originalsquarefeet}
                      name="originalsquarefeet"
                      onChange={handleControlChange}
                      inputRef={originalSquareFeetRef}
                      onKeyDown={ifEnterKey(focusNewSquareFeet)}
                      endAdornment={
                        <InputAdornment position="end">sq. ft</InputAdornment>
                      }
                      aria-describedby="standard-area-helper-text"
                      inputProps={{
                        "aria-label": "weight",
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl variant="standard" fullWidth>
                    <InputLabel htmlFor="original-area">
                      New Square Feet
                    </InputLabel>
                    <Input
                      required
                      id="new-area"
                      value={project.newsquarefeet}
                      name="newsquarefeet"
                      onChange={handleControlChange}
                      inputRef={newSquareFeetRef}
                      onKeyDown={ifEnterKey(focusOriginalValue)}
                      endAdornment={
                        <InputAdornment position="end">sq. ft</InputAdornment>
                      }
                      aria-describedby="standard-area-helper-text"
                      inputProps={{
                        "aria-label": "weight",
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl variant="standard" fullWidth>
                    <InputLabel htmlFor="original-value-amount">
                      Original Home Value
                    </InputLabel>
                    <Input
                      required
                      type="number"
                      id="original-value-amount"
                      value={project.originalvalue}
                      placeholder={"0"}
                      name="originalvalue"
                      onChange={handleControlChange}
                      inputRef={originalValueRef}
                      onKeyDown={ifEnterKey(focusAppraisedValue)}
                      startAdornment={
                        <InputAdornment position="start">$</InputAdornment>
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl variant="standard" fullWidth>
                    <InputLabel htmlFor="appraised-value-amount">
                      Anticipated Appraised Home Value
                    </InputLabel>
                    <Input
                      required
                      type="number"
                      id="appraised-value-amount"
                      placeholder={"0"}
                      value={project.appraisedvalue}
                      name="appraisedvalue"
                      onChange={handleControlChange}
                      inputRef={appraisedValueRef}
                      startAdornment={
                        <InputAdornment position="start">$</InputAdornment>
                      }
                      onKeyDown={ifEnterKey(onLastFieldEntered)}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            ) : projecttype === "newconstruction" ? (
              <Grid container direction="row">
                <Grid item>
                  <FormControl variant="standard" fullWidth>
                    <InputLabel htmlFor="original-area">Square Feet</InputLabel>
                    <Input
                      required
                      id="original-area"
                      value={project.squarefeet}
                      name="squarefeet"
                      onChange={handleControlChange}
                      inputRef={newConstSquareFeetRef}
                      onKeyDown={ifEnterKey(focusNewConstAppraisedValue)}
                      endAdornment={
                        <InputAdornment position="end">sq. ft</InputAdornment>
                      }
                      aria-describedby="standard-area-helper-text"
                      inputProps={{
                        "aria-label": "weight",
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl variant="standard" fullWidth>
                    <InputLabel htmlFor="appraised-value-amount">
                      Appraised Home Value
                    </InputLabel>
                    <Input
                      required
                      type="number"
                      id="appraised-value-amount"
                      placeholder={"0"}
                      value={project.appraisedvalue}
                      name="appraisedvalue"
                      onChange={handleControlChange}
                      inputRef={newConstAppraisedValueRef}
                      onKeyDown={ifEnterKey(onLastFieldEntered)}
                      startAdornment={
                        <InputAdornment position="start">$</InputAdornment>
                      }
                    />
                  </FormControl>
                </Grid>
              </Grid>
            ) : null}
          </>
        )}
      </Grid>
    </Root>
  );
}

// spell-checker:ignore zipcode projecttype owneroccupied originalsquarefeet newsquarefeet originalvalue
// spell-checker:ignore appraisedvalue squarefeet appraisedarea originalarea newconstruction projectaddress
// spell-checker:ignore projectcity statelabel projectstate projectzipcode
