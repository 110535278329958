function mappedBy<T, K extends keyof T>(items: T[], prop: K) {
  return items.reduce((uniquesSoFar, item) => {
    if (item && item[prop]) {
      return uniquesSoFar.set(item[prop], item);
    } else {
      return uniquesSoFar;
    }
  }, new Map<unknown, T>());
}

/**
 * Filter out items that have duplicate values for a given property
 * @param items source items
 * @returns functor to return source items, less any duplicates. Order and which are kept are not defined
 */
export function uniqByProp<T, K extends keyof T>(items: T[]) {
  return function uniques(prop: K): T[] {
    return Array.from(mappedBy(items, prop).values());
  };
}

/**
 * Map an array of objects by property (e.g. for quick lookup). *Lossy if properties duplicate*
 * @param items source items
 * @returns functor returning object keyed by specified property, with values being the source objects themselves. *This is lossy if the keys are duplicated*
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function byProp<T extends Record<string, any>, K extends keyof T>(
  items: ReadonlyArray<T>
) {
  return function mapped(propName: K): Record<T[K], T> {
    return Object.fromEntries(items.map((i) => [i[propName], i]));
  };
}

export const reorder: <T>(
  list: Iterable<T>,
  startIndex: number,
  endIndex: number
) => Array<T> = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

export function nanIsEmptyString(value: number): number | "" {
  return Number.isNaN(value) ? "" : value;
}

export function nanIshIsEmptyString(value?: number): number | "" {
  return value === undefined || Number.isNaN(value) ? "" : value;
}

/**
 * Enforce satisfying a particular interface. Use like this:
 *
 * ```typescript
 * interface Foo { s: string }
 * interface PretendThisIsFooButSomebodyWidenedIt extends Foo { t: string }
 * interface Ok extends MustSatisfy<Foo, Ok> { s: string }
 * interface Fail extends MustSatisfy<PretendThisIsFooButSomebodyWidenedIt, Fail> { s:string }
 * ```
 *
 * https://github.com/Microsoft/TypeScript/issues/24274
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export type MustSatisfy<T, U extends T> = Record<string, unknown>;

// interface Foo { s: string }
// interface PretendThisIsFooButSomebodyWidenedIt extends Foo { t: string }
// interface Ok extends MustSatisfy<Foo, Ok> {  s: string }
// interface Fail extends MustSatisfy<PretendThisIsFooButSomebodyWidenedIt, Fail> { s:string }

export function multiRef<T extends HTMLElement>(
  refs: (React.MutableRefObject<T> | ((r: T) => void) | null)[]
): React.MutableRefObject<T> | ((r: T) => void) | null {
  return (r: T) =>
    refs.forEach((ref) => {
      if (typeof ref === "function") {
        ref(r);
      } else if (typeof ref === "object" && ref != null) {
        ref.current = r;
      }
    });
}
/** unwrap an array one level; e.g. Unarray<Foo[]> === Foo  */
export type Unarray<T> = T extends Array<infer U> ? U : T;
