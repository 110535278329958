import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import {
  Calendar,
  DateHeaderProps,
  momentLocalizer,
  View,
  ViewsProps,
} from "react-big-calendar";
import moment from "moment";
import Button from "@mui/material/Button";
import "react-big-calendar/lib/css/react-big-calendar.css";
import EventDialog from "./EventDialog";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useTheme } from "@mui/material/styles";
import { BackendGetProjectTasksContractorEntry } from "@lib/APITypes";
import { useBackend } from "@lib/hooks";
import config from "../../../config";
import { logError } from "@lib/ErrorLogging";
import { Task } from "../../../Models";

const PREFIX = "Calendar";

const classes = {
  formControl: `${PREFIX}-formControl`,
  highlightedDateButtonLink: `${PREFIX}-highlightedDateButtonLink`,
};

const Root = styled("a")(({ theme: { spacing } }) => ({
  [`& .${classes.formControl}`]: {
    width: "80vw",
    margin: "auto",
  },

  [`& .${classes.highlightedDateButtonLink}`]: {
    backgroundColor: "transparent",
    // border: "none",
    cursor: "pointer",
    textDecoration: "underline",
    display: "inline",
    margin: 0,
    // padding: 0,
    borderColor: "red",

    border: "2px solid red",
    borderRadius: "100%",
    padding: "5px",
    zIndex: 22,
    "&hover": {
      textDecoration: "none",
    },
    "&focus:": {
      textDecoration: "none",
    },
  },
}));

const allViews: ViewsProps = {
  month: true,
  week: true,
  work_week: true,
};

// /**
//  * This is a BackendGetTaskResponse, with:
//  *   - `start` converted from a string to a Date
//  *   - `end`, a Date, synthesized from `end_date`
//  */
// interface CalendarTask
//   extends Omit<BackendGetTaskResponse, "start"> {
//   end: Date;
//   start: Date;
// }

// const calendarTaskFromBackendTask = (task: BackendGetTaskResponse) => ({
//     ...task,
//     start: new Date(task.start),
//     end: new Date(task.end_date || task.start),
//   });

// interface CalendarTaskWithVacatingDates extends CalendarTask {
//   daysVacatingStart?: Date;
//   daysVacatingEnd?: Date;
// }

enum Modes {
  SCHEDULE = "Schedule",
  VACATING = "Days Vacating",
  ALL_TASKS = "All Tasks",
  CRITICAL_TASKS = "Critical",
}

export interface CalendarViewProps {
  taskData: {
    project_id: string;
    all_tasks: string[];
    next_decision_date: string | null;
    retention: number;
  };
  onTasksUpdated: (arg0: Task[]) => void;
  onDecisionDateClicked?: () => void;
}

const extractDate =
  (
    field: "start" | "end_date" | "taskvacating_start" | "taskvacating_end",
    fallbackField?:
      | "start"
      | "end_date"
      | "taskvacating_start"
      | "taskvacating_end"
  ) =>
  (task: Task) =>
    (fallbackField ? task[field] || task[fallbackField] : task[field]) ||
    new Date();
// new Date(
//   (fallbackField
//     ? task[field] || task[fallbackField]
//     : task[field]) as string
// );

export default function CalendarView(props: CalendarViewProps): JSX.Element {
  const {
    taskData: { project_id: projectId },
    onDecisionDateClicked,
  } = props;

  const localizer = momentLocalizer(moment);

  const { palette } = useTheme();
  const { getProjectTasks, deleteTask } = useBackend();

  const [mode, setMode] = React.useState<Modes>(Modes.SCHEDULE);
  const [criticalEvents, setCriticalEvents] = React.useState<Task[]>([]);
  const [daysVacating, setDaysVacating] = React.useState<Task[]>([]);
  const [showTaskDetails, setShowTaskDetails] = React.useState<
    Task | undefined
  >();
  const [events, setEventsData] = React.useState<Task[]>([]);
  const [contractor, setContractor] = React.useState<
    BackendGetProjectTasksContractorEntry | undefined
  >();
  const [deleteDialog, setDeleteDialog] = React.useState(false);

  const refreshTasks = React.useCallback(
    () =>
      getProjectTasks(projectId).then((response) => {
        const { tasks, contractor } = response;
        createSchedule(tasks);
        createDaysVacating(tasks);
        setCriticalEvents(tasks.filter(({ critical }) => critical));
        setContractor(contractor[0]);
        return response;
      }),
    [getProjectTasks, projectId]
  );

  const callBack = () => {
    refreshTasks()
      .then((response) => {
        props.onTasksUpdated && props.onTasksUpdated(response.tasks);
        return response;
      })
      .catch((error) => {
        logError(error);
        console.log(error);
      });
  };

  const handleClose = () => {
    setShowTaskDetails(undefined);
    callBack();
  };

  const handleDeleteClose = () => {
    setDeleteDialog(false);
  };

  const handleDeleteOpen = () => {
    setDeleteDialog(true);
  };

  const handleDeleteAgree = () => {
    const taskId = showTaskDetails?.id;
    if (taskId) {
      setDeleteDialog(false);
      setShowTaskDetails(undefined);
      deleteTask(taskId, projectId)
        .then((response) => {
          callBack();
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      if (config.nonProdEnv) {
        throw new Error("Task went missing before delete");
      }
    }
  };

  const createSchedule = (tasks: Task[]) => {
    setEventsData(tasks);
    // tasks.map(calendarTaskFromBackendTask)
    // );
  };

  const createDaysVacating = (tasks: Task[]) => {
    setDaysVacating(tasks.filter(({ taskvacating }) => taskvacating));
    // .map(task => ({
    //   ...calendarTaskFromBackendTask(task),
    //   startDaysVacating: new Date(task.taskvacating_start),
    //   endDaysVacating: new Date(task.taskvacating_end)
    // })));
  };

  useEffect(() => {
    if (props.taskData.all_tasks.length > 0) {
      refreshTasks().catch((error) => {
        console.log(error);
      });
    } else {
      return;
    }
  }, [props.taskData, refreshTasks]);

  const NEXT_DECISION_DATE = new Date(
    props.taskData.next_decision_date || 0 /* epoch; never match */
  );

  const modeSpecificCalendarConfig = ((mode: Modes) => {
    switch (mode) {
      case Modes.SCHEDULE:
      case Modes.ALL_TASKS:
        return {
          events,
          startAccessor: extractDate("start"),
          endAccessor:
            mode === "Schedule"
              ? extractDate("end_date")
              : // "All Tasks" will make tasks with no end be one second long so they show
                (task: Task) =>
                  new Date(
                    extractDate("end_date", "start")(task).getTime() + 1000
                  ),
        };

      case Modes.CRITICAL_TASKS:
        return {
          events: criticalEvents,
          startAccessor: extractDate("start"),
          endAccessor: extractDate("end_date", "start"),
        };
      case Modes.VACATING:
        return {
          events: daysVacating,
          startAccessor: extractDate("taskvacating_start"),
          endAccessor: extractDate("taskvacating_end"),
        };
    }
  })(mode);

  return (
    <div>
      <div style={{ width: "80vw", margin: "auto" }}>
        <FormControl variant="standard" className={classes.formControl}>
          {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
          <Select
            variant="standard"
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={mode}
            onChange={(event) => setMode(event.target.value as Modes)}
          >
            <MenuItem value={"Schedule"}>Schedule</MenuItem>
            <MenuItem value={"Critical"}>Critical</MenuItem>
            <MenuItem value={"Days Vacating"}>Days Vacating</MenuItem>
            <MenuItem value={"All Tasks"}>All Tasks</MenuItem>
          </Select>
        </FormControl>
        <Calendar
          {...modeSpecificCalendarConfig}
          popup
          localizer={localizer}
          views={allViews}
          step={60}
          onSelectEvent={setShowTaskDetails}
          showMultiDayTimes
          defaultDate={new Date()}
          eventPropGetter={() => ({
            style: {
              backgroundColor: palette.primary.main,
            },
          })}
          style={{ height: "75vh" }}
          components={{
            month: {
              dateHeader: highlightingDateHeader(
                NEXT_DECISION_DATE,
                classes.highlightedDateButtonLink
              ),
            },
          }}
          onDrillDown={(date, view) => {
            if (date.toDateString() === NEXT_DECISION_DATE.toDateString()) {
              if (typeof onDecisionDateClicked === "function") {
                onDecisionDateClicked();
              }
            }
          }}
        />

        {showTaskDetails ? (
          <EventDialog
            task={showTaskDetails}
            contractor={contractor}
            handleClose={handleClose}
            handleDeleteOpen={handleDeleteOpen}
            project={props.taskData.project_id}
            retention={props.taskData.retention}
          />
        ) : null}
        <Dialog
          open={deleteDialog}
          onClose={handleDeleteClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Are you sure you want to delete this Task?"}
          </DialogTitle>
          <DialogActions>
            <Button onClick={handleDeleteClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleDeleteAgree} color="primary" autoFocus>
              Agree
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}

/**
 * dateHeader replacement for (our use of) react-big-calendar
 *
 * @param highlightDate If the date we are being asked to render is this date, highlight it (currently we are drawing a red circle around it)
 */
function highlightingDateHeader(
  highlightDate: Date,
  highlightClassName: string
): React.ComponentType<DateHeaderProps> | undefined {
  const highlightDateString = highlightDate.toDateString();
  return (props) => {
    const { date, label, drilldownView, onDrillDown } = props as {
      date: Date;
      label: React.ReactNode;
      drilldownView: View; // spell-checker:ignore drilldown
      onDrillDown: React.MouseEventHandler;
    };
    const highlightDate = date.toDateString() === highlightDateString;

    if (!drilldownView) {
      return <span>{label}</span>;
    }

    return (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <Root
        href="#"
        className={highlightDate ? highlightClassName : undefined}
        onClick={onDrillDown}
        role="cell"
      >
        {label}
      </Root>
    );
  };
}
