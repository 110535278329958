import React from "react";
import { styled } from "@mui/material/styles";
import { Link, Navigate, useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import CircularProgress from "@mui/material/CircularProgress";
import config from "../config";
import { ifEnterKey } from "@lib/boilerplate";
import { useFocus } from "@lib/hooks/useFocus";
import { useAppContext } from "@lib/UserContext";
import { BusinessPersonInputs } from "@lib/APITypes";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { LinearProgress } from "@mui/material";
import { useBackend } from "@lib/hooks";
import { useAuth } from "@lib/hooks/useAuth";

const PREFIX = "LenderSetup";

const classes = {
  formWrapper: `${PREFIX}-formWrapper`,
  margin: `${PREFIX}-margin`,
  navBarContainer: `${PREFIX}-navBarContainer`,
  buttonNav: `${PREFIX}-buttonNav`,
  icon: `${PREFIX}-icon`,
};

const Root = styled("div")({
  [`& .${classes.formWrapper}`]: {
    paddingRight: "5vw",
    paddingLeft: "5vw",
    textAlign: "center",
  },
  [`& .${classes.margin}`]: {
    marginTop: "20px",
  },
  [`& .${classes.navBarContainer}`]: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "10px",
  },
  [`& .${classes.buttonNav}`]: {
    marginLeft: "10px",
    marginRight: "10px",
  },
  [`& .${classes.icon}`]: {
    marginLeft: "5px",
    marginRight: "5px",
  },
});

export default function LenderSetup({
  firstTimeLoginMode,
}: {
  firstTimeLoginMode?: boolean;
}): JSX.Element {
  const navigate = useNavigate();
  const { role } = useAppContext();
  const { currentUserEmail: email, isAuthenticated } = useAuth();
  const { selfAddLender } = useBackend();

  const [loading, setLoading] = React.useState(false);

  const handleSubmit = (lender: BusinessPersonInputs) => {
    setLoading(true);

    selfAddLender({ lender })
      .then(() => {
        setLoading(false);

        navigate("/dashboard");
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        alert(
          `Error: ${error.message}. Please contact ${config.contact.customerSuccess} for assistance.`
        );
        setLoading(false);
      });
  };

  const [firstNameRef, focusFirstName] = useFocus();
  const [lastNameRef, focusLastName] = useFocus();
  const [emailRef, focusEmail] = useFocus();
  const [phoneRef, focusPhone] = useFocus();

  const {
    control,
    handleSubmit: submitWrapper,

    formState: {
      // watch,
      // setValue,
      errors,
    },
  } = useForm<BusinessPersonInputs>({
    resolver: zodResolver(BusinessPersonInputs),
  });
  // React.useEffect(() => {
  //   setValue("email", email);
  // }, [email, setValue]);

  // console.log({ errors, watches: watch(), email });
  const stdProps = ({ field: { name } }: { field: { name: string } }) => ({
    required: true,
    fullWidth: true,
    error: Boolean(errors[name as keyof typeof errors]),
    helperText: errors[name as keyof typeof errors]?.message,
  });

  return firstTimeLoginMode && isAuthenticated && role ? (
    <Navigate replace to="/" />
  ) : (
    <Root>
      {loading && firstTimeLoginMode ? (
        <div className={classes.formWrapper}>
          <h1>Setting Up Your Account...</h1>
          <LinearProgress />
        </div>
      ) : (
        <form
          onSubmit={submitWrapper(
            handleSubmit /* , (errors, e) =>
          alert(
            Object.keys(errors)
              .map(
                (k) =>
                  `${k}: ${
                    errors[k as keyof typeof errors]?.message ?? "undefined??"
                  }`
              )
              .join("\n")
          ) */
          )}
        >
          <div className={classes.formWrapper}>
            <h1>
              {firstTimeLoginMode
                ? "Please Complete Your Profile"
                : "Lender Information"}
            </h1>

            <div>
              <Controller
                control={control}
                name="company"
                defaultValue=""
                render={(props) => (
                  <FormControl
                    variant="standard"
                    fullWidth
                    className={classes.margin}
                  >
                    <TextField
                      variant="standard"
                      {...props.field}
                      {...stdProps(props)}
                      id="lendercompany"
                      label="Company"
                      autoFocus
                      onKeyDown={ifEnterKey(focusFirstName)}
                    />
                  </FormControl>
                )}
              />

              <Controller
                control={control}
                name="first_name"
                defaultValue=""
                render={(props) => (
                  <FormControl
                    variant="standard"
                    fullWidth
                    className={classes.margin}
                  >
                    <TextField
                      variant="standard"
                      {...props.field}
                      {...stdProps(props)}
                      id="lenderfirstname"
                      label="First Name"
                      inputRef={firstNameRef}
                      onKeyDown={ifEnterKey(focusLastName)}
                    />
                  </FormControl>
                )}
              />

              <Controller
                control={control}
                name="last_name"
                defaultValue=""
                render={(props) => (
                  <FormControl
                    variant="standard"
                    fullWidth
                    className={classes.margin}
                  >
                    <TextField
                      variant="standard"
                      {...props.field}
                      {...stdProps(props)}
                      id="lenderlastname"
                      label="Last Name"
                      inputRef={lastNameRef}
                      onKeyDown={ifEnterKey(focusEmail)}
                    />
                  </FormControl>
                )}
              />
              <div hidden={firstTimeLoginMode}>
                <Controller
                  control={control}
                  name="email"
                  defaultValue={email}
                  render={(props) => (
                    <FormControl
                      variant="standard"
                      fullWidth
                      className={classes.margin}
                    >
                      <TextField
                        variant="standard"
                        {...props.field}
                        {...stdProps(props)}
                        id="lenderemail"
                        label="Email"
                        inputRef={emailRef}
                        onKeyDown={ifEnterKey(focusPhone)}
                      />
                    </FormControl>
                  )}
                />
              </div>
              <Controller
                control={control}
                name="phone_number"
                defaultValue=""
                render={(props) => (
                  <FormControl
                    variant="standard"
                    fullWidth
                    className={classes.margin}
                  >
                    <TextField
                      variant="standard"
                      hidden={firstTimeLoginMode}
                      {...props.field}
                      {...stdProps(props)}
                      id="lenderphone"
                      label="Phone"
                      inputRef={phoneRef}
                    />
                  </FormControl>
                )}
              />
            </div>
          </div>
          <div className={classes.navBarContainer}>
            <Link to="/dashboard" style={{ textDecoration: "none" }}>
              <Button
                className={classes.buttonNav}
                variant="outlined"
                color="secondary"
                size="large"
              >
                <CancelPresentationIcon className={classes.icon} /> Cancel{" "}
              </Button>
            </Link>
            <Button
              className={classes.buttonNav}
              variant="outlined"
              size="large"
              color="primary"
              disabled={loading}
              type="submit"
            >
              {loading ? <CircularProgress /> : "Submit"}
            </Button>
          </div>
        </form>
      )}
    </Root>
  );
}
