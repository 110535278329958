import React, { useEffect, useMemo } from "react";
import { styled } from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import AddDecision from "./AddDecision";
import DisplayDecisions from "./DisplayDecisions";
import Button from "@mui/material/Button";

import { usePermissions } from "@lib/hooks";
import { ExtraAdminDataProtocolProps } from "../../ProjectView";
import { useProjectDecisions } from "@lib/hooks/useProjectDecisions";
import { useProjectUsers } from "@lib/hooks/useProjectUsers";
import { useProjectDetails } from "@lib/hooks/useProjectDetails";
import { useProjectTasks } from "@lib/hooks/useProjectTasks";
import { Role } from "@lib/roles";
import { PeopleRoutingEditor } from "../../../Components/PeopleRoutingEditor";
import { logError } from "@lib/ErrorLogging";
import { User } from "@/Lib/APITypes";

const PREFIX = "DecisionMatrixView";

const classes = {
  root: `${PREFIX}-root`,
  wrapper: `${PREFIX}-wrapper`,
  formControl: `${PREFIX}-formControl`,
  buttonCTA: `${PREFIX}-buttonCTA`,
};

const Root = styled("div")(({ theme: { palette } }) => ({
  [`&.${classes.root}`]: {
    margin: "20px",
    marginBottom: "100px",
  },

  [`& .${classes.wrapper}`]: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  [`& .${classes.formControl}`]: {
    width: "50vw",
    fontSize: "2em",
    borderRadius: "80%",
  },

  [`& .${classes.buttonCTA}`]: {
    color: palette.cta.main,
    fontSize: "1em !important",
    textDecoration: "none",
  },
}));

interface HasEmail {
  email: string;
}

interface PersonWithEmail extends HasEmail {
  first_name: string;
  last_name: string;
}
export interface PersonWithEmailAndRole extends PersonWithEmail {
  role: Role;
}

export default function DecisionView(
  props: {
    data: { project_id: string; decision_routing: string[] };
  } & ExtraAdminDataProtocolProps
): JSX.Element {
  const {
    setExtraAdminData,
    data: { project_id: projectId },
  } = props;
  const {
    project: { tasks },
  } = useProjectTasks(projectId);
  const [task, setTask] = React.useState("Add Decision");
  const [newDecisions, setNewDecisions] = React.useState<{ title: string }[]>(
    []
  );

  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const {
    project: { decisions, updateRouting, approveDecision, removeDecision },
  } = useProjectDecisions(projectId);
  const {
    project: { users },
  } = useProjectUsers(projectId);
  const { project } = useProjectDetails(projectId);
  const decisionRouting = project?.details?.decision_routing;

  //Selecting a New Decision to be Added
  const handleTaskChange = (event: { target: { value: unknown } }) => {
    setTask("Add Decision");
    setNewDecisions(
      newDecisions.concat([{ title: event.target.value as string }])
    );
  };

  //Handling Approval Routing
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const approvers = useMemo(() => {
    const usersByEmail = Object.fromEntries(
      (users ?? []).map((user) => [user.email, user])
    );
    return (decisionRouting ?? [])
      .map((deciderEmail) => usersByEmail[deciderEmail])
      .filter(Boolean);
  }, [decisionRouting, users]);

  const handleSubmitRouting = (
    newApprovers: ReadonlyArray<Pick<User, "email">>
  ) => {
    setLoading(true);
    updateRouting(newApprovers)
      .then(() => {
        setOpen(false);
      })
      .catch((error) => {
        logError(error);
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setExtraAdminData?.({ tasks, newDecisions, decisions });
  }, [tasks, newDecisions, decisions, setExtraAdminData]);

  useEffect(() => {
    // do nothing on mount, but cleanup on un-mount
    return () => setExtraAdminData?.({});
  }, [setExtraAdminData]);

  const { canAddDecisionRequests, canEditDecisionRouting } = usePermissions();
  return (
    <Root className={classes.root}>
      <div className={classes.wrapper}>
        {canAddDecisionRequests ? (
          <FormControl variant="standard" className={classes.formControl}>
            <Select
              variant="standard"
              value={task}
              id="task"
              onChange={handleTaskChange}
            >
              <MenuItem value={"Add Decision"} disabled>
                Add Decision
              </MenuItem>
              {tasks?.tasks.map((task, key) => {
                return (
                  <MenuItem value={task.title} key={key}>
                    {task.title}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        ) : null}
        {canEditDecisionRouting ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button className={classes.buttonCTA} onClick={handleOpen}>
              <strong>EDIT DECISION ROUTING</strong>
            </Button>
            <PeopleRoutingEditor
              open={open}
              busy={loading}
              title="Current Approver(s)"
              onClose={handleClose}
              addablePeople={users || []}
              onSubmit={handleSubmitRouting}
              initialPeople={approvers}
            />
          </div>
        ) : null}

        <AddDecision data={newDecisions} project={props.data.project_id} />
        <DisplayDecisions
          data={decisions ?? []}
          project={props.data.project_id}
          onDeleteDecision={(decisionId) => {
            removeDecision(decisionId);
          }}
          onApproveDecision={(decisionId, approval) => {
            approveDecision({ approval, decisionId, projectId });
          }}
        />
      </div>
    </Root>
  );
}
