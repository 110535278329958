import React from "react";
import { logAnomaly } from "../ErrorLogging";
import { useAnalytics } from "./useAnalytics";
import {
  useDeviceSelectors,
  isSafari,
  isMobileSafari,
  browserVersion,
} from "react-device-detect";

// import { useSnackbar } from "notistack";
// import { createContext, useContext } from "react";
// import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

// type LocationState =
//   | "indeterminate"
//   | "rejected"
//   | "asking"
//   | "have-location"
//   | "other-error"
//   | "prompting";
interface PositionContextValue {
  // locationState: LocationState;
  locationPermission: PermissionState | "not-available" | undefined | "pending";
  getPosition: () => Promise<GeolocationPosition>;
}
const PositionContext = React.createContext<PositionContextValue>({
  // locationState: "indeterminate",
  locationPermission: undefined,
  getPosition: () => Promise.reject(new Error("uninitialized")),
});

export const PositionProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const analytics = useAnalytics();

  // const [locationState, setLocationState] =
  //   React.useState<PositionContextValue["locationState"]>("indeterminate");
  // React.useEffect(() => {
  //   console.log("locationState -->", locationState);
  // }, [locationState]);

  const [locationPermission, setLocationPermission] =
    React.useState<PositionContextValue["locationPermission"]>();
  // React.useEffect(() => {
  //   console.log("locationPermission -->", locationPermission);
  // }, [locationPermission]);
  const [selectors, data] = useDeviceSelectors(window.navigator.userAgent);
  React.useEffect(() => {
    let isMounted = true;
    if (locationPermission === undefined) {
      if (
        navigator.geolocation &&
        navigator.permissions &&
        navigator.permissions.query
      ) {
        analytics.track("loc:check-permission");
        setLocationPermission("pending");
        navigator.permissions.query({ name: "geolocation" }).then((result) => {
          if (isMounted) {
            const onChange = () => {
              if (isMounted) {
                setLocationPermission(result.state);
              }
            };
            result.onchange = onChange;
            onChange();
          }
        });
      } else {
        // Match specific instances seen to date, so we only get alerted on surprises
        const suppress =
          (isSafari && (browserVersion === "15" || browserVersion === "13")) ||
          (isMobileSafari && browserVersion === "15");
        if (!suppress) {
          logAnomaly(
            new Error("Cannot query location permission on this browser"),
            { navigator, selectors, data }
          );
        }
        analytics.track("loc:no-permission", { selectors, data, suppress });
        setLocationPermission("not-available");
      }
    }

    return () => {
      isMounted = false;
    };
  }, [analytics, data, locationPermission, selectors]);

  const getPosition = () =>
    new Promise<GeolocationPosition>((resolve, reject) => {
      if (navigator.geolocation) {
        const positionOptions = {
          enableHighAccuracy: true,
          maximumAge: 0,
          timeout: 15000,
        };

        const onPositionObtained = (pos: GeolocationPosition) => {
          analytics.track("loc:obtained");
          resolve(pos);
        };

        const onPositionError = (err: GeolocationPositionError) => {
          if (err.code === GeolocationPositionError.PERMISSION_DENIED) {
            analytics.track("loc:denied");
            return reject("denied");
          }

          analytics.track("loc:error");
          logAnomaly(new Error("Position error"), { err });
          reject(err);
        };

        // function noSoupForYou() {
        //   analytics.track("loc:denied");
        //   enqueueSnackbar(
        //     "Photos without location will not be accepted for Digital Inspections; please allow location permission and refresh",
        //     { variant: "warning" }
        //   );
        //   reject("denied");
        // }

        // if (state === "denied") {
        //   noSoupForYou();
        // } else if (state === "prompt") {
        //   analytics.track("loc:prompt");

        navigator.geolocation.getCurrentPosition(
          onPositionObtained,
          onPositionError,
          positionOptions
        );
        // } else if (state === "granted") {
        //   analytics.track("loc:granted");
        //   navigator.geolocation.getCurrentPosition(
        //     onPositionObtained,
        //     onPositionError,
        //     positionOptions
        //   );
        // } else {
        //   const error = new Error("Unexpected location permission");
        //   logAnomaly(error, { state });
        //   analytics.track("loc:error");
        //   reject(error);
        // }
        // });
      } else {
        // enqueueSnackbar(
        //   "Location information is not available for Digital Inspections",
        //   { variant: "warning" }
        // );
        logAnomaly(new Error("browser does not have/allow geolocation"), {
          navigator,
        });
        analytics.track("loc:not-available");
        reject("location not available on this device");
      }
    });

  //   return { getPosition };
  // };

  return (
    <PositionContext.Provider
      value={{ /* locationState, */ locationPermission, getPosition }}
    >
      {children}
    </PositionContext.Provider>
  );
};

export const usePosition = () => {
  const positionContext = React.useContext(PositionContext);
  if (positionContext === undefined) {
    throw new Error("usePosition must be inside a PositionProvider");
  }
  return positionContext;
};
