import React from "react";
import TextField from "@mui/material/TextField";
import { Button, FormControl, Grid } from "@mui/material";
import { buttonCTA, buttonCancel } from "@/Lib/StyleHelpers";
import { useFocus } from "@/Lib/hooks";
import { Controller, useForm } from "react-hook-form";
import { AddressForm, AddressInputs } from "@/Forms/AddressForm";
import { FormProvider } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { ZodSchema } from "@/Forms/AddressForm";
import { InspectionTypeRadio } from "@/Components/InspectionTypeRadio";

const NewRequestPage = () => {
  const [addressRef] = useFocus<HTMLInputElement>();
  const formMethods = useForm<AddressInputs>({
    mode: "onChange",
    resolver: zodResolver(ZodSchema),
    defaultValues: {
      address: "",
      city: "",
      state: "",
      zipcode: "",
    },
  });
  return (
    <FormProvider {...formMethods}>
      <div>
        <div style={{ marginBottom: "1vh" }}>New Request</div>
        <div style={{ display: "flex" }}>
          <div
            style={{
              width: "40vw",
              backgroundColor: "#f5f5f5",
              height: "50vh",
              boxShadow: "0px 5px 8px #C0C0C0	",
              minWidth: 400,
            }}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Grid item>
                <InspectionTypeRadio />
              </Grid>
            </div>
            <div style={{ marginLeft: "1vw" }}>
              <div
                style={{
                  display: "flex",
                  marginRight: "1vw",
                  marginTop: "2vh",
                }}
              >
                <AddressForm
                  addressRef={addressRef}
                  oneLine={true}
                  variant="outlined"
                />
              </div>
            </div>
            <div style={{ marginTop: "2vh", marginLeft: "1vw" }}>
              Point of Contact
            </div>
            <div style={{ marginTop: "2vh", marginLeft: "1vw" }}>
              <Grid container spacing={2}>
                <Grid item md={6}>
                  <Controller
                    name="First-Name"
                    render={({ field: { ref, ...rest } }) => (
                      <FormControl>
                        <TextField
                          variant="outlined"
                          id="First-Name"
                          {...rest}
                          label="First Name"
                          type="text"
                          sx={{ width: "18vw" }}
                          required
                        />
                      </FormControl>
                    )}
                  />
                </Grid>
                <Grid item md={6}>
                  <Controller
                    name="Last-Name"
                    render={({ field: { ref, ...rest } }) => (
                      <FormControl>
                        <TextField
                          variant="outlined"
                          id="Last-Name"
                          {...rest}
                          label="Last Name"
                          type="text"
                          sx={{ width: "18vw" }}
                          required
                        />
                      </FormControl>
                    )}
                  />
                </Grid>
                <Grid item md={6}>
                  <Controller
                    name="Phone-Number"
                    render={({ field: { ref, ...rest } }) => (
                      <FormControl>
                        <TextField
                          variant="outlined"
                          id="Phone-Number"
                          {...rest}
                          label="Phone Number"
                          type="text"
                          sx={{ width: "18vw" }}
                          required
                        />
                      </FormControl>
                    )}
                  />
                </Grid>
                <Grid item md={6}>
                  <Controller
                    name="Email"
                    render={({ field: { ref, ...rest } }) => (
                      <FormControl>
                        <TextField
                          variant="outlined"
                          id="Email"
                          {...rest}
                          label="Email"
                          type="text"
                          sx={{ width: "18vw" }}
                          required
                        />
                      </FormControl>
                    )}
                  />
                </Grid>
              </Grid>
            </div>
            <div
              style={{
                marginTop: "3vh",
                marginBottom: "3vh",
                display: "flex",
                justifyContent: "flex-start",
                marginLeft: "1vw",
              }}
            >
              <Grid container spacing={2}>
                <Grid item md={6}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{
                      justifyContent: "center",
                      width: "18vw",
                    }}
                  >
                    Upload
                  </Button>
                </Grid>
                <Grid item md={6}>
                  <Controller
                    name="Comments"
                    render={({ field: { ref, ...rest } }) => (
                      <FormControl>
                        <TextField
                          variant="outlined"
                          id="Comments"
                          {...rest}
                          label="Comments"
                          type="text"
                          multiline
                          sx={{ width: "18vw" }}
                        />
                      </FormControl>
                    )}
                  />
                </Grid>
              </Grid>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginLeft: "1vw",
                marginRight: "1vw",
              }}
            >
              <Button
                variant="contained"
                size="medium"
                color="cancel"
                sx={buttonCancel}
              >
                Leave
              </Button>
              <Button
                variant="contained"
                size="medium"
                color="cta"
                sx={buttonCTA}
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
      </div>
    </FormProvider>
  );
};

export default NewRequestPage;
