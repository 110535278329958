import React, {
  createContext,
  ReactChild,
  useContext,
  useMemo,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import { Role } from "../roles";
import { useAppContext } from "../UserContext";
import { useAuth } from "@lib/hooks/useAuth";

const trueRegex = /true/gi;
const featureDefaults = {
  reportingFeature:
    process.env.REACT_APP_FEATURE_REPORTING !== undefined
      ? trueRegex.test(process.env.REACT_APP_FEATURE_REPORTING)
      : false,
  fancyHealthIndicator: true,
  dhlcCustom: false,
  drawReportHTML: false,
  maxFileUploadSize: 50 * 1024 * 1024,
  maxFileUploadCount: 100,
  conciergeCreatedDraws: false,
  showMaxDraws: false,
};

export type Features = typeof featureDefaults;

type SetActiveFeatures = React.Dispatch<React.SetStateAction<Features[]>>;

const FeaturesContext = createContext<{
  activeFeatures: Features[];
  setActiveFeatures: SetActiveFeatures;
}>({
  activeFeatures: [featureDefaults],
  setActiveFeatures: () => {
    throw new Error("uninitialized");
  },
});

export const FeaturesProvider = ({
  children,
}: {
  children: ReactChild;
}): JSX.Element => {
  const [activeFeatures, setActiveFeatures] = useState([featureDefaults]);
  const featuresCtx = React.useMemo(() => {
    return { activeFeatures, setActiveFeatures };
  }, [activeFeatures]);

  return (
    <FeaturesContext.Provider value={featuresCtx}>
      {children}
    </FeaturesContext.Provider>
  );
};

export const useFeatures: () => Features & {
  override: <K extends keyof Features>(
    feature: K,
    value: Features[K]
  ) => {
    value: Features[K];
    done: () => void;
  };
} = () => {
  const { activeFeatures, setActiveFeatures } = useContext(FeaturesContext);
  const { search } = useLocation();
  const { role } = useAppContext();
  const { host } = window.location;
  const { currentUserEmail } = useAuth();

  const health = new URLSearchParams(search).get("health");
  const overrideFancyHealth = useMemo(
    () => (health === "circles" ? { fancyHealthIndicator: false } : {}),
    [health]
  );

  // TODO: entity or other better way
  const emailDomain = currentUserEmail?.split("@").slice(-1)[0];
  const overrideDhlcCustom = useMemo(
    () =>
      emailDomain === "dhlc.com" ||
      host === "dev-ben.projectcenterline.com" ||
      currentUserEmail?.slice(-"+dhlc@projectcenterline.com".length) ===
        "+dhlc@projectcenterline.com"
        ? { dhlcCustom: true }
        : {},
    [currentUserEmail, emailDomain, host]
  );

  const overrideConciergeCreatedDraws = useMemo(
    () =>
      role === Role.SuperAdmin ||
      emailDomain === "sunsetequitygroup.com" || // spell-checker:ignore sunsetequitygroup
      host === "dev-sunset.projectcenterline.com" ||
      currentUserEmail?.slice(-"+sunset@projectcenterline.com".length) ===
        "+sunset@projectcenterline.com"
        ? { conciergeCreatedDraws: true }
        : {},
    [role, currentUserEmail, emailDomain, host]
  );

  const featuresDecision = useMemo(
    () => ({
      ...featureDefaults,
      ...overrideFancyHealth,
      ...overrideDhlcCustom,
      ...overrideConciergeCreatedDraws,
      ...(activeFeatures.length > 1 // 0 is featureDefaults
        ? activeFeatures[activeFeatures.length - 1]
        : {}),
    }),
    [
      overrideConciergeCreatedDraws,
      overrideDhlcCustom,
      overrideFancyHealth,
      activeFeatures,
    ]
  );

  const memoizedFeatureDecision = React.useMemo(
    () => ({
      ...featuresDecision,
      override: <K extends keyof Features>(feature: K, value: Features[K]) => {
        const ourFeatureSet = {
          ...featuresDecision,
          [feature]: value,
        };
        setActiveFeatures((activeFeatures) => [
          ...activeFeatures,
          ourFeatureSet,
        ]);

        const done = () => {
          setActiveFeatures((activeFeatures) => {
            const withoutOurs = activeFeatures.slice();
            withoutOurs.splice(activeFeatures.indexOf(ourFeatureSet), 1);
            return withoutOurs;
          });
        };
        return {
          value,
          done,
        };
      },
    }),
    [featuresDecision, setActiveFeatures]
  );
  return memoizedFeatureDecision;
};

// const featureKeys = Object.keys(featureDefaults) as (keyof Features)[];

// export type FeatureOverride = (
//   overrideValue: boolean
// ) => {
//   value: boolean;
//   done: () => void;
// };

// type FeatureOverrides = Record<keyof Features, FeatureOverride>;

// /**
//  * override a feature. We stack overrides, but you only remove your own (so removing may not have an effect,
//  * if someone has stacked on top of you)
//  */
// const adminOverrideFeatureSetting: (
//   featureName: keyof Features
// ) => (value: boolean) => { value: boolean; done: () => void } = (
//   featureName
// ) => (overrideValue) => {
//   const { activeFeatures, setActiveFeatures } = useContext(FeaturesContext);
//   const ourFeatureSet = {
//     ...activeFeatures[activeFeatures.length - 1],
//     [featureName]: overrideValue,
//   };
//   setActiveFeatures((activeFeatures) => [...activeFeatures, ourFeatureSet]);

//   const done = () => {
//     setActiveFeatures((activeFeatures) => {
//       const withoutOurs = activeFeatures.slice();
//       withoutOurs.splice(activeFeatures.indexOf(ourFeatureSet), 1);
//       return withoutOurs;
//     });
//   };
//   return {
//     value: overrideValue,
//     done,
//   };
// };

// export const useFeatureOverride: () => FeatureOverrides = () => {
//   return featureKeys.reduce(
//     (overrides, featureName) => ({
//       ...overrides,
//       [featureName]: adminOverrideFeatureSetting(featureName),
//     }),
//     {} as FeatureOverrides
//   );
// };
