import { Theme } from "@mui/material";
import { pink } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";
import type {} from "@mui/x-date-pickers/themeAugmentation";
import { CSSProperties } from "react";

import creativeTimMUITheme from "@tim/assets/theme";

// https://github.com/microsoft/TypeScript/issues/56528
import "../third-party/creative-tim/material-dashboard-2-pro-react/src/types/mui-theme.d";

// project centerline customizations:
declare module "@mui/material/styles/createPalette" {
  interface Palette {
    cta: PaletteColor;
    muted: PaletteColor;
    cancel: PaletteColor;
  }
  interface PaletteOptions {
    cta?: PaletteColorOptions;
    muted?: PaletteColorOptions;
    cancel?: PaletteColorOptions;
  }
}

/*
 * Add typography.tile
 */
declare module "@mui/material/styles" {
  interface TypographyVariants {
    tile: React.CSSProperties;
  }
  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    tile?: React.CSSProperties;
  }
}
// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    tile: true;
  }
}

declare module "@mui/material" {
  /** @deprecated this will not work with Creative tim because https://github.com/Project-Centerline/project-centerline-ac-frontend/issues/1400 */
  interface ButtonPropsColorOverrides {
    muted: true;
    cta: true;
    cancel: true;
  }

  interface BadgePropsColorOverrides {
    muted: true;
    cta: true;
    cancel: true;
  }

  interface SvgIconPropsColorOverrides {
    cta: true;
    cancel: true;
  }
}

const defaultMUITheme = createTheme();

const fontFamily = ["Source Sans Pro", "sans-serif"].join(",");

/**
 * Fixes table display for creative tim
 * https://discord.com/channels/448469260587630594/931587556645175347/1123055883824406578
 */
const bugFixedTimTheme = createTheme(creativeTimMUITheme, {
  components: {
    MuiTableHead: {
      styleOverrides: {
        root: {
          display: "table-header-group",
        },
      },
    },
  },
});

/**
 * Decorate a theme to make it usable by creative tim components without crashing
 *
 * @param theme starting point
 * @returns `theme` upgraded to have things that creative tim will crash without
 */
const withCreativeTimCompatibility = (theme: Theme) =>
  createTheme(theme, {
    borders: creativeTimMUITheme.borders,
    boxShadows: creativeTimMUITheme.boxShadows,
    functions: creativeTimMUITheme.functions,
    palette: {
      gradients: creativeTimMUITheme.palette.gradients,
      white: creativeTimMUITheme.palette.white,
      black: creativeTimMUITheme.palette.black,
      dark: creativeTimMUITheme.palette.dark,
      transparent: creativeTimMUITheme.palette.transparent,
      cancel: creativeTimMUITheme.palette.cancel,
    },
    typography: {
      size: creativeTimMUITheme.typography.size,
    },
  });

// strictly MUI customizations
const projectCenterlineBaseTheme = createTheme(
  withCreativeTimCompatibility(defaultMUITheme),
  {
    palette: {
      primary: {
        main: "#429adf",
        dark: "#006cad",
        light: "#7dcbff",
      },
      secondary: {
        // colors from V4
        light: pink.A200,
        main: pink.A400,
        dark: pink.A700,
      },
      muted: {
        main: creativeTimMUITheme.palette.grey[200],
      },
    },
    typography: {
      fontFamily,
      // https://stackoverflow.com/questions/63519101/why-does-body2-overrides-body-font-size-in-material-ui
      body2: {
        fontSize: 16,
      },
      body1: {
        // TODO: understand and fix font sizes for real
        fontSize: "1.14286rem",
      },
      h6: {
        fontSize: "1.42857rem",
      },
      // size: { ...creativeTimMUITheme.typography.size },
    },
    components: {
      MuiTableHead: {
        styleOverrides: {
          root: {
            backgroundColor: creativeTimMUITheme.palette.grey[200],
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          head: {
            fontWeight: 600,
          },
          root: {
            fontFamily,
          },
        },
      },
      // TODO: should not have to do so many of these, maybe any of these if I do it right
      MuiButton: {
        styleOverrides: {
          root: {
            fontFamily,
            fontSize: "1em",
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            fontFamily,
            fontSize: "1.14286rem",
          },
          input: {
            fontFamily,
          },
        },
      },
      MuiInput: {
        styleOverrides: {
          root: {
            fontFamily,
          },
          input: {
            fontFamily,
          },
        },
      },
      MuiBottomNavigationAction: {
        styleOverrides: {
          label: {
            fontSize: "1em !important",
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            fontSize: "1rem",
          },
        },
      },
    },
  }
);

// add-ons, etc, possibly using values from above
export const projectCenterlineTheme = (tim: boolean) =>
  createTheme(
    tim
      ? /* tim gets base tim theme plus what we need to do to fix tables, and our own additions like palette and typography addons */
        createTheme(bugFixedTimTheme, {
          palette: {
            muted: creativeTimMUITheme.palette.grey,
          },
          typography: {
            tile: creativeTimMUITheme.typography.h5,
          },
        })
      : /* non-tim gets all our existing customizations, plus tim add ons required to at least not crash when changing settings */
        createTheme(projectCenterlineBaseTheme, {
          typography: {
            tile: {
              ...projectCenterlineBaseTheme.typography.h5,
              fontSize: "1.3rem",
              fontWeight: 700,
              textAlign: "center",
              display: "inline-block",
              width: "100%",
            },
          },
        }),
    /* both flavors get the following: */ {
      palette: {
        cta: {
          main: "#66B64B",
        },
        cancel: {
          main: "#f52c2c",
        },
      },
    }
  );

export const invertedButtonStyle: ({
  palette,
  overrideColor,
}: Pick<Theme, "palette"> & { overrideColor?: string }) => CSSProperties = ({
  palette,
  overrideColor,
}) => ({
  border: "1px solid",
  color: "white",
  backgroundColor: overrideColor ?? palette.primary.main,
  "&:hover": {
    color: overrideColor ?? palette.primary.main,
    backgroundColor: "white",
    border: "1px solid",
  },
});

/// approximately button-sized (but I couldn't bring myself to go quite that big)
export const bigAssIcon: ({ spacing }: Pick<Theme, "spacing">) => {
  width: string;
  height: string;
  // marginLeft: string;
} = ({ spacing }) => ({
  width: spacing(4),
  height: spacing(4),
  // marginLeft: spacing(1), // TODO: should not be including margin here
});
