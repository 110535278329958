import {
  Button,
  CircularProgress,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { formatDistanceStrict } from "date-fns/fp";
import { DropzoneArea } from "react-mui-dropzone";
import React, { Suspense } from "react";
import { ApprovalStatus } from "@lib/APITypes";
import { useAnalytics, useFeatures, usePermissions } from "@lib/hooks";
import { useProjectDetails } from "@lib/hooks/useProjectDetails";

import { uploadSupportingFiles } from "@lib/API";
import { useProjectDecisions } from "@lib/hooks/useProjectDecisions";
import { logError } from "@lib/ErrorLogging";
import { useSnackbar } from "notistack";
import { useProjectFiles } from "@lib/hooks/useProjectFiles";
import { Storage } from "aws-amplify"; // TODO: don't use this directly
import Carousel from "react-material-ui-carousel";
import { StoredFile } from "@project-centerline/project-centerline-api-types";
import { useAuth } from "@lib/hooks/useAuth";
import { throwIfNot } from "@lib/util/throwIfNot";
import { StoredFilesOnly } from "../../../Lib/StoredFilesOnly";
import {
  UploadProgressDialog,
  useProgress,
} from "@/Components/UploadProgressDialog";

const PREFIX = "FeasibilityTab";

const classes = {
  root: `${PREFIX}-root`,
  valuesHeader: `${PREFIX}-valuesHeader`,
  valuesHeaderContainer: `${PREFIX}-valuesHeaderContainer`,
  appBar: `${PREFIX}-appBar`,
  title: `${PREFIX}-title`,
  divider: `${PREFIX}-divider`,
  countAndPlus: `${PREFIX}-countAndPlus`,
  plus: `${PREFIX}-plus`,
};

const StyledCircularProgress = styled(CircularProgress)(({ theme }) => ({
  [`& .${classes.root}`]: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    width: "60%",
    margin: "auto",
  },

  [`& .${classes.valuesHeader}`]: { width: "100%" },

  [`& .${classes.valuesHeaderContainer}`]: {
    paddingBottom: theme.spacing(2),
  },

  [`& .${classes.appBar}`]: {
    position: "relative",
  },

  [`& .${classes.title}`]: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },

  [`& .${classes.divider}`]: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.countAndPlus}`]: {
    display: "flex",
    alignItems: "center",
  },

  [`& .${classes.plus}`]: {
    marginLeft: theme.spacing(1),
  },
}));

export interface FeasibilityTabProps {
  /** We'll fetch with SWR but hopefully it's already ready :-) */
  projectId: string;
}

type ViewableFile = StoredFile & {
  viewer: { fileType: string; filePath: string };
};

const usePresignedFileInfo = (files?: StoredFile[]) => {
  const [fileInfos, setFileInfos] = React.useState<ViewableFile[]>([]);

  React.useEffect(() => {
    let stillMounted = true;

    Promise.all(
      (files || []).map(
        async (file) =>
          ({
            ...file,
            viewer: {
              fileType: file.path.slice(file.path.lastIndexOf(".") + 1),
              filePath: String(await Storage.get(file.storageKey)),
            },
          } as ViewableFile)
      )
    ).then((infos) => {
      if (stillMounted) {
        setFileInfos(infos);
      }
    });
    // cleanup
    return () => {
      stillMounted = false;
    };
  }, [files]);

  return fileInfos;
};

const FileViewer = React.lazy(() => import("react-file-viewer"));

const StoredFilesViewer = ({ files }: { files?: StoredFile[] }) => {
  const fileInfos = usePresignedFileInfo(files);
  return (
    <Paper>
      <Suspense fallback={<CircularProgress />}>
        <Carousel
          navButtonsAlwaysVisible={fileInfos.length > 1}
          indicators={fileInfos.length > 1}
          autoPlay={false}
          fullHeightHover={false} // We want the nav buttons wrapper to only be as big as the button element is
          navButtonsProps={{
            // Change the colors and radius of the actual buttons. THIS STYLES BOTH BUTTONS
            style: {
              background: "#429adf",
            },
          }}
        >
          {fileInfos.map((fileInfo) => (
            <a
              key={fileInfo.storageKey}
              target="_blank"
              rel="noopener noreferrer"
              href={fileInfo.viewer.filePath}
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  width: "50vw",
                  maxWidth: "50vw",
                  maxHeight: "60vh", // TODO: figure out feasibility tab layout so it actually just fits
                  aspectRatio: "1",
                }}
              >
                <FileViewer {...fileInfo.viewer} />
              </div>
              <p
                style={{
                  width: "200px",
                  overflow: "hidden",
                  textAlign: "center",
                }}
              >
                {fileInfo.path}
              </p>
            </a>
          ))}
        </Carousel>
      </Suspense>
    </Paper>
  );
};

/**
 * Feasibility Tab - https://trello.com/c/KyLyhNIf/534-pre-closing-feasibility-report
 */
export function FeasibilityTab({
  projectId,
}: FeasibilityTabProps): JSX.Element {
  const { canSeeSpecialDecisions } = usePermissions();
  const analytics = useAnalytics();
  const { project /* , errors: projectErrors */ } =
    useProjectDetails(projectId);
  const {
    project: { requestFeasibilityReport, decisions, approveDecision },
  } = useProjectDecisions(projectId);
  const {
    project: { addFile, files: projectFiles },
  } = useProjectFiles({ projectId });
  const [progress, setProgress] = useProgress();

  const projectDetails = project?.details;

  const [files, setFiles] = React.useState<File[]>([]);

  function onDropzoneFiles(newFiles: File[]) {
    setFiles(newFiles);
  }
  const { maxFileUploadSize, maxFileUploadCount } = useFeatures();

  const relevant = React.useMemo(() => {
    const decision = (decisions || []).find(
      ({ type, approval_status }) =>
        type === "feasibility_report" &&
        approval_status !== ApprovalStatus.Rejected
    );
    return {
      decision,
      tabState:
        (!decisions && "loading") || (decision?.approval_status ?? "noneYet"),
      requestDate: decision && new Date(decision.created_at),
      responseDate: decision && new Date(decision.approved_at),
    };
  }, [decisions]);
  const { tabState, requestDate, responseDate } = relevant;

  const [loading, setLoading] = React.useState<"files" | "other stuff" | false>(
    false
  );
  const { enqueueSnackbar } = useSnackbar();
  function requestReport() {
    setLoading("files");
    analytics.track("feasibility:request", {
      projectId,
      numFiles: files.length,
    });
    uploadSupportingFiles({ files, prefix: projectId, progress: setProgress })
      .then((storedFiles) => requestFeasibilityReport(storedFiles))
      .then(() => {
        setFiles([]);
        analytics.track("feasibility:request:success", { projectId });
      })
      .catch((err) => {
        analytics.track("feasibility:request:error", { projectId });
        logError(new Error("Failed to create feasibility report"), { err });
        enqueueSnackbar(
          "Failed to create feasibility report. Please try again later.",
          { variant: "error" }
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const { currentUserEmail: email } = useAuth();
  function submitReport() {
    setLoading("files");
    analytics.track("feasibility:submit", {
      projectId,
      numFiles: files.length,
    });
    uploadSupportingFiles({ files, prefix: projectId, progress: setProgress })
      .then((storedFiles) =>
        Promise.all(
          storedFiles.map((storedFile) =>
            addFile({
              storedFile,
              email: throwIfNot(email, "email is required"),
              tags: ["feasibility_report"],
            })
          )
        )
      )
      .then(() => {
        setLoading("other stuff");
        const decisionId = relevant.decision?.decision_id;
        if (!decisionId) {
          throw new Error("decision id should not be null");
        }
        return approveDecision({
          approval: ApprovalStatus.Approved,
          decisionId,
          projectId,
        });
      })
      .then(() => {
        setFiles([]);
        analytics.track("feasibility:submit:success", { projectId });
      })
      .catch((err) => {
        analytics.track("feasibility:submit:error", { projectId });
        logError(new Error("Failed to create feasibility report"), { err });
        enqueueSnackbar(
          "Failed to create feasibility report. Please try again later.",
          { variant: "error" }
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }
  const now = new Date();
  const fromNow = formatDistanceStrict(now);

  return projectDetails && tabState !== "loading" ? (
    <>
      <Grid
        container
        direction="column"
        alignItems="center"
        className={classes.root}
        spacing={1}
      >
        <Grid item>
          <Typography variant="h5">Feasibility Report</Typography>
        </Grid>
        <Grid item className={classes.valuesHeader}>
          <Grid
            container
            className={classes.valuesHeaderContainer}
            justifyContent="space-around"
            alignItems="center"
          >
            <Grid item></Grid>
          </Grid>
        </Grid>
        {tabState === "noneYet" ? (
          <>
            <Grid item>
              <DropzoneArea
                maxFileSize={maxFileUploadSize}
                filesLimit={maxFileUploadCount}
                onChange={onDropzoneFiles}
                alertSnackbarProps={{ autoHideDuration: 1500 }}
                useChipsForPreview
                dropzoneText="Upload supporting documents"
                getFileAddedMessage={(fileName) =>
                  `File ${fileName} queued for upload.`
                }
              />
            </Grid>
            <Grid item>
              {loading ? (
                <StyledCircularProgress />
              ) : (
                <Button
                  onClick={requestReport}
                  color="primary"
                  variant="contained"
                >
                  Request Inspection and Report
                </Button>
              )}
            </Grid>
          </>
        ) : undefined}
        {tabState === ApprovalStatus.Pending ? (
          <>
            <Grid item>
              <Typography variant="h6">
                Report is pending. It was requested{" "}
                {requestDate
                  ? fromNow(requestDate)
                  : (() => {
                      // should never happen but we promised we'd fix it, so tell ourselves about it :-)
                      logError(new Error("pending feasibility without date"));
                      return "an unknown time (we'll fix it)";
                    })()}{" "}
                ago.
              </Typography>
            </Grid>
            {canSeeSpecialDecisions ? (
              <>
                <Grid item>
                  <StoredFilesViewer files={relevant.decision?.storedFiles} />
                </Grid>
                <Grid item>
                  <DropzoneArea
                    maxFileSize={maxFileUploadSize}
                    filesLimit={maxFileUploadCount}
                    onChange={setFiles}
                    alertSnackbarProps={{ autoHideDuration: 1500 }}
                    useChipsForPreview
                    dropzoneText="Upload Report"
                    getFileAddedMessage={(fileName) =>
                      `File ${fileName} queued for upload.`
                    }
                  />
                </Grid>
                <Grid item>
                  {loading ? (
                    <CircularProgress />
                  ) : (
                    <Button
                      onClick={submitReport}
                      color="primary"
                      variant="contained"
                      disabled={files.length < 1}
                    >
                      Submit Report
                    </Button>
                  )}
                </Grid>
              </>
            ) : undefined}
          </>
        ) : undefined}
        {tabState === ApprovalStatus.Approved ? (
          <>
            <Grid item>
              <Typography variant="h6">
                Report received{" "}
                {responseDate
                  ? fromNow(responseDate)
                  : (() => {
                      // should never happen but we promised we'd fix it, so tell ourselves about it :-)
                      logError(new Error("approved feasibility without date"));
                      return "an unknown time (we'll fix it)";
                    })()}{" "}
                ago.
              </Typography>
            </Grid>
            <Grid item>
              <StoredFilesViewer
                files={
                  projectFiles &&
                  StoredFilesOnly(
                    projectFiles?.userFiles.filter(({ tags }) =>
                      tags?.includes("feasibility_report")
                    )
                  )
                }
              />
            </Grid>
          </>
        ) : undefined}
      </Grid>
      <UploadProgressDialog progress={progress} open={loading === "files"} />
    </>
  ) : (
    <CircularProgress />
  );
}
