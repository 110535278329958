import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { ProjectInfoStep } from "../Forms/ProjectForm";
import { Link, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import CircularProgress from "@mui/material/CircularProgress";
import { useAnalytics, useBackend, useFocus } from "@lib/hooks";
import InfoView from "./ProjectView/Info/InfoView";

import { logAnomaly, logError } from "@lib/ErrorLogging";
import { usePrefill } from "@lib/hooks/usePrefill";
import { BusinessPersonInputs } from "@lib/APITypes";
import { LenderInfoStep } from "./LenderInfoStep";
import { useSnackbar } from "notistack";

import { AddProjectInputs } from "@lib/API";
import { FormInputs as ProjectFormInputs } from "../Forms/ProjectForm";
import { Typography } from "@mui/material";
import { useAddProject } from "@/Lib/hooks/useAddProject";

const PREFIX = "QuickSetup";

const classes = {
  navBarContainer: `${PREFIX}-navBarContainer`,
  buttonNav: `${PREFIX}-buttonNav`,
  icon: `${PREFIX}-icon`,
};

const Root = styled("div")({
  [`& .${classes.navBarContainer}`]: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "10px",
  },
  [`& .${classes.buttonNav}`]: {
    marginLeft: "10px",
    marginRight: "10px",
  },
  [`& .${classes.icon}`]: {
    marginLeft: "5px",
    marginRight: "5px",
  },
});

const StepControlsContainer = styled("div")(({ theme: { spacing } }) => ({
  paddingTop: spacing(2),
}));

function useFormProgress() {
  const [currentStep, setCurrentStep] = useState(0);

  function goForward() {
    setCurrentStep(currentStep + 1);
  }

  function goBack() {
    setCurrentStep(currentStep - 1);
  }

  return { currentStep, goForward, goBack };
}

export type StepCompleteFn = (complete: boolean) => void;

export default function ProjectSetup(): JSX.Element {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { addProject } = useAddProject();
  const { addUserToProject_projectVariant } = useBackend();

  const { prepopulateLenderUser, isLoading: lenderIsLoading } = usePrefill();

  // legacy, grumble
  const [legacyStateBasedData, setLegacyStateBasedData] = React.useState<{
    lender: BusinessPersonInputs;
    project: Omit<AddProjectInputs, keyof ProjectFormInputs>;
  }>({
    lender: {
      company: "",
      first_name: "",
      last_name: "",
      email: "",
      phone_number: "",
    },
    project: {
      projecttype: "",
      owneroccupied: "",
      originalsquarefeet: "",
      newsquarefeet: "",
      originalvalue: "",
      appraisedvalue: "",
      squarefeet: "",
    },
  });

  const { currentStep, goForward, goBack } = useFormProgress();
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [stepComplete, setStepComplete] = React.useState(false);
  const analytics = useAnalytics();

  const handleDialogOpen = () => {
    setOpen(true);
  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  const addData = (
    data: string,
    value: unknown,
    record: "lender" | "project"
  ) => {
    setLegacyStateBasedData({
      ...legacyStateBasedData,
      [record]: { ...legacyStateBasedData[record], [data]: value },
    });
  };

  const legacyHandleSubmit = (modernFormValues: ProjectFormInputs) => {
    async function doSubmit() {
      setLoading(true);

      addProject({
        projectData: {
          ...legacyStateBasedData,
          project: {
            ...legacyStateBasedData.project,
            ...modernFormValues,
          },
        },
        uploadedFiles: [],
      })
        .then((projectId) => {
          analytics.track("project:created", {
            projectId,
            name: modernFormValues.address,
          });

          //Add Lender
          addUserToProject_projectVariant({
            projectData: legacyStateBasedData,
            type: "Add Lender",
            projectId,
          })
            .catch((err) => {
              if (err.response?.status !== 304) {
                // 304 is cool; it means we added the user in the previous call
                throw err;
              } else {
                logAnomaly(new Error("Error adding lender"), {
                  err,
                  legacyStateBasedData,
                  modernFormValues,
                  projectId,
                });
                enqueueSnackbar(
                  "Project was created, but we could not add Lender",
                  { variant: "warning" }
                );
              }
            })
            .then(() => {
              navigate(`/projectview/${projectId}/${InfoView.Tabs.Budget}`);
            });
        })
        .catch((err) => {
          logError(new Error("Project create error"), { err });
          enqueueSnackbar("Error creating project", {
            variant: "warning",
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }

    doSubmit();
  };

  const [submitRef, focusSubmit] = useFocus<HTMLButtonElement>();

  const steps = [
    <LenderInfoStep
      preload={prepopulateLenderUser}
      onSubmit={(lender) => {
        setLegacyStateBasedData((data) => ({ ...data, lender }));
        goForward();
      }}
      onLastFieldEntered={focusSubmit}
    />,
    <ProjectInfoStep
      requiredOnly={true}
      addData={addData}
      data={legacyStateBasedData}
      setStepComplete={setStepComplete}
      onLastFieldEntered={focusSubmit}
      onSubmit={(projectData) => {
        legacyHandleSubmit(projectData);
      }}
    />,
  ];
  const isFirst = currentStep === 0;
  const isLast = currentStep === steps.length - 1;

  return lenderIsLoading ? (
    <Root style={{ display: "flex", justifyContent: "center" }}>
      <CircularProgress />
    </Root>
  ) : (
    <Root>
      {steps[currentStep]}

      <StepControlsContainer>
        <div className={classes.navBarContainer}>
          {!isFirst && (
            <Button
              className={classes.buttonNav}
              variant="outlined"
              size="large"
              onClick={() => goBack()}
            >
              <ArrowBackIcon className={classes.icon} /> Previous
            </Button>
          )}

          <Button
            className="buttonNav"
            variant="outlined"
            size="large"
            disabled={currentStep !== 0 && !stepComplete}
            type="submit"
            // component="button"
            // innerRef={submitRef}
            ref={submitRef}
            form={currentStep === 0 ? "lender-data-form" : "project-data-form"}
            // (e) => {
            //   if (currentStep !== 0) {
            //     advanceOrSubmit(e);
            //   }
            // }}
          >
            {isLast && loading ? <CircularProgress /> : null}
            {isLast && !loading ? "Submit" : null}
            {!isLast ? "Next" : null}
            <ArrowForwardIcon className={classes.icon} />
          </Button>
        </div>
        <div className={classes.navBarContainer}>
          <Button
            className={classes.buttonNav}
            variant="outlined"
            color="secondary"
            size="large"
            onClick={handleDialogOpen}
          >
            <CancelPresentationIcon className={classes.icon} /> Cancel{" "}
          </Button>
        </div>
        <div className={classes.navBarContainer}>
          <Typography>
            Step {currentStep + 1} of {steps.length}
          </Typography>
        </div>
      </StepControlsContainer>

      <Dialog
        open={open}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Abandon this Project?"}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary" autoFocus>
            No, Keep Editing
          </Button>
          <Link to="/dashboard" style={{ textDecoration: "none" }}>
            <Button color="primary">Yes, Abandon</Button>
          </Link>
        </DialogActions>
      </Dialog>
    </Root>
  );
}

// function emptyLoan(loanAmount, retention): LoanModel {
//   return {
//     interestRate: null,
//     maturityDate: null,
//     maxDraws: null,
//     loanAmount,
//     reporting: null,
//     retention,
//     startDate: null,
//   };
// }
// spell-checker: ignore zipcode projecttype owneroccupied originalsquarefeet newsquarefeet originalvalue appraisedvalue
// spell-checker: ignore squarefeet servicetype totalloanamount workorder addproject
