import React from "react";
import { useAppContext } from "../UserContext";

/**
 * Set the document title at the top of the browser tab
 *
 * // TODO: #61 Do we need a way for the mobile user to know where they are?
 *
 * @param title title to use
 * @param keepAfter whether to keep it after the component gets unmounted
 */

export function useDocumentTitle(title: string, keepAfter = false): void {
  const defaultTitle = React.useRef(document.title);

  React.useEffect(() => {
    document.title = title;
  }, [title]);

  React.useEffect(
    () => () => {
      if (!keepAfter) {
        document.title = defaultTitle.current;
      }
    },
    [keepAfter]
  );
}

/**
 * Set some text (or other content) in the header
 * *
 * @param content content to use
 */

export function useHeader(content?: React.ReactNode): void {
  const { headerContent, setHeaderContent } = useAppContext();
  const initialContent = React.useRef(headerContent);

  React.useEffect(() => {
    setHeaderContent(content);
  }, [content, setHeaderContent]);

  React.useEffect(
    () => () => {
      setHeaderContent(initialContent.current);
    },
    [setHeaderContent]
  );
}
