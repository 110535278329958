import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { uploadSupportingFiles } from "@lib/API";
import { logError } from "@lib/ErrorLogging";
import { useEntity } from "@lib/hooks/useEntity";
import {
  UploadProgressDialog,
  openAccordingToProgress,
  useProgress,
} from "../../Components/UploadProgressDialog";
import { EntityEditor } from "./EntityEditor";

export default function EditEntity() {
  const navigate = useNavigate();
  const { updateEntity, entities } = useEntity();
  const { enqueueSnackbar } = useSnackbar();
  const { entityId } = useParams();
  const entity = entities?.find(({ id }) => id === Number(entityId));

  const [progress, setProgress] = useProgress();

  //   if (entityId === undefined) {
  //     return navigate("/404");
  //   }
  return !entity ? (
    <CircularProgress />
  ) : (
    <>
      <EntityEditor
        open={Boolean(entity)}
        initialValue={entity}
        onClose={() => {
          navigate("..");
        }}
        title="Edit Entity"
        onSubmit={(values) => {
          const { newLogoFile, ...ingredients } = values;
          const uploadLogo = uploadSupportingFiles({
            files: newLogoFile ? [newLogoFile] : [],
            prefix: "e/(embryos)",
            progress: setProgress,
          });
          uploadLogo
            .then((logoFiles) =>
              updateEntity({
                ...entity,
                ...ingredients,
                ...(logoFiles[0]?.storageKey && {
                  // new file ? replace
                  logo: logoFiles[0]?.storageKey,
                }),
                // null is a signal to delete
                ...(newLogoFile === null && { logo: null }),
              })
            )
            .then(() => {
              navigate("..");
            })
            .catch((err) => {
              logError(err);
              enqueueSnackbar(
                "Sorry; something went wrong and we could not update",
                { variant: "error" }
              );
            });
        }}
      />
      <UploadProgressDialog
        progress={progress}
        open={openAccordingToProgress(progress)}
      />
    </>
  );
}
