import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import { CircularProgress, Theme } from "@mui/material";
import { useBackend } from "@lib/hooks";
import { useAuth } from "@lib/hooks/useAuth";
import { throwIfNot } from "@lib/util/throwIfNot";

const PREFIX = "AddChatRoom";

const classes = {
  formControl: `${PREFIX}-formControl`,
  chips: `${PREFIX}-chips`,
  chip: `${PREFIX}-chip`,
};

const StyledDialog = styled(Dialog)({
  [`& .${classes.formControl}`]: {
    minWidth: 120,
    maxWidth: 300,
  },
  [`& .${classes.chips}`]: {
    display: "flex",
    flexWrap: "wrap",
  },
  [`& .${classes.chip}`]: {
    margin: 2,
  },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name: string, personNames: string[], { typography }: Theme) {
  return {
    fontWeight:
      personNames.indexOf(name) === -1
        ? typography.fontWeightRegular
        : typography.fontWeightMedium,
  };
}

export interface AddChatRoomProps {
  open: boolean;
  users: {
    email: string;
    first_name: string;
    last_name: string;
  }[];
  handleCloseCallback: () => void;
  /** project ID, despite its name */
  project: string;
  callBack: () => void;
}

export default function AddChatRoom(props: AddChatRoomProps): JSX.Element {
  const theme = useTheme();
  const [namesInChat, setNamesInChat] = React.useState<string[]>([]);
  const [modal, setModal] = React.useState(false);
  const [roomName, setRoomName] = React.useState("");
  const [users, setUsers] = React.useState<
    { email: string; first_name: string; last_name: string }[]
  >([]);
  const { currentUserEmail: email } = useAuth();
  const [loading, setLoading] = React.useState(false);
  const { addChatRoom } = useBackend();

  const handlePersonChange = (event: { target: { value: unknown } }) => {
    setNamesInChat(event.target.value as string[]);
  };

  useEffect(() => {
    setModal(props.open);
    setUsers(props.users);
  }, [props.open, props.users]);

  const handleClose = () => {
    props.handleCloseCallback();
  };

  const handleChange = (event: { target: { value: string } }) => {
    setRoomName(event.target.value);
  };

  const handleSubmit = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    if (roomName === "") {
      alert("Please enter a room name.");
      return;
    }
    setLoading(true);
    addChatRoom({
      roomName,
      namesInChat,
      email: throwIfNot(email, "email is required"),
      projectId: props.project,
    })
      .then((response) => {
        console.log(response);
        setLoading(false);
        props.callBack();
        props.handleCloseCallback();
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  return (
    <StyledDialog open={modal} onClose={handleClose}>
      <DialogContent>
        <h1>Add A New Room</h1>
        <form>
          <FormControl variant="standard" fullWidth>
            <TextField
              variant="standard"
              required
              fullWidth
              id="chat-room-name"
              label="Room Name"
              name="room-name"
              value={roomName}
              onChange={handleChange}
            />
          </FormControl>
          <FormControl variant="standard" className={classes.formControl}>
            <InputLabel id="add-user-label">Add Users</InputLabel>
            <Select
              variant="standard"
              labelId="add-user-label"
              id="add-user"
              multiple
              fullWidth
              value={namesInChat}
              onChange={handlePersonChange}
              input={<Input id="select-multiple" />}
              renderValue={(selected) => (
                <div className={classes.chips}>
                  {(selected as string[]).map((value) => (
                    <Chip key={value} label={value} className={classes.chip} />
                  ))}
                </div>
              )}
              MenuProps={MenuProps}
            >
              {users.map((user) => (
                <MenuItem
                  key={user.email}
                  value={user.email}
                  style={getStyles(user.email, namesInChat, theme)}
                >
                  {user.first_name + " " + user.last_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary" disabled={loading}>
          {loading ? <CircularProgress /> : "Submit"}
        </Button>
      </DialogActions>
    </StyledDialog>
  );
}
