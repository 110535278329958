import {
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import BusinessIcon from "@mui/icons-material/Business";

import React from "react";
import { EntityList } from "./EntityList";
import { usePrefs } from "@lib/hooks/usePrefs";
import { UserList } from "./UserList";

const PREFIX = "AdminPage";

const classes = {
  root: `${PREFIX}-root`,
  drawer: `${PREFIX}-drawer`,
  drawerPaper: `${PREFIX}-drawerPaper`,
  toolbar: `${PREFIX}-toolbar`,
  content: `${PREFIX}-content`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: "flex",
    position: "relative",
    // zIndex: -1,
  },

  // appBar: {
  //   width: `calc(100% - ${drawerWidth}px)`,
  //   marginLeft: drawerWidth,
  // },
  [`& .${classes.drawer}`]: {
    width: drawerWidth,
    flexShrink: 0,
  },

  [`& .${classes.drawerPaper}`]: {
    width: drawerWidth,
  },

  // necessary for content to be below app bar
  [`& .${classes.toolbar}`]: theme.mixins.toolbar,

  [`& .${classes.content}`]: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
}));

const drawerWidth = 240;

export const AdminPage: () => JSX.Element = () => {
  const [activeSubTab, setActiveSubTab] =
    usePrefs("user.ui.state.admin.subtab") || "Users";

  return (
    <Root className={classes.root}>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
        // anchor="left"
      >
        <div className={classes.toolbar} />
        <Divider />
        <List>
          {["Entity", "Users"].map((text) => (
            <ListItem
              button
              key={text}
              selected={activeSubTab === text}
              onClick={() => {
                setActiveSubTab(text);
              }}
            >
              <ListItemIcon>
                <BusinessIcon />
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List>
        <Divider />
        <List>
          {[].map((text, index) => (
            <ListItem button key={text}>
              <ListItemIcon>
                <BusinessIcon />
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List>
      </Drawer>
      {/* <main className={classes.content}> */}
      <div className={classes.toolbar} />
      {activeSubTab === "Entity" ? <EntityList /> : undefined}
      {activeSubTab === "Users" ? <UserList /> : undefined}

      {/* </main> */}
    </Root>
  );
};
