/* eslint-disable no-irregular-whitespace */
import React from "react";
import { styled } from '@mui/material/styles';
const PREFIX = 'Privacy';

const classes = {
  wrapper: `${PREFIX}-wrapper`
};

const Root = styled('div')({
  [`&.${classes.wrapper}`]: {
    paddingTop: "20px",
  },
});

export default function Privacy(): JSX.Element {


  return (
    <Root className={classes.wrapper}>
      <p>
        <strong>Last updated July 1, 2020</strong>
      </p>{" "}
      <p>
        By using our website, www.projectcenterline.com, social media, and/or
        PROJECT CENTERLINE RESIDENTIAL LENDING SOFTWARE (“SaaS”), whether web or
        mobile-based (collectively, “Digital Property”), you agree to the
        collection and use of information in accordance with this Privacy
        Policy.
      </p>
      <p>
        <strong>Definitions</strong>
        <br /> The following definitions apply to this Privacy Policy:
      </p>
      <p>
        <strong>Service.</strong>
        <br /> Service is the use of PROJECT CENTERLINE LLC’s Digital Property.
      </p>{" "}
      <p>
        <strong>Personal Data.</strong>
        <br /> Personal Data means data about a person who can be identified
        from those data (or from those data and other information either in our
        possession or likely to come into our possession).
      </p>
      <p>
        <strong>Usage Data.</strong>
        <br /> Usage Data is data collected automatically either generated by
        the use of the Service or from the Service infrastructure itself (for
        example, the duration of a page visit).
      </p>{" "}
      <p>
        <strong>Cookies.</strong>
        <br /> Cookies are small pieces of data stored on your computer or
        mobile device.
      </p>{" "}
      <p>
        <strong>Data Controller.</strong>
        <br /> Data Controller means the natural or legal person who (either
        alone or jointly or in common with other persons) determines the
        purposes for which and the manner in which any personal information are,
        or are to be, processed.  For the purpose of this Privacy Policy,
        PROJECT CENTERLINE LLC is a Data Controller of your Personal Data.
      </p>{" "}
      <p>
        <strong>Data Processors (or Service Providers).</strong>
        <br /> Data Processor (or Service Provider) means any natural or legal
        person who processes the data on behalf of the Data Controller. We may
        use the services of various Service Providers in order to carry out
        purchases and to process your data more effectively.
      </p>{" "}
      <p>
        <strong>Data Subject (or User).</strong>
        <br /> Data Subject is any living individual who is using our Service
        and is the subject of Personal Data.
      </p>{" "}
      <p>
        <strong>Information Collection and Use</strong>
        <br /> We collect several different types of information for various
        purposes to provide and improve our Service to you.  While using our
        Service, we may ask you to provide us with certain personally
        identifiable information that can be used to contact or identify you
        ("Personal Data"). Personally identifiable information may include, but
        is not limited to: Email address, first and last name, phone number,
        address, birthdate, third party profile (twitter, instagram, etc.)
        information. We may use your Personal Data to contact you with marketing
        or promotional materials and other information that may be of interest
        to you. You may opt out of receiving marketing and/or promotional
        communications from us by following the unsubscribe link or instructions
        provided in any email we send.
      </p>{" "}
      <p>
        <strong>Cookies and Usage Data</strong>
        <br /> We may collect information how the Service is accessed and used
        ("Usage Data")  including information such as your computer's Internet
        Protocol address (e.g. IP address), browser type, browser version, the
        pages of our Service that you visit, the time and date of your visit,
        the time spent on those pages, unique device identifiers and other
        diagnostic data. We use cookies and similar tracking technologies to
        track the activity on our Service and hold certain information. Cookies
        are files with a small amount of data that may include an anonymous
        unique identifier. Cookies are sent to your browser from a website and
        stored on your computer or device. Tracking technologies also used are
        beacons, tags, and scripts to collect and track information and to
        improve and analyze our Service. You can instruct your browser to refuse
        all cookies or to indicate when a cookie is being sent. However, if you
        do not accept cookies, you may not be able to use some portions of our
        Service.
      </p>{" "}
      <p>
        <strong>Use and Retention of Data</strong>
        <br /> We value your privacy.
      </p>{" "}
      <p>
        By accessing, using, registering with, or providing personal information
        to PROJECT CENTERLINE LLC, you explicitly accept, without limitation or
        qualification, (i) the collection, use, storage and transfer (including
        international transfer) of user information in the manner described in
        this Privacy Policy and (ii) the PROJECT CENTERLINE LLC’s Terms of Use
        incorporated by reference. THIS PRIVACY POLICY AFFECTS YOUR RIGHTS AND
        LIABILITIES UNDER THE LAW. If you do not agree to the terms of this
        Privacy Policy, do not access or use the PROJECT CENTERLINE LLC Digital
        Property or provide us with personal information.
      </p>
      <p>
        PROJECT CENTERLINE LLC may collect different types of information on our
        Digital Property, including, but not limited to, personal information,
        financial information, demographic information, log, device, user, and
        aggregate data. Sometimes PROJECT CENTERLINE LLC collects combinations
        of all these types of information. In each case, when using PROJECT
        CENTERLINE LLC Digital Property, it is indicated on the Digital Property
        whether any personal or demographic data must be provided for you to
        access, use, and/or purchase the Digital Property or Service.
      </p>
      <p>
        When you provide us with information in connection with a particular
        activity or otherwise sign up for our Digital Property or Service or
        provide your contact information, including an email address or
        telephone number in connection with that activity, product or service,
        you agree that this action constitutes a purchase or inquiry
        establishing a business relationship with us. You expressly consent to
        our communicating with you about PROJECT CENTERLINE LLC using the
        information you provided to us. You also attest that you have the legal
        authority over any contact information you provide to us and can provide
        us with the authorization to contact you. This means we may contact you,
        in person or by recorded message, by email, telephone and/or mobile
        telephone number (including use of automated dialing equipment), text
        (SMS) message, or any other means of communication that your wireless or
        other telecommunications device may be capable of receiving.
      </p>
      <p>
        We will collect personal information from you if you register with
        PROJECT CENTERLINE LLC Digital Property or Services and/or place an
        order for Services, which may include, but is not limited to, name,
        address, phone number(s), email address(es), personal interest
        preferences, and any other information about you we consider relevant
        personal Information. Financial information is information that you
        provide to us, including loan information, debit or credit card number,
        and/or related billing information. We require such financial
        information in order to process payment for the purchase of the Service
        that you have requested and/or complete the Master Service Agreement and
        any necessary Work Orders. By submitting your financial information, you
        consent to our providing it to those third-party vendors as necessary to
        support and process your transactions as well as your credit card
        issuer/banking institution for their purposes. We ask that you do not
        enter financial information into your profile, except in the fields
        provided for that information.
      </p>
      <p>
        PROJECT CENTERLINE LLC does not sell your Personal Data to third
        parties. PROJECT CENTERLINE LLC may use or share your Personal Data with
        third party vendors for internal uses including for customer
        communications, delivery, troubleshooting, research and development,
        product development, data, analytics, and surveys, to enhance your
        experience and to provide, and maintain our Service, to notify you about
        changes to our Service, to allow you to participate in interactive
        features of our Service when you choose to do so, to provide customer
        support, to gather analysis or valuable information so that we can
        improve our Service, to monitor the usage of our Service, to detect,
        prevent and address technical issues and to provide you with news,
        offers and general information about other goods, services and events
        which we offer that are similar to those that you have already engaged
        in unless you have opted not to receive such information.
      </p>
      <p>
        We will retain your Personal Data only for as long as is necessary and
        only for the purposes set out in this Privacy Policy. We will retain and
        use your Personal Data to the extent necessary to comply with legal
        obligations, resolve disputes, to enforce our policies and for internal
        analysis purposes. Information, including Personal Data, may be
        transferred and maintained on computers located outside of your state,
        country or other governmental jurisdiction where the data protection
        laws may differ.  If you are located outside the United States and
        choose to provide information to us, please note that we transfer and
        process the data, including Personal Data, to the United States. PROJECT
        CENTERLINE LLC will take all steps reasonably necessary to ensure that
        your data is treated securely and in accordance with this Privacy
        Policy. PROJECT CENTERLINE LLC may disclose your Personal Data in the
        good faith belief that such action is necessary to comply with a legal
        obligation, protect and defend the rights or property of PROJECT
        CENTERLINE LLC or its users, prevent or investigate wrongdoing in
        connection with the Service, protect the personal safety of users of the
        Service or the public and to protect against legal liability.
      </p>
      <p>
        Security of your data is important to us.  However, no method of
        transmission or electronic storage is 100% secure. While we use
        commercially reasonable means to protect your Personal Data, we cannot
        guarantee its absolute security.
      </p>
      <p>
        BY ACCESSING OR USING THE WEBSITE AND SERVICE IN ANY WAY OR BY PROVIDING
        INFORMATION TO PROJECT CENTERLINE LLC THROUGH ITS DIGITAL PROPERTY, YOU
        ARE AGREEING TO THE PRIVACY POLICY.
      </p>
      <p>
        <strong>Third Party Service Providers</strong>
        <br /> We may employ third party companies and individuals to facilitate
        our Service ("Service Providers") to provide the Service and perform
        Service-related activities or to assist us in analyzing how our Service
        is used and to monitor and analyze the use of our Service. These third
        parties have access to your Personal Data only to perform these tasks on
        our behalf and are obligated not to disclose or use it for any other
        purpose.  We use third-party services for sales, marketing, technical
        development and maintenance, fraud protection, analytics, and hosting
        services. The third parties we work with are:
      </p>{" "}
      <p>
        <strong>WordPress:</strong>
        <br /> Their privacy policy can be viewed here:
        https://automattic.com/privacy/
      </p>{" "}
      <p>
        <strong>AnnieCannons:</strong>
        <br /> Their privacy policy can be viewed here:
        https://anniecannons.com/privacy-policy/
      </p>{" "}
      <p>
        <strong>AWS:</strong>
        <br /> Their privacy policy can be viewed here:
        https://aws.amazon.com/privacy/
      </p>
      <p>
        <strong>Google:</strong>
        <br /> Their privacy policy can be viewed here: 
        https://policies.google.com/privacy
      </p>
      <p>
        <strong>Google Analytics:</strong>
        <br /> Their privacy policy can be viewed here: 
        https://support.google.com/analytics/topic/2919631?hl=en&ref_topic=1008008
      </p>
      <p>
        <strong>LinkedIn:</strong>
        <br /> Their privacy policy can be viewed here: 
        https://www.linkedin.com/legal/privacy-policy
      </p>
      <p>
        <strong>Jump Crew:</strong>
        <br /> Their privacy policy can be viewed here: 
        https://jumpcrew.com/privacy/
      </p>
      <p>
        <strong>Twitter:</strong>
        <br /> Their privacy policy can be viewed here: 
        https://twitter.com/en/privacy
      </p>
      <p>
        <strong>Under Age 18</strong>
        <br /> We do not knowingly collect personally identifiable information
        from anyone under the age of 18. If you are a parent or guardian and you
        are aware that your child(ren) has provided us with Personal Data,
        please contact us. If we become aware that we have collected Personal
        Data from children without verification of parental consent, we take
        reasonable steps to remove that information from our servers.
      </p>
      <p style={{ textAlign: "center", textDecoration: "underline" }}>
        <strong>YOUR CALIFORNIA PRIVACY RIGHTS</strong>
      </p>
      <p>
        {" "}
        This section of the Privacy Policy applies if you are a resident of
        California and use our Site.
      </p>
      <p>
        California Law (CA Civil Code section 1798.83) grants California
        residents the right to obtain certain information about the types of
        Personal Information that companies with whom those individuals have an
        established business relationship have shared with third parties for
        direct marketing purposes during the preceding calendar year. In
        particular, that law provides that California residents may request a
        copy of the Personal Information that we hold about them. California
        residents may also request information regarding the categories of
        Personal Information that have been shared with third parties, the names
        and addresses of those third parties and examples of the types of
        services or products marketed by those third parties. To request either
        a copy of the information maintained by us about you or to request a
        copy of the information disclosure provided by us during the preceding
        calendar year please contact us via email at 
        legal@projectcenterline.com, or by writing us at the address at the
        bottom of this Privacy Policy. Please allow 30 days for a response to
        your request.
      </p>
      <p>
        The California Consumer Privacy Act of 2018 (California Civil Code
        section 1798.100) (CCPA) grants residents of California certain rights
        pertaining to the Personal Information we may collect and share with
        third parties for a business purpose. These purposes may include:
        providing our products and services, providing customer service,
        performing surveys, analytics and quality control,researching and
        testing features and improvements, detecting and preventing fraud and
        security incidents, debugging or repairing technical errors, and
        marketing our products.  PROJECT CENTERLINE LLC does not sell your
        information to third parties.
      </p>
      <p>
        The categories of information we have collected from California
        residents in the past 12 months, the sources of that information, the
        business purpose for the collection of that information, and the third
        parties to whom we may disclose that information may include:
      </p>
      <ul>
        <li>
          Identifiers: We may collect your name, aliases, postal address, email
          address, online identifier, Internet Protocol address, account name,
          or other similar unique personal identifiers. We may collect this
          Personal Information directly from you, indirectly from you (e.g.,
          from observing your actions on our website) or from third party
          service providers, such as a data analytics provider. The business
          purposes for which we may collect this information may include
          responding to an inquiry from you, providing information about
          products or special offers, or processing documentation required for
          legal purposes. We may disclose this information to third parties,
          including providers of internet, marketing, and administrative
          services.
        </li>
        <li>
          Commercial information: We may collect information pertaining to
          products or services purchased, obtained, or considered, or other
          purchasing or consuming histories or tendencies. We may collect this
          information directly from you. The business purposes for which we may
          collect this information may include responding to an inquiry from
          you, providing information about products, or special offers. We may
          disclose this information to third parties, including providers of
          marketing services.
        </li>
        <li>
          Internet or other similar network activity: We may collect information
          related to browsing history, search history, information on a
          consumer’s interaction with a website, application, or advertisement.
          We may collect this information indirectly from you (e.g., from
          observing your actions on our Site) or from third-party service
          providers, such as a data analytics provider. The business purpose for
          which we may collect this information includes providing targeted
          advertising and providing information about products or special
          offers. We may disclose this information to third parties, including,
          internet service providers and advertising networks.
        </li>
        <li>
          Geolocation data: We may collect information pertaining to location.
          We may collect this information directly from you. The business
          purposes for which we may collect this information includes responding
          to an inquiry, processing your order or to provide targeted
          advertising. We may disclose this information to third parties,
          including, internet service providers and advertising networks.
        </li>
        <li>
          Inferences drawn from other personal data: We may collect profiles
          reflecting a person’s shopping preferences and behavior. We may
          collect this information indirectly from you (e.g., from observing
          your actions on our Site), and from third-party service providers,
          such as a data analytics provider. The business purposes for which we
          may collect this information include improving the online experience
          for consumers. We may disclose this information to third parties,
          including advertising networks, in aggregated and/or deidentified,
          i.e., anonymized, form.
        </li>
        <li>
          We may collect information for human resources requirements related to
          qualifications for employment. We may collect this information
          directly from you. The business purposes for which we may collect this
          information includes processing employment applications and
          maintaining employee records as required by law. We may disclose this
          information to third parties, including providers of human resources
          administrative services.  
        </li>
      </ul>
      <p>
        If you are a California resident, the CCPA may grant you certain rights
        in connection with the Personal Information we collect, including:
      </p>
      <ul>
        <li>
          You have the right to know and request, no more than twice in a
          12-month period, information about the categories of Personal
          Information we have collected about you, as well as the categories of
          sources from which such information is collected, the purpose for
          collecting such information, and the categories of third parties with
          whom we share such information. You also have the right to know if we
          have sold your Personal Information or disclosed your Personal
          Information for a business purpose, and, if so, the categories of
          Personal Information we sold or disclosed for a business purpose. You
          also have the right to request the specific Personal Information we
          have collected about you and the third parties to whom we may disclose
          Personal Information. We will strive to respond to a verified request
          within thirty (30) days of its receipt. If more time is reasonably
          necessary to provide you with the information you request, we may take
          an additional thirty (30) days to respond to your request, and will
          notify you in writing of the reason(s) an extension is required.
        </li>
        <li>
          You have the right to “opt out” of the sale of Personal Information
          (or, for consumers who are under 16 years old, the right not to have
          their Personal Information sold absent their, or their parent’s opt-in
          (for consumers ages 13-15)). 
        </li>
        <li>
          You have the right to request that we delete your Personal
          Information.  We may retain your Personal Information, however,
          pursuant to one or more exceptions provided by law.
        </li>
        <li>
          You have the right to non-discrimination. You have the right to
          receive equal service and pricing from a business, even after
          exercising your rights.
        </li>
      </ul>
      <p>
        To submit an access or deletion request, email
        legal@projectcenterline.com.  
      </p>
      <p>
        We verify your identity before completing your request for access or
        deletion, and, may ask for you to confirm data points we already have
        about you in order to assure this is a verifiable consumer request. We
        will only use Personal Information provided in a request to verify the
        requestor’s identity or authority to make the request. You may designate
        an authorized agent to make a request on your behalf. Such authorized
        agent must be registered with the California Secretary of State. We may
        deny a request from an agent that does not submit written proof that
        they have been authorized by you to act on your behalf.
      </p>
      <p style={{ textAlign: "center", textDecoration: "underline" }}>
        <strong>GENERAL DATA PROTECTION REGULATION (GDPR)</strong>
      </p>
      <p>
        If you are from the European Economic Area (EEA), the legal basis for
        collecting and using the personal information described in this Privacy
        Policy depends on the Personal Data and the specific context in which we
        collect it. PROJECT CENTERLINE LLC may process your Personal Data
        because: We are performing a contract with you, or you have given us
        permission to do so, or it is in our legitimate and reasonable interests
        and it's not overridden by your rights. If you are a resident of the
        European Economic Area (EEA), you have certain data protection rights.
        We take reasonable steps to allow you to correct, amend, delete, or
        limit the use of your Personal Data.  Under the GDPR, you have the
        following data protection rights:
      </p>
      <p>
        <strong>Access, update or to delete.</strong>
        <br /> Whenever reasonably possible, you can access, update or request
        deletion of your Personal Data.
      </p>
      <p>
        <strong>Rectification.</strong>
        <br /> You can have your information rectified, completed or corrected
        if that information is inaccurate or incomplete.
      </p>
      <p>
        <strong>Object.</strong>
        <br /> You can object to our processing of your Personal Data.
      </p>
      <p>
        <strong>Restriction.</strong>
        <br /> You can restrict the processing of your personal information.
      </p>
      <p>
        <strong>Data portability.</strong>
        <br /> You request to be provided with a copy of the information we have
        on you in a structured, machine-readable and commonly used format.
      </p>
      <p>
        <strong>Withdraw consent.</strong>
        <br /> You can withdraw your consent at any time where we have your
        consent to process your personal information.
      </p>
      <p>
        You have the right to complain to a Data Protection Authority about our
        collection and use of your Personal Data. For more information, please
        contact your local data protection authority in the European Economic
        Area (EEA).
      </p>
      <p>
        If you wish to exercise a GDPR right, to be informed what Personal Data
        we hold about you and if you want Personal Data to be removed from our
        systems or want Personal Data to be corrected or changed please contact
        us as indicated below. Please note that we may ask you to verify your
        identity before responding to such requests.
      </p>
      <p>
        To make a GDPR request or inquiry please contact us at
        legal@projectcenterline.com
      </p>
      <p>
        The GDPR rights and obligations identified herein are specific and
        exclusive to users from the European Economic Area (EEA).  However,
        where commercially reasonable, and at the sole discretion of PROJECT
        CENTERLINE LLC, these rights and obligations may be provided and applied
        to other users.
      </p>
      <p>
        <strong>Changes to Privacy Policy</strong>
        <br /> We may update our Privacy Policy from time to time. We will
        notify you of any changes by posting the new Privacy Policy on this
        page.  You are advised to review this Privacy Policy periodically for
        any changes. Changes to this Privacy Policy are effective when they are
        posted on this page.
      </p>
      <p>
        <strong>Contact</strong>
        <br />{" "}
        <strong>
          If you have any questions about this Privacy Policy, please contact us
          at legal@projectcenterline.com.
        </strong>
      </p>
    </Root>
  );
}

//spell-checker:ignore LLC’s birthdate CCPA deidentified anonymized requestor’s
