import React from "react";
import { styled } from "@mui/material/styles";
import { useLocation, Link } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import logoFull from "./../Assets/Images/logo_house_pc.svg";
import logoTruncated from "./../Assets/Images/logo_house_pc_letters_only.svg";
import MenuItem from "@mui/material/MenuItem";
import Menu, { MenuProps } from "@mui/material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MoreIcon from "@mui/icons-material/MoreVert";
import SearchIcon from "@mui/icons-material/Search";
import { StealthyLink } from "./StealthyLink";
import { Box, Theme, Tooltip, useMediaQuery } from "@mui/material";
import { useAuth } from "@lib/hooks/useAuth";
import { observer } from "mobx-react-lite";
import { useNewUIHook } from "@/Lib/hooks/useNewUI";
import { NewUISwitch } from "@/Pages/ProfilePage";

const LogoContainer = styled("div")(({ theme: { mixins } }) => ({
  height: mixins.toolbar.minHeight,
  alignSelf: "flex-end",
  display: "flex",
  alignItems: "center",
  // height: "100%"
}));

const LogoImage = styled("img")({
  filter: "invert(1)",
  height: "80%",
  // aspectRatio:
});

export interface LoggedInHeaderProps {
  logout: () => Promise<void>;
  children?: React.ReactNode;
}

const SearchButton = ({ label }: { label?: string }) => {
  const location = useLocation();

  return (
    <StealthyLink
      to={`${location.pathname}/../Search`}
      // state={{
      //   value: "Search",
      // }}
    >
      <IconButton aria-haspopup="true" color="inherit" size="large">
        <SearchIcon />
      </IconButton>
      {label ? <span>{label}</span> : null}
    </StealthyLink>
  );
};

const UserProfileMenu = ({
  anchorEl,
  showSearch,
  title,
  logout,
  ...menuProps
}: {
  anchorEl: HTMLElement | null;
  showSearch: boolean;
  title: string;
  logout: () => Promise<unknown>;
} & MenuProps) => {
  const { featureFlag } = useNewUIHook();

  return (
    <Menu
      {...menuProps}
      MenuListProps={{ dense: true }}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
    >
      {showSearch ? (
        <MenuItem>
          <SearchButton label="Search" />
        </MenuItem>
      ) : null}
      <Tooltip title={title}>
        <MenuItem onClick={logout}>
          <IconButton
            aria-label="account of current user"
            aria-controls="primary-search-account-menu"
            aria-haspopup="true"
            color="inherit"
            size="large"
          >
            <AccountCircle />
          </IconButton>
          <span>Logout</span>
        </MenuItem>
      </Tooltip>
      {featureFlag !== "no-offer" ? <NewUISwitch /> : null}
    </Menu>
  );
};

function LoggedInHeaderComponent(props: LoggedInHeaderProps): JSX.Element {
  const { currentUserEmail: email } = useAuth();
  const location = useLocation();

  const { children, logout } = props;
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const isMenuOpen = Boolean(anchorEl);
  const largeEnoughScreen = useMediaQuery("(min-width:600px)"); // this is where mui app (tool?) bar switches
  const useMobileMenu = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  const useFullIcon = !children || largeEnoughScreen;
  const logo = useFullIcon ? logoFull : logoTruncated;

  const handleProfileMenuOpen = (event: { currentTarget: HTMLElement }) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const menuId = "primary-search-account-menu";

  // TOD probably better done via react-router
  const showSearch = React.useMemo(
    () => !["/", "/dashboard", "/portfolio"].includes(location.pathname),
    [location.pathname]
  );

  return (
    <div
      style={{
        flexGrow: 1,
      }}
    >
      <AppBar position="static">
        <Toolbar
          sx={{
            alignItems: "center",
          }}
        >
          <Link to="/dashboard">
            <LogoContainer>
              <LogoImage src={logo} alt="Project Centerline" />
            </LogoContainer>
          </Link>
          <div style={{ flexGrow: 1 }} />
          {props.children}
          <div style={{ flexGrow: 1 }} />

          <Box>
            {showSearch && !useMobileMenu ? <SearchButton /> : null}
            <Tooltip title={useMobileMenu ? "more" : email}>
              <IconButton
                onClick={handleProfileMenuOpen}
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                color="inherit"
                size="large"
              >
                {useMobileMenu ? <MoreIcon /> : <AccountCircle />}
              </IconButton>
            </Tooltip>
          </Box>
          <UserProfileMenu
            anchorEl={anchorEl}
            logout={logout}
            open={isMenuOpen}
            onClose={handleMenuClose}
            showSearch={showSearch}
            title={email ?? ""}
          />
        </Toolbar>
      </AppBar>
    </div>
  );
}

export const LoggedInHeader = observer(LoggedInHeaderComponent);
export default LoggedInHeader;
