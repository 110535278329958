import { Button } from "@mui/material";

import { styled } from "@mui/material/styles";

import React from "react";
import { BackendUserResponse } from "@lib/APITypes";
import { usePermissions } from "@lib/hooks";
import { Role } from "@lib/roles";
import { UserAdminPane } from "./UserAdminPane";
import { EmailAddress } from "@project-centerline/project-centerline-api-types";

const PREFIX = "UserDetailsView";

const classes = {
  element: `${PREFIX}-element`,
};

const Root = styled("div")({
  [`& .${classes.element}`]: {
    marginTop: "10px",
  },
});

export function UserDetailsView({
  user,
  onEditUser,
  onDeleteUser,
  hidePhone,
  suppressAdmin,
}: {
  user: BackendUserResponse;
  onEditUser?: (arg0: Omit<BackendUserResponse, "projects">) => void;
  onDeleteUser?: (email: EmailAddress) => void;
  hidePhone?: boolean;
  suppressAdmin?: boolean;
}): JSX.Element {
  const { canModifyUser, canSeeUserAdminInfo } = usePermissions();

  return (
    <Root>
      <p className={classes.element}>
        <strong>Role</strong>
        <br />
        {user.role === Role.Owner ? "Borrower" : user.role}
      </p>
      <p className={classes.element}>
        <strong>Email</strong>
        <br />
        {user.email}
      </p>
      {hidePhone ? null : (
        <p className={classes.element}>
          <strong>Phone Number</strong>
          <br />
          {user.phone_number}
        </p>
      )}
      <div>
        {canModifyUser(user) ? (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              margin: "10px",
            }}
          >
            {onEditUser ? (
              <Button
                variant="outlined"
                color="primary"
                onClick={() =>
                  onEditUser({
                    ...user,
                    role: user.role as Role /* TODO: sanitize in API */,
                  })
                }
              >
                Edit
              </Button>
            ) : (
              <div />
            )}
            {onDeleteUser ? (
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => onDeleteUser(user.email)}
              >
                Remove
              </Button>
            ) : (
              <div />
            )}
          </div>
        ) : null}
      </div>
      {canSeeUserAdminInfo && user.id && !suppressAdmin ? (
        <UserAdminPane id={user.id} />
      ) : undefined}
    </Root>
  );
}
