import { TaskModel } from "./ImportTasksModels";

const looksValid = (rows: Record<string, unknown>) =>
  TaskModel.is({ id: 1, ...rows });

const simpleThreeColumnParser = <
  HeaderTester extends { test(content: string): boolean },
  SniffType extends {
    titleColumn?: number;
    valueColumn?: number;
    descriptionColumn?: number;
    headerRowIndex: number;
  }
>({
  title,
  value,
  desc,
}: {
  title: HeaderTester;
  value: HeaderTester;
  desc?: HeaderTester;
}) => {
  const sniffRawRowData = (rows: string[][]) => {
    let titleColumn: number | undefined,
      valueColumn: number | undefined,
      descriptionColumn: number | undefined;
    const headerRowIndex = rows.findIndex((row) => {
      titleColumn = row.findIndex((s) => title.test(s));
      valueColumn = row.findIndex((s) => value.test(s));
      descriptionColumn = desc ? row.findIndex((s) => desc.test(s)) : undefined;
      return valueColumn >= 0 && titleColumn >= 0;
    });
    if (headerRowIndex < 0) {
      return {
        fail: {
          reasons: [new Error("Data headers not found")],
        },
      };
    }
    return {
      success: {
        titleColumn,
        valueColumn,
        descriptionColumn,
        headerRowIndex,
      } as SniffType,
    };
  };

  const parseRawRowData = (rows: string[][]) => {
    const sniffData = sniffRawRowData(rows);
    if (sniffData.fail) {
      throw sniffData.fail.reasons[0];
    }
    return parsePreSniffed(rows, sniffData.success);
  };

  const parsePreSniffed = (
    rows: string[][],
    { titleColumn, valueColumn, descriptionColumn, headerRowIndex }: SniffType
  ) => {
    if (titleColumn === undefined || valueColumn === undefined) {
      throw new Error("bad sniff data");
    }

    const extractColumns = (row: string[]) => {
      const candidate = (row[valueColumn] || "").trim().replace(/[$\s,]/g, "");
      const value = Number(candidate === "-" ? 0 : candidate);
      const title = (row[titleColumn] || "").trim();
      const description =
        descriptionColumn !== undefined
          ? (row[descriptionColumn] || "").trim()
          : undefined;
      return { title, value, description };
    };

    const validRows = rows
      .slice(headerRowIndex + 1)
      .map(extractColumns)
      .filter(looksValid)
      .map((row, idx) => ({ ...row, id: idx }))
      .map((parsedValues) => TaskModel.create(parsedValues));
    if (validRows.length === 0) {
      console.log({ rows });
      throw new Error("No valid rows found");
    }

    return validRows;
  };

  return { sniffRawRowData, parseRawRowData, parsePreSniffed }; // premature optimization, I bet :-)
};

type Parser = ReturnType<typeof simpleThreeColumnParser>;

/**
 * Knows how to read g703 tables, at least the ones from `200930_2350_Mission_College_4th_Floor_RR_20051013.zip`
 */
const g703 = simpleThreeColumnParser({
  title: /\s*description of work\s*/i,
  value: /\s*schedule(?:d)? value\s*/i,
  desc: /^\s*Description\s*$/i,
});

/**
 * Knows how to read a DHLC spreadsheet, circa 4/2021 - at least the one John shared `3155_Whitehall_Drive_Dallas_TX.csv`
 */
const dhlc = simpleThreeColumnParser({
  title: /\s*Repair category\s*/i,
  value: /\s*Selected budget\s*/i,
  desc: /^\s*Description\s*$/i,
});

/**
 * Knows how to read a Rock East spreadsheet, circa 6/2021 - at least the one John shared `Draw_Tracker_-_146_Johnston_St..xlsx_-_Sheet1.csv`
 */
const rockEast = simpleThreeColumnParser({
  title: /\s*Construction Item\s*/i,
  value: /\s*Budget\s*/i,
  desc: /^\s*Description\s*$/i,
});

/**
 * Knows how to read a Skybeam spreadsheet, circa 1/2022 - at least the one John shared `2512 Perkerson Rd Budget.xlsx`, saved to CSV by Mitch via Macos Numbers as `2512_Perkerson_Rd_Budget-1.csv`
 * spell-checker:ignore perkerson
 */
const skybeam = simpleThreeColumnParser({
  title: /^\s*Category\s*$/i,
  value: /^\s*(Amount)|(Budget)\s*$/i,
  desc: /^\s*Comment\s*\/\s*Detail\s*$/i,
});

/**
 * Choose a parser (based on some options that don't exist yet, or after sniffing the contents, etc).
 *
 * @returns an array or parsers sorted by how suitable they seem for these contents.
 *  Currently there is only one,^H^H^H^H^H^H^H^H^H :-) a strict g703 parser
 *     and a custom one for DHLC (which is just alternate, orthogonal, column names) // spell-checker:ignore DHLC
 */
export const chooseParser: (rows: string[][]) => Parser[] = (rows) => {
  const analysis = [g703, dhlc, rockEast, skybeam].map((parser) => ({
    parser,
    sniff: parser.sniffRawRowData(rows),
  }));

  const successes = analysis.filter(({ sniff: { success } }) =>
    Boolean(success)
  );

  if (successes.length === 0) {
    throw analysis[0].sniff.fail?.reasons[0];
  }
  if (successes.length > 1) {
    throw new Error("too many matching parsers"); // RN we think they are completely orthogonal, so complain (for now)
  }
  return successes.map(({ parser }) => parser);
};

export const _test = { g703, dhlc, rockEast, skybeam };
