import React from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import MailIcon from "@mui/icons-material/Mail";
import { Auth } from "aws-amplify";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CircularProgress from "@mui/material/CircularProgress";
import ForgotPassword from "./ForgotPassword";
import { ifEnterKey } from "@lib/boilerplate";
import { useFocus } from "@lib/hooks/useFocus";
import { ConfirmUserDialog, SignUp } from "./SignUp";
import { querystring } from "@lib/querystring";
import { SxProps, Typography } from "@mui/material";
import { CognitoUser } from "@aws-amplify/auth";
import Terms from "./Terms";
import Privacy from "./Privacy";
import { logError } from "@lib/ErrorLogging";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "@lib/hooks/useAuth";
import { useGetUserRole } from "@/Lib/hooks/useGetUserRole";

const maxWidth = {
  maxWidth: 345,
};

const sx: Record<string, SxProps> = {
  mailIcon: {
    width: "20px",
    marginRight: "10px",
  },
};

const aWeirdInheritedBlobOfStyle = {
  display: "flex",
  justifyContent: "center",
  paddingTop: "20px",
};

const Root = styled("div")(aWeirdInheritedBlobOfStyle);
const FormWithLegacyStyle = styled("form")(maxWidth);

const NewPasswordDialog = ({
  open,
  onClose,
  onSubmit,
}: {
  open: boolean;
  onClose: () => void;
  onSubmit: (password: string) => void;
}) => {
  const [password, setPassword] = React.useState("");
  const [confirm, setConfirm] = React.useState("");
  const [termsOpen, setTermsOpen] = React.useState(false);
  const [privacyOpen, setPrivacyOpen] = React.useState(false);

  const errors = React.useMemo(() => {
    if (!password) {
      return {
        password: "You must enter a new password",
      };
    }

    // tempted to just let it all go and let AWS tell us what's wrong. But AWS messages ("password does not conform to policy") are in the wrong voice :-)
    const [UPPER, lower] = [password.toUpperCase(), password.toLowerCase()];
    if (
      password.length < 8 ||
      password === lower ||
      password === UPPER ||
      !/\d/.test(password)
    ) {
      return {
        password:
          "Password must be at least 8 characters, with upper and lower case and at least one number",
      };
    }

    if (confirm !== password) {
      return {
        confirm: "Passwords do not match",
      };
    }

    return null;
  }, [password, confirm]);

  const [passwordRef, focusPassword] = useFocus();

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="new-password-dialog-title"
      >
        <DialogTitle id="new-password-dialog-title">
          Set New Password
        </DialogTitle>
        <DialogContent>
          <Typography>You must create a new password</Typography>
          <form autoComplete="off">
            <TextField
              variant="standard"
              id="new-password"
              name="password"
              label="New Password"
              type="password"
              fullWidth
              required
              autoFocus
              value={password}
              error={Boolean(errors?.password)}
              helperText={errors?.password}
              onChange={({ target: { value } }) => setPassword(value)}
              onKeyDown={ifEnterKey(focusPassword)}
            />
            <TextField
              variant="standard"
              id="confirm-password"
              name="confirm-password"
              label="Confirm"
              type="password"
              fullWidth
              required
              value={confirm}
              error={Boolean(errors?.confirm)}
              helperText={errors?.confirm}
              onChange={({ target: { value } }) => setConfirm(value)}
              onKeyDown={ifEnterKey(() => onSubmit(password))}
              inputRef={passwordRef}
            />
          </form>
        </DialogContent>
        <DialogActions
          sx={({ spacing }) => ({
            display: "flex",
            justifyContent: "space-between",
            marginLeft: spacing(2),
          })}
        >
          <p>
            By clicking Submit, you agree to{" "}
            <span
              onClick={() => setTermsOpen(true)}
              style={{
                textDecoration: "underline",
                color: "blue",
                cursor: "pointer",
              }}
            >
              Terms
            </span>{" "}
            and{" "}
            <span
              onClick={() => setPrivacyOpen(true)}
              style={{
                textDecoration: "underline",
                color: "blue",
                cursor: "pointer",
              }}
            >
              Privacy Policy
            </span>
            .
          </p>
          <div>
            <Button onClick={onClose} color="primary">
              Cancel
            </Button>
            <Button
              type="submit"
              disabled={Boolean(errors)}
              color="primary"
              onClick={() => onSubmit(password)}
            >
              Submit
            </Button>
          </div>
        </DialogActions>
      </Dialog>
      <Dialog
        onClose={() => setTermsOpen(false)}
        aria-labelledby="terms-dialog-title"
        open={termsOpen}
        maxWidth={"lg"}
      >
        <DialogTitle id="terms-dialog-title">Terms Of Use</DialogTitle>
        <DialogContent>
          <Terms />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setTermsOpen(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        onClose={() => setPrivacyOpen(false)}
        aria-labelledby="privacy-dialog-title"
        open={privacyOpen}
        maxWidth={"lg"}
      >
        <DialogTitle id="privacy-dialog-title">Privacy Policy</DialogTitle>
        <DialogContent>
          <Privacy />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setPrivacyOpen(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default function Login({
  signupMode,
  newUserMode,
}: {
  signupMode?: boolean;
  newUserMode?: boolean;
}): JSX.Element {
  const {
    signIn,
    signOut,
    isAuthenticated,
    isValidating,
    refresh: refreshAuth,
  } = useAuth();
  const { user } = useGetUserRole();

  const [forgotPassword, setForgotPassword] = React.useState(false);
  const [open, setOpen] = React.useState(Boolean(newUserMode || signupMode));
  const [signupFormOpen, setSignupFormOpen] = React.useState(
    Boolean(signupMode)
  );
  const [resetPassword, setResetPassword] = React.useState<
    (CognitoUser & { challengeParam: { requiredAttributes: unknown } }) | false
  >(false);

  React.useEffect(() => {
    if (signupMode && isAuthenticated) {
      (async () => {
        await signOut();
      })();
    }
  }, [signupMode, isAuthenticated, signOut]);

  React.useEffect(() => {
    if (newUserMode && user?.loaded) {
      if (isAuthenticated) {
        (async () => {
          await signOut();
        })();
      }
    }
  }, [isAuthenticated, newUserMode, signOut, user]);

  const searchU = querystring("u");
  const preload = React.useMemo(
    () =>
      searchU &&
      (() => {
        const [email, password] = JSON.parse(
          window.atob(decodeURIComponent(searchU))
        ).map(decodeURIComponent);
        return { email, password };
      })(),
    [searchU]
  );

  const [needConfirm, setNeedConfirm] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [values, setValues] = React.useState(
    preload || {
      email: "",
      password: "",
    }
  );
  const [redirect, setRedirect] = React.useState<string | undefined>();
  const { pathname } = useLocation();

  const openForm = () => {
    setOpen(true);
  };

  const closeForm = () => {
    setSignupFormOpen(false);
    setOpen(false);
  };

  const handleChange = (event: {
    target: { name: string; value: unknown };
  }) => {
    setValues({ ...values, [event.target.name]: event.target.value });
  };

  const handleForgotPassword = () => {
    setForgotPassword(true);
  };

  const callBack = () => {
    setForgotPassword(false);
  };

  const handleSubmit = async (event?: { preventDefault: () => void }) => {
    event?.preventDefault();
    setCredsToAuth(values);
  };

  const [credsToAuth, setCredsToAuth] = React.useState<
    { email: string; password: string } | undefined
  >();
  React.useEffect(() => {
    if (credsToAuth) {
      setCredsToAuth(undefined);
      const { email, password } = credsToAuth;
      setLoading(true);

      try {
        signIn(email.toLowerCase().trim(), password)
          .then((response) => {
            if (response.challengeName === "NEW_PASSWORD_REQUIRED") {
              setResetPassword(response);
              return;
            }
          })
          //need another error message for if it is less than 8 characters - let the user know "Password must be minimum 8 characters"
          //test by creating another account, f
          .catch((err) => {
            if (err.code === "UserNotConfirmedException") {
              setNeedConfirm(true);
            } else if (err.code === "NotAuthorizedException") {
              alert(
                "You entered the incorrect email or password. Please try again."
              );
            } else if (err.code === "UserNotFoundException") {
              alert(
                "You entered the incorrect email or password. Please try again."
              );
            } else if (
              err.code === "InvalidParameterException" &&
              err.message.includes(
                "Value at 'userName' failed to satisfy constraint: Member must satisfy regular expression pattern: "
              )
            ) {
              alert("Email format is invalid");
            } else {
              alert(err.message);
            }
          })
          .finally(() => {
            setLoading(false);
          });
      } catch (err) {
        logError(err, { credsToAuth, email });
      }
    }
  }, [credsToAuth, signIn]);

  React.useEffect(() => {
    if (user?.loaded && isAuthenticated && !isValidating) {
      const whereTo = user.role
        ? querystring("redirect") || "/dashboard"
        : "/firstTimeLogin";

      if (!redirect) {
        setRedirect(whereTo);
      }
    }
  }, [user, isAuthenticated, isValidating, pathname, redirect]);

  const [autoLoginOneShot, setAutoLoginOneShot] = React.useState(true);
  React.useEffect(() => {
    if (autoLoginOneShot) {
      setAutoLoginOneShot(false);

      if (newUserMode && values.email && values.password) {
        openForm();
        setCredsToAuth(values);
      }
    }
  }, [autoLoginOneShot, newUserMode, values]);

  const [passwordRef, focusPassword] = useFocus();

  if (redirect) {
    return <Navigate replace to={redirect} />;
  }

  if (isValidating) {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <Root>
      <Card sx={maxWidth}>
        <CardActionArea>
          <CardContent>
            <h2>Login</h2>
          </CardContent>
        </CardActionArea>
        <Divider />
        <CardActions
          sx={{
            display: "flex",
            flexDirection: "column",
            minHeight: "100px",
            justifyContent: "space-around",
          }}
        >
          {/* <Button
            size="large"
            variant="contained"
            color="primary"
            //onClick={() => Auth.federatedSignIn()}
          >
            <img
              className={classes.icon}
              src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg"
              alt="Google"
            ></img>
            Sign in with Google
          </Button> */}
          <Button
            size="large"
            variant="contained"
            color="primary"
            onClick={openForm}
            autoFocus
          >
            <MailIcon sx={sx.mailIcon} />
            Sign in with email
          </Button>
          {/* <p
            style={{
              textDecoration: "underline",
              color: "primary",
              cursor: "pointer",
            }}
            onClick={() => setSignupFormOpen(true)}
          >
            Sign Up
          </p> */}
          <p
            style={{
              textDecoration: "underline",
              color: "primary",
              cursor: "pointer",
            }}
            onClick={handleForgotPassword}
          ></p>
          <SignUp
            open={signupFormOpen}
            onComplete={(email, password) => {
              setNeedConfirm(false);
              setCredsToAuth({ email, password });
              closeForm();
            }}
            onClose={(email, password) => {
              setValues({ email, password });
              setSignupFormOpen(false);
              setOpen(true);
              setNeedConfirm(false);
            }}
          />
        </CardActions>
      </Card>
      <Dialog
        onClose={closeForm}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth={"lg"}
        container={() => document.getElementById("ui-container")}
      >
        <DialogTitle id="customized-dialog-title">
          Sign in with Email
        </DialogTitle>
        <DialogContent>
          <FormWithLegacyStyle noValidate autoComplete="off">
            <TextField
              variant="standard"
              id="loginEmail"
              name="email"
              label="Email"
              fullWidth
              required
              onChange={handleChange}
              autoFocus
              onKeyDown={ifEnterKey(focusPassword)}
            />
            <br />
            <TextField
              variant="standard"
              id="loginPassword"
              label={newUserMode ? "Temporary Password" : "Password"}
              name="password"
              type="password"
              fullWidth
              required
              onChange={handleChange}
              onKeyDown={ifEnterKey(handleSubmit)}
              inputRef={passwordRef}
            />
          </FormWithLegacyStyle>
          <p
            style={{
              textDecoration: "underline",
              color: "primary",
              cursor: "pointer",
            }}
            onClick={handleForgotPassword}
          >
            Forgot Password?
          </p>
          <ForgotPassword open={forgotPassword} callBack={callBack} />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeForm} color="primary">
            Cancel
          </Button>
          <Button
            type="submit"
            disabled={loading}
            color="primary"
            onClick={handleSubmit}
          >
            {loading ? <CircularProgress /> : "Submit"}
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmUserDialog
        email={values.email}
        password={values.password}
        onClose={() => {
          setNeedConfirm(false);
        }}
        onConfirmed={(email, password) => {
          setNeedConfirm(false);
          setCredsToAuth({ email, password });
        }}
        open={Boolean(needConfirm)}
      />
      <NewPasswordDialog
        open={Boolean(resetPassword)}
        onClose={() => setResetPassword(false)}
        onSubmit={(newPassword) => {
          resetPassword &&
            Auth.completeNewPassword(
              resetPassword,
              newPassword,
              resetPassword.challengeParam.requiredAttributes
            )
              .then(refreshAuth)
              .catch((err) => {
                console.error(err);
                alert(err.message);
              })
              .finally(() => {
                setResetPassword(false);
              });
        }}
      />
    </Root>
  );
}

//spell-checker:ignore creds
