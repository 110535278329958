import React from "react";
import { SnackbarKey, useSnackbar } from "notistack";

export function useWarning(error: boolean, complaint: string): void {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [snackbarId, setSnackbarId] = React.useState<SnackbarKey | undefined>();
  // we only want to fire the snackbar when error state changes
  React.useEffect(() => {
    if (error && !snackbarId) {
      setSnackbarId(
        enqueueSnackbar(complaint, {
          variant: "warning",
        })
      );
    } else if (snackbarId && !error) {
      closeSnackbar(snackbarId);
      setSnackbarId(undefined);
    }
  }, [closeSnackbar, complaint, enqueueSnackbar, error, snackbarId]);
}
