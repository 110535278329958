import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button, CircularProgress, Grid } from "@mui/material";
import {
  AddressForm,
  AddressInputs,
  ZodSchema as AddressSchema,
} from "@/Forms/AddressForm";
import { useFocus } from "@/Lib/hooks";
// import { useAddProject } from "@/Lib/hooks/useAddProject";
import React from "react";
import { useSnackbar } from "notistack";
import { logError } from "@/Lib/ErrorLogging";

export const CreateProjectCheckInPage = () => {
  const formMethods = useForm<AddressInputs>({
    mode: "onChange",
    resolver: zodResolver(AddressSchema),
    defaultValues: {
      address: "",
      city: "",
      state: "",
      zipcode: "",
    },
  });

  const {
    // reset: resetFormValues,
    formState: { isValid },
  } = formMethods;

  const [addressRef] = useFocus<HTMLInputElement>();
  // const { addProject } = useAddProject();

  const [submitting, setSubmitting] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const onSubmit: SubmitHandler<AddressInputs> = () =>
    // {
    //   address,
    //   city,
    //   state,
    //   zipcode,
    // }
    {
      setSubmitting(true);

      const createProject = Promise.resolve("Not implemented");
      enqueueSnackbar({
        message: "Not implemented",
        variant: "error",
      });
      // addProject({
      //   projectData: { project: { address, city, state, zipcode } },
      //   uploadedFiles: [],
      // }).then(() => resetFormValues())
      createProject

        .catch((err) => {
          logError(new Error("Add project failure (create from inspection)"), {
            err,
          });
          enqueueSnackbar({
            message:
              "Oops! Something went wrong and project could not be added. The team has been notified",
            variant: "error",
          });
        })
        .finally(() => {
          return setSubmitting(false);
        });
    };
  return (
    <FormProvider {...formMethods}>
      <form
        id="project-data-form"
        onSubmit={formMethods.handleSubmit(onSubmit)}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            paddingRight: "5vw",
            paddingLeft: "5vw",
            textAlign: "center",
          }}
        >
          <Grid container spacing={2} direction="column">
            <Grid item>
              {/* <Typography variant="h3">Create Inspection</Typography> */}
              <h1>Create Check In</h1>
            </Grid>
            <Grid item>
              <AddressForm
                expanded
                addressRef={addressRef}
                // onLastFieldEntered={ifEnterKey(
                //   requiredOnly ? onLastFieldEntered : focusProjectType
                // )}
              />
            </Grid>

            <Grid item>
              <Button
                type="submit"
                disabled={submitting || !isValid}
                color="primary"
              >
                {submitting ? <CircularProgress /> : "Submit"}
              </Button>
            </Grid>
          </Grid>
        </div>
      </form>
    </FormProvider>
  );
};
