import { useProjectFiles } from "@/Lib/hooks/useProjectFiles";
import { Task } from "@/Models";
import React from "react";

export const useTaskFiles = (task?: Pick<Task, "id" | "project_id">) => {
  const { id, project_id: projectId } = task ?? {};
  const {
    project: { files: projectFiles },
  } = useProjectFiles(projectId && { projectId });
  const taskFiles = React.useMemo(
    () => projectFiles?.userFiles.filter(({ task_id }) => task_id === id) ?? [],
    [id, projectFiles?.userFiles]
  );

  return taskFiles;
};
