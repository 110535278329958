import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import ClearIcon from "@mui/icons-material/Clear";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { DropzoneArea } from "react-mui-dropzone";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import { Divider } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { useBackend, useFeatures } from "@lib/hooks";
import { invertedButtonStyle } from "../../../projectCenterlineTheme";
import { createDecision } from "@lib/API";
import { logError } from "@lib/ErrorLogging";
import { useSnackbar } from "notistack";
import { useAuth } from "@lib/hooks/useAuth";
import { throwIfNot } from "@lib/util/throwIfNot";
import {
  UploadProgressDialog,
  useProgress,
} from "@/Components/UploadProgressDialog";

const PREFIX = "AddDecision";

const classes = {
  root: `${PREFIX}-root`,
  buttonSubmit: `${PREFIX}-buttonSubmit`,
};

const Root = styled("div")(({ theme: { palette } }) => ({
  [`&.${classes.root}`]: {
    margin: "20px",
    // marginBottom: "100px",
    display: "flex",
  },

  [`& .${classes.buttonSubmit}`]: {
    ...invertedButtonStyle({ palette }),
    marginTop: "5px",
    width: "100%",
  },
}));

export default function AddDecisions(props: {
  project: string;
  data: { title: string }[]; //any[] | ((prevState: never[]) => never[]);
  callBack?: () => void;
}): JSX.Element {
  const { currentUserEmail: email } = useAuth();
  const { project: projectId } = props;
  const { uploadSupportingFiles } = useBackend();
  const { maxFileUploadSize, maxFileUploadCount } = useFeatures();
  const { enqueueSnackbar } = useSnackbar();

  const [decision, setDecision] = React.useState<{ title: string }[]>([]);
  const [newDecision, setNewDecision] = React.useState({ description: "" });

  const [files, setFiles] = React.useState<File[]>([]);
  const [timeFrame, setTimeFrameDate] = React.useState<Date | null>(new Date());
  const [loading, setLoading] = React.useState<"files" | "decision" | false>(
    false
  );
  const [progress, setProgress] = useProgress();

  const handleChange = (event: { target: { name: string; value: string } }) => {
    setNewDecision({ ...newDecision, [event.target.name]: event.target.value });
  };

  const handleRemoveDecision = () => {
    setDecision([]); // TODO this is wrong we should filter out.
  };

  const handleSubmitDecision = () => {
    async function doSubmit() {
      setLoading("files");
      let complaint = "";
      await uploadSupportingFiles({
        files, // TODO: I think the react-mui-dropzone fork we are using is borked.
        // files.map(({ file }) => file),
        prefix: projectId,
        progress: setProgress,
      })
        .catch((err) => {
          complaint = "Error uploading files";
          throw err;
        })
        .then((files) => {
          setLoading("decision");
          return createDecision({
            projectId,
            description: newDecision.description,
            email: throwIfNot(email, "email is required"),
            files,
            type: "standard",
            task: props.data[0],
            timeFrame,
          });
        })
        .then((response) => {
          setDecision([]);
          props.callBack?.();
        })
        .catch((error) => {
          logError(new Error("Create decision fail"), { error });
          enqueueSnackbar(complaint || "Error adding Decision");
        })
        .finally(() => setLoading(false));
    }

    doSubmit();
  };

  useEffect(() => {
    setDecision(props.data);
  }, [props.data]);

  return (
    <Root className={classes.root}>
      {decision.map((task, key) => {
        return (
          <Card key={key}>
            <CardHeader
              avatar={
                <ClearIcon
                  color="secondary"
                  style={{ cursor: "pointer" }}
                  onClick={(e) => handleRemoveDecision(/* task */)}
                />
              }
              title={task.title}
            />

            <Divider />
            <CardContent>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <FormControl variant="standard" fullWidth>
                  <DatePicker
                    renderInput={(params) => (
                      <TextField
                        variant="standard"
                        {...params}
                        margin="normal"
                        id="time-frame"
                        name="timeFrame"
                        required
                      />
                    )}
                    // disableToolbar
                    // required
                    // variant="inline"
                    inputFormat="MM/dd/yyyy"
                    label="Need by Date"
                    value={timeFrame}
                    onChange={(value) => {
                      setTimeFrameDate(value as Date);
                    }}
                    // KeyboardButtonProps={{
                    //   "aria-label": "change date",
                    // }}
                  />
                </FormControl>
              </div>

              <div>
                <p>Description</p>
                <FormControl variant="standard" fullWidth>
                  <TextField
                    required
                    id="description"
                    multiline
                    variant="outlined"
                    rows={4}
                    name="description"
                    onChange={handleChange}
                  />
                </FormControl>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              >
                <DropzoneArea
                  maxFileSize={maxFileUploadSize}
                  filesLimit={maxFileUploadCount}
                  onChange={setFiles}
                  alertSnackbarProps={{ autoHideDuration: 1500 }}
                  useChipsForPreview
                  getFileAddedMessage={(fileName) =>
                    `File ${fileName} queued for upload.`
                  }
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "10px",
                }}
              >
                <Button
                  className={classes.buttonSubmit}
                  onClick={handleSubmitDecision}
                >
                  {loading ? "Loading..." : "Add Decision"}
                </Button>
              </div>
            </CardContent>
          </Card>
        );
      })}
      <UploadProgressDialog progress={progress} open={loading === "files"} />
    </Root>
  );
}
