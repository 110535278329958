import { Stack } from "@mui/material";
import { ReactNode } from "react";
import { Link, Outlet } from "react-router-dom";

const NotFound = ({ children }: { children?: ReactNode }) => (
  <div>
    <Outlet />
    <Stack spacing={2}>
      <h1>404 - Sorry but that page does not exist</h1>
      {children}
      <Link to="/">Go Home</Link>
    </Stack>
  </div>
);

export default NotFound;
