import React from "react";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  FormControl,
  TextField,
} from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import BackupIcon from "@mui/icons-material/Backup";
import ClearIcon from "@mui/icons-material/Clear";
import { UserChooser, PermissionsMode } from "../../../Components/UserChooser";
import { useBackend } from "@lib/hooks";
import { useAppContext } from "@lib/UserContext";
import {
  BackendGetProjectFilesResponse,
  BackendUserResponse,
  PersistentProjectFile,
} from "@lib/APITypes";
import { uniqByProp } from "@lib/misc";
import { Storage } from "aws-amplify"; // TODO: don't use this directly
import { useProjectFiles } from "@lib/hooks/useProjectFiles";
import { useProjectUsers } from "@lib/hooks/useProjectUsers";
import { logError } from "@lib/ErrorLogging";
import { throwIfNot } from "@lib/util/throwIfNot";
import { useAuth } from "@lib/hooks/useAuth";
import { StoredFile } from "@project-centerline/project-centerline-api-types";
import { extractFileTitle } from "../Invoice/extractFileTitle";
import {
  UploadProgressDialog,
  useProgress,
} from "@/Components/UploadProgressDialog";

export interface FilesTabProps {
  projectId: string;
}

type ExistingDocument = PersistentProjectFile & {
  urlLink: string;
};

export function FilesTab({ projectId }: FilesTabProps): JSX.Element {
  const { role } = useAppContext();
  const { project } = useProjectFiles({ projectId });
  const { files } = project;
  const {
    project: { users: projectUsers },
  } = useProjectUsers(projectId);
  const { uploadSupportingFiles } = useBackend();

  /*
   * Add file section (which sucks :-))
   */

  const [newFile, setNewFile] = React.useState("Click to add file");
  const [fileObj, setFileObj] = React.useState<File | undefined>();
  const [loading, setLoading] = React.useState<"files" | "project" | false>(
    false
  );
  const [progress, setProgress] = useProgress();

  const handleNewFileChosen = (event: {
    target: { files: FileList | null };
  }) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      setNewFile(file.name);
      setFileObj(file);
    }
  };

  /*
   * permissions section
   */

  // const { users: projectUsers, files } = project;
  const [allowedUsers, setAllowedUsers] = React.useState<
    Readonly<BackendUserResponse[]>
  >([]);

  const email = throwIfNot(useAuth().currentUserEmail, "email is required");
  const handleNewPermissionChoice = React.useCallback(
    /* () => */ ({
      permissionsMode,
      users,
    }: {
      permissionsMode: PermissionsMode;
      users: BackendUserResponse[];
    }) => {
      setAllowedUsers(
        permissionsMode === "private"
          ? ([{ email }] as BackendUserResponse[])
          : permissionsMode === "project"
          ? projectUsers || []
          : users || []
      );
    },
    [email, projectUsers]
  );

  const handleSubmittingDoc = () => {
    if (fileObj) {
      setLoading("files");
      uploadSupportingFiles({
        files: [fileObj],
        prefix: projectId,
        progress: setProgress,
      })
        .then((storedFiles) => {
          setLoading("project");
          project
            .addFiles(
              storedFiles.map((storedFile) => ({
                storedFile,
                email,
                allowedUsers,
              }))
            )
            .then(() => {
              setLoading(false);
              setFileObj(undefined);
              setNewFile("Click to add file");
              // setAllowedUsers([]);
              //   refreshProjectFiles();
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          alert("Error uploading file.");
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      alert("Please select a file.");
    }
  };

  const [docs, setDocs] = React.useState<ExistingDocument[]>([]);
  async function fetchFilesOnceEach({
    userFiles,
  }: BackendGetProjectFilesResponse) {
    const allFiles = userFiles.filter(
      ({ invoice_id, task_id }) => !invoice_id && !task_id
    );
    const uniqueFiles = uniqByProp(allFiles)("file_id");
    const retrievedFiles = uniqueFiles.map((file) =>
      StoredFile.is(file)
        ? Storage.get(file.storageKey).then((urlLink) => ({
            ...file,
            urlLink: urlLink as string,
          }))
        : Promise.reject("expected StoredFile")
    );
    return Promise.all(retrievedFiles);
  }

  React.useEffect(() => {
    async function resolveFiles(files: BackendGetProjectFilesResponse) {
      const storedFiles = await fetchFilesOnceEach(files);
      setDocs(storedFiles);
    }

    if (files) {
      resolveFiles(files);
    }
    return undefined;
  }, [files]);

  const [open, setOpen] = React.useState(false);
  const handleDialogOpen = (event: { stopPropagation: () => void }) => {
    event.stopPropagation();
    setOpen(true);
  };
  const handleDialogClose = () => {
    setOpen(false);
  };

  const handleRemoveDocument = (doc: ExistingDocument) => {
    project
      .removeFile(doc)
      .then((response) => {
        setOpen(false);
        // refreshProjectFiles();
      })
      .catch((error) => {
        logError(error);
        console.log(error);
      });
  };

  return (
    <div>
      {role === "Subcontractor" ? null : (
        <div
          style={{
            width: "60%",
            margin: "10px auto",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <AttachFileIcon />
            <FormControl
              variant="standard"
              fullWidth
              sx={{
                width: "90%",
              }}
            >
              <TextField
                variant="standard"
                required
                id="new-file"
                value={newFile}
                style={{ cursor: "pointer" }}
              />
              <input
                title="Upload"
                className="hiddenChooseFile"
                onChange={(e) => handleNewFileChosen(e)}
                type="file"
              />
            </FormControl>
          </div>
          <div
            style={{
              paddingTop: "20px",
            }}
          >
            <UserChooser
              users={projectUsers || []}
              onChoice={handleNewPermissionChoice}
            />
          </div>
        </div>
      )}

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "10px",
        }}
      >
        <Button
          variant="contained"
          sx={{
            width: "200px",
            marginLeft: "5px",
          }}
          onClick={handleSubmittingDoc}
          disabled={Boolean(loading)}
        >
          Upload
          {loading ? (
            <CircularProgress />
          ) : (
            <BackupIcon style={{ marginLeft: "5px" }} />
          )}
        </Button>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
        }}
      >
        {docs.map((doc, key) => {
          const title = extractFileTitle(doc);
          return (
            <div key={key}>
              <Card
                sx={{
                  width: "300px",
                  margin: "20px",
                }}
              >
                {role === "Super Admin" ? (
                  <CardHeader
                    avatar={
                      <ClearIcon
                        color="secondary"
                        onClick={handleDialogOpen}
                        style={{ cursor: "pointer" }}
                      />
                    }
                    title={title}
                  />
                ) : (
                  <CardHeader title={title} />
                )}
                <Divider />
                <CardContent>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <p>Upload Date:</p>
                    <p>{doc.created_at.toISOString().split("T").shift()}</p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <p>Uploader:</p>
                    <p>{doc.uploader}</p>
                  </div>

                  <Button
                    variant="contained"
                    sx={{
                      width: "100%",
                    }}
                    href={doc.urlLink}
                    target="_blank"
                  >
                    View
                  </Button>
                </CardContent>
              </Card>
              <Dialog
                open={open}
                onClose={handleDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {"Remove This Document?"}
                </DialogTitle>
                <DialogActions>
                  <Button onClick={handleDialogClose} color="primary">
                    Cancel
                  </Button>
                  <Button
                    onClick={(e) => handleRemoveDocument(doc)}
                    color="primary"
                    autoFocus
                  >
                    Agree
                  </Button>
                </DialogActions>
              </Dialog>
              <UploadProgressDialog
                progress={progress}
                open={loading === "files"}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}
