/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Box from "@mui/material/Box";
import { Theme, styled } from "@mui/material/styles";
import type * as CSS from "csstype";

const validGradients = [
  "primary",
  "secondary",
  "info",
  "success",
  "warning",
  "error",
  "dark",
  "light",
] as const;

const validColors = [
  "transparent",
  "white",
  "black",
  "primary",
  "secondary",
  "info",
  "success",
  "warning",
  "error",
  "light",
  "dark",
  "text",
  "grey-100",
  "grey-200",
  "grey-300",
  "grey-400",
  "grey-500",
  "grey-600",
  "grey-700",
  "grey-800",
  "grey-900",
] as const;

const validBorderRadius = ["xs", "sm", "md", "lg", "xl", "xxl", "section"] as const;
const validBoxShadows = ["xs", "sm", "md", "lg", "xl", "xxl", "inset"] as const;

export type OwnerState = {
  variant: "contained" | "gradient" | undefined;
  bgColor: (typeof validColors)[number] | undefined;
  color: string | undefined;
  opacity: number | undefined;
  borderRadius: string | undefined;
  shadow: string | undefined;
  coloredShadow: string | undefined;
};

export default styled(Box)<{ ownerState?: OwnerState }>(
  ({ theme, ownerState }: { theme: Theme; ownerState?: OwnerState }) => {
    const { palette, functions, borders, boxShadows } = theme;
    const { variant, bgColor, color, opacity, borderRadius, shadow, coloredShadow } =
      ownerState ?? {};

    const { gradients, grey, white } = palette;
    const { linearGradient } = functions;
    const { borderRadius: radius } = borders;
    const { colored } = boxShadows;

    const greyColors: { [key: string]: string } = {
      "grey-100": grey[100],
      "grey-200": grey[200],
      "grey-300": grey[300],
      "grey-400": grey[400],
      "grey-500": grey[500],
      "grey-600": grey[600],
      "grey-700": grey[700],
      "grey-800": grey[800],
      "grey-900": grey[900],
    };

    // background value
    let backgroundValue = bgColor;

    if (variant === "gradient") {
      backgroundValue = validGradients.find((el) => el === bgColor)
        ? linearGradient(gradients[bgColor].main, gradients[bgColor].state)
        : white.main;
    } else if (bgColor && validColors.find((el) => el === bgColor)) {
      const paletteColor = palette[bgColor as keyof typeof palette];
      backgroundValue = paletteColor?.main ?? greyColors[bgColor];
    } else {
      backgroundValue = bgColor;
    }

    // color value
    let colorValue = color;

    if (color && validColors.find((el) => el === color)) {
      const paletteColor = palette[color as keyof typeof palette];
      colorValue = paletteColor?.main ?? greyColors[color];
    }

    // borderRadius value
    let borderRadiusValue: string | number | undefined = borderRadius;

    if (validBorderRadius.find((el) => el === borderRadius)) {
      borderRadiusValue = borderRadius && radius[borderRadius as keyof typeof radius];
    }

    // boxShadow value
    let boxShadowValue: CSS.Properties["boxShadow"] = "none";

    if (validBoxShadows.find((el) => el === shadow)) {
      boxShadowValue =
        (shadow &&
          boxShadows[
            shadow as unknown as keyof Pick<typeof boxShadows, (typeof validBoxShadows)[number]>
          ]) ??
        "none";
    } else if (coloredShadow) {
      boxShadowValue = colored[coloredShadow as keyof typeof colored] ?? "none";
    }

    return {
      opacity,
      background: backgroundValue,
      color: colorValue,
      borderRadius: borderRadiusValue,
      boxShadow: boxShadowValue,
    };
  }
);
