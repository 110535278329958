import { EditTaskForm } from "@/Forms";
import { SlideUp } from "@/Lib/Transitions";
import { Task } from "@/Models";
import { Dialog, DialogProps, DialogTitle } from "@mui/material";
import { useTaskFiles } from "../../../Lib/hooks/useTaskFiles";
import { useProjectTasks } from "@/Lib/hooks/useProjectTasks";
import { APIError } from "@/Lib/API";
import { logError } from "@/Lib/ErrorLogging";
import { useSnackbar } from "notistack";

export interface EditTaskDialogProps {
  task: Task;
  done: () => void;
  onNewFiles: (files: ReadonlyArray<File>) => void;
  taskTitleEditable: boolean;
}

export const EditTaskDialog = ({
  done,
  open,
  task,
  onNewFiles,
  taskTitleEditable,
}: EditTaskDialogProps & Pick<DialogProps, "open">) => {
  const { id: taskId } = task;
  const existingFiles = useTaskFiles(task);
  const { enqueueSnackbar } = useSnackbar();

  const {
    project: { completeTask, editTask },
  } = useProjectTasks(task.project_id);
  return (
    <Dialog open={open} onClose={done} TransitionComponent={SlideUp}>
      <DialogTitle>Edit Task</DialogTitle>
      <EditTaskForm
        taskTitleEditable={taskTitleEditable}
        existingFiles={existingFiles}
        onCancel={done}
        onSubmit={async ({ taskData, dirtyFields, files }) => {
          if (files.length) {
            onNewFiles(files);
          }
          try {
            if (dirtyFields.includes("completed")) {
              await completeTask(taskId, taskData.completed);
            }
            if (
              dirtyFields.some(
                (field) => field !== "completed" && field !== "amountRequested"
              )
            ) {
              await editTask(taskId, taskData);
            }

            done();
          } catch (error) {
            // we don't really expect 304 NOT_MODIFIED, but we don't prevent useless requests
            if ((error as APIError).response?.status !== 304) {
              logError(error);
              enqueueSnackbar({
                message: "Could not edit task",
                variant: "error",
              });
            }
          }
        }}
        taskData={task}
      />
    </Dialog>
  );
};

export default EditTaskDialog;
