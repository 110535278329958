import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { Extras } from "@sentry/types";
import { addExceptionMechanism } from "@sentry/utils";
import config from "../config";

export function initSentry(): void {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      new Integrations.BrowserTracing({
        tracePropagationTargets: [
          "2o2tug5ckd.execute-api.us-west-2.amazonaws.com", // prod
          "d43o4jtbzj.execute-api.us-west-2.amazonaws.com", // staging spell-checker:ignore jtbzj
          "localhost",
          "localhost:4000",
          "r5zcvdjbfk.execute-api.us-west-2.amazonaws.com", // image handler spell-checker:ignore r5zcvdjbfk
        ],
        // routingInstrumentation: Sentry.reactRouterV5Instrumentation(history)
      }),
      new Sentry.Replay({
        maskAllText: false,
        maskAllInputs: false,
      }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: config.nonProdEnv ? 1.0 : 0.1,
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: config.nonProdEnv ? 1.0 : 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
  });
}

export function identifyUser(user: { id?: string; email?: string }): void {
  const { id, email } = user;
  if (!id && !email) {
    Sentry.configureScope((scope) => scope.setUser(null));
  } else {
    Sentry.setUser(user);
  }
}

export function logError(error: unknown, errorInfo: unknown = null): void {
  Sentry.withScope((scope) => {
    errorInfo && scope.setExtras(errorInfo as Extras);
    Sentry.captureException(error);
  });
  if (config.nonProdEnv) {
    console.error(error, errorInfo);
  }
}

export function logAndThrow(error: Error, errorInfo: unknown = null): never {
  logError(error, errorInfo);
  throw error;
}

export function logAnomaly(error: unknown, errorInfo: unknown = null): void {
  Sentry.withScope((scope) => {
    scope.addEventProcessor((event) => {
      addExceptionMechanism(event, {
        handled: true,
        type: "logAnomaly",
      });
      return event;
    });
    if (errorInfo) {
      scope.setExtras(errorInfo as Extras);
    }
    Sentry.captureException(error);
  });
  if (config.nonProdEnv) {
    console.warn(
      `anomaly detected: ${(error as { message?: string }).message || error}`,
      errorInfo
    );
  }
}

export function onError(error: {
  message?: string;
  config?: { url: string };
}): void {
  let errorInfo: typeof error & { url?: string } = {};
  let message = error.toString();

  // Auth errors
  if (!(error instanceof Error) && error.message) {
    errorInfo = error;
    message = error.message;
    error = new Error(message);
    // API errors
  } else if (error.config && error.config.url) {
    errorInfo.url = error.config.url;
  }

  logError(error, errorInfo);

  alert(message);
}
