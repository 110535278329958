import { CircularProgress, IconButton } from "@mui/material";
import React from "react";
import { useEntity } from "@lib/hooks/useEntity";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { MUIDataTableColumnDef } from "mui-datatables";
import { Entity } from "@lib/APITypes";
import { ConfirmDialog } from "../../Components/ConfirmDialog";
import { prettyName } from "./prettyName";
import { useAllUsers } from "./useAllUsers";
import { useMatch, useNavigate } from "react-router-dom";
import { MUIDataTable } from "../../Components/MUIDataTableWithTypeHack";
import { logError } from "@lib/ErrorLogging";
import { useSnackbar } from "notistack";
import Tooltip from "@mui/material/Tooltip";

const columns: MUIDataTableColumnDef[] = [
  {
    name: "Domains",
  },
  {
    name: "Emails",
  },
  {
    name: "Add to Projects",
    options: {
      display: "false",
    },
  },
  {
    name: "Invoice Routing",
    options: {
      display: "false",
    },
  },
  {
    name: "Loan Contact",
    options: {
      display: "false",
    },
  },
  {
    name: "etag",
    options: {
      searchable: false,
      display: "excluded",
    },
  },
];

export const EntityList: () => JSX.Element = () => {
  const { entities, deleteEntity } = useEntity();
  const { users = [] } = useAllUsers();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  /* HACK */ const atRootAdminPage = Boolean(useMatch("admin"));

  const [entitiesToDelete, setEntitiesToDelete] = React.useState<
    Entity[] | undefined
  >();
  const [selectedRows, setSelectedRows] = React.useState<number[]>([]);

  return !entities || !users ? (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <CircularProgress />
    </div>
  ) : (
    <>
      <MUIDataTable
        data={[...entities].map((entity) => ({
          Domains: entity.matchDomains?.join(","),
          Emails: entity.matchEmails
            ?.map((matchedEmail) => {
              const knownUser = users.find(
                ({ email }) => email === matchedEmail
              );
              return knownUser ? prettyName(knownUser) : matchedEmail;
            })
            .join(","),
          "Add to Projects": (
            (entity.autoAddUserEmails as (string | null)[]) || []
          )
            ?.concat(entity.autoAddUserIdentities || [])
            .join(","),
          "Invoice Routing": entity.overrideInvoiceRouting?.join(","),
          "Loan Contact": entity.defaultLoanContact?.join(","),
          etag: entity.etag,
        }))}
        columns={[
          {
            name: "Edit",
            options: {
              download: false,
              filter: false,
              print: false,
              sort: false,
              customBodyRenderLite: (dataIndex) => (
                <IconButton
                  onClick={() => {
                    navigate(
                      `/admin/entity/${String(entities?.[dataIndex].id)}`
                    );
                  }}
                  size="large"
                >
                  <EditIcon />
                </IconButton>
              ),
            },
          } as MUIDataTableColumnDef,
        ].concat(columns)}
        title="Entities"
        options={{
          sortOrder: {
            name: "Domains",
            direction: "asc",
          },
          responsive: "simple",
          // resizableColumns: true,
          rowsSelected: selectedRows,
          onRowSelectionChange: (rowsSelectedData, allRows, rowsSelected) => {
            setSelectedRows(rowsSelected ?? []);
          },
          onRowsDelete: (rowsDeleted: { data: { dataIndex: number }[] }) => {
            if (!entities) {
              throw new Error("How did we get here?");
            }
            setEntitiesToDelete(
              rowsDeleted.data.map(({ dataIndex }) => entities[dataIndex])
            );
            return false;
          },
          customToolbar: () => (
            <Tooltip title={"Add new Entity"}>
              <IconButton
                onClick={() => {
                  navigate(atRootAdminPage ? "entity/new" : "new");
                }}
              >
                <AddIcon />
              </IconButton>
            </Tooltip>
          ),
        }}
      />
      <ConfirmDialog
        title="Delete Entities"
        open={Boolean(entitiesToDelete && entitiesToDelete.length > 0)}
        setOpen={(open: boolean) => {
          if (!open) {
            setEntitiesToDelete(undefined);
          }
        }}
        onConfirm={() => {
          Promise.all(
            entitiesToDelete?.map((entity) =>
              deleteEntity(entity).catch((err) => {
                logError(err, {
                  entity,
                });
                throw err;
              })
            ) ?? []
          )
            .then(() => {
              setSelectedRows([]);
            })
            .catch((err) => {
              logError(new Error("Entity delete failure"), { err });
              enqueueSnackbar("Sorry; deletion failed", { variant: "error" });
            });
        }}
        onCancel={() => {
          setEntitiesToDelete(undefined);
        }}
        confirmActionWords="delete"
        // cancelActionWords="cancel"
        defaultAction="cancel"
      >
        Delete{" "}
        {entitiesToDelete && entitiesToDelete.length > 1
          ? "entities"
          : "entity"}
        ? THERE IS NO UNDO
      </ConfirmDialog>
    </>
  );
};
