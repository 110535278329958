import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import {
  BackendGetNVMSConfigResponse,
  BackendGetStripeResponse,
  Entity,
} from "@lib/APITypes";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import useSWR from "swr";
import { useBackend, useMoney } from "@lib/hooks";
import { useWarning } from "@lib/hooks/useWarning";
import { nanIshIsEmptyString } from "@lib/misc";
import {
  validNVMSSecretSchema,
  placeholderNVMSSecretSchema,
} from "./EntityEditor";
import { ControlledSwitch } from "../../Components/ControlledSwitch";

export function NVMSConfigEditor(props: {
  baseNVMSSetup?: Entity["nvms"];
  entity?: Omit<Entity, "id">;
}) {
  const { baseNVMSSetup, entity } = props;

  const { control } = useFormContext();
  const features = useWatch({
    control,
    name: "features",
    defaultValue: entity?.features,
  });
  const customNVMS = useWatch({
    control,
    name: "customNVMS",
    defaultValue: !!baseNVMSSetup,
  });
  const payments = useWatch({
    control,
    name: "nvms.payments",
    defaultValue: baseNVMSSetup?.payments,
  });
  const nvmsSecret = useWatch({
    control,
    name: "nvms.apiSecret",
    defaultValue: baseNVMSSetup?.apiSecret,
  });
  const paymentType = useWatch({
    control,
    name: "nvms.payments.sfr.type",
    defaultValue: baseNVMSSetup?.payments.sfr.type,
  });

  const { getNVMSConfig, getStripeStuff } = useBackend();
  const { formatMoney } = useMoney();

  const effectiveNVMSSecret = deriveNVMSSecret(nvmsSecret, entity);
  const { data: nvmsInspectionServices, error: nvmsInspectionServicesError } =
    useSWR<BackendGetNVMSConfigResponse["services"]>(
      effectiveNVMSSecret &&
        `/entity/nvmsConfig/${effectiveNVMSSecret}/ClientServices`,
      () =>
        getNVMSConfig(effectiveNVMSSecret ?? "can't happen").then(
          (response) => response.services
        )
    ) || [];
  useWarning(nvmsInspectionServicesError, "Error fetching inspection services");

  const { data: stripePrices, error: stripePricesError } =
    useSWR<BackendGetStripeResponse["prices"]>(
      "/config/stripe",
      () => getStripeStuff().then((response) => response.prices),
      { focusThrottleInterval: 5 * 60 * 1000 }
    ) || [];
  useWarning(stripePricesError, "Error fetching price info from Stripe");

  const stripePricesReady =
    payments?.sfr?.type === "stripe" && stripePrices && stripePrices.length > 0; //&&

  // payments.sfr.priceKey;
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>
          NVMS Inspections
          {features?.automatedInspections
            ? customNVMS
              ? " (custom)"
              : " (default)"
            : ` (feature flag not enabled)`}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2} direction="column">
          <Grid item>
            <ControlledSwitch name="customNVMS" description="Custom" />
          </Grid>
          {customNVMS ? (
            <>
              <Grid item>
                <ControlledSwitch
                  name="nvms.sendAllItems"
                  description="Send Budget as Draw"
                />
              </Grid>{" "}
              <Grid item>
                <ControlledSwitch
                  name="nvms.staticFileNumber"
                  description="Use Same FileNumber for all draws in a project (SkyBeam)"
                />
              </Grid>
              <Grid item>
                <Paper style={{ width: "100%" }}>
                  <Controller
                    name="nvms.apiSecret"
                    defaultValue={baseNVMSSetup?.apiSecret}
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <FormControl variant="standard" fullWidth>
                        <TextField
                          variant="standard"
                          {...field}
                          fullWidth
                          placeholder="Enter key you received from NVMS"
                          id="api-secret"
                          label="NVMS Web Service Security Key"
                          error={Boolean(error)}
                          helperText={error?.message}
                        />
                      </FormControl>
                    )}
                  />
                </Paper>
              </Grid>
              <Grid item>
                <Paper style={{ width: "100%" }}>
                  <Controller
                    name="nvms.nvmsUserId"
                    defaultValue={baseNVMSSetup?.nvmsUserId}
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <FormControl variant="standard" fullWidth>
                        <TextField
                          variant="standard"
                          {...field}
                          fullWidth
                          value={nanIshIsEmptyString(field.value)}
                          onChange={({ target: { value } }) => {
                            field.onChange(Number.parseInt(value));
                          }}
                          placeholder="Enter user ID you received from NVMS (should be a number)"
                          id="user-id"
                          label="NVMS User ID"
                          error={Boolean(error)}
                          helperText={error?.message}
                        />
                      </FormControl>
                    )}
                  />
                </Paper>
              </Grid>
              <Grid item>
                <Paper style={{ width: "100%" }}>
                  <Controller
                    name="nvms.twoDayInspectionService"
                    defaultValue={baseNVMSSetup?.twoDayInspectionService}
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <FormControl variant="standard" fullWidth>
                        <InputLabel id="nvms-service-label">
                          Service Type
                        </InputLabel>
                        <Select
                          variant="standard"
                          labelId="nvms-service-label"
                          disabled={!nvmsInspectionServices?.length}
                          {...field}
                          value={
                            (nvmsInspectionServices?.length && field.value) ||
                            ""
                          }
                          id="inspection-service"
                          label="Inspection Service"
                          // helperText={error?.message}
                          error={Boolean(error)}
                        >
                          {/* <MenuItem value="">
                                <em>Available after you enter Security Key</em>
                              </MenuItem> */}
                          {nvmsInspectionServices?.map(
                            ({ Price, ServiceID, ServiceName }) => (
                              <MenuItem key={ServiceID} value={ServiceID}>
                                {ServiceName} ({formatMoney(Price)})
                              </MenuItem>
                            )
                          )}
                        </Select>
                      </FormControl>
                    )}
                  />
                </Paper>
              </Grid>
              <Grid item>
                <Paper style={{ width: "100%" }}>
                  <Controller
                    name="nvms.payments.sfr.type"
                    defaultValue={payments?.sfr?.type}
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <FormControl variant="standard" fullWidth>
                        <InputLabel id="nvms-payment-label">
                          Payment Requirement
                        </InputLabel>
                        <Select
                          variant="standard"
                          {...field}
                          labelId="nvms-payment-label"
                          fullWidth
                          // placeholder="Enter user ID you received from NVMS (should be a number)"
                          id="inspection-payment"
                          label="Payment Requirement Can You See Me?"
                          // helperText={error?.message}
                          error={Boolean(error)}
                        >
                          <MenuItem value={"stripe"} key="stripe">
                            Stripe
                          </MenuItem>
                          <MenuItem value={"john"} key="john">
                            Concierge Approval
                          </MenuItem>
                          <MenuItem value={"not-required"} key="not-required">
                            No Payment Required
                          </MenuItem>
                        </Select>
                        {/* <FormHelperText>{error?.message}</FormHelperText> */}
                      </FormControl>
                    )}
                  />
                </Paper>
              </Grid>
              {paymentType === "stripe" && stripePricesReady ? (
                <Grid item>
                  <Paper style={{ width: "100%" }}>
                    <Controller
                      name="nvms.payments.sfr.priceKey"
                      defaultValue={
                        (baseNVMSSetup?.payments?.sfr?.type === "stripe" &&
                          baseNVMSSetup?.payments.sfr.priceKey) ||
                        undefined
                      }
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <FormControl variant="standard" fullWidth>
                          <InputLabel id="nvms-payment-price">Price</InputLabel>
                          <Select
                            variant="standard"
                            {...field}
                            labelId="nvms-payment-price"
                            fullWidth
                            value={field.value || ""}
                            // placeholder="Enter user ID you received from NVMS (should be a number)"
                            id="inspection-price"
                            // helperText={error?.message}
                            error={Boolean(error)}
                          >
                            {stripePrices?.map((p) => {
                              const key = p.metadata.pkey; // spell-checker:word pkey
                              return (
                                <MenuItem value={key} key={key}>
                                  {p.product.description}{" "}
                                  {formatMoney(p.amount / 100)} (
                                  {p.metadata.pkey})
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      )}
                    />
                  </Paper>
                </Grid>
              ) : undefined}
            </>
          ) : undefined}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}
function deriveNVMSSecret(
  nvmsSecret: string | undefined,
  initialValue: Readonly<Partial<Entity>> | undefined
) {
  if (initialValue?.id === undefined) {
    return undefined;
  }

  return (
    (validNVMSSecretSchema.safeParse(nvmsSecret).success && nvmsSecret) ||
    (placeholderNVMSSecretSchema.safeParse(nvmsSecret).success &&
      `unknown;entity=${initialValue.id}`) ||
    undefined
  );
}
