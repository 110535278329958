import { Button, Grid } from "@mui/material";
import React from "react";
import LoanForm, { LoanFormLoanInput } from "../../../Forms/LoanForm";
import { LoanModel, loanDataSchema } from "../../../Models/Loan";
import { useProjectDetails } from "@lib/hooks/useProjectDetails";
import { ErrorOrProgressOr } from "../../../Components/ErrorOrProgressOr";
import { useFeatures, usePermissions } from "@lib/hooks";
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod"; // spell-checker:ignore hookform
// import { DevTool } from "@hookform/devtools";
import * as z from "zod";
import { useProjectUsers } from "@lib/hooks/useProjectUsers";
import { Role } from "@lib/roles";
import { useSnackbar } from "notistack";
import { Prompt } from "../../../Components/Prompt";
import { LoanType } from "@lib/LoanType";
import { useEntityFeatures } from "@/Lib/hooks/useEntityFeatures";

export interface LoanTabProps {
  /** We'll fetch with SWR but hopefully it's already ready :-) */
  // TODO: make getprojects and getprojectdetails cross-update each other - as long as they currently return the same content
  projectId: string;
}

/** alias - does it make it easier to use or heder to grep? */
export type LoanInputs = LoanModel;

/**
 * Like @see loanDataSchema but allows some things to be optional
 */
const loanEditingSchema = loanDataSchema.extend({
  loanAmount: z
    .union([loanDataSchema.shape.loanAmount, z.literal("")])
    .nullable(),
  interestRate: z
    .union([loanDataSchema.shape.interestRate, z.literal("")])
    .nullable(),
  reporting: loanDataSchema.shape.reporting.optional().nullable(),
  maxDraws: z.union([loanDataSchema.shape.maxDraws, z.literal("")]),
  retention: z
    .union([loanDataSchema.shape.retention, z.literal("")])
    .nullable(),
  // custom: z.object({
  custom_1: z.string().nullable().optional(),
  loanContact: z.string().nullable().optional(),
  loanBorrower: z.string().nullable().optional(),
  disbursedAtClosing: z.union([
    loanDataSchema.shape.disbursedAtClosing,
    z.literal(""),
  ]),
  // })
});

/**
 * Mostly just the loan form, but in an editing mode with a submit button
 */
export function LoanTab({ projectId }: LoanTabProps): JSX.Element {
  const { project, errors: projectErrors } = useProjectDetails(projectId);
  const {
    project: { users = [] },
  } = useProjectUsers(projectId);
  const { reportingFeature, dhlcCustom, showMaxDraws } = useFeatures();
  const {
    frontend: { showRetention },
  } = useEntityFeatures();

  const { details, mutateLoanDetails } = project || {};
  const [reFetching, setReFetching] = React.useState(false);
  const { canEditLoanInfo } = usePermissions();
  const { enqueueSnackbar } = useSnackbar();

  // const [loanEditingValues, setLoanEditingValues] = React.useState<
  //   z.infer<typeof loanEditingSchema> | undefined
  // >(details);
  // React.useEffect(() => {
  //   setLoanEditingValues(details);
  // }, [details]);

  const borrowerUser = users.find((u) => u.role === Role.Owner);

  // for lack of a pick(details, keyof LoanFromLoanInput), because I don't want to send the excess props to useForm:
  const defaultValues: LoanFormLoanInput = generateDefaults(details);

  /* react-hook-form magic */
  const formMethods = useForm({
    mode: "onChange",
    resolver: zodResolver(loanEditingSchema),
    defaultValues,
  });
  const { handleSubmit, formState, reset } = formMethods;
  const { isDirty, dirtyFields } = formState;

  return (
    <FormProvider {...formMethods}>
      <form
        onSubmit={handleSubmit(
          (loanData) => {
            setReFetching(true);
            mutateLoanDetails?.(loanData)
              // (
              //   {
              //   ...loanData,
              //   loanAmount: loanData.loanAmount ?? undefined,
              //   maxDraws:
              //     loanData.maxDraws === "" || !showMaxDraws
              //       ? null
              //       : Number(loanData.maxDraws),
              //   retention:
              //     loanData.retention === "" ? null : Number(loanData.retention),
              //   interestRate:
              //     loanData.interestRate === ""
              //       ? null
              //       : Number(loanData.interestRate),
              //   disbursedAtClosing:
              //     loanData.disbursedAtClosing === ""
              //       ? null
              //       : Number(loanData.disbursedAtClosing),
              // }
              // )
              // .then(setLoanEditingValues)
              .then(() => {
                reset(loanData);
              })
              .catch((err) => {
                console.log(err);
                enqueueSnackbar("Error updating project", { variant: "error" });
              })
              .finally(() => setReFetching(false));
          },
          (invalid) => {
            console.log(invalid);
          }
        )}
      >
        <Grid container direction="column" alignItems="center">
          {/* {loanEditingValues ? (
            <> */}
          <LoanForm
            {...{
              reportingFeature,
              dhlcCustom,
              showMaxDraws,
              showRetention,
            }}
            disabled={!canEditLoanInfo}
            borrowerPlaceholder={
              borrowerUser &&
              `${borrowerUser.first_name} ${borrowerUser.last_name}`
            }
          />
          {canEditLoanInfo ? (
            <ErrorOrProgressOr
              error={projectErrors?.details?.message}
              progress={reFetching}
              render={() => (
                // RN we *require* everybody to put in all (reporting) fields to edit *anything*.
                // If eventually we need to let them say "yes I know" this would the the place
                <Button type="submit" color="primary" disabled={!isDirty}>
                  Update
                </Button>
              )}
            />
          ) : null}
          {/* </>
          ) : null} */}
        </Grid>
      </form>
      {/* <DevTool control={formMethods.control} /> */}
      <Prompt
        message={`Your loan changes are not saved. Do you really want to leave?`}
        when={
          isDirty &&
          /* this is a hack but seems to work and I'm lazy see https://github.com/react-hook-form/react-hook-form/issues/4740 */ Object.keys(
            dirtyFields
          ).length > 0
        }
      />
    </FormProvider>
  );
}
function generateDefaults(details?: Readonly<LoanFormLoanInput>) {
  const {
    loanType = LoanType.Term,
    loanAmount = null,
    disbursedAtClosing = null,
    startDate = null,
    maturityDate = null,
    interestRate = null,
    retention = null,
    maxDraws = null,
    loanIdentifier = "",
    loanBorrowerOverride = "",
    loanContact = "",
    custom_1 = "",
    reporting: existingReporting,
  } = details ?? {};

  // stuff in reporting
  const {
    enabled = false,
    interestReserve = null,
    statementReturnAddress = "",
    type = "Interest Reserve",
  } = existingReporting || {};
  const reportingDefaults: LoanFormLoanInput["reporting"] = {
    enabled,
    interestReserve,
    statementReturnAddress,
    type,
  };
  const defaultValues: LoanFormLoanInput = {
    custom_1,
    disbursedAtClosing,
    interestRate,
    loanAmount,
    loanBorrowerOverride,
    loanContact,
    loanIdentifier,
    loanType,
    maturityDate,
    maxDraws,
    reporting: existingReporting == null ? null : reportingDefaults,
    retention,
    startDate,
  };
  return defaultValues;
}

export const __test__ = {
  generateDefaults,
};
