import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { Button, CircularProgress, Grid } from "@mui/material";
import { AddressForm, ZodSchema as AddressSchema } from "@/Forms/AddressForm";
import { useBackend, useFocus } from "@/Lib/hooks";
import { useAddProject } from "@/Lib/hooks/useAddProject";
import React from "react";
import { useSnackbar } from "notistack";
import { logError } from "@/Lib/ErrorLogging";
import {
  InspectionTypeRadio,
  ZodSchema as InspectionTypeSchema,
} from "../Components/InspectionTypeRadio";
import { useInspectors } from "@/Lib/hooks/useInspectors";
import { useAuth } from "@/Lib/hooks/useAuth";
import { throwIfNot } from "@/Lib/util/throwIfNot";
import { Role } from "@/Lib/roles";

const Schema = AddressSchema.extend(InspectionTypeSchema.shape);
type Inputs = z.infer<typeof Schema>;

export const CreateProjectFromInspectionPage = () => {
  const formMethods = useForm<Inputs>({
    mode: "onChange",
    resolver: zodResolver(Schema),
    defaultValues: {
      address: "",
      city: "",
      state: "",
      zipcode: "",
      inspectionType: "Physical",
    },
  });

  const {
    reset: resetFormValues,
    formState: { isValid },
  } = formMethods;

  const [addressRef] = useFocus<HTMLInputElement>();
  const { addProject } = useAddProject();
  const { addUserToProject } = useBackend();

  const [submitting, setSubmitting] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const { availableInspectors, isVirtualInspector, isPhysicalInspector } =
    useInspectors();
  const virtualInspector = React.useMemo(
    () => availableInspectors.find(isVirtualInspector),
    [availableInspectors, isVirtualInspector]
  );
  const physicalInspector = React.useMemo(
    () => availableInspectors.find(isPhysicalInspector),
    [availableInspectors, isPhysicalInspector]
  );

  const { currentUserEmail } = useAuth();
  const creatingUserEmail = throwIfNot(currentUserEmail, "not logged in?");

  const onSubmit: SubmitHandler<Inputs> = ({
    address,
    city,
    inspectionType,
    state,
    zipcode,
  }) => {
    // changed during review of https://github.com/Project-Centerline/project-centerline-ac-frontend/pull/1384 to make sure it blows up (intentionally).
    // probably the thing to do is to require a point of contact add them as an owner
    const userToAdd =
      (inspectionType === "Physical" && physicalInspector) ||
      (inspectionType === "Virtual" && virtualInspector);
    if (!userToAdd) {
      enqueueSnackbar({
        variant: "error",
        message: "Could not find suitable inspector",
      });
      return;
    }

    setSubmitting(true);
    addProject({
      projectData: { project: { address, city, state, zipcode } },
      uploadedFiles: [],
    })
      .then((projectId) => {
        addUserToProject(projectId, creatingUserEmail, {
          ...userToAdd,
          role: Role.Inspector,
          external: false,
        });
      })
      .then(() => resetFormValues())
      .then(() =>
        enqueueSnackbar({ variant: "success", message: "Inspection created" })
      )
      .catch((err) => {
        logError(new Error("Add project failure (create from inspection)"), {
          err,
        });
        enqueueSnackbar({
          message:
            "Oops! Something went wrong and project could not be added. The team has been notified",
          variant: "error",
        });
      })
      .finally(() => setSubmitting(false));
  };
  return (
    <FormProvider {...formMethods}>
      <form
        id="project-data-form"
        onSubmit={formMethods.handleSubmit(onSubmit)}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            paddingRight: "5vw",
            paddingLeft: "5vw",
            textAlign: "center",
          }}
        >
          <Grid container spacing={2} direction="column">
            <Grid item>
              {/* <Typography variant="h3">Create Inspection</Typography> */}
              <h1>Create Inspection</h1>
            </Grid>
            <Grid item>
              <InspectionTypeRadio />
            </Grid>
            <Grid item>
              <AddressForm
                expanded
                addressRef={addressRef}
                // onLastFieldEntered={ifEnterKey(
                //   requiredOnly ? onLastFieldEntered : focusProjectType
                // )}
              />
            </Grid>

            <Grid item>
              <Button
                type="submit"
                disabled={submitting || !isValid}
                color="primary"
              >
                {submitting ? <CircularProgress /> : "Submit"}
              </Button>
            </Grid>
          </Grid>
        </div>
      </form>
    </FormProvider>
  );
};
