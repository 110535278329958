import { IconButton, Badge } from "@mui/material";
import React from "react";
import { LightboxFiles } from "../Pages/ProjectView/Invoice/LightboxFile";
import { AttachedFilesIcon } from "@lib/uikit";

export const DisplayFiles: (arg: {
  files: LightboxFiles;
  onClick: (files: LightboxFiles) => void;
}) => JSX.Element | null = ({ files, onClick }) => {
  // const { value: files } = useAsyncEffect(
  //   async () =>
  //     Promise.all(
  //       storedFiles.map(async (file) =>
  //         LinkedFile.is(file)
  //           ? {
  //               name: file.title,
  //               url: file.href,
  //             }
  //           : {
  //               name: file.path,
  //               url: await Storage.get(file.storageKey),
  //             }
  //       )
  //     ),
  //   [storedFiles]
  // );
  // return files && files.length > 0 ? (
  //   <div className="invoice-documents">
  //     <p>Documents:</p>
  //     <Carousel
  //       navButtonsAlwaysVisible={true}
  //       indicators={false}
  //       autoPlay={false}
  //     >
  //       {files.map((item, i) => {
  //         return (
  //           <a
  //             key={i}
  //             target="_blank"
  //             rel="noopener noreferrer"
  //             href={item.url as string}
  //             style={{
  //               display: "flex",
  //               alignItems: "center",
  //               flexDirection: "column",
  //             }}
  //           >
  //             <InsertDriveFileIcon />
  //             <p
  //               style={{
  //                 width: "200px",
  //                 overflow: "hidden",
  //                 textAlign: "center",
  //               }}
  //             >
  //               {item.name}
  //             </p>
  //           </a>
  //         );
  //       })}
  //     </Carousel>
  //   </div>
  // ) : null;
  return files && files.length > 0 ? (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <IconButton onClick={() => onClick(files)} size="large">
        <Badge badgeContent={files.length} color="primary">
          <AttachedFilesIcon />
        </Badge>
      </IconButton>
    </div>
  ) : null;
};
