import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import "date-fns";
import CalendarView, { CalendarViewProps } from "./Calendar";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import moment from "moment";
import { replaceInvalidNumberWith } from "@lib/util";

const PREFIX = "ScheduleView";

const classes = {
  root: `${PREFIX}-root`,
  paper: `${PREFIX}-paper`,
  primaryColor: `${PREFIX}-primaryColor`,
};

const Root = styled("div")(({ theme: { spacing, palette } }) => ({
  [`&.${classes.root}`]: {
    margin: "20px",
    marginBottom: "100px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  [`& .${classes.paper}`]: {
    padding: spacing(2),
    textAlign: "center",
    color: palette.text.secondary,
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },

  [`& .${classes.primaryColor}`]: {
    color: palette.primary.main,
  },
}));

const emptyTaskData: CalendarViewProps["taskData"] & {
  tasks_complete: string[];
  budgetRemaining: number;
  original_value: number | null;
  effectiveBudget: number;
} = {
  tasks_complete: [],
  all_tasks: [],
  budgetRemaining: 0,
  original_value: 0,
  effectiveBudget: 0,
  next_decision_date: null,
  project_id: "",
  retention: 0,
};

type TaskData = typeof emptyTaskData;

export default function Schedule(props: {
  data: TaskData;
  callBack: () => void;
  onDecisionDateClicked: () => void;
}): JSX.Element {
  const { onDecisionDateClicked } = props;
  const [taskData, setTaskData] = React.useState(emptyTaskData);

  useEffect(() => {
    setTaskData(props.data);
  }, [props.data]);

  return (
    <Root className={classes.root}>
      <CalendarView
        taskData={taskData}
        onTasksUpdated={(tasks) => {
          // I don't really like locally fudging data that originally came from a different backend call, but this seems pretty safe:
          setTaskData((taskData) => ({
            ...taskData,
            all_tasks: tasks.map(({ id }: { id: string }) => id),
            tasks_complete: tasks
              .filter(({ completed }: { completed: boolean }) => completed)
              .map(({ id }) => id),
            tasks_outstanding: tasks
              .filter(({ completed }) => !completed)
              .map(({ id }) => id),
          }));
        }}
        onDecisionDateClicked={() => {
          if (typeof onDecisionDateClicked === "function") {
            onDecisionDateClicked();
          }
        }}
      />
      <div style={{ width: "80vw", margin: "auto" }}>
        {" "}
        <Grid container spacing={3}>
          <Grid item xs>
            <Paper className={classes.paper}>
              <h1 className={classes.primaryColor}>
                {replaceInvalidNumberWith(0)(
                  Math.round(
                    ((taskData.effectiveBudget - taskData.budgetRemaining) /
                      taskData.effectiveBudget) *
                      100
                  )
                )}
                %
              </h1>
              <p>Invoiced</p>
            </Paper>
          </Grid>
          <Grid item xs>
            <Paper className={classes.paper}>
              <h1 style={{ color: "green" }}>
                {taskData.tasks_complete.length}
              </h1>
              <p>Tasks Done</p>
            </Paper>
          </Grid>
          <Grid item xs>
            <Paper className={classes.paper}>
              <h1 style={{ color: "red" }}>
                {taskData.next_decision_date === null
                  ? "No Decisions"
                  : moment(taskData.next_decision_date)
                      .format()
                      .split("T")
                      .shift()}
              </h1>
              <p>Next Decision Date</p>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </Root>
  );
}
