import useSWR, { mutate as globalMutate } from "swr";
import { useBackend } from ".";
import { FetchError, updateProjectDetails, getProjectDetails } from "../API";
import { LoanModel, Task } from "../../Models";
import {
  legacyBackendGetProjectDetailsResponse,
  CreateTaskInputs,
  UpdateProjectInputs,
  PartiallyModernizedGetProjectDetailsResponse,
} from "../APITypes";
import { useFeatures } from "./useFeatures";
import { ProjectId } from "@project-centerline/project-centerline-api-types";
import { useAuth } from "@lib/hooks/useAuth";
import { throwIfNot } from "../util/throwIfNot";
import { SWRKeys } from "../swrKeys";

type ReturnType = {
  project?: {
    details?: Readonly<PartiallyModernizedGetProjectDetailsResponse>;
    forceRefreshDetails: () => Promise<
      legacyBackendGetProjectDetailsResponse | undefined
    >;
    mutateLoanDetails: (
      newInfo: Partial<LoanModel>
    ) => Promise<legacyBackendGetProjectDetailsResponse | undefined>;
    addTask: (task: CreateTaskInputs) => Promise<Task>;
    update: (
      updates: UpdateProjectInputs
    ) => Promise<legacyBackendGetProjectDetailsResponse>;
  };
  errors?: {
    details?: Readonly<FetchError>;
  };
  isValidating?: boolean;
};

/** SWR-load project, CRUD project_table */
export function useProjectDetails(projectId: string): ReturnType {
  const { getProjectDetails, editProjectDetails, createTask } = useBackend();
  const { currentUserEmail: email } = useAuth();

  const {
    data: details,
    error: detailsError,
    mutate: mutateProject,
    isValidating,
  } = useSWR<PartiallyModernizedGetProjectDetailsResponse, FetchError>(
    SWRKeys.project(projectId as ProjectId).self,
    (_url: unknown) => getProjectDetails(projectId)
  );

  const { reportingFeature } = useFeatures();
  const project: ReturnType["project"] = details
    ? {
        details,
        forceRefreshDetails: () => mutateProject(),
        mutateLoanDetails: async (loan: Partial<LoanModel>) => {
          const { /* interestReserve, */ reporting, ...loanWithoutReporting } =
            loan;
          const featureAdjustedLoan = reportingFeature
            ? loan
            : loanWithoutReporting;
          const optimisticProject = { ...details, ...featureAdjustedLoan };
          const sendToServerLoan = featureAdjustedLoan;

          // first, optimistic update
          mutateProject(optimisticProject, false);

          // now ask server (and wait)
          const newDetails = await editProjectDetails(projectId, "loan", {
            loan: sendToServerLoan,
          });

          // server returned updated version; save it, return it, don't refetch
          mutateProject(newDetails, false);
          return newDetails;
        },
        // mutateStatus: async (status: ProjectStatus) => {
        //   // first, optimistic update
        //   mutateProject({ ...details, project_status: status }, false);

        //   // now ask server (and wait)
        //   const newDetails = await editProjectDetails(projectId, "status", {
        //     status,
        //   });

        //   // server returned updated version; save it, return it, don't refetch
        //   mutateProject(newDetails, false);
        //   return newDetails;
        // },

        addTask: (task: CreateTaskInputs) =>
          createTask(
            projectId,
            details.retention || 0,
            throwIfNot(email, "email is required"),
            task
          ),
        update: async (updates) => {
          // optimistic
          mutateProject({ ...details, ...updates }, false);

          // roundtrip it
          const newDetails = await updateProjectDetails({
            projectId,
            updates,
            etag: details.etag,
          });

          // save new and don't refetch
          mutateProject(
            { ...newDetails, project_id: newDetails.project_id as ProjectId },
            false
          );
          return newDetails;
        },
      }
    : undefined;
  const errors = detailsError
    ? {
        details: detailsError || undefined,
      }
    : undefined;

  return {
    project,
    errors,
    isValidating,
  };
}

export const loadProject = ({ projectId }: { projectId?: string }) =>
  projectId
    ? getProjectDetails(projectId).then((details) =>
        globalMutate(SWRKeys.project(projectId as ProjectId).self, details)
      )
    : Promise.reject("no project specified");
