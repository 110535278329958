import { Container, Grid, Typography } from "@mui/material";
import React from "react";
import { useProjectDetails } from "@lib/hooks/useProjectDetails";
import { legacyBackendGetProjectDetailsResponse } from "@lib/APITypes";
import { ErrorOrProgressOr } from "../../../Components/ErrorOrProgressOr";
import config from "../../../config";

export interface StatementsTabProps {
  /** We'll fetch with SWR but hopefully it's already ready :-) */
  projectId: string;
}

/** https://trello.com/c/yP0p95K3/190-add-report-list-to-info-tab */
export function StatementsTab({ projectId }: StatementsTabProps): JSX.Element {
  const { project, errors } = useProjectDetails(projectId);
  const { details } = project || {};
  // const [reFetching, setReFetching] = React.useState(false);
  const reFetching = false;

  const [mutableDetails, setMutableDetails] = React.useState<
    legacyBackendGetProjectDetailsResponse | undefined
  >(details);
  React.useEffect(() => {
    setMutableDetails(details);
  }, [details]);

  return (
    <Container>
      <ErrorOrProgressOr
        error={errors?.details?.message}
        progress={reFetching || (!mutableDetails && !errors)}
        render={() => (
          <Grid container direction="column" alignItems="center">
            <Typography variant="h3">
              (Temporary for testing) on-demand statements
              <br></br>
            </Typography>
            <Grid container spacing={2} justifyContent="space-around">
              <Grid item>
                <a
                  rel="noopener noreferrer"
                  // spell-checker:ignore pdftest pdftestfromtemplate
                  href={`${config.apiGateway.URL}pdftest?projectId=${projectId}&html=1`}
                  target="_blank"
                >
                  HTML Statement
                </a>
              </Grid>
              <Grid item>
                <a
                  rel="noopener noreferrer"
                  href={`${config.apiGateway.URL}pdftest?projectId=${projectId}`}
                  target="_blank"
                >
                  PDF Statement
                </a>
              </Grid>
              <Grid item>
                <a
                  rel="noopener noreferrer"
                  href={`${config.apiGateway.URL}pdftestfromtemplate?projectId=${projectId}`}
                  target="_blank"
                >
                  Fancy PDF Statement
                </a>
              </Grid>
            </Grid>
          </Grid>
        )}
      />
    </Container>
  );
}
