import * as t from "io-ts";
import * as tt from "io-ts-types";

import { getOrElseW } from "fp-ts/lib/Either";
import { ValidationError } from "io-ts";
import { failure } from "io-ts/lib/PathReporter";

/** value or null */
export function nullable<RT extends t.Any>(
  type: RT,
  name?: string
): t.UnionC<[RT, typeof t.null]> {
  return t.union([type, t.null], name);
}

/**
 * value or null
 * @deprecated use nullable as it is easier to read
 */
export const maybe = nullable;

/** value or undefined */
export function optional<RT extends t.Any>(
  type: RT,
  name?: string
): t.UnionC<[RT, typeof t.undefined]> {
  return t.union([type, t.undefined], name);
}

export const DateFromDateOrISO = t.union([tt.date, tt.DateFromISOString]);

export const getOrThrowDecodeError = getOrElseW((errors) => {
  throw new Error(failure(errors as ValidationError[]).join("\n"));
});

export type RecursivePartial<T> = {
  [P in keyof T]?: T[P] extends (infer U)[]
    ? RecursivePartial<U>[]
    : T[P] extends object | undefined
    ? RecursivePartial<T[P]>
    : T[P];
};

export type Rename<
  R extends Record<string, unknown>,
  Old extends keyof R,
  New extends string
> = Omit<R, Old> & Record<New, R[Old]>;
