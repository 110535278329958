import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useState } from "react";
import { useUser } from "./useUser";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { JSONTree } from "react-json-tree";
import RedHealth from "../Assets/Images/redHealth.png";
import GreenHealth from "../Assets/Images/greenHealth.png";
import YellowHealth from "../Assets/Images/yellowHealth.png";
import { logAnomaly, logError } from "@lib/ErrorLogging";
import { useSnackbar } from "notistack";
import { Role } from "@lib/roles";
import { useAppContext } from "@lib/UserContext";
import { User } from "@/Lib/APITypes";

const PREFIX = "UserAdminPane";

const classes = {
  healthIcon: `${PREFIX}-healthIcon`,
};

const StyledAccordion = styled(Accordion)(({ theme: { spacing } }) => ({
  [`& .${classes.healthIcon}`]: {
    width: spacing(3),
    height: spacing(3),
  },
}));

function AdminDetails(forUser: Pick<User, "id">): JSX.Element {
  const { role } = useAppContext();
  const { user, fixUser } = useUser(forUser);
  const neverLoggedIn =
    user &&
    !user.identity &&
    user.cognito?.user?.UserCreateDate ===
      user.cognito?.user?.UserLastModifiedDate;
  const loggedInButNoIdentityRecorded =
    user &&
    !user.identity &&
    user.cognito?.user?.UserCreateDate !==
      user.cognito?.user?.UserLastModifiedDate;

  const emailVerified = user?.cognito?.user?.Attributes?.some(
    ({ Name, Value }) => Name === "email_verified" && Value === "true"
  );

  const [busy, setBusy] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const performFixes = () => {
    setBusy(true);
    (
      fixUser ??
      (() => Promise.reject("should not be able to get here with no fixUser"))
    )()
      .then((actions) =>
        actions.map((action) =>
          enqueueSnackbar(`Performed "${action}"`, {
            variant: "success",
          })
        )
      )
      .catch((err) => {
        logError(new Error("Admin fix user fail"), err);
        enqueueSnackbar(`Error: ${err.message}`, {
          variant: "error",
        });
      })
      .finally(() => {
        setBusy(false);
      });
  };

  const emptyBackendCognitoUser = user?.cognito && !user.cognito.user;
  if (emptyBackendCognitoUser) {
    // TODO: have backend not return empty object if no cognito
    logAnomaly(new Error("did not expect empty cognito user from backend"));
  }
  const externalUser = user?.identity?.startsWith("external:");
  return user ? (
    <Grid container direction="column">
      {emptyBackendCognitoUser && !externalUser ? (
        <Grid
          item
          container
          direction="row"
          alignContent="space-between"
          alignItems="center"
        >
          <Grid item container direction="row">
            <img
              className={classes.healthIcon}
              src={YellowHealth}
              alt="warning"
            />
            <Typography>
              <strong>
                {role === Role.SuperAdmin
                  ? "User does not exist in Cognito"
                  : "Invalid user state"}
              </strong>
            </Typography>
          </Grid>
          <Typography>
            Consider deleting and re-adding or adding to another project to
            force creation.
          </Typography>
        </Grid>
      ) : (
        <>
          {neverLoggedIn ? (
            <Grid
              item
              container
              direction="row"
              alignContent="space-between"
              alignItems="center"
            >
              <Grid item container direction="row">
                <img
                  className={classes.healthIcon}
                  src={YellowHealth}
                  alt="warning"
                />
                <Typography>User has never logged in</Typography>
              </Grid>
            </Grid>
          ) : undefined}
          {loggedInButNoIdentityRecorded ? (
            <Grid
              item
              container
              direction="row"
              alignContent="space-between"
              alignItems="center"
            >
              <Grid item container direction="row">
                <img
                  className={classes.healthIcon}
                  src={YellowHealth}
                  alt="warning"
                />
                <Typography>
                  User has not logged in in the modern era
                </Typography>
              </Grid>
            </Grid>
          ) : undefined}
          {user?.cognito?.user?.UserStatus === "FORCE_CHANGE_PASSWORD" ? (
            <Grid item container direction="row" alignItems="center">
              <img
                className={classes.healthIcon}
                src={YellowHealth}
                alt="warning"
              />
              <Typography>Waiting for password (change)</Typography>
              {busy ? (
                <CircularProgress />
              ) : (
                <Button variant="contained" onClick={performFixes}>
                  Send New Code
                </Button>
              )}
            </Grid>
          ) : undefined}
          {!emailVerified && !externalUser ? (
            <Grid
              item
              container
              direction="row"
              alignContent="space-between"
              alignItems="center"
            >
              <Grid item container direction="row" wrap="nowrap">
                <img
                  className={classes.healthIcon}
                  src={RedHealth}
                  alt="danger"
                />
                <Typography>Email is not verified</Typography>
              </Grid>
              <Grid item>
                {busy ? (
                  <CircularProgress />
                ) : (
                  <Button variant="contained" onClick={performFixes}>
                    Attempt Repair
                  </Button>
                )}
              </Grid>
            </Grid>
          ) : undefined}
          {user?.cognito?.user?.UserStatus === "CONFIRMED" ? (
            <Grid
              item
              container
              direction="row"
              alignContent="space-between"
              alignItems="center"
            >
              <Grid item container direction="row">
                <img
                  className={classes.healthIcon}
                  src={GreenHealth}
                  alt="yay"
                />
                <Typography>Confirmed user</Typography>
              </Grid>
            </Grid>
          ) : undefined}
          {externalUser ? (
            <Grid
              item
              container
              direction="row"
              alignContent="space-between"
              alignItems="center"
            >
              <Grid item container direction="row">
                <img
                  className={classes.healthIcon}
                  src={GreenHealth}
                  alt="yay"
                />
                <Typography>External user</Typography>
              </Grid>
            </Grid>
          ) : undefined}
        </>
      )}
      {role === Role.SuperAdmin ? (
        <Grid item>
          <JSONTree data={user ?? { status: "no user" }} />
        </Grid>
      ) : null}
    </Grid>
  ) : (
    <CircularProgress />
  );
}

export function UserAdminPane(props: Pick<User, "id">): JSX.Element {
  // the details don't even *exist* if the accordion is closed, so we don't swamp the backend with stuff we'll never look at
  const [detailsOpen, setDetailsOpen] = useState(false);
  const { id } = props;

  return (
    <StyledAccordion
      TransitionProps={{ unmountOnExit: true }}
      onChange={(_, expanded) => setDetailsOpen(expanded)}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="admin-content"
        id="admin-header"
      >
        <Typography>Admin</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {detailsOpen ? <AdminDetails id={id} /> : undefined}
      </AccordionDetails>
    </StyledAccordion>
  );
}
