import "./wdyr";
import "./reactotronConfig";

import React from "react";
import { createRoot } from "react-dom/client";
import { Amplify } from "aws-amplify";
import "./index.css";
import App from "./App";
import config from "./config";
import { initSentry } from "@lib/ErrorLogging";
import * as serviceWorker from "./serviceWorker";
import { initPrefs } from "@lib/hooks/usePrefs";
import {
  createBrowserRouter,
  Navigate,
  Outlet,
  RouterProvider,
  useParams,
} from "react-router-dom";
import ErrorPage from "./Pages/ErrorPage";
import AppRoutes, { routes } from "./Routes";
import { CircularProgress } from "@mui/material";
import { MSTProvider, rootStore } from "./Models/Root";
import { useNewUIHook } from "./Lib/hooks/useNewUI";

const OldUIContainer = () => {
  React.useEffect(() => {
    document.body.classList.add("old-ui");

    return () => {
      document.body.classList.remove("old-ui");
    };
  }, []);

  return (
    <div className="old-ui">
      <App root={<AppRoutes />} />
    </div>
  );
};

if (
  process.env.NODE_ENV !== "test" &&
  process.env.REACT_APP_SENTRY_ENVIRONMENT
) {
  initSentry();
}

initPrefs();

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
  },
  Storage: {
    region: config.s3.REGION,
    bucket: config.s3.BUCKET,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
  },
  API: {
    endpoints: [
      {
        name: "project-centerline",
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION,
      },
    ],
  },
});

const container = document.getElementById("root");
if (!container) {
  throw new Error("No root container. Something is very wrong.");
}
const root = createRoot(container);

const NewOrOldUI = () => {
  const { useNewUI } = useNewUIHook();
  return useNewUI ? (
    <div className="new-ui">
      <App root={<Outlet />} />
    </div>
  ) : (
    <OldUIContainer />
  );
};

const RedirectWithoutPrefix = () => {
  const { "*": splat } = useParams();
  return (
    <div>
      <CircularProgress />
      <Navigate to={`/${splat}`} />
    </div>
  );
};

// console.log({ routes: routes() });
const router = createBrowserRouter([
  {
    path: "/new/*",
    element: <RedirectWithoutPrefix />,
    errorElement: <ErrorPage />,
    handle: {
      newUIBecauseRoute: true,
    },
  },
  {
    path: "/old/*",
    element: <RedirectWithoutPrefix />,
    errorElement: <ErrorPage />,
    handle: {
      newUIBecauseRoute: false,
    },
  },
  {
    // path: "/*", // rootStore.deviceSettings.useNewUI ? "" : "/*", // would rather get this from useNewUIHook()
    element: (
      <MSTProvider value={rootStore}>
        <NewOrOldUI />
      </MSTProvider>
    ),
    errorElement: <ErrorPage />,
    // ...(rootStore.deviceSettings.useNewUI && {
    children: routes(),
    // }),
    handle: { "root!": true, newUI: rootStore.deviceSettings.useNewUI },
  },
]);

root.render(<RouterProvider router={router}></RouterProvider>);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
