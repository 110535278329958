import { FormControl, Grid, TextField } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { ifEnterKey, IfEnterKeyEventHandler } from "@lib/boilerplate";
import { useFocus } from "@lib/hooks";
import React from "react";
import { multiRef, MustSatisfy } from "@lib/misc";
// TODO: Surely there is some error handling to do around google maps
// import { useSnackbar } from "notistack";
import { MoneyEditor } from "../Components/MoneyEditor";
import { z } from "zod";
import * as t from "io-ts";

import { PatchProjectBodySchema } from "@project-centerline/project-centerline-api-types";
import {
  PropertyTypeForm,
  ZodSchema as PropertyTypeSchema,
} from "./PropertyTypeForm";
import { AddressForm, ZodSchema as AddressFormSchema } from "./AddressForm";

export interface ProjectInfoFormProps {
  /** for a containing tab to move forward */
  onLastFieldEntered?: IfEnterKeyEventHandler;
}

// zod generates better errors with react-hook-form
export const ProjectInfoFormZodSchema = AddressFormSchema.merge(
  z.object({
    name: z.string().min(1),
    originalValue: z.coerce.number().nonnegative().nullable(),
    futureValue: z.coerce.number().nonnegative().nullable(),
    originalSquareFeet: z.coerce.number().nonnegative().nullable(),
    newSquareFeet: z.coerce.number().nonnegative().nullable(),
  })
).merge(PropertyTypeSchema);

export type ProjectInfoFormOutputs = z.infer<typeof ProjectInfoFormZodSchema>;
type InferredPatchProjectType = t.TypeOf<typeof PatchProjectBodySchema>;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
type _sanity = MustSatisfy<InferredPatchProjectType, ProjectInfoFormOutputs>;

/**
 * (Collection of) Form (inputs) presenting the various project properties
 */
export function ProjectInfoForm({
  onLastFieldEntered,
}: ProjectInfoFormProps): JSX.Element {
  const { control, formState } = useFormContext<ProjectInfoFormOutputs>();
  const { errors } = formState;

  // const { enqueueSnackbar } = useSnackbar();

  const [addressRef, focusAddress] = useFocus<HTMLInputElement>();
  const [originalValueRef, focusOriginalValue] = useFocus<HTMLInputElement>();

  return (
    <div
      style={{
        paddingRight: "5vw",
        paddingLeft: "5vw",
        textAlign: "center",
      }}
    >
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Controller
            name="name"
            control={control}
            render={({ field: { ref, ...rest } }) => (
              <FormControl
                variant="standard"
                fullWidth
                // sx={margin}
              >
                <TextField
                  variant="standard"
                  fullWidth
                  required
                  id="name"
                  label="Project Name"
                  // inputRef={multiRef([ref, loanIdentifierRef])}
                  inputRef={ref}
                  {...rest}
                  onKeyDown={ifEnterKey(focusAddress)}
                />
              </FormControl>
            )}
          />
        </Grid>
        <Grid item /* spacer */ />

        <Grid item>
          <AddressForm
            onLastFieldEntered={ifEnterKey(focusOriginalValue)}
            addressRef={addressRef}
          />
        </Grid>
        <Grid item /* spacer */ />
        <Grid item container spacing={2}>
          <Grid item>
            <Controller
              name="originalValue"
              render={({ field: { ref, ...field } }) => (
                <MoneyEditor
                  {...field}
                  ref={multiRef([ref, originalValueRef])}
                  label="Value Before Improvements"
                  helperText={errors.originalValue?.message}
                  error={Boolean(errors.originalValue?.message)}
                  // onKeyDown={ifEnterKey(focusSubmit)}
                />
              )}
              control={control}
            />
          </Grid>
          <Grid item>
            <Controller
              name="futureValue"
              render={({ field }) => (
                <MoneyEditor
                  {...field}
                  // ref={multiRef([ref, valueRef])}
                  label="Value After Improvements"
                  helperText={errors.futureValue?.message}
                  error={Boolean(errors.futureValue?.message)}
                  // onKeyDown={ifEnterKey(focusSubmit)}
                />
              )}
              control={control}
            />
          </Grid>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item>
            <Controller
              name="originalSquareFeet"
              control={control}
              render={({ field: { ref, value, ...rest } }) => (
                <FormControl
                  variant="standard"
                  fullWidth
                  // sx={margin}
                >
                  <TextField
                    variant="standard"
                    type="number"
                    fullWidth
                    id="originalSquareFeet"
                    label="Square Footage Before Improvements"
                    InputLabelProps={{ shrink: true }}
                    // inputRef={multiRef([ref, addressRef])}
                    {...rest}
                    value={value === null ? "" : value}
                    // onKeyDown={ifEnterKey(focusCity)}
                  />
                </FormControl>
              )}
            />
          </Grid>

          <Grid item>
            <Controller
              name="newSquareFeet"
              control={control}
              render={({ field: { ref, value, ...rest } }) => (
                <FormControl
                  variant="standard"
                  fullWidth
                  // sx={margin}
                >
                  <TextField
                    variant="standard"
                    type="number"
                    fullWidth
                    id="newSquareFeet"
                    label="Square Footage After Improvements"
                    InputLabelProps={{ shrink: true }}
                    // inputRef={multiRef([ref, addressRef])}
                    {...rest}
                    value={value === null ? "" : value}

                    // onKeyDown={ifEnterKey(focusCity)}
                  />
                </FormControl>
              )}
            />
          </Grid>
        </Grid>
        <Grid item>
          <PropertyTypeForm onLastFieldEntered={onLastFieldEntered} />
        </Grid>
      </Grid>
      {/* <DevTool control={control} /> */}
    </div>
  );
}

export default ProjectInfoForm;
