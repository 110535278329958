import * as t from "io-ts";
import { MustSatisfy } from "./misc";

export enum Role {
  SuperAdmin = "Super Admin",
  Contractor = "Contractor",
  Subcontractor = "Subcontractor",
  Lender = "Lender",
  LenderAdmin = "LenderAdmin",
  Inspector = "Inspector",
  InspectorAdmin = "InspectorAdmin",
  Approver = "Approver",
  Owner = "Owner",
}

export const RoleC = t.union([
  t.literal(Role.SuperAdmin),
  t.literal(Role.Contractor),
  t.literal(Role.Subcontractor),
  t.literal(Role.Lender),
  t.literal(Role.LenderAdmin),
  t.literal(Role.Inspector),
  t.literal(Role.InspectorAdmin),
  t.literal(Role.Approver),
  t.literal(Role.Owner),
]);

type InferredRole = t.TypeOf<typeof RoleC>;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
type _sanity = MustSatisfy<Role, InferredRole> &
  MustSatisfy<InferredRole, Role>;
