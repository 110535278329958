import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Toolbar,
  Typography,
} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";

import { backOutRetention } from "@lib/hacks";
import { EditTaskForm } from "@/Forms/EditTaskForm";
import { LicensedBusinessPersonInputs } from "@lib/APITypes";
import { usePermissions } from "@lib/hooks/usePermissions";
import { SlideUp } from "@lib/Transitions";
import { useBackend } from "@lib/hooks";
import { logError } from "@lib/ErrorLogging";
import { useProjectTasks } from "@lib/hooks/useProjectTasks";
import { useSnackbar } from "notistack";
import { Task } from "../../../Models";

const PREFIX = "EventDialog";

const classes = {
  appBar: `${PREFIX}-appBar`,
  title: `${PREFIX}-title`,
  element: `${PREFIX}-element`,
  primaryColor: `${PREFIX}-primaryColor`,
};

const Root = styled("div")(({ theme: { spacing, palette } }) => ({
  [`& .${classes.appBar}`]: {
    position: "relative",
  },

  [`& .${classes.title}`]: {
    marginLeft: spacing(2),
    flex: 1,
  },

  [`& .${classes.element}`]: {
    marginTop: "10px",
  },

  [`& .${classes.primaryColor}`]: {
    color: palette.primary.main,
  },
}));

interface FullScreenDialogProps {
  task: Task;
  project: string;
  handleClose: () => void;
  contractor?: LicensedBusinessPersonInputs;
  retention: number;
  handleDeleteOpen?: () => void;
}

// TODO: #64 EventDialog is a hot mess. Lots of data shadowing, some stuff in state and some not, disagreeing names, etc...

export default function FullScreenDialog(
  props: FullScreenDialogProps
): JSX.Element | null {
  const {
    project: projectId,
    task: { id: taskId },
  } = props;

  const { getProjectUsers } = useBackend();
  const {
    project: { completeTask, editTask },
  } = useProjectTasks(projectId);

  const { enqueueSnackbar } = useSnackbar();
  const { canCompleteTasks, canEditTasks, canDeleteTasks } = usePermissions();
  const [open, setOpen] = React.useState(false);
  const [taskData, setTask] = React.useState<Task | undefined>();
  // eslint-disable-next-line no-unused-vars
  const [contractorData, setContractor] =
    React.useState<LicensedBusinessPersonInputs>();
  const [completed, setCompleted] = React.useState(false);
  const [isEditing, setIsEditing] = React.useState(false);
  interface SubcontractorRequiredInfo {
    first_name: string;
    last_name: string;
    company: string;
    email: string;
  }

  const [subcontractor, setSubcontractor] = React.useState<
    SubcontractorRequiredInfo | undefined
  >();

  const handleChange = (event: { target: { checked: boolean } }) => {
    const {
      target: { checked: completed },
    } = event;
    setCompleted(completed);
    completeTask(taskId, completed)
      .then(() => {
        props.handleClose?.();
      })
      .catch((error) => {
        logError(error);
        console.log(error);
      });
  };

  const handleUpdateTask = () => {
    setIsEditing(true);
  };

  const handleClose = () => {
    setIsEditing(false);
    const parentHandleClose = props.handleClose;
    if (typeof parentHandleClose === "function") {
      parentHandleClose();
    }
  };

  useEffect(() => {
    setOpen(!!props.task);
    setTask(props.task);
    if (props.task) {
      if (props.task.task_subcontractor) {
        setSubcontractor(JSON.parse(props.task.task_subcontractor));
      } else {
        setSubcontractor(undefined);
      }

      //setSubcontractor(props.task_subcontractor);
      setCompleted(props.task.completed);
      setContractor(props.contractor);
    }
  }, [
    props.project,
    props.task,
    props.contractor,
    props.task.completed,
    getProjectUsers,
  ]);

  return taskData ? (
    <Root>
      <Dialog open={open} onClose={handleClose} TransitionComponent={SlideUp}>
        {isEditing && taskData ? (
          <EditTaskForm
            taskData={{
              ...taskData,
              task_value: backOutRetention(
                taskData.task_value,
                props.retention
              ),
            }}
            onSubmit={async ({ taskData, dirtyFields }) => {
              try {
                if (dirtyFields.includes("completed")) {
                  await completeTask(props.task.id, taskData.completed);
                }
                if (dirtyFields.some((field) => field !== "completed")) {
                  await editTask(props.task.id, taskData);
                }

                props.handleClose();
              } catch (error) {
                logError(error);
                enqueueSnackbar("Could not edit task", {
                  variant: "error",
                });
              }
            }}
            onCancel={props.handleClose}
            onDelete={canDeleteTasks ? props.handleDeleteOpen : undefined}
            existingFiles={/* TODO: not implemented! */ []}
            scheduleMode
          />
        ) : (
          <div>
            {/* <AppBar className={classes.appBar}> */}
            <Toolbar sx={{ backgroundColor: "#429adf" }}>
              {canEditTasks ? (
                <IconButton
                  edge="start"
                  aria-label="close"
                  onClick={handleUpdateTask}
                  size="large"
                  sx={{ color: "white" }}
                >
                  <EditIcon />
                </IconButton>
              ) : null}
              <Typography variant="h5" color="white" className={classes.title}>
                {taskData.title}
              </Typography>
            </Toolbar>
            {/* </AppBar> */}
            <DialogContent style={{ backgroundColor: "#f5f5f5" }}>
              <DialogContentText>
                <strong>{taskData.division}</strong>
              </DialogContentText>
              <DialogContentText>{taskData.subdivision}</DialogContentText>
              <DialogContentText>{taskData.description}</DialogContentText>
              {subcontractor ? (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <p>
                      Subcontractor:{" "}
                      <strong>
                        {subcontractor.first_name +
                          " " +
                          subcontractor.last_name}
                      </strong>
                    </p>
                  </AccordionSummary>
                  <AccordionDetails style={{ flexDirection: "column" }}>
                    <p className={classes.element}>
                      <strong>Company</strong>
                      <br />
                      {subcontractor.company}
                    </p>
                    <p className={classes.element}>
                      <strong>Email</strong>
                      <br />
                      {subcontractor.email}
                    </p>
                    {/* <p className={classes.element}>
                    <strong>Phone Number</strong>
                    <br />
                    {contractorData.phone_number}
                  </p>
                  <p className={classes.element}>
                    <strong>Division</strong>
                    <br />
                    {taskData.division}
                  </p>
                  <p className={classes.element}>
                    <strong>Subdivision</strong>
                    <br />
                    {taskData.subdivision}
                  </p> */}
                  </AccordionDetails>
                </Accordion>
              ) : contractorData ? (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <p>
                      <strong>
                        {contractorData.first_name +
                          " " +
                          contractorData.last_name}
                      </strong>
                    </p>
                  </AccordionSummary>
                  <AccordionDetails style={{ flexDirection: "column" }}>
                    <p className={classes.element}>
                      <strong>Company</strong>
                      <br />
                      {contractorData.company}
                    </p>
                    <p className={classes.element}>
                      <strong>Email</strong>
                      <br />
                      {contractorData.email}
                    </p>
                    <p className={classes.element}>
                      <strong>Phone Number</strong>
                      <br />
                      {contractorData.phone_number}
                    </p>
                    <p className={classes.element}>
                      <strong>Division</strong>
                      <br />
                      {taskData.division}
                    </p>
                    <p className={classes.element}>
                      <strong>Subdivision</strong>
                      <br />
                      {taskData.subdivision}
                    </p>
                  </AccordionDetails>
                </Accordion>
              ) : (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <p>No Contractor</p>
                  </AccordionSummary>
                  <AccordionDetails style={{ flexDirection: "column" }}>
                    <p className={classes.element}>
                      There is no contractor associated with this project
                    </p>
                  </AccordionDetails>
                </Accordion>
              )}
              <DialogContentText style={{ marginTop: "10px" }}>
                {"Task Status: "}{" "}
                {taskData.completed ? "Completed" : "In Progress"}
              </DialogContentText>
              {canCompleteTasks ? (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={completed}
                      onChange={handleChange}
                      name="complete"
                      className={classes.primaryColor}
                    />
                  }
                  label="Completed"
                />
              ) : null}
            </DialogContent>
            <DialogActions style={{ backgroundColor: "#f5f5f5" }}>
              {canDeleteTasks ? (
                <Button onClick={props.handleDeleteOpen} color="secondary">
                  Delete
                </Button>
              ) : null}
              <Button onClick={props.handleClose} color="primary">
                Close
              </Button>
            </DialogActions>
          </div>
        )}
      </Dialog>
    </Root>
  ) : null;
}
