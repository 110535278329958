import React, { PropsWithChildren } from "react";
import { Link, LinkProps } from "react-router-dom";

export function StealthyLink({
  children,
  ...linkProps
}: PropsWithChildren<LinkProps>): JSX.Element {
  return (
    <Link {...linkProps} style={{ textDecoration: "none", color: "inherit" }}>
      {children}
    </Link>
  );
}
