import { throwIfNot } from "@lib/util/throwIfNot";
import { useParams } from "react-router-dom";
import { UserGrid } from "./UserGrid";

export const UsersPage = () => {
  const projectId = throwIfNot(
    useParams<"projectId">().projectId,
    "Missing project id"
  );

  return <UserGrid projectId={projectId} />;
};

export default UsersPage;
