// import { Typography } from "@mui/material";
import { CircularProgress, Typography } from "@mui/material";
import React from "react";
import { Navigate, useLocation, Outlet } from "react-router-dom";
import { useAuth } from "@lib/hooks/useAuth";
import { useGetUserRole } from "@/Lib/hooks/useGetUserRole";
import { Role } from "@lib/roles";

export const AuthenticatingProgress = () => (
  <div style={{ margin: "auto", textAlign: "center" }}>
    <Typography>Identifying you...</Typography>
    <CircularProgress />
  </div>
);
export default function AuthenticatedRoute({
  roleMustBeNull,
  prefix,
}: {
  roleMustBeNull?: boolean;
  prefix: string;
} & Record<string, unknown>): JSX.Element {
  const { pathname, search } = useLocation();
  const { user, isValidating: userValidating } = useGetUserRole();
  const { role } = user || { role: null };
  const { isValidating: authValidating, isAuthenticated } = useAuth();

  const allowRoute =
    (isAuthenticated &&
      role !== null &&
      role !== ("**uninitialized**" as Role)) /* TODO: wtf */ ||
    (!!roleMustBeNull && role === null);

  return allowRoute ? (
    <Outlet />
  ) : authValidating || userValidating ? (
    <AuthenticatingProgress />
  ) : (
    <Navigate
      replace
      to={`${prefix}/login?redirect=${encodeURIComponent(
        `${pathname}${search}`
      )}`}
    />
  );
}

// export const AdminRoute: (
//   props: {
//     children: React.ReactNode;
//   } & Record<string, unknown>
// ) => JSX.Element = ({ children, ...rest }) => {
//   const { role } = useAppContext();

//   return (
//     <Route {...rest}>
//       {role === Role.SuperAdmin ? (
//         children
//       ) : (
//         <div>
//           <Typography>DENIED</Typography>
//         </div>
//       )}
//     </Route>
//   );
// };
