import {
  InvoiceId,
  ProjectId,
  UserId,
} from "@project-centerline/project-centerline-api-types";
import { byCreatedAt } from "@lib/hooks/useProjectTasks";

type DecisionId = string; /* TODO: DecisionId should be a type */
type EmailAddress = string; /* TODO: EmailAddress should be a type */

// TODO: not sure we want tho conditionals here; put them in the useSWR calls?. For now, kept it a refactor

export const SWRKeys = {
  user: (id?: UserId) => ({
    self: id && (`/api/user/${id}` as const),
  }),
  userByEmail: (email?: EmailAddress) => ({
    role: () => email && (`/api/user/${email}/role` as const),
  }),
  invoice: (invoiceId?: InvoiceId) => ({
    comments: () =>
      invoiceId && (`/api/invoices/${invoiceId}/comments` as const),
  }),
  decision: (decisionId?: DecisionId) => ({
    comments: () =>
      decisionId && (`/api/decisions/${decisionId}/comments` as const),
    self: () => `/api/decisions/${decisionId}` as const,
  }),
  project: (projectId?: ProjectId) => ({
    decisions: () =>
      projectId && (`/api/project/${projectId}/decisions` as const),
    self: () => projectId && (`/api/project/${projectId}` as const),
    files: () => projectId && (`/api/project/${projectId}/files` as const),
    invoices: () =>
      projectId && (`/api/project/${projectId}/invoices` as const),
    tasks: () => projectId && (`/api/project/${projectId}/tasks` as const),
    tasksSorted: ({ sortBy }: { sortBy: typeof byCreatedAt }) =>
      projectId &&
      (`/api/project/${projectId}/tasks?sort=${sortBy._tag}` as const),
    users: () => projectId && (`/api/project/${projectId}/users` as const),
  }),
  projects: {
    list: ({ extra }: { extra?: string }) =>
      `/api/project${extra ? `?${extra}` : ""}` as const,
    all: () => (key: unknown) =>
      typeof key === "string" && key.startsWith("/api/project/"),
  },
};
