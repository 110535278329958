import React, { useEffect } from "react";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FinalizedInvoices from "./FinalizedInvoices";
import PendingInvoices, { PendingInvoicesProps } from "./PendingInvoices";
import CreateDrawRequest from "./CreateDrawRequest";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import { TaskAPIs, uploadSupportingFiles, APIError } from "@lib/API";
import { usePermissions } from "@lib/hooks/usePermissions";
import { SlideUp } from "@lib/Transitions";
import {
  EditTaskForm,
  EditTaskFormProps,
  Inputs as EditTaskFormTaskInputs,
} from "../../../Forms/EditTaskForm";
import { backOutRetention } from "@lib/hacks";
import {
  legacyBackendGetProjectDetailsResponse,
  BackendUserResponse,
  ApprovalStatus,
  PersistentProjectFile,
  FileWithId,
} from "@lib/APITypes";
import { ExtraAdminDataProtocolProps } from "../../ProjectView";
import { useProjectUsers } from "@lib/hooks/useProjectUsers";
import { PeopleRoutingEditor } from "../../../Components/PeopleRoutingEditor";
import { byCreatedAt, useProjectTasks } from "@lib/hooks/useProjectTasks";
import {
  useInvoiceAncestors,
  useProjectInvoices,
} from "@lib/hooks/useProjectInvoices";
import { ConfirmDialog } from "../../../Components/ConfirmDialog";
import { logAnomaly, logError } from "@lib/ErrorLogging";
import { useSnackbar } from "notistack";
import { TaskDeleteRequest, TaskEditRequest } from "./DrawRequestValuesEditor";
import {
  Invoice,
  PendingInvoice,
  NotPendingInvoice,
  // PendingInvoice,
  Task,
} from "../../../Models";
import { DialogTitle } from "@mui/material";
import { useProjectFiles } from "@lib/hooks/useProjectFiles";
import { CalculatedDraw, invoiceDescription } from "./InvoicesCommon";
import { LocalTaskFile } from "./LocalTaskFile";
import { validateInvoiceRouting } from "./validateInvoiceRouting";
import { useAnalytics } from "@lib/hooks";
import { useEntityFeatures } from "@lib/hooks/useEntityFeatures";
import { buttonCTA, HorizontallyAligned } from "@lib/StyleHelpers";
import { useSearchParams } from "react-router-dom";
import {
  ProjectId,
  StoredFile,
  LinkedFile,
} from "@project-centerline/project-centerline-api-types";
import { NonEmptyString } from "io-ts-types";
import { byProp } from "@lib/misc";
import { throwIfNot } from "@lib/util/throwIfNot";
import { useAuth } from "@lib/hooks/useAuth";
import {
  UploadProgressDialog,
  useProgress,
} from "@/Components/UploadProgressDialog";
import { hasBeenInvoiced } from "./hasBeenInvoiced";

export const drawsFromItems = (items: Invoice["drawItems"]) => {
  return items.map(({ amount, title, task }) => [task?.title ?? title, amount]);
};

export type InvoiceViewProps = {
  data: Pick<
    legacyBackendGetProjectDetailsResponse,
    | "invoice_routing"
    | "address"
    | "draw_requests_remaining"
    | "retention"
    | "loanType"
  > & {
    project_id: ProjectId;
  };
} & ExtraAdminDataProtocolProps;

const root = {
  margin: "20px",
  marginBottom: "100px",
};

export default function InvoiceView(props: InvoiceViewProps): JSX.Element {
  const analytics = useAnalytics();
  const {
    data: { project_id: projectId, invoice_routing, address, retention },
    setExtraAdminData,
  } = props;
  const [routingEditorOpen, setRoutingEditorOpen] = React.useState(false);
  const [invoiceTypesDisplayed, setInvoiceTypeDisplayed] =
    React.useState<ApprovalStatus>(ApprovalStatus.Pending);
  const { currentUserEmail: email } = useAuth();
  const { restrictInspectors } = useEntityFeatures();
  const {
    canCompleteTasks,
    canEditTasks,
    canDeleteTasks,
    canEditInvoiceRouting,
    canModifyDrawRequests,
  } = usePermissions();
  const drawsModifiable = canModifyDrawRequests({ restrictInspectors });

  const {
    project: {
      tasks: { tasks } = {},
      deleteTask,
      completeTask,
      editTask: updateTask,
      forceRefreshTasks: refreshTasks,
    },
  } = useProjectTasks(projectId, { sortBy: byCreatedAt });

  const [taskToDelete, setTaskToDelete] = React.useState<Task | undefined>(
    undefined
  );

  const {
    project: { addFiles, removeFile, files: projectFiles },
  } = useProjectFiles({ projectId });
  const existingFiles = React.useMemo(
    () => (projectFiles && projectFiles.userFiles) || [],
    [projectFiles]
  );

  const {
    project: {
      invoices,
      updateRouting: updateProjectInvoiceRouting,
      invoice: invoiceFns,
    },
    errors,
  } = useProjectInvoices(projectId);
  const categorizedInvoices = React.useMemo(
    () =>
      Object.fromEntries(
        ["Pending", "Approved", "Rejected"].map((status) => [
          status,
          invoices?.filter(
            ({ approval_status }) => approval_status === status
          ) ?? [],
        ])
      ),
    [invoices]
  );

  const { fetchInitialRequest } = useInvoiceAncestors({ projectId });

  const {
    project: { users: projectUsers = [] },
  } = useProjectUsers(projectId);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [searchParams] = useSearchParams();
  const scrollToInvoiceId = searchParams.get("invoiceId");
  React.useEffect(() => {
    if (scrollToInvoiceId) {
      document.getElementById(scrollToInvoiceId)?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    }
  }, [scrollToInvoiceId, tasks]);
  const [approvers, setApprovers] = React.useState<
    ReadonlyArray<BackendUserResponse>
  >([]);
  const [loading, setLoading] = React.useState<"files" | "other stuff" | false>(
    false
  );

  const [taskOrDrawBeingEdited, editTaskOrDraw] = React.useState<
    | {
        task: Task;
        inputs: EditTaskFormTaskInputs;
        disabled?: boolean;
        draw?: EditTaskFormProps["draw"] & { invoice: Invoice };
        dialogTitle?: string;
      }
    | undefined
  >();
  const invoiceId = taskOrDrawBeingEdited?.draw?.invoice.invoice_id;
  const taskId = taskOrDrawBeingEdited?.task.id;

  const closeEditTaskDialog = (_?: unknown, reason?: string) => {
    if (reason === "backdropClick") {
      return;
    }
    editTaskOrDraw(undefined);
  };

  //Change Invoice View
  //TODO: better name
  const handleInvoiceCategoryChange = (event: {
    target: { value: unknown };
  }) => {
    const type = event.target.value as string;
    if (Object.keys(ApprovalStatus).includes(type)) {
      setInvoiceTypeDisplayed(type as ApprovalStatus);
    } else {
      throw new Error("bad invoice type");
    }
  };

  //Handling Approval Routing

  const handleEditProjectRouting = () => {
    analytics.track("button:edit-routing:project-default", { projectId });
    setRoutingEditorOpen(true);
  };

  const closeRoutingEditor = () => {
    setRoutingEditorOpen(false);
  };

  const handleSubmitRouting = (
    newRouting: ReadonlyArray<BackendUserResponse>
  ) => {
    if (
      !validateInvoiceRouting(newRouting, projectUsers ?? [], enqueueSnackbar)
    ) {
      return;
    }

    setLoading("other stuff");
    updateProjectInvoiceRouting(newRouting.map(({ email }) => ({ email })))
      .then(() => {
        setRoutingEditorOpen(false);
        setApprovers(newRouting);
      })
      .catch((error) => {
        enqueueSnackbar("Error updating routing. Please try again later.", {
          variant: "error",
        });
        logError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    const usersByEmail = byProp(projectUsers)("email");
    setApprovers(invoice_routing.map((email) => usersByEmail[email]));
  }, [invoice_routing, projectUsers]);

  useEffect(() => {
    setExtraAdminData?.({
      approvers,
      invoices: Object.fromEntries(
        [
          ApprovalStatus.Pending,
          ApprovalStatus.Approved,
          ApprovalStatus.Rejected,
        ].map((status) => [
          status,
          Object.fromEntries(
            invoices
              ?.map((invoice, i) => ({ invoice, i }))
              .filter(
                ({ invoice: { approval_status } }) => approval_status === status
              )
              .map(({ invoice, i }) => [
                invoiceDescription(invoice, i, invoice.timestamp),
                invoice,
              ]) ?? []
          ),
        ])
      ),
    });
  }, [invoices, approvers, setExtraAdminData]);

  useEffect(() => {
    // do nothing on mount, but cleanup on un-mount
    return () => setExtraAdminData?.({});
  }, [setExtraAdminData]);

  const handleDeleteTaskRequested: TaskDeleteRequest | undefined =
    canDeleteTasks
      ? (taskId) => {
          const taskToDelete = tasks?.find(({ id }) => id === taskId);
          if (taskToDelete) {
            if (hasBeenInvoiced(invoices)(taskToDelete)) {
              alert(
                `Task ${taskToDelete.title} cannot be deleted because it has been invoiced`
              );
            } else {
              setTaskToDelete(taskToDelete);
            }
          }
        }
      : undefined;

  const onTaskEditRequested: TaskEditRequest = (taskId) => {
    const taskToEdit = tasks?.find(({ id }) => id === taskId);
    if (!taskToEdit) {
      logError(new Error("can't find task to edit"), {
        taskId,
        tasks,
      });
      enqueueSnackbar("Sorry, something went wrong preparing to edit.", {
        variant: "error",
      });
      return;
    }
    return editTaskOrDraw({
      task: taskToEdit,
      inputs: EditTaskFormTaskInputs.parse(taskToEdit),
      disabled: !drawsModifiable,
    });
  };

  const onEditLineItemRequested: PendingInvoicesProps["onEditLineItemRequested"] =
    ({ invoice, task }) => {
      const item =
        invoice.drawItems.find(
          ({ task: candidateTask }) => candidateTask?.id === task.id
        ) ||
        (() => {
          logAnomaly(new Error("Invoice needs upgrading"), { invoice });
          return invoice.drawItems.find(({ title }) => title === task.title);
        })();
      if (!item) {
        logError(new Error("can't find line item to edit"), {
          task,
          invoice,
        });
        enqueueSnackbar(
          "Sorry, something went wrong. Can't edit this item right now.",
          { variant: "error" }
        );
        return;
      }

      return editTaskOrDraw({
        task,
        draw: {
          amountRequested: item.amount,
          originalRequest: fetchInitialRequest(invoice.invoice_id)(task) ?? 0,
          invoice,
        },
        inputs: EditTaskFormTaskInputs.parse(task),
        disabled: !drawsModifiable,
      });
    };

  // useExtraAdminData(
  //   {
  //     approvers,
  //     finalizedInvoices,
  //     pendingInvoices,
  //   },
  //   setExtraAdminData
  // );

  const handleViewLineItem = ({
    invoice,
    draw,
  }: {
    invoice: Invoice;
    draw: CalculatedDraw;
  }) => {
    const task = draw.task;
    if (!task) {
      logError(new Error("Missing task"), {
        taskId,
        tasks,
      });
    }

    if (!task) {
      enqueueSnackbar("Sorry, something went wrong viewing item.", {
        variant: "error",
      });
      return;
    }

    return editTaskOrDraw({
      task,
      inputs: EditTaskFormTaskInputs.parse(task),
      disabled: true,
      dialogTitle: "Item Details",
      draw: {
        amountRequested: draw.drawRequestAmount,
        originalRequest: fetchInitialRequest(invoice.invoice_id)(task) ?? 0,
        invoice,
      },
    });
  };

  // TODO: These names and arch suck
  const handleEditLineItem = ({
    invoice,
    draw,
  }: {
    invoice: Invoice;
    draw: CalculatedDraw;
  }) => {
    const task = draw.task;
    if (!task) {
      logError(new Error("Missing task"), {
        taskId,
        tasks,
      });
    }

    if (!task) {
      enqueueSnackbar("Sorry, something went wrong editing item.", {
        variant: "error",
      });
      return;
    }

    return editTaskOrDraw({
      task,
      inputs: EditTaskFormTaskInputs.parse(task),
      disabled: !drawsModifiable,
      dialogTitle: "Item Details",
      draw: {
        amountRequested: draw.drawRequestAmount,
        originalRequest: fetchInitialRequest(invoice.invoice_id)(task) ?? 0,
        invoice,
      },
    });
  };

  const handleDeleteInvoiceFile = (
    invoice: Invoice,
    file: StoredFile | LinkedFile
  ): Promise<void> => {
    if (FileWithId.is(file)) {
      // This delete is for generic invoice-wide files; should not be deleting something with an ID
      logAnomaly(new Error("Wasn't expecting to see a project file here"), {
        file,
      });
    } else {
      return (
        (invoice.invoice_id &&
          invoiceFns(invoice.invoice_id)?.deleteFile(file)) ||
        Promise.reject(new Error("invoice deletion function missing"))
      );
    }

    return Promise.reject(new Error("Unexpected type for invoice file"));
  };

  const handleDeleteLineItemFile = (
    file: PersistentProjectFile
  ): Promise<void> => {
    if (!FileWithId.is(file)) {
      logAnomaly(new Error("Expected to see a project file here"), { file });
      return Promise.reject(new Error("Unexpected type for line item file"));
    }

    return removeFile(file);
  };

  const [ingredientFiles, setIngredientFiles] = React.useState<LocalTaskFile[]>(
    []
  );

  React.useEffect(() => {
    const snackbar =
      errors &&
      enqueueSnackbar({
        message: "Error fetching draws. We're on it.",
        variant: "warning",
      });
    if (snackbar) {
      logAnomaly(new Error("InvoiceView error fetching invoices"), {
        errors,
        invoiceErrors: errors?.invoices,
        json: JSON.stringify(errors),
      });
    }
    if (!errors && snackbar) {
      closeSnackbar(snackbar);
    }
  }, [errors, enqueueSnackbar, closeSnackbar]);

  const [progress, setProgress] = useProgress();

  const existingTaskFiles = React.useMemo(
    () =>
      existingFiles.filter(
        (f) =>
          f.task_id === taskId &&
          (invoiceId ? f.invoice_id === invoiceId : !f.invoice_id)
      ),
    [existingFiles, invoiceId, taskId]
  );

  const invoiced = React.useMemo(
    () => hasBeenInvoiced(invoices)(taskOrDrawBeingEdited?.task),
    [invoices, taskOrDrawBeingEdited?.task]
  );

  return (
    <div style={root}>
      <HorizontallyAligned>
        {canEditInvoiceRouting ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button sx={buttonCTA} onClick={handleEditProjectRouting}>
              <strong>EDIT DRAW ROUTING</strong>
            </Button>
            <PeopleRoutingEditor
              open={routingEditorOpen}
              onClose={closeRoutingEditor}
              addablePeople={projectUsers || []}
              busy={Boolean(loading)}
              onSubmit={handleSubmitRouting}
              initialPeople={approvers}
              title="Draw Approval Routing"
            />
          </div>
        ) : null}
        <FormControl
          variant="standard"
          fullWidth
          sx={{
            width: "50vw",
            fontSize: "2em",
            borderRadius: "80%",
          }}
        >
          <Select
            variant="standard"
            value={invoiceTypesDisplayed}
            id="invoice-type"
            onChange={handleInvoiceCategoryChange}
          >
            <MenuItem value={ApprovalStatus.Pending}>Pending</MenuItem>
            <MenuItem value={ApprovalStatus.Approved}>Approved</MenuItem>
            <MenuItem value={ApprovalStatus.Rejected}>Rejected</MenuItem>
          </Select>
        </FormControl>
        {invoiceTypesDisplayed === ApprovalStatus.Approved && tasks ? (
          <FinalizedInvoices
            invoices={
              categorizedInvoices[
                ApprovalStatus.Approved
              ] as NotPendingInvoice[]
            }
            tasks={tasks}
            filenameHint={address}
            reverseInvoice={(arg) =>
              invoiceFns(arg.invoiceId)
                ?.reverse()
                .then(() => setInvoiceTypeDisplayed(ApprovalStatus.Pending)) ??
              Promise.reject("not ready")
            }
            files={existingFiles}
            projectId={projectId}
            onTitleClicked={handleViewLineItem}
          />
        ) : null}
        {invoiceTypesDisplayed === "Rejected" && tasks ? (
          <FinalizedInvoices
            invoices={
              categorizedInvoices[
                ApprovalStatus.Rejected
              ] as NotPendingInvoice[]
            }
            tasks={tasks}
            filenameHint={address}
            files={existingFiles}
            projectId={projectId}
            onTitleClicked={handleViewLineItem}
          />
        ) : null}
        {invoiceTypesDisplayed === "Pending" && tasks ? (
          <PendingInvoices
            invoices={
              categorizedInvoices[ApprovalStatus.Pending] as PendingInvoice[]
            }
            projectId={projectId}
            filenameHint={address}
            tasks={tasks}
            onSomethingChanged={() => {
              refreshTasks(); // need new available and previous request info
            }}
            files={existingFiles}
            onEditLineItemRequested={onEditLineItemRequested}
            onTitleClicked={handleEditLineItem}
            onDeleteInvoiceFile={handleDeleteInvoiceFile}
            onDeleteLineItemFile={handleDeleteLineItemFile}
          />
        ) : null}

        <CreateDrawRequest
          loading={!tasks}
          project={
            {
              ...props.data,
              retention: Number(props.data.retention),
            } /* TODO: sanitize on input */
          }
          onRefreshNeeded={(reason) => {
            if (reason === "Invoice Created") {
              setIngredientFiles([]);
              setInvoiceTypeDisplayed(ApprovalStatus.Pending);
            } else if (reason === "Task Deleted") {
              refreshTasks();
            }
          }}
          onTaskCompleteRequested={
            canCompleteTasks
              ? (taskId, completed) =>
                  TaskAPIs.markTaskCompleted(
                    projectId,
                    taskId,
                    completed,
                    throwIfNot(email, "should never get here without a user")
                  ).then(refreshTasks)
              : undefined
          }
          onTaskEditRequested={onTaskEditRequested}
          onTaskViewRequested={onTaskEditRequested}
          onTaskDeleteRequested={handleDeleteTaskRequested}
          files={ingredientFiles}
          onDeleteFile={(file) => {
            setIngredientFiles((files) => {
              const newLocal = files.filter((f) => f.href !== file.href);
              return newLocal;
            });
            return Promise.resolve();
          }}
        />
      </HorizontallyAligned>
      <Dialog
        open={Boolean(taskOrDrawBeingEdited)}
        onClose={closeEditTaskDialog}
        TransitionComponent={SlideUp}
        disableEscapeKeyDown
      >
        <DialogTitle>
          {taskOrDrawBeingEdited?.dialogTitle ??
            (taskOrDrawBeingEdited?.draw ? "Update Draw Item" : "Update Task")}
        </DialogTitle>
        {taskOrDrawBeingEdited ? (
          <EditTaskForm
            taskTitleEditable={!invoiced}
            disabled={
              taskOrDrawBeingEdited.disabled ||
              (!canEditTasks && !taskOrDrawBeingEdited.draw)
            }
            taskData={{
              ...taskOrDrawBeingEdited.inputs,
              task_value: backOutRetention(
                taskOrDrawBeingEdited.inputs.task_value,
                retention || 0
              ),
              invoiced_amount: taskOrDrawBeingEdited.task.invoiced_amount,
              requested_amount: taskOrDrawBeingEdited.task.requested_amount,
            }}
            draw={taskOrDrawBeingEdited.draw}
            onSubmit={async ({ taskData, drawData, dirtyFields, files }) => {
              const draw = taskOrDrawBeingEdited.draw;
              if (!taskId) {
                throw new Error("logic error: no task Id?");
              }

              if (
                taskOrDrawBeingEdited.inputs.title !== taskData.title &&
                invoiced
              ) {
                alert(`Task cannot be renamed because it has been invoiced`);
                return;
              }

              try {
                if (draw) {
                  setLoading("files");
                  const uploadedFiles = await uploadSupportingFiles({
                    files,
                    prefix: `${projectId}/t/${taskId}${
                      invoiceId ? `/i/${invoiceId}` : ""
                    }`,
                    progress: setProgress,
                  });
                  setLoading("other stuff");

                  await addFiles(
                    uploadedFiles.map((storedFile) => ({
                      storedFile,
                      email: throwIfNot(
                        email,
                        "should never get here without a user"
                      ),
                      invoiceId,
                      taskId,
                    }))
                  );
                } else {
                  setIngredientFiles((existing) =>
                    existing.concat(
                      files.map((f) => ({
                        title: f.name as NonEmptyString,
                        href: URL.createObjectURL(f) as NonEmptyString,
                        id: taskId,
                        file: f,
                      }))
                    )
                  );
                }

                if (draw) {
                  if (drawData && dirtyFields.includes("amountRequested")) {
                    const invoice = draw.invoice;
                    await invoiceFns(invoice.invoice_id)?.updateDraws(
                      invoice.drawItems.map((existing) =>
                        taskOrDrawBeingEdited.task.id === existing.task?.id
                          ? { ...existing, amount: drawData.amountRequested }
                          : existing
                      )
                    );
                  }
                } else {
                  if (dirtyFields.includes("completed")) {
                    await completeTask(taskId, taskData.completed);
                  }
                  if (
                    dirtyFields.some(
                      (field) =>
                        field !== "completed" && field !== "amountRequested"
                    )
                  ) {
                    await updateTask(taskId, taskData);
                  }
                }
                closeEditTaskDialog();
              } catch (error) {
                // we don't really expect 304 NOT_MODIFIED, but we don't prevent useless requests
                if ((error as APIError).response?.status !== 304) {
                  logError(error);
                  enqueueSnackbar(`Could not edit ${draw ? "draw" : "task"}`, {
                    variant: "error",
                  });
                }
              } finally {
                setLoading(false);
              }
            }}
            onCancel={closeEditTaskDialog}
            onDelete={
              handleDeleteTaskRequested && !taskOrDrawBeingEdited.draw
                ? () => handleDeleteTaskRequested(taskOrDrawBeingEdited.task.id)
                : undefined
            }
            existingFiles={existingTaskFiles}
          />
        ) : null}
      </Dialog>
      {canDeleteTasks && taskToDelete ? (
        <ConfirmDialog
          open={true}
          title="Delete Task"
          setOpen={(open: boolean) => {
            if (!open) {
              setTaskToDelete(undefined);
            }
          }}
          onConfirm={() =>
            deleteTask(taskToDelete.id)
              .then(() => {
                setTaskToDelete(undefined);
              })
              .catch((err: Error) => {
                console.log(err);
                alert(err.message);
              })
          }
        >
          Are you sure you want to delete "{taskToDelete.title}"?
        </ConfirmDialog>
      ) : null}
      <UploadProgressDialog progress={progress} open={loading === "files"} />
    </div>
  );
}

export const TabNames = Object.values(ApprovalStatus);
