import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import { Auth } from "aws-amplify";
import React from "react";
// import { useAppContext } from "@lib/UserContext";
import { useAuth } from "@lib/hooks/useAuth";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";

export const ConfirmUserDialog: (props: {
  open: boolean;
  onClose: (email: string, password: string) => void;
  onConfirmed: (email: string, password: string) => void;
  email: string;
  password: string;
  codeSentTo?: string;
}) => JSX.Element = ({
  open,
  onClose,
  onConfirmed,
  email,
  password,
  codeSentTo: defaultCodeSentTo,
}) => {
  const [confirmCode, setConfirmCode] = React.useState("");
  const [busy, setBusy] = React.useState(0);
  const [helperPrefix, setHelperPrefix] = React.useState("");
  const [codeSentTo, setCodeSentTo] = React.useState(
    defaultCodeSentTo ?? "you"
  );
  const { signIn } = useAuth();

  async function sendNewCode() {
    try {
      setBusy((busy) => busy + 1);
      const resendResult = await Auth.resendSignUp(email.trim().toLowerCase());
      setHelperPrefix(`you. A new code has been sent to `);
      setCodeSentTo(resendResult.CodeDeliveryDetails.Destination);
      setConfirmCode("");
    } catch (err) {
      alert(`error resending code: ${(err as Error).message}`);
    } finally {
      setBusy((busy) => busy - 1);
    }
  }

  const handleConfirmationCode = async (event: {
    preventDefault: () => void;
  }) => {
    event.preventDefault();

    try {
      setBusy((busy) => busy + 1);
      await Auth.confirmSignUp(email, confirmCode);
      await signIn(email, password);
      setBusy((busy) => busy - 1);
      onConfirmed(email, password);
    } catch (e) {
      console.log(e);
      alert((e as Error).message);
      setHelperPrefix("");
      setBusy((busy) => busy - 1);
      if ((e as { code?: string }).code === "NotAuthorizedException") {
        onClose(email, password);
      }
    }
  };
  return (
    <Dialog open={open} aria-labelledby="conf-dialog-label">
      <DialogTitle id="conf-dialog-label">Enter Confirmation Code</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please enter the confirmation code sent to {helperPrefix}
          {codeSentTo}
        </DialogContentText>
        <TextField
          variant="standard"
          id="confirm-code"
          label="Confirmation Code"
          name="confirm-code"
          // type="password"
          fullWidth
          required
          value={confirmCode}
          // onKeyDown={ifEnterKey(handleConf)}
          onChange={({ target: { value } }) => setConfirmCode(value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={sendNewCode} color="primary" disabled={busy > 0}>
          Request New Code
        </Button>
        <Button
          onClick={() => onClose(email, password)}
          color="primary"
          disabled={busy > 0}
        >
          Cancel
        </Button>
        <Button
          onClick={handleConfirmationCode}
          color="primary"
          disabled={busy > 0}
        >
          {busy ? <CircularProgress /> : "Confirm"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const SignUp: (props: {
  open: boolean;
  onComplete: (email: string, password: string) => void;
  onClose: (email: string, password: string) => void;
}) => JSX.Element = ({ open, onClose, onComplete }) => {
  const [creatingUser, setCreatingUser] = React.useState(false);
  const [confirmationHint, setConfirmationHint] = React.useState<
    string | false
  >(false);

  const createUser = async ({
    email,
    password,
    confirm,
  }: {
    email: string;
    password: string;
    confirm: string;
  }) => {
    setCreatingUser(true);

    try {
      const signUpResult = await Auth.signUp({
        username: email,
        password,
      });
      setConfirmationHint(
        ` sent to ${signUpResult.codeDeliveryDetails.Destination}`
      );
    } catch (err) {
      console.log(err);
      alert((err as Error).message);
    } finally {
      setCreatingUser(false);
    }
  };

  const { control, handleSubmit, formState, watch } = useForm({
    defaultValues: { email: "", password: "", confirm: "" },
    resolver: zodResolver(
      z
        .object({
          email: z.string().email(),
          password: z.string().min(8),
          confirm: z.string().min(8),
        })
        .refine((data) => data.password === data.confirm, {
          message: "Passwords don't match",
          path: ["confirm"], // path of error
        })
    ),
  });
  const { errors } = formState;
  const { email, password } = watch();

  const handleConfirmedUser = () => {
    setCreatingUser(false);
    onComplete(email, password);
  };

  return (
    <div>
      <Dialog open={open}>
        <DialogTitle>Sign Up</DialogTitle>
        <form onSubmit={handleSubmit(createUser)}>
          <DialogContent>
            <Controller
              control={control}
              name="email"
              render={(props) => (
                <TextField
                  variant="standard"
                  {...props}
                  id="email"
                  label="Email Address"
                  type="email"
                  error={Boolean(errors.email)}
                  helperText={errors.email?.message}
                  fullWidth
                  required
                />
              )}
            />
            <Controller
              control={control}
              name="password"
              render={(props) => (
                <TextField
                  variant="standard"
                  {...props}
                  id="password"
                  label="Password"
                  type="password"
                  error={Boolean(errors.password)}
                  helperText={errors.password?.message}
                  fullWidth
                  // helperText="Password needs to be a minimum of 8 characters."
                  required
                />
              )}
            />

            <Controller
              control={control}
              name="confirm"
              render={(props) => (
                <TextField
                  variant="standard"
                  {...props}
                  id="password-confirm"
                  label="Confirm Password"
                  type="password"
                  error={Boolean(errors.confirm)}
                  helperText={errors.confirm?.message}
                  fullWidth
                  // onKeyDown={ifEnterKey(handleSubmit)}
                  required
                />
              )}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => onClose(email, password)} color="primary">
              Cancel
            </Button>
            <Button
              type="submit"
              color="primary"
              disabled={creatingUser /* || !isValid */}
            >
              {creatingUser ? <CircularProgress /> : "Sign Up"}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <ConfirmUserDialog
        open={Boolean(confirmationHint)}
        email={email}
        password={password}
        onClose={() => {
          setConfirmationHint(false);
          onClose(email, password);
        }}
        onConfirmed={handleConfirmedUser}
        codeSentTo={confirmationHint || undefined}
      />
    </div>
  );
};
