import { Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import { CSVLink } from "react-csv";
import { LinkProps } from "react-csv/components/Link";
import csvDownloadIcon from "../Assets/Images/csvDownload.png";
import { bigAssIcon } from "../projectCenterlineTheme";

const LargeIconSizedImage = styled("img")(({ theme }) => bigAssIcon(theme));

// https://github.com/react-csv/react-csv/issues/323
const LinkWithRef = React.forwardRef(
  (props: JSX.IntrinsicClassAttributes<CSVLink> & Readonly<LinkProps>, ref) => (
    <CSVLink
      uFEFF={false /* spell-checker:ignore uFEFF */}
      {...props}
      ref={(c: unknown) => {
        const foo = c as { link: HTMLAnchorElement };
        ref && typeof ref === "function" && foo?.link && ref(foo?.link);
      }}
    >
      {props?.children}
    </CSVLink>
  )
);
export function CSVExport(
  props: JSX.IntrinsicClassAttributes<CSVLink> & Readonly<LinkProps>
): JSX.Element {
  return (
    <Tooltip title={props.title || "Export to CSV"} aria-label="export">
      <LinkWithRef
        {...props}
        target="_blank"
        rel="noopener"
        ref={() => {
          /* no op */
        }}
      >
        <LargeIconSizedImage src={csvDownloadIcon} alt="Download CSV" />
      </LinkWithRef>
    </Tooltip>
  );
}
