import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import React from "react";
import { z } from "zod";
import { ifEnterKey, IfEnterKeyEventHandler } from "@lib/boilerplate";

const errorMap = () => ({
  message: "Please specify Residential or Commercial",
});

export const ZodSchema = z.object({
  propertyType: z
    .union([z.literal("cml", { errorMap }), z.literal("res")], {
      errorMap,
    })
    .nullable(),
  units: z.coerce.number().int().min(1).nullable(),
});

export function PropertyTypeForm({
  onLastFieldEntered,
}: {
  onLastFieldEntered?: IfEnterKeyEventHandler;
}) {
  const { control, formState } = useFormContext<z.infer<typeof ZodSchema>>();
  const { errors } = formState;
  return (
    <Grid container spacing={2} alignItems="end">
      <Grid item>
        <Controller
          name="propertyType"
          control={control}
          render={({ field: { ref, ...rest } }) => (
            <FormControl error={!!errors.propertyType?.message}>
              {/* <FormLabel
                      id="property-type-label"
                      sx={{ textAlign: "left", }}
                    >
                      Property Type
                    </FormLabel> */}
              <RadioGroup
                {...rest}
                row
                // aria-labelledby="property-type-label"
                name="property-type-group"
              >
                <FormControlLabel
                  value="res"
                  control={<Radio size="small" />}
                  label="Residential"
                />
                <FormControlLabel
                  value="cml"
                  control={<Radio size="small" />}
                  label="Commercial"
                />
              </RadioGroup>
              <FormHelperText>
                {errors.propertyType?.message ?? ""}
              </FormHelperText>
            </FormControl>
          )}
        />
      </Grid>

      <Grid item>
        <Controller
          name="units"
          control={control}
          render={({ field: { ref, value, ...rest } }) => (
            <FormControl variant="standard">
              <TextField
                variant="standard"
                type="number"
                fullWidth
                id="units"
                label="Number of Units"
                // inputRef={multiRef([ref, addressRef])}
                {...rest}
                value={value === null ? "" : value}
                onKeyDown={
                  onLastFieldEntered
                    ? ifEnterKey(onLastFieldEntered)
                    : undefined
                }
                helperText={errors.units?.message}
                error={Boolean(errors.units?.message)}
              />
            </FormControl>
          )}
        />
      </Grid>
    </Grid>
  );
}
