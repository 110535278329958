import React, { PropsWithChildren } from "react";
import { BackendGetCommentsResponse } from "@/Lib/APITypes";
import ClearIcon from "@mui/icons-material/Clear";
import { CircularProgress, IconButton, Stack, Tooltip } from "@mui/material";
import { useSnackbar } from "notistack";
import { logError } from "@/Lib/ErrorLogging";

type TComment = BackendGetCommentsResponse;

type CommentRemover = ((x: TComment["comment_id"]) => Promise<unknown>) | false;

export const CommentHeader = ({
  commenter_email: email,
  comment_id,
  onRemoveComment,
}: Pick<TComment, "commenter_email" | "comment_id"> & {
  onRemoveComment?: CommentRemover;
}) => {
  const [deleting, setDeleting] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  return (
    <Stack
      direction="row"
      style={{ wordWrap: "break-word" }}
      justifyContent="space-between"
      alignItems="center"
    >
      <strong>{email}</strong>
      {onRemoveComment ? (
        deleting ? (
          <CircularProgress />
        ) : (
          <Tooltip title="Delete comment">
            <IconButton
              onClick={() => {
                setDeleting(true);
                onRemoveComment(comment_id)
                  .catch((err) => {
                    enqueueSnackbar({
                      message:
                        "Oops; something went wrong. Comment not deleted.",
                      variant: "error",
                    });
                    logError(new Error("Error deleting comment"), { err });
                  })
                  .finally(() => {
                    setDeleting(false);
                  });
              }}
            >
              <ClearIcon color="secondary" />
            </IconButton>
          </Tooltip>
        )
      ) : (
        <div />
      )}
    </Stack>
  );
};

export const CommentBody = ({ message }: Pick<TComment, "message">) => (
  <p style={{ whiteSpace: "pre-wrap" }}>{message}</p>
);

export const CommentContainer = ({
  key,
  children,
}: PropsWithChildren<{ key?: React.Key }>) => <div key={key}>{children}</div>;

export const CommentEntry = ({
  comment,
  onRemoveComment,
}: {
  comment: TComment;
  onRemoveComment: CommentRemover;
}) => (
  <CommentContainer key={comment.comment_id}>
    <CommentHeader {...comment} onRemoveComment={onRemoveComment} />
    <CommentBody {...comment} />
  </CommentContainer>
);

export const Comment = {
  Header: CommentHeader,
  Body: CommentBody,
  Container: CommentContainer,
};

export default TComment;
