import { ApprovalStatus } from "@lib/APITypes";
import { Invoice, Task } from "../../../Models";

// This exists primarily because changing the name on something that was used in a draw would break things,
// when we used task names as keys. That's mostly gone, but maybe not on staging.  Also, we use this to
// not let you edit amount or description in similar situations. That may or may not be the right thing to do
// TODO: I think once all draws refer to tasks by ID we will be able to allow renaming invoiced items
export const hasBeenInvoiced =
  (invoices?: ReadonlyArray<Pick<Invoice, "drawItems" | "approval_status">>) =>
  (taskInQuestion?: Pick<Task, "id">) => {
    return (
      taskInQuestion &&
      invoices?.some(
        ({ drawItems, approval_status }) =>
          ["Pending", ApprovalStatus.Approved].includes(approval_status) &&
          drawItems.some(({ task }) => task?.id === taskInQuestion.id)
      )
    );
  };
