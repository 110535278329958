import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import { CircularProgress } from "@mui/material";
import { logError } from "@lib/ErrorLogging";
import { useBackend } from "@lib/hooks";
import { ChatData } from "@lib/API";
import { useAuth } from "@lib/hooks/useAuth";

const PREFIX = "IndividualChat";

const classes = {
  root: `${PREFIX}-root`,
  chatContainer: `${PREFIX}-chatContainer`,
  margin: `${PREFIX}-margin`,
};

const Root = styled("div")({
  [`&.${classes.root}`]: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "calc(100vh - 254px)",
  },
  [`& .${classes.chatContainer}`]: {
    height: "80%",
    overflow: "scroll",
  },
  [`& .${classes.margin}`]: {
    display: "flex",
  },
});

export default function IndividualChat(props: {
  socket: WebSocket;
  chat: { chat_room_id: string; users: string[] };
  /** project id, despite the name */
  project: string;
}): JSX.Element {
  const { currentUserEmail: email } = useAuth();
  const [message, setMessage] = React.useState("");
  const [chats, setChats] = React.useState<ChatData[]>([]);
  const [loading, setLoading] = React.useState(false);
  const { getChats } = useBackend();

  const handleMessage = (event: { target: { value: string } }) => {
    setMessage(event.target.value);
  };

  useEffect(() => {
    props.socket.onopen = () => {
      console.log("open connection");
      props.socket.send(
        JSON.stringify({
          action: "subscribeToChannel",
          chat_room_id: props.chat.chat_room_id,
          user: email,
        })
      );
    };
    props.socket.onclose = () => {
      console.log("disconnect");
      props.socket.send(JSON.stringify({ action: "$disconnect", user: email }));
    };
  });

  useEffect(() => {
    props.socket.onmessage = (message: { data: string }) => {
      const chatData = JSON.parse(message.data);
      setMessage("");
      setLoading(false);
      setChats((chats) => [...chats, chatData]);
    };
  });

  const sendMessage = () => {
    props.socket.send(
      JSON.stringify({
        action: "sendMessage",
        chat_room_id: props.chat.chat_room_id,
        project_id: props.project,
        message: message,
        user: email,
      })
    );
  };

  const trySendMessage = () => {
    if (props.socket.readyState === 1) {
      sendMessage();
    } else {
      setTimeout(() => {
        trySendMessage();
      }, 1000);
    }
  };

  const addChat = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    trySendMessage();
    setLoading(true);
  };

  useEffect(() => {
    getChats({ chatRoomId: props.chat.chat_room_id })
      .then(setChats)
      .catch((error) => {
        logError(error);
        console.log(error);
      });
  }, [getChats, props.chat.chat_room_id]);

  return (
    <Root className={classes.root}>
      <p>
        <strong>Users In Chat: </strong>
        {props.chat.users.map((user, key) => {
          if (props.chat.users.length === key + 1) {
            return <span key={key}>{user}</span>;
          } else {
            return <span key={key}>{user + ", "}</span>;
          }
        })}
      </p>
      <div className={classes.chatContainer}>
        {chats.map((chat, key) => {
          return (
            <div key={key}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <p style={{ marginBottom: "0px" }}>
                  <strong>{chat.sender_name}</strong>: {chat.sender_company}
                </p>
                <p style={{ marginBottom: "0px" }}>
                  {new Date(chat.created_at).toString().split("G").shift()}
                </p>
              </div>
              <p style={{ marginTop: "0px" }}>{chat.message}</p>
            </div>
          );
        })}
      </div>
      <div className="chat">
        <form className={classes.margin} onSubmit={addChat}>
          <FormControl variant="standard" fullWidth>
            <TextField
              required
              fullWidth
              value={message}
              variant="outlined"
              id="chat-message"
              name="chat-message"
              onChange={handleMessage}
            />
          </FormControl>
          <Button
            type="submit"
            color="primary"
            variant="outlined"
            disabled={loading}
          >
            {loading ? <CircularProgress /> : "Send"}
          </Button>
        </form>
      </div>
    </Root>
  );
}
