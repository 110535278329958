import useSWR, { mutate as globalMutate } from "swr";
import { useBackend } from ".";
import { BackendGetCommentsResponse } from "../APITypes";
import { FetchError } from "../API";
import { Invoice } from "../../Models";
import { Decision } from "../../Pages/ProjectView/DecisionMatrix/DisplayDecisions";
import { useAuth } from "@lib/hooks/useAuth";
import { SWRKeys } from "../swrKeys";
import React from "react";

type InvoiceId = Invoice["invoice_id"];

export function useInvoiceComments({ invoiceId }: { invoiceId?: InvoiceId }) {
  const {
    getComments,
    addComment: backendAddComment,
    deleteComment,
  } = useBackend();
  const { currentUserEmail: email } = useAuth();

  const { data, error } = useSWR<BackendGetCommentsResponse[], FetchError>(
    SWRKeys.invoice(invoiceId).comments,
    (_url: unknown) =>
      invoiceId
        ? getComments({ invoiceId, decisionId: null })
        : Promise.reject(
            new Error(
              "fetching invoice comment without invoice. You should not be able to get here."
            )
          )
  );

  return React.useMemo(() => {
    /**
     * Add a comment to an invoice
     * @param arg.invoiceId invoice to target.
     * @returns function for adding comment
     */
    const addCommentImpl =
      (invoiceId: InvoiceId) => async (message: string) => {
        if (!invoiceId) {
          throw new Error("invoiceId is required");
        }

        // leaving this commented out because (a) not quite there and b) maybe we want them to wait, i this case.
        //   if (files) {
        //      // update the local data immediately, but disable the revalidation
        //      mutate(`/api/project/${projectId}/files`, files.userFiles.concat([{ ...fileName, project_id: projectId}]), false)
        //   }
        // wait for the backend to store
        if (!email) {
          throw new Error("Missing email for comment");
        }
        /* const addedFile = */ await backendAddComment({
          invoiceId,
          decisionId: null,
          message,
          email,
        });

        // trigger a revalidation (refetch) to make sure our local data is correct
        globalMutate(SWRKeys.invoice(invoiceId).comments);

        // return [addedFile];
      };
    const addComment = invoiceId ? addCommentImpl(invoiceId) : undefined;

    const addCommentToInvoice = (
      explicitInvoiceId: InvoiceId,
      message: string
    ) => addCommentImpl(explicitInvoiceId)(message);

    const removeComment = async (commentId: string) => {
      await deleteComment(commentId);
      if (invoiceId) {
        globalMutate(SWRKeys.invoice(invoiceId).comments);
      }
    };

    const comments = data;

    return {
      comments,
      addComment,
      /**
       * Alternate way of adding comments, for when you didn't know the invoice ID when calling the hook (e.g. in a .then() right after creating it)
       * @param invoiceId Invoice ID
       * @param message comment string
       * @returns Promise<void>
       */
      addCommentToInvoice,
      removeComment,
      error,
    };
  }, [backendAddComment, data, deleteComment, email, error, invoiceId]);
}

type DecisionId = Decision["decision_id"];

export function useDecisionComments(decisionId: DecisionId) {
  const key = SWRKeys.decision(decisionId).comments();
  const {
    getComments,
    addComment: backendAddComment,
    deleteComment,
  } = useBackend();
  const { currentUserEmail: email } = useAuth();

  const { data, error } = useSWR<BackendGetCommentsResponse[], FetchError>(
    () => key,
    (_url: unknown) =>
      getComments({
        decisionId,
        invoiceId: null,
      })
  );

  return React.useMemo(() => {
    const addComment = async (message: string) => {
      // leaving this commented out because (a) not quite there and b) maybe we want them to wait, i this case.
      //   if (files) {
      //      // update the local data immediately, but disable the revalidation
      //      mutate(`/api/project/${projectId}/files`, files.userFiles.concat([{ ...fileName, project_id: projectId}]), false)
      //   }
      // wait for the backend to store
      if (!email) {
        throw new Error("Missing email for comment");
      }
      /* const addedFile = */ await backendAddComment({
        decisionId,
        invoiceId: null,
        message,
        email,
      });

      // trigger a revalidation (refetch) to make sure our local data is correct
      globalMutate(key);

      // return [addedFile];
    };

    const comments = data;

    const removeComment = async (commentId: string) => {
      await deleteComment(commentId);
      if (decisionId) {
        globalMutate(SWRKeys.decision(decisionId).comments);
      }
    };

    return {
      comments,
      addComment,
      removeComment,
      error,
    };
  }, [backendAddComment, data, decisionId, deleteComment, email, error, key]);
}
